import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Table,
  Upload,
  Typography,
  Select,
  Input,
  Row,
  Col,
  InputNumber,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  Space,
  Tabs,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./CreateLot.scss";

// Excel Render
import { ExcelRenderer } from "react-excel-renderer";

// componentes
import FRM from "../../../utils/FrmService";
import { reloadData } from "../../../utils/ReloadEnv";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";

export default function CreateLot(props) {
  const {
    currentUser,
    closeModal,
    setIsLoading,
    myNumber,
    detalleCarga,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [usrId, setUsrId] = useState(currentUser.id);
  const [idOrigen, setIdOrigen] = useState(detalleCarga.cmp_id);
  const [idCampo, setIdCampo] = useState(0);
  const [animales, setAnimales] = useState("");
  const [area, setArea] = useState("");
  const [areaCtrl, setAreaCtrl] = useState(0);
  const [nota, setNota] = useState("");
  const [fechaEntr, setFechaEntr] = useState(null);
  const [data, setData] = useState([]);
  const [dataDef, setDataDef] = useState([]);
  const [data2, setData2] = useState([]);
  const [dataCmp, setDataCmp] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [tipoCarga, setTipoCarga] = useState(0);
  const [stateFld, setStateFld] = useState(Math.random());
  const [caravana, setCaravana] = useState("");
  const [dataErr, setDataErr] = useState([]);

  const { Option } = Select;
  const [form] = Form.useForm();
  const { TabPane } = Tabs;

  const fieldsList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.campos
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  useEffect(() => {
    (async () => {
      setIdOrigen(detalleCarga.cmp_id);
      getDetalleCampo(detalleCarga.cmp_id, detalleCarga.cmb_numero);
    })();
  }, [stateFld]);

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaEntr(dateString);
  }

  //Se actualiza el nombre
  const updateCampo = async (value) => {
    setIdCampo(value);
    getAreaFld(fieldsList, value);
  };

  const onChangeCaravana = (e) => {
    let dato = e.target.value;
    if (dato.length > 0) {
      setAnimales(1);
      setCaravana(dato.toUpperCase());
    } else {
      setAnimales(0);
      setCaravana("");
    }
    setDataDef([]);
    setData2([]);
    setData([]);
    setDataErr([]);
    setTipoCarga(0);
  };

  const onChangeAnimales = (value) => {
    if (tipoCarga === 0) {
      setCaravana("");
      if (Number.parseInt(value) >= 0) {
        if (Number.parseInt(value) <= Number.parseInt(dataDef.length)) {
          setAnimales(value);
        } else {
          message.error("CANTIDAD INCORRECTA", 1);
          return false;
        }
      } else {
        message.error("VALOR INCORRECTO!", 1);
        return false;
      }
    } else {
      message.error(
        "LOS ANIMALES VIENEN DEL BASTON... NO SE PUEDE CAMBIAR EL NUMERO!",
        2
      );
      return false;
    }
  };

  const onChangeArea = (value) => {
    if (Number.parseFloat(value) >= 0) {
      if (Number.parseFloat(value) <= Number.parseFloat(areaCtrl)) {
        setArea(value);
      } else {
        message.error("AREA INCORRECTA", 1);
        return false;
      }
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  const selectAnimalesFld = () => {
    setDataDef(dataCmp);
    setData2(dataCmp);
    setAnimales(Number.parseInt(dataCmp.length));
    setData([]);
    setDataErr([]);
    setTipoCarga(0);
  };

  //Funcion para traer el area del campo destino
  const getAreaFld = async (listaFld, idFld) => {
    console.log(listaFld);
    listaFld.forEach((arrayValue) => {
      if (Number.parseInt(arrayValue.id) === Number.parseInt(idFld)) {
        setAreaCtrl(Number.parseFloat(arrayValue.cmp_area));
        setArea(Number.parseFloat(arrayValue.cmp_area));
      }
    });
  };

  const propis = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (fileList.length > 0) {
        setFileList([file]);
      } else {
        setFileList([...fileList, file]);
      }
      UploadExcel(file);
      return false;
    },
    fileList,
  };

  const UploadExcel = async (file) => {
    //Obtengo la informacion del Input File
    let fileObj = file;

    //Render del Excel
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        // Elimino el encabezado del archivo
        var excel = resp.rows.filter(function (item, index, arr) {
          return item[0] !== "Fecha Lectura";
        });
        const bh_excel2 = [];
        excel.forEach(function (item, index, arr) {
          let cod = item[1].toString();
          let codigo = cod.split(" ");
          let dispositivo = codigo[0].substr(-8, 8); //codigo[1].substr(-4, 4) + codigo[2].trim();
          let key = index;
          let anm_codigo = dispositivo.trim();
          let anm_peso_neto = item[2];

          let exceldata = {
            key: key,
            anm_codigo: anm_codigo,
            anm_peso_neto: anm_peso_neto,
            anm_id: 0,
          };

          bh_excel2.push(exceldata);
        });
        setData(bh_excel2);
        setDataDef(bh_excel2);
        setData2([]);
        setDataErr([]);
        setTipoCarga(1);
        validarDatos(bh_excel2);
      }
    });
  };

  //////////////////////////////////
  //Carga de los animales en campo
  const getDetalleCampo = async (idOrg, idCmb) => {
    if (idOrg === 0) {
      showNotification(
        "topRight",
        2,
        "CARGAR ANIMALES",
        "DEBE REGISTRAR EL CAMPO DE ORIGEN"
      );
      setDataDef([]);
      setData2([]);
      setAnimales(0);
      setData([]);
      setDataCmp([]);
      setTipoCarga(0);
      return false;
    }
    setLoadingButton(true);
    let datos = await FRM.getCampoDet(currentUser, idOrg, idCmb);
    if (datos.type && datos.type > 0) {
      setDataCmp(datos.listado);
      setDataDef([]);
      setData2([]);
      setAnimales(0);
      setData([]);
      setTipoCarga(0);
    } else {
      showNotification(
        2,
        "ANIMALES EN CAMPO",
        "No hay animales ACTIVOS EN EL CAMPO... Verifique!"
      );
    }
    setLoadingButton(false);
  };
  /////////////////////////////////

  // ENVIO A WEBSERVICES
  const uploadLotWS = async () => {
    if (idOrigen === idCampo) {
      showNotification(
        "topRight",
        2,
        "CAMBIO DE LOTE",
        "EL ORIGEN Y EL DESTINO DEBEN SER DIFERENTES"
      );
      return false;
    }
    setLoadingButton(true);
    if (
      Number.parseInt(idOrigen) === 0 ||
      idOrigen === "SELECCIONE ORIGEN" ||
      Number.parseInt(idCampo) === 0 ||
      idCampo === "SELECCIONE DESTINO" ||
      Number.parseInt(animales) === 0 ||
      Number.parseInt(area) === 0 ||
      fechaEntr.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "CAMBIO DE LOTE",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      let record = {
        usr_id: currentUser.id,
        cmb_id: detalleCarga.cmb_numero,
        cmp_old: detalleCarga.cmp_id,
        org_id: idOrigen,
        cmp_id: idCampo,
        cmb_cantidad: dataDef.length,
        cmb_animales: animales,
        cmb_area: area,
        cmb_nota: nota,
        cmb_fecha: fechaEntr,
        record_def: dataDef,
        cmb_tipo: tipoCarga,
        anm_caravana: caravana,
      };
      var val_inventory = await FRM.sendLote(currentUser, record);
      console.log(val_inventory);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "CARGAR CARAVANA",
          "REGISTRO DE CARAVANA EXITOSO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CARGAR CARAVANA",
          val_inventory.message
        );
      }
    }
    setData([]);
    setFileList([]);
    setLoadingButton(false);
    closeModal();
  };

  //Validacion de las caravanas del excel
  const validarDatos = async (datosXls) => {
    setLoadingButton(true);
    const validaCaravana = [];
    const validaErr = [];
    datosXls.forEach((row) => {
      let anmId = estaEnArray(row.anm_codigo, dataCmp);
      if (anmId > 0) {
        let newsnig = {
          anm_codigo: row.anm_codigo,
          anm_peso_neto: row.anm_peso_neto,
          anm_id: anmId,
        };
        validaCaravana.push(newsnig);
      } else {
        let newerr = {
          anm_codigo: row.anm_codigo,
          anm_peso_neto: row.anm_peso_neto,
        };
        validaErr.push(newerr);
      }
    });
    setDataDef(validaCaravana);
    setData(validaCaravana);
    setDataErr(validaErr);
    setAnimales(Number.parseInt(validaCaravana.length));
    setLoadingButton(false);
  };

  //Validar la existencia de un animal
  const estaEnArray = (value, array) => {
    let count = 0;
    let idAnm = 0;
    array.forEach((arrayValue) => {
      if (Number.parseInt(arrayValue.anm_codigo) === Number.parseInt(value)) {
        count += 1;
        idAnm = arrayValue.id;
      }
    });
    return count > 0 ? idAnm : 0;
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "ID", //10%
      dataIndex: "anm_id",
      key: "anm_id",
    },
    {
      title: "CARAVANA", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
    },
    {
      title: "PESO Kg",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      width: "20%",
      align: "right",
    },
  ];

  const lerror = [
    {
      title: "CARAVANA", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
    },
    {
      title: "PESO Kg",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      width: "20%",
      align: "right",
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"MOVER ANIMALES DE " + detalleCarga.cmp_nombre}
      </Title>
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item label="Fecha Proceso">
              <DatePicker onChange={onChangeFecha} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Campo Destino">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={
                  idCampo > 0 ? idCampo : "SELECCIONE CAMPO DESTINO"
                }
                placeholder="CAMPO DESTINO"
                onChange={updateCampo}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {fieldsList.map((campo) => (
                  <Option key={campo.id} value={campo.id}>
                    {campo.cmp_describe}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="No. de Animales">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={animales}
                value={animales}
                onChange={onChangeAnimales}
                placeholder="Animales"
              />{" "}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Caravana">
              <Input
                placeholder="Caravana"
                defaultValue={caravana}
                style={{ width: "100%" }}
                value={caravana}
                onChange={onChangeCaravana}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label="Area">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={area}
                value={area}
                onChange={onChangeArea}
                placeholder="Area"
              />{" "}
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <TextArea
              rows={2}
              value={nota}
              placeholder={"Observaciones"}
              onChange={onChangeNota}
              allowClear
            />
          </Col>
        </Row>
      </Form>

      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <div
        style={{
          display: "flex",
          alignContent: "left",
          marginBottom: 15,
          marginTop: 15,
        }}
      >
        <Button
          type="primary"
          shape="round"
          className={curStyle.btnBlueLight}
          loading={loadingButton}
          onClick={() => selectAnimalesFld()}
        >
          <UploadOutlined /> ANIMALES EN CAMPO
        </Button>

        <Upload {...propis}>
          <Button
            type="primary"
            shape="round"
            className={curStyle.btnInfo}
            style={{
              marginLeft: 20,
              display: idOrigen > 0 ? "block" : "none",
            }}
          >
            <UploadOutlined /> ARCHIVO DEL BASTON
          </Button>
        </Upload>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="EXISTENTES" key="1">
          <Table columns={header} dataSource={dataDef} bordered size="middle" />
        </TabPane>
        <TabPane tab="ERRORES" key="2">
          <Table columns={lerror} dataSource={dataErr} bordered size="middle" />
        </TabPane>
      </Tabs>
      <Space>
        <Title
          level={4}
          style={{ color: colorSec, textAlign: "left", marginTop: 15 }}
        >
          {"Animales en CAMPO: " + myNumber(1, data2.length)}
        </Title>
        <Title
          level={4}
          style={{
            color: colorGrn,
            textAlign: "left",
            marginLeft: 30,
            marginTop: 15,
          }}
        >
          {"Registros BASTON: " + myNumber(1, data.length)}
        </Title>
      </Space>
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => uploadLotWS()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            block
            style={{
              display:
                dataDef.length > 0 || caravana.length > 0 ? "block" : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> PROCESAR EL CAMBIO DE LOTE
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
