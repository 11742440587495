import React, { useState, useRef } from "react";
import { Table, Typography, Divider, Tag, Button, Input, Space } from "antd";
import Highlighter from "react-highlight-words";

//Iconos y constantes
import { SearchOutlined } from "@ant-design/icons";

//Styles
import "./Informes.scss";

export default function DetalleEntrada(props) {
  const { currentUser, closeModal, detalleModal, myNumber } = props;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const { Title } = Typography;

  //Nuevas funciones de busqueda de columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //Fin de nuevas funciones de busqueda


  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "No", //10%
      dataIndex: "ent_id",
      key: "ent_id",
      width: "3%",
    },
    {
      title: "FECHA",
      dataIndex: "anm_entrada",
      key: "anm_entrada",
      width: "10%",
    },
    {
      title: "CANTD",
      dataIndex: "numero",
      key: "numero",
      align: "right",
      width: "5%",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.numero));
      },
    },
    {
      title: "Kg ENTRADA",
      dataIndex: "total_ent",
      key: "total_ent",
      align: "right",
      width: "10%",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.total_ent));
      },
    },
    {
      title: "Kg ACTUALES",
      dataIndex: "total_kgm",
      key: "total_kgm",
      align: "right",
      width: "10%",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.total_kgm));
      },
    },
    {
      title: "Kg GENERADOS",
      dataIndex: "total_kgm",
      key: "total_kgm",
      align: "right",
      width: "10%",
      render: (text, row, index) => {
        return row.ent_generada === 0
          ? myNumber(
            1,
            Number.parseFloat(row.total_kgm) -
            Number.parseFloat(row.total_ent)
          )
          : Number.parseFloat(row.total_kgm);
      },
    },
    {
      title: "AVG Kg ENT",
      dataIndex: "total_kgm",
      key: "total_kgm",
      align: "right",
      width: "10%",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.total_ent) / row.numero);
      },
    },
    {
      title: "AVG Kg GEN",
      dataIndex: "total_kgm",
      key: "total_kgm",
      align: "right",
      width: "10%",
      render: (text, row, index) => {
        return row.ent_generada === 0
          ? myNumber(
            1,
            (Number.parseFloat(row.total_kgm) -
              Number.parseFloat(row.total_ent)) /
            row.numero
          )
          : myNumber(1, Number.parseFloat(row.total_kgm) / row.numero);
      },
    },
  ];

  const columns_kg = [
    {
      title: "CARAVANA", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
      ...getColumnSearchProps("anm_codigo"),
      render: (text, row, index) => {
        return row.ent_generada === 0
          ? <Tag style={{ fontSize: 15 }} color="orange">{row.anm_codigo}</Tag>
          : <Tag style={{ fontSize: 15 }} color="green">{row.anm_codigo}</Tag>
      },
    },
    {
      title: "ENTRADA",
      dataIndex: "anm_entrada",
      key: "anm_entrada",
      ...getColumnSearchProps("anm_entrada"),
      render: (text, row, index) => {
        return (
          <Tag style={{ fontSize: 15 }} color="geekblue">{row.anm_entrada}</Tag>
        );
      },
    },
    {
      title: "PESO ENTRA",
      dataIndex: "anm_peso_ent",
      key: "anm_peso_ent",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag style={{ fontSize: 15 }} color="green">{myNumber(
            1,
            Number.parseFloat(row.anm_peso_ent))}</Tag>
        );
      },
    },
    {
      title: "PESO INI",
      dataIndex: "anm_fecha_info_ini",
      key: "anm_fecha_info_ini",
      render: (text, row, index) => {
        return (
          <Tag style={{ fontSize: 15 }} color="cyan">{row.anm_fecha_corte}</Tag>
        );
      },
    },
    {
      title: "PESO INICIAL",
      dataIndex: "anm_peso_info_ini",
      key: "anm_peso_info_ini",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag style={{ fontSize: 15 }} color="orange">{myNumber(
            1,
            Number.parseFloat(row.anm_peso_corte))}
          </Tag>
        );
      },

    },
    {
      title: "ULT PESAJE",
      dataIndex: "anm_fecha_info_fin",
      key: "anm_fecha_info_fin",
      render: (text, row, index) => {
        return (
          <Tag style={{ fontSize: 15 }} color="cyan">{row.anm_fecha_info_fin}</Tag>
        );
      },
    },
    {
      title: "PESO FINAL",
      dataIndex: "anm_peso_info_fin",
      key: "anm_peso_info_fin",
      align: 'right',
      render: (text, row, index) => {
        return (
          <Tag style={{ fontSize: 15 }} color="magenta">{myNumber(
            1,
            Number.parseFloat(row.anm_peso_info_fin))}</Tag>
        );
      },
    },
    {
      title: "KG GEN",
      dataIndex: "peso_act",
      key: "peso_act",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag style={{ fontSize: 15 }} color="blue">{myNumber(
            1,
            Number.parseFloat((row.anm_peso_info_fin - row.anm_peso_corte)) + Number.parseFloat(row.kg_ppo))}
          </Tag>
        );
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        DETALLE KILOS GENERADOS
      </Title>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Table
        columns={columns_kg}
        dataSource={detalleModal}
        bordered
      //scroll={{ x: "calc(1000px + 50%)", y: 640 }}
      />
    </div>
  );
}
