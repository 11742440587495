import React, { useState } from "react";
import {
  Form,
  Modal,
  Button,
  Table,
  Upload,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  Space,
  InputNumber,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ReactExport from "react-export-excel";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./CrearGasto.scss";

// componentes
import FRM from "../../../utils/FrmService";
import { reloadData } from "../../../utils/ReloadEnv";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";

export default function CrearGasto(props) {
  const {
    currentUser,
    closeModal,
    myNumber,
    detalleCarga,
    detalleList,
    listaCnc,
    cuentasEfe,
    cuentasBnc,
    cuentasChk,
    opcionCarga,
    tituloModal,
    id_mov,
    numero_anm,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [usrId, setUsrId] = useState(currentUser.id);
  const [idProveedor, setIdProveedor] = useState(0);
  const [idCnc, setIdCnc] = useState(0);
  const [detalleCnc, setDetalleCnc] = useState("");
  const [valorGto, setValorGto] = useState(0);
  const [idEfe, setIdEfe] = useState(0);
  const [valorEfe, setValorEfe] = useState(0);
  const [idBnc, setIdBnc] = useState(0);
  const [valorBnc, setValorBnc] = useState(0);
  const [nota, setNota] = useState("");
  const [fechaGto, setFechaGto] = useState(null);

  const { Option } = Select;
  const [form] = Form.useForm();

  //Listado de proveedores
  const suppliersList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.servicios
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaGto(dateString);
  }
  //Se actualiza el nombre
  const updateProveedor = async (value) => {
    setIdProveedor(value);
  };

  //Se actualiza el concepto
  const updateCnc = async (value) => {
    setIdCnc(value);
  };
  const onChangeDeta = (e) => {
    const detaCnc = e.target.value;
    setDetalleCnc(detaCnc.toUpperCase());
  };

  //Se actualiza cuenta de efectuvo
  const updateEfe = async (value) => {
    setIdEfe(value);
    setValorEfe(0);
  };

  //Se actualiza cuenta de banco
  const updateBnc = async (value) => {
    setIdBnc(value);
    setValorBnc(0);
  };

  const onChangeGto = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setValorGto(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeEfe = (value) => {
    if (Number.parseFloat(value) >= 0 && Number.parseInt(idEfe) > 0) {
      setValorEfe(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeBnc = (value) => {
    if (Number.parseFloat(value) >= 0 && Number.parseInt(idBnc) > 0) {
      setValorBnc(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const registrarGasto = async () => {
    if (
      Number.parseInt(idProveedor) === 0 ||
      Number.parseInt(idCnc) === 0 ||
      detalleCnc.length === 0 ||
      fechaGto.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "REGISTRAR GASTOS",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      if (Number.parseFloat(valorGto) === 0) {
        showNotification(
          "topRight",
          2,
          "REGISTRAR GASTOS",
          "EL VALOR DEL GASTO NO PUEDE SER CERO"
        );
      } else {
        let record = {
          tipoMov: 1,
          idMov: id_mov,
          numero_anm: numero_anm,
          usr_id: currentUser.id,
          trc_id: idProveedor,
          cnc_id: idCnc,
          efe_id: idEfe,
          bnc_id: idBnc,
          meg_detalle: detalleCnc,
          meg_importe: Number.parseFloat(valorGto),
          meg_efectivo: Number.parseFloat(valorEfe),
          meg_transfer: Number.parseFloat(valorBnc),
          meg_nota: nota,
          meg_fecha: fechaGto,
        };

        var val_gasto = await FRM.sendGasto(currentUser, record);
        if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
          updateEnviroment(val_gasto.datos.rol_entorno);
          showNotification(
            "topRight",
            4,
            "CARGAR GASTOS",
            "REGISTRO DE GASTO EXITOSO"
          );
          closeModal();
        } else {
          showNotification("topRight", 2, "CARGAR GASTOS", val_gasto.message);
          closeModal();
        }
      }
    }
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "FECHA", //10%
      dataIndex: "meg_fecha",
      key: "meg_fecha",
      width: "15%",
    },
    {
      title: "BENEFICIARIO",
      dataIndex: "trc_nombre",
      key: "trc_nombre",
    },
    {
      title: "CONCEPTO",
      dataIndex: "cnc_nombre",
      key: "cnc_nombre",
    },
    {
      title: "VALOR",
      dataIndex: "meg_importe",
      key: "meg_importe",
      width: "15%",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.meg_importe);
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 20 }}>
        {" " + tituloModal + " "}
      </Divider>
      <Row gutter={8}>
        <Col span={4}>
          <DatePicker onChange={onChangeFecha} style={{ width: "100%" }} />
        </Col>
        <Col span={10}>
          <Select
            showSearch
            showArrow
            style={{ width: "100%" }}
            defaultValue={
              idProveedor > 0 ? idProveedor : "SELECCIONE BENEFICIARIO"
            }
            placeholder="PROVEEDOR"
            onChange={updateProveedor}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {suppliersList.map((proveedor) => (
              <Option key={proveedor.trc_id} value={proveedor.trc_id}>
                {proveedor.trc_nombre}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={10}>
          <Select
            showSearch
            showArrow
            style={{ width: "100%" }}
            defaultValue={idCnc > 0 ? idCnc : "ELIJA CONCEPTO"}
            placeholder="CONCEPTO"
            onChange={updateCnc}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {listaCnc.map((conceptos) => (
              <Option key={conceptos.cnc_id} value={conceptos.cnc_id}>
                {conceptos.cnc_nombre}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={8} style={{ marginTop: 10 }}>
        <Col span={18}>
          <Input
            placeholder="Detalle Concepto"
            defaultValue={""}
            style={{ width: "100%" }}
            value={detalleCnc}
            onChange={onChangeDeta}
          />
        </Col>
        <Col span={6}>
          <InputNumber
            style={{ width: "100%", textAlign: "right" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
            defaultValue={valorGto}
            value={valorGto}
            onChange={onChangeGto}
            placeholder="Valor"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <TextArea
            rows={2}
            value={nota}
            placeholder={"Observaciones"}
            onChange={onChangeNota}
            allowClear
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
        {" "}
        Gastos Anteriores{" "}
      </Divider>
      <Table
        columns={header}
        dataSource={detalleList}
        bordered
        size="middle"
        scroll={{ y: 640 }}
      />
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => registrarGasto()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            block
            style={{
              display: Number.parseFloat(valorGto) > 0 ? "block" : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> REGISTRAR EL GASTO
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}

//https://pastebin.com/yKpF4A2h

function info(text) {
  Modal.info({
    title: "OBSERVACIONES",
    content: (
      <div>
        <p>{text}</p>
      </div>
    ),
    onOk() {},
  });
}
