import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Button,
  Table,
  Tag,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  Space,
  InputNumber,
} from "antd";
import { useHistory } from "react-router-dom";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

import {
  ReloadOutlined,
  HomeOutlined,
  SearchOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

import moment from "moment";

//Componentes Propios
import ModalApp from "../../../Modal";
import DetalleGastos from "../../Procesos/CjbGastosFrm/DetalleGastos";
import DetalleEntrada from "../../DetalleEntrada";

// componentes
import FRM from "../../../../utils/FrmService";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import CST from "../../../../utils/CustomSettings";
import { URL_FILES } from "../../../../constants";
import TableGastos from "../../TableGastos";

export default function FrmCuentasxP(props) {
  const {
    myNumber,
    currentUser,
    currentSetup,
    setIsLoading,
    currentMoneda,
    suppliersList,
  } = props;
  const [fechaEntr, setFechaEntr] = useState("");
  const [totalAnterior, setTotalAnterior] = useState({});
  const [totalFinal, setTotalFinal] = useState({});
  const [movimientos, setMovimientos] = useState([]);
  //Constantes para modales
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [detalleList, setDetalleList] = useState({});
  const [idProvee, setIdProvee] = useState(0);
  const [idMoneda, setIdMoneda] = useState(1);

  const history = useHistory();
  const [form] = Form.useForm();
  const { Title, Text } = Typography;
  const { Option } = Select;

  //Cambio de fecha
  function onChangeFecha(value, dateString) {
    setFechaEntr(dateString.toString());
  }

  const updateProvee = async (value) => {
    setIdProvee(value);
  };

  const updateMoneda = async (value) => {
    setIdMoneda(value);
  };

  const openAdjunto = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/gastos/" + record.meg_adjunto;
    window.open(`${url_temp}`);
  };

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  // TRAER MOVIMIENTOS
  const downloadCaja = async () => {
    if (Number.parseInt(idProvee) === 0) {
      showNotification(
        "topRight",
        2,
        "CUENTAS POR PAGAR",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL INFORME"
      );
    } else {
      //setIsLoading(true);
      let record = {
        tipoMov: 3,
        usr_rgs: currentUser.id,
        id_moneda: idMoneda,
        trc_id: idProvee,
      };
      var val_movcaja = await FRM.getMovCaja(currentUser, record);
      console.log(val_movcaja);
      if (val_movcaja.type && Number.parseInt(val_movcaja.type) > 0) {
        setTotalAnterior(0);
        setTotalFinal(0);
        armarDetalle(val_movcaja.movimientos, val_movcaja.saldo_act);
        showNotification(
          "topRight",
          4,
          "CUENTAS POR PAGAR",
          "PROCESO DE CONSULTA EXITOSO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CUENTAS POR PAGAR",
          val_movcaja.message
        );
      }
    }
    //setIsLoading(false);
  };

  //Armado del array definitivo
  const armarDetalle = (array, val_saldo) => {
    const newDet = [];
    let saldo = Number.parseFloat(val_saldo);
    array.forEach((row) => {
      let item = {
        cte_id: row.cte_id,
        cte_fecha: row.cte_fecha,
        cte_compr: row.cte_compr,
        cte_numero: row.cte_numero,
        cte_debito: row.cte_tipocmp === 1 ? row.cte_total : 0,
        cte_credito: row.cte_tipocmp !== 1 ? row.cte_total : 0,
        cte_saldo: saldo,
        cte_tipo: row.cte_compr === "FACT" ? 1 : 0,
      };
      newDet.push(item);
      saldo =
        Number.parseFloat(saldo) -
        Number.parseFloat(row.cte_total) * Number.parseFloat(row.cte_tipocmp);
    });
    setMovimientos(newDet);
  };

  //Columnas resumen caja
  const resCuentas = [
    {
      title: "ID", //10%
      dataIndex: "cte_id",
      key: "cte_id",
      width: "2%",
    },
    {
      title: "FECHA",
      dataIndex: "cte_fecha",
      key: "cte_fecha",
    },
    {
      title: "CLASE",
      dataIndex: "cte_compr",
      key: "cte_compr",
    },
    {
      title: "NUMERO",
      dataIndex: "cte_numero",
      key: "cte_numero",
    },
    {
      title: "DEBITOS",
      dataIndex: "cte_debito",
      key: "cte_debito",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.cte_debito));
      },
    },
    {
      title: "CREDITOS",
      dataIndex: "cte_credito",
      key: "cte_credito",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.cte_credito));
      },
    },
    {
      title: "SALDO",
      dataIndex: "cte_saldo",
      key: "cte_saldo",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.cte_saldo));
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col span={3}>
            <Form.Item label="MONEDA">
              <Select
                showSearch
                size="large"
                showArrow
                style={{ width: "100%" }}
                defaultValue={idMoneda}
                onChange={updateMoneda}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={1}>DOLARES</Option>
                <Option value={2}>PESOS UYU</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="PROVEEDOR A CONSULTAR">
              <Select
                showSearch
                size="large"
                showArrow
                style={{ width: "100%" }}
                defaultValue={
                  idProvee > 0 ? idProvee : "SELECCIONE EL PROVEEDOR"
                }
                placeholder="CUENTA BANCARIA"
                onChange={updateProvee}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {suppliersList.map((provee) => (
                  <Option key={provee.trc_id} value={provee.trc_id}>
                    {provee.trc_nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item label=".">
              <Space>
                <Button
                  type="primary"
                  className={curStyle.btnLila}
                  shape="round"
                  icon={<ReloadOutlined />}
                  size="large"
                  onClick={() => downloadCaja()}
                >
                  Consultar
                </Button>
                <Button
                  type="primary"
                  className={curStyle.btnSky}
                  shape="round"
                  icon={<HomeOutlined />}
                  size="large"
                  onClick={() => history.push(`/${currentUser.pagina}`)}
                >
                  Volver
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <div
        style={{
          height: window.innerHeight,
          overflow: "scroll",
          paddingBottom: 250,
          marginBottom: 250,
        }}
      >
        <div>
          <Row gutter={8}>
            <Col span={24}>
              <Table
                columns={resCuentas}
                dataSource={movimientos}
                pagination={false}
                style={{ padding: 3, marginTop: 5 }}
                size="large"
              />
            </Col>
          </Row>
        </div>
        <ModalApp
          isVisibleModal={isVisibleModal}
          closeModal={closeModal}
          widthModal={tipoModal === 4 ? 1307 : widthModal}
        >
          {tipoModal === 1 ? (
            <DetalleGastos
              currentUser={currentUser}
              closeModal={closeModal}
              setStateInf={Math.random()}
              currentSetup={currentSetup}
              myNumber={myNumber}
              detalleModal={detalleModal}
              currentMoneda={currentMoneda}
            />
          ) : tipoModal === 2 ? (
            <DetalleEntrada
              currentUser={currentUser}
              closeModal={closeModal}
              setIsLoading={setIsLoading}
              myNumber={myNumber}
              detalleCarga={detalleModal}
              opcionCarga={3}
              detalleList={detalleList}
            />
          ) : (
            <DetalleGastos
              currentUser={currentUser}
              closeModal={closeModal}
              setStateInf={Math.random()}
              currentSetup={currentSetup}
              myNumber={myNumber}
              detalleModal={detalleModal}
              currentMoneda={currentMoneda}
            />
          )}
        </ModalApp>
      </div>
    </div>
  );
}
