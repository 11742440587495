import React, { useState } from "react";
import {
  Form,
  Button,
  Statistic,
  Upload,
  Typography,
  Select,
  Input,
  Row,
  Col,
  InputNumber,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  Space,
} from "antd";
import { UploadOutlined, CalendarOutlined } from "@ant-design/icons";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./SalidasAnm.scss";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { URL_FILES } from "../../../constants";
import CST from "../../../utils/CustomSettings";

export default function CreateAlimentoInv(props) {
  const {
    currentUser,
    closeModal,
    setStateInf,
    currentSetup,
    myNumber,
    detalleModal,
    totalKg,
    totalAnm,
    trcId,
    trcName,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [nota, setNota] = useState("");
  const [fechaEntr, setFechaEntr] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [nameAdjunto, setNameAdjunto] = useState("");
  const [numeroGuia, setNumeroGuia] = useState("");
  const [destino, setDestino] = useState("");
  const [balanza, setBalanza] = useState(0);
  const [pie, setPie] = useState(0);
  const [rendimiento, setRendimiento] = useState(0);
  const [promedio, setPromedio] = useState(0);
  const [neto, setNeto] = useState(0);
  const [vrKilo, setVrKilo] = useState(0);
  const [vrImeba, setVrImeba] = useState(0);
  const [vrIva, setVrIva] = useState(0);
  const [vrMevir, setVrMevir] = useState(0);
  const [vrInia, setVrInia] = useState(0);
  const [vrComision, setVrComision] = useState(0);
  const [vrTotalNeto, setVrTotalNeto] = useState(0);
  const [vrTotalBruto, setVrTotalBruto] = useState(0);

  const { Option } = Select;
  const [form] = Form.useForm();

  const alimentoList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.tipoalimento
  );

  //Listado de proveedores
  const suppliersList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.servicios
  );

  const openAdjunto = (pdf_file) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp = URL_FILES + currentUser.dominio + "/temporal/" + pdf_file;
    window.open(`${url_temp}`);
  };

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaEntr(dateString);
  }

  const onChangeGuia = (e) => {
    const valor = e.target.value;
    setNumeroGuia(valor.toUpperCase());
  };

  const onChangeDstn = (e) => {
    const valor = e.target.value;
    setDestino(valor.toUpperCase());
  };

  const onChangeBlz = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setBalanza(value);
      setPie(0);
      setPromedio(0);
      setRendimiento(0);
      setNeto(0);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangePie = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setPie(value);
      let prm = value / totalAnm;
      setPromedio(Number.parseFloat(prm).toFixed(2));
      setRendimiento(0);
      setNeto(0);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeRdm = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setRendimiento(value);
      let prm = (value / 100) * pie;
      setNeto(Number.parseFloat(prm).toFixed(2));
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeKg = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setVrKilo(value);
      let brt = value * neto;
      setVrTotalBruto(Number.parseFloat(brt).toFixed(2));
      setVrTotalNeto(Number.parseFloat(brt).toFixed(2));
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeIva = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setVrIva(value);
      let nto =
        vrTotalBruto - (vrComision + vrImeba + vrInia + vrMevir + value);
      setVrTotalNeto(Number.parseFloat(nto).toFixed(2));
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeImeba = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setVrImeba(value);
      let nto = vrTotalBruto - (vrComision + value + vrInia + vrMevir + vrIva);
      setVrTotalNeto(Number.parseFloat(nto).toFixed(2));
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeInia = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setVrInia(value);
      let nto = vrTotalBruto - (vrComision + vrImeba + value + vrMevir + vrIva);
      setVrTotalNeto(Number.parseFloat(nto).toFixed(2));
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeMevir = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setVrMevir(value);
      let nto = vrTotalBruto - (vrComision + vrImeba + vrInia + value + vrIva);
      setVrTotalNeto(Number.parseFloat(nto).toFixed(2));
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeCms = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setVrComision(value);
      let nto = vrTotalBruto - (value + vrImeba + vrInia + vrMevir + vrIva);
      setVrTotalNeto(Number.parseFloat(nto).toFixed(2));
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  //GESTION DE CARGAR DE ARCHIVOS PDF
  // specify upload params and url for your files
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    body.append("file", file);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return { url: "https://api.aal-erp.com/frm/api/712", body, headers };
  };

  // called every time a file's `status` changes
  //const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }
  const handleChangeStatus = ({ meta, remove }, status) => {
    console.log(meta);
    if (status === "headers_received") {
      setNameAdjunto(meta.name);
      message.success("CARGA DE ADJUNTO EXITOSA", 1);
      //alert(`${meta.name} uploaded!`);
      remove();
    } else if (status === "aborted") {
      message.error("CARGA DE ADJUNTO FALLIDA", 2);
    }
  };
  //FIN GESTION DE CARGA DE ARCHIVOS

  // ENVIO A WEBSERVICES
  const uploadDspWS = async () => {
    setLoadingButton(true);
    if (
      Number.parseInt(balanza) === 0 ||
      Number.parseFloat(pie) === 0 ||
      Number.parseFloat(rendimiento) === 0 ||
      Number.parseFloat(vrKilo) === 0 ||
      fechaEntr.length === 0 ||
      numeroGuia.length === 0 ||
      destino.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "REGISTRAR FACTURA",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      let record = {
        tipoMov: 2,
        usr_id: currentUser.id,
        trc_id: trcId,
        sal_id: 0,
        fac_fecha: fechaEntr,
        fac_guia: numeroGuia,
        fac_destino: destino,
        fac_animales: totalAnm,
        fac_kg_sys: totalKg,
        fac_balanza: balanza,
        fac_enpie: pie,
        fac_rinde: rendimiento,
        fac_kg_neto: neto,
        fac_precio_kg: vrKilo,
        fac_iva: vrIva,
        fac_imeba: vrImeba,
        fac_mevir: vrMevir,
        fac_inia: vrInia,
        fac_comision: vrComision,
        fac_total: vrTotalNeto,
        fac_nota: nota,
        fac_adjunto: nameAdjunto.length === 0 ? "NA" : nameAdjunto,
        salidas: detalleModal,
      };
      var val_inventory = await FRM.sendFactura(currentUser, record);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        setStateInf(Math.random());
        showNotification(
          "topRight",
          4,
          "REGISTRO DE FACTURAS",
          "PROCESO DE REGISTRO EXITOSO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "REGISTRO DE FACTURAS",
          val_inventory.message
        );
      }
    }
    setFileList([]);
    setLoadingButton(false);
    closeModal();
  };

  return (
    <div className="upload-panel">
      <Title level={3} style={{ textAlign: "center" }}>
        {"REGISTRAR FACTURA DE MULTIPLES SALIDAS (" + detalleModal.length + ")"}
      </Title>
      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col span={6}>
            <Statistic
              title="Fecha Salida"
              value={"MULTIPLES"}
              prefix={<CalendarOutlined />}
            />
          </Col>
          <Col span={10}>
            <Statistic title="Cliente Comprador" value={trcName} />
          </Col>
          <Col span={4}>
            <Statistic title="Animales" value={totalAnm} />
          </Col>
          <Col span={4}>
            <Statistic title="Kg Sistema" value={myNumber(1, totalKg, 2)} />
          </Col>
        </Row>
        <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Fecha de Factura">
              <DatePicker onChange={onChangeFecha} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Numero de GUIA">
              <Input
                placeholder="Guia"
                defaultValue={""}
                style={{ width: "100%" }}
                value={numeroGuia}
                onChange={onChangeGuia}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Destino">
              <Input
                placeholder="Destino"
                defaultValue={""}
                style={{ width: "100%" }}
                value={destino}
                onChange={onChangeDstn}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item label="ANIMALES">
              <Title
                level={4}
                style={{
                  padding: 2,
                  margin: 2,
                  fontWeight: 600,
                  fontSize: 16,
                  textAlign: "right",
                  color: CST.blueBdy,
                }}
              >
                {myNumber(1, totalAnm)}
              </Title>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="KG EN BALANZA">
              <InputNumber
                style={{
                  width: "100%",
                  textAlign: "right",
                  color: "orangered",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={balanza}
                value={balanza}
                onChange={onChangeBlz}
                placeholder="BALANZA"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="KG EN PIE">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={pie}
                value={pie}
                onChange={onChangePie}
                placeholder="KG EN PIE"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="KG PROMEDIO">
              <Title
                level={4}
                style={{
                  padding: 2,
                  margin: 2,
                  fontWeight: 600,
                  fontSize: 16,
                  textAlign: "right",
                  color: CST.greenBdy,
                }}
              >
                {myNumber(1, promedio, 2)}
              </Title>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="% RENDIMIENTO">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{4})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={rendimiento}
                value={rendimiento}
                onChange={onChangeRdm}
                placeholder="KG EN PIE"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="KG NETOS">
              <Title
                level={4}
                style={{
                  padding: 2,
                  margin: 2,
                  fontWeight: 600,
                  fontSize: 16,
                  textAlign: "right",
                  color: CST.blueBdy,
                }}
              >
                {myNumber(1, neto, 2)}
              </Title>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="US$ KG">
              <InputNumber
                style={{
                  width: "100%",
                  textAlign: "right",
                  color: "orangered",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{4})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={vrKilo}
                value={vrKilo}
                onChange={onChangeKg}
                placeholder="US$ Kg"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="US$ BRUTO">
              <Title
                level={4}
                style={{
                  padding: 2,
                  margin: 2,
                  fontWeight: 600,
                  fontSize: 16,
                  textAlign: "right",
                  color: CST.blueBdy,
                }}
              >
                {myNumber(1, vrTotalBruto, 2)}
              </Title>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="VR IMEBA">
              <InputNumber
                style={{
                  width: "100%",
                  textAlign: "right",
                  color: "orangered",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={vrImeba}
                value={vrImeba}
                onChange={onChangeImeba}
                placeholder="VR IMEBA"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="VR IVA">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={vrIva}
                value={vrIva}
                onChange={onChangeIva}
                placeholder="VR IVA"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="VR MEVIR">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={vrMevir}
                value={vrMevir}
                onChange={onChangeMevir}
                placeholder="VR MEVIR"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="VR INIA">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={vrInia}
                value={vrInia}
                onChange={onChangeInia}
                placeholder="VR INIA"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="VR COMISION">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={vrComision}
                value={vrComision}
                onChange={onChangeCms}
                placeholder="VR COMISION"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="IMPORTE NETO">
              <Title
                level={4}
                style={{
                  padding: 2,
                  margin: 2,
                  fontWeight: 600,
                  fontSize: 16,
                  textAlign: "right",
                  color: CST.greenBdy,
                }}
              >
                {myNumber(1, vrTotalNeto, 2)}
              </Title>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <TextArea
            rows={2}
            value={nota}
            placeholder={"Observaciones"}
            onChange={onChangeNota}
            allowClear
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
        {"Comprobante de respaldo"}
      </Divider>
      {nameAdjunto.length > 0 ? (
        <Button
          type="link"
          size="large"
          onClick={() => openAdjunto(nameAdjunto)}
        >
          {nameAdjunto}
        </Button>
      ) : null}
      <div
        style={{
          textAlign: "center",
          marginTop: 20,
          border: 0,
        }}
      >
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          autoUpload={true}
          maxFiles={1}
          multiple={false}
          canCancel={false}
          accept=".pdf"
          inputContent={"Arrastre o seleccione el PDF a adjuntar"}
          inputWithFilesContent={"Agregar Archivos"}
          submitButtonContent={"Subir Archivos"}
        />
      </div>
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => uploadDspWS()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={curStyle.btnSuccess}
            shape="round"
            block
            loading={loadingButton}
          >
            <UploadOutlined /> PROCESAR LA FACTURA
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
