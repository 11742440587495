import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Typography,
  Row,
  Col,
  Popconfirm,
  Statistic,
  Divider,
  Tabs,
  Tag,
} from "antd";
import { CalendarOutlined, DeleteOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./Inventario.scss";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";

export default function DetalleLote(props) {
  const {
    currentUser,
    closeModal,
    detalleAnimal,
    detalleField,
    detalleSale,
    detalleVacuna,
    detalleDespa,
    detalleCome,
    detallePeso,
    myNumber,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataDef, setDataDef] = useState([]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title, Text } = Typography;
  const { TabPane } = Tabs;

  // Encabezados de la tabla de Historico en Campos
  const header = [
    {
      title: "ENTRADA", //10%
      dataIndex: "cmb_fecha",
      key: "cmb_fecha",
    },
    {
      title: "No",
      dataIndex: "cmb_id",
      key: "cmb_id",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnLila}
          size="small"
          shape="circle"
        >
          {record.cmb_id}
        </Button>
      ),
    },
    {
      title: "UBICACION", //10%
      dataIndex: "cmp_ubica",
      key: "cmp_ubica",
      width: "30%",
    },
    {
      title: "SALIDA", //10%
      dataIndex: "cmb_final",
      key: "cmb_final",
    },
    {
      title: "DIAS",
      dataIndex: "dias_fld",
      key: "dias_fld",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.dias_fld, 0)}
          </Tag>
        );
      },
    }
  ];

  const vacunas = [
    {
      title: "FECHA", //10%
      dataIndex: "cmb_fecha",
      key: "cmb_fecha",
    },
    {
      title: "UBICACION", //10%
      dataIndex: "cmp_ubica",
      key: "cmp_ubica",
      width: "30%",
    },
    {
      title: "TIPO", //10%
      dataIndex: "vcn_nombre",
      key: "vcn_nombre",
    },
    {
      title: "COSTO",
      dataIndex: "anm_costo",
      key: "anm_costo",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.anm_costo, 2)}
          </Tag>
        );
      },
    },
    {
      title: "KILOS",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.anm_peso_neto, 2)}
          </Tag>
        );
      },
    },
  ];

  const despara = [
    {
      title: "FECHA", //10%
      dataIndex: "vnc_fecha",
      key: "cmb_fecha",
    },
    {
      title: "CAMPO", //10%
      dataIndex: "cmp_ubica",
      key: "cmp_ubica",
      width: "30%",
    },
    {
      title: "TIPO", //10%
      dataIndex: "vcn_nombre",
      key: "vcn_nombre",
    },
    {
      title: "COSTO",
      dataIndex: "anm_costo",
      key: "anm_costo",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.anm_costo, 2)}
          </Tag>
        );
      },
    },
    {
      title: "KILOS",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.anm_peso_neto, 2)}
          </Tag>
        );
      },
    },
  ];

  const alimenta = [
    {
      title: "FECHA", //10%
      dataIndex: "alm_fecha",
      key: "alm_fecha",
    },
    {
      title: "No",
      dataIndex: "cmb_id",
      key: "cmb_id",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnInfo}
          size="small"
          shape="circle"
        >
          {record.cmb_id}
        </Button>
      ),
    },
    {
      title: "CAMPO", //10%
      dataIndex: "cmp_ubica",
      key: "cmp_ubica",
      width: "30%",
    },
    {
      title: "TIPO", //10%
      dataIndex: "alm_nombre",
      key: "alm_nombre",
    },
    {
      title: "COSTO",
      dataIndex: "valor_anm",
      key: "valor_anm",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.valor_anm, 2)}
          </Tag>
        );
      },
    },
    {
      title: "KILOS",
      dataIndex: "kilos_anm",
      key: "kilos_anm",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.kilos_anm, 2)}
          </Tag>
        );
      },
    },
  ];

  const pesosclm = [
    {
      title: "FECHA", //10%
      dataIndex: "upd_fecha",
      key: "upd_fecha",
    },
    {
      title: "CAMPO", //10%
      dataIndex: "cmp_ubica",
      key: "cmp_ubica",
      width: "30%",
    },
    {
      title: "DIAS",
      dataIndex: "anm_dias",
      key: "anm_dias",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.anm_dias)}
          </Tag>
        );
      },
    },
    {
      title: "KILOS",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.anm_peso_neto, 2)}
          </Tag>
        );
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={4} style={{ color: "#6A3E98", textAlign: "center" }}>
        {detalleAnimal.anm_sexo === 1
          ? "DETALLES DEL ANIMAL " +
          detalleAnimal.anm_codigo +
          " - MACHO " +
          detalleAnimal.rza_nombre
          : "DETALLES DEL ANIMAL " +
          detalleAnimal.anm_codigo +
          " - HEMBRA " +
          detalleAnimal.rza_nombre}
      </Title>

      {Number.parseInt(detalleAnimal.anm_estado) > 0 ? (
        <>
          <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
          <Row gutter={8}>
            <Col span={4}>
              <Statistic
                title="Entrada"
                value={detalleAnimal.anm_entrada}
                prefix={<CalendarOutlined />}
                valueStyle={{ color: "#3f8600", fontSize: 18 }}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Categoria"
                value={detalleAnimal.ctg_nombre}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Ubicacion Actual"
                value={detalleAnimal.anm_ubica}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Salida"
                value={detalleSale.sal_fecha}
                prefix={<CalendarOutlined />}
                valueStyle={{ color: "red", fontSize: 18 }}
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
          <Row gutter={8}>
            <Col span={5}>
              <Statistic
                title="Peso Neto"
                value={detalleAnimal.anm_peso_neto}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={5}>
              <Statistic
                title="Costo Kg"
                value={detalleAnimal.anm_costo ? detalleAnimal.anm_costo : 0}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={5}>
              <Statistic
                title="Comision"
                value={
                  detalleAnimal.anm_comision ? detalleAnimal.anm_comision : 0
                }
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Transporte"
                value={
                  detalleAnimal.anm_transporte
                    ? detalleAnimal.anm_transporte
                    : 0
                }
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={5}>
              <Statistic
                title="Costo Entrada"
                value={
                  detalleAnimal.anm_costo_cif ? detalleAnimal.anm_costo_cif : 0
                }
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
          <Row gutter={8}>
            <Col span={8}>
              <Statistic
                title="Lugar de Salida"
                value={detalleSale.cmp_ubica}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Causa"
                value={detalleSale.sal_motivo}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Destino"
                value={detalleSale.sal_tercero}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
          <Row gutter={8}>
            <Col span={4}>
              <Statistic
                title="Entrada"
                value={detalleAnimal.anm_entrada}
                prefix={<CalendarOutlined />}
                valueStyle={{ color: "#3f8600", fontSize: 18 }}
              />
            </Col>
            <Col span={10}>
              <Statistic
                title="Categoria"
                value={detalleAnimal.ctg_nombre}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={10}>
              <Statistic
                title="Ubicacion Actual"
                value={detalleAnimal.anm_ubica}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
          <Row gutter={8}>
            <Col span={5}>
              <Statistic
                title="Peso Neto"
                value={detalleAnimal.anm_peso_neto}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={5}>
              <Statistic
                title="Costo Kg"
                value={detalleAnimal.anm_costo ? detalleAnimal.anm_costo : 0}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={5}>
              <Statistic
                title="Comision"
                value={
                  detalleAnimal.anm_comision ? detalleAnimal.anm_comision : 0
                }
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Transporte"
                value={
                  detalleAnimal.anm_transporte
                    ? detalleAnimal.anm_transporte
                    : 0
                }
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={5}>
              <Statistic
                title="Costo Entrada"
                value={
                  detalleAnimal.anm_costo_cif ? detalleAnimal.anm_costo_cif : 0
                }
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
          </Row>
        </>
      )}
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
        DETALLES DE LA ESTANCIA
      </Divider>
      <Tabs defaultActiveKey="1">
        <TabPane tab="CAMPOS HABITADOS" key="1">
          <Table
            columns={header}
            dataSource={detalleField}
            bordered
            size="middle"
            scroll={{ y: 640 }}
          />
        </TabPane>
        <TabPane tab="ALIMENTACION" key="4">
          <Table
            columns={alimenta}
            dataSource={detalleCome}
            pagination={false}
            bordered
            size="small"
            scroll={{ y: 240 }}
            summary={pageData => {
              let totalKilos = 0;
              let totalPesos = 0;

              pageData.forEach(({ kilos_anm, valor_anm }) => {
                totalKilos += Number.parseFloat(kilos_anm);
                totalPesos += Number.parseFloat(valor_anm);
              });

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={4}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Title level={5}>{myNumber(1, totalPesos, 2)}</Title>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <Title level={5}>{myNumber(1, totalKilos, 2)}</Title>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </TabPane>
        <TabPane tab="PESAJES" key="5">
          <Table
            columns={pesosclm}
            dataSource={detallePeso}
            bordered
            size="small"
          />
        </TabPane>
        <TabPane tab="VACUNAS APLICADAS" key="2">
          <Table
            columns={vacunas}
            dataSource={detalleVacuna}
            bordered
            size="middle"
            scroll={{ y: 640 }}
          />
        </TabPane>
        <TabPane tab="DESPARASITADAS" key="3">
          <Table
            columns={despara}
            dataSource={detalleDespa}
            bordered
            size="middle"
            scroll={{ y: 640 }}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}
