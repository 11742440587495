import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  message,
  Tag,
  Modal as ModalB,
} from "antd";
import Highlighter from "react-highlight-words";

//Floating Buttons
import FloatingButtons from "react-floating-buttons";
import moneyl from "../../../../assets/icons/clipboard-list.svg";
import moneyf from "../../../../assets/icons/clipboard-list-outline.svg";

//Iconos y constantes
import {
  SearchOutlined,
  FilePdfOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  DollarOutlined,
} from "@ant-design/icons";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../../Modal";
import CST from "../../../../utils/CustomSettings";
import CjbGastosOrd from "./CjbGastosOrd";
import CjbOrdenCmp from "./CjbOrdenCmp";
import DetalleNcr from "./DetalleNcr";
import CjbNotaCrd from "./CjbNotaCrd";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../../utils/ShowNotification";
import FRM from "../../../../utils/FrmService";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { myNumber } from "../../../../utils/ArrayFunctions";
import { URL_FILES } from "../../../../constants";

//Estilos
import "./CjbGastosFrm.scss";

export default function CjbNotasCrd(props) {
  const { setIsLoading, tipoGastos } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState(0);
  const [listGastos, setListGastos] = useState([]);
  const [stateInf, setStateInf] = useState(Math.random());

  const { confirm } = ModalB;

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const currentSetup = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.utlsetup
  );

  const currentMoneda = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      let datos = await FRM.getGastos(currentUser, tipoGastos);
      if (datos.type && datos.type > 0) {
        setListGastos(datos.listado);
      } else {
        showNotification(
          "topRight",
          1,
          "NOTAS DE CREDITO",
          "No hay NOTAS REGISTRADAS"
        );
      }
      setIsLoading(false);
    })();
  }, [stateInf]);

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const openAdjunto = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/notascrd/" + record.ntc_adjunto;
    window.open(`${url_temp}`);
  };

  const showNota = (record) => {
    showNotification(
      "topRight",
      1,
      "NOTA DE CREDITO",
      record.ntc_detalle + " - " + record.ntc_nota
    );
  };

  const deleRecord = async (record) => {
    confirm({
      title: "EILIMINAR NOTA CR A " + record.trc_nombre,
      icon: <ExclamationCircleOutlined />,
      okText: "ELIMINAR",
      cancelText: "Cancelar",
      content: "DESEA ELIMINAR LA NOTA?",
      onOk() {
        deleNotaCr(record);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //Eliminar Nota Cr
  const deleNotaCr = async (notacr) => {
    setIsLoading(true);
    let record = {
      tipoMov: 18,
      ntc_id: notacr.ntc_numero,
      ntc_moneda: notacr.ntc_moneda,
      usr_apr: notacr.usr_apr,
    };
    var val_gasto = await FRM.sendGasto(currentUser, record);
    if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
      showNotification(
        "topRight",
        4,
        "ELIMINAR NOTA CREDITO",
        "MOVIMIENTO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "ELIMINAR NOTA CREDITO",
        val_gasto.message
      );
    }
    setIsLoading(false);
  };

  //Funciones para busqueda en las columnas
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.focus());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  //Fin funciones para busqueda en las columnas

  //Definicion de los floating
  const buttonsList = [
    {
      onClick: () => openModal(null, 1, "900px"),
      src: moneyl,
    },
    {
      onClick: () => openModal(null, 2, "900px"),
      src: moneyf,
    },
  ];
  //Fin floating

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSuccess}
          size="large"
          shape="circle"
        >
          {record.ntc_id}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "ntc_fecha",
      key: "ntc_fecha",
      ...getColumnSearchProps("ntc_fecha"),
    },
    {
      title: "PROVEEDOR", //10%
      dataIndex: "trc_nombre",
      key: "trc_nombre",
      ...getColumnSearchProps("trc_nombre"),
    },
    {
      title: "CONCEPTO",
      dataIndex: "cnc_nombre",
      key: "cnc_nombre",
      ...getColumnSearchProps("cnc_nombre"),
    },
    {
      title: "REGISTRO",
      dataIndex: "registro",
      key: "registro",
      ...getColumnSearchProps("registro"),
    },
    {
      title: "ESTADO",
      dataIndex: "ntc_estado",
      key: "ntc_estado",
      render: (text, row, index) => {
        return row.ntc_estado === 0 ? (
          <Tag color="green">POR APROBAR</Tag>
        ) : row.ntc_estado === 1 ? (
          <Tag color="geekblue">POR APLICAR</Tag>
        ) : row.ntc_estado === 2 ? (
          <Tag color="volcano">APLICADA</Tag>
        ) : (
          <Tag color="magenta">ANULADA</Tag>
        );
      },
    },
    {
      title: "MONTO",
      dataIndex: "ntc_importe",
      key: "ntc_importe",
      align: "right",
      render: (text, row, index) => {
        return row.ntc_moneda === 1 ? (
          <Tag color="orange" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo + myNumber(1, row.ntc_importe)}
          </Tag>
        ) : (
          <Tag color="green" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo_frn + myNumber(1, row.ntc_importe)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="large"
          shape="circle"
          icon={<FilePdfOutlined />}
          onClick={() =>
            record.ntc_adjunto
              ? openAdjunto(record)
              : message.error("NO HAY ARCHIVO ADJUNTO", 1)
          }
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => showNota(record, 3, "900px")}
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnLila}
          size="large"
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() => deleRecord(record)}
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listGastos}
        style={{ padding: 3, marginTop: 5 }}
      />
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 1 ? (
          <CjbNotaCrd
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            ntc_moneda={1}
          />
        ) : tipoModal === 2 ? (
          <CjbNotaCrd
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            ntc_moneda={2}
          />
        ) : tipoModal === 4 ? (
          <CjbGastosOrd
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
          />
        ) : (
          <DetalleNcr
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            currentMoneda={currentMoneda}
          />
        )}
      </ModalApp>
      {Number.parseInt(currentUser.tipo) === 4 ? (
        <FloatingButtons
          buttonType="plus"
          buttonColor={"#ffffff"}
          backgroundColor={CST.greenBdy}
          dimension={60}
          buttonsList={buttonsList}
          top={"calc(90% - 25px)"}
          left={"120px"}
          direction="circular"
          degree={-180}
          distance={90}
        />
      ) : null}
    </div>
  );
}
