import React from "react";
import { Button, Typography, Divider, message } from "antd";

//Iconos y constantes
import { UploadOutlined } from "@ant-design/icons";

//Styles
import "./CambiosLote.scss";

// componentes
import curStyle from "../../../css/GlobalCmp.module.css";

export default function OpcionesLote(props) {
  const { detalleCarga, onClickMenu } = props;

  const { Title } = Typography;

  //Se actualiza el nombre
  const updateRecord = async (value) => {
    onClickMenu(value);
  };

  return (
    <div className="upload-panel">
      <Title level={4} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"OPCIONES " + detalleCarga.cmp_nombre}
      </Title>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Button
        type="primary"
        className={curStyle.btnLila}
        shape="round"
        block
        onClick={() => updateRecord("1")}
      >
        <UploadOutlined /> MOVER ANIMALES DEL CAMPO
      </Button>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Button
        type="primary"
        className={curStyle.btnDark}
        shape="round"
        block
        onClick={() => updateRecord("2")}
      >
        <UploadOutlined /> SUMINISTRAR ALIMENTOS
      </Button>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Button
        type="primary"
        className={curStyle.btnOrange}
        shape="round"
        block
        onClick={() => updateRecord("9")}
      >
        <UploadOutlined /> SUMINISTRAR ALIMENTO EXCEL
      </Button>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Button
        type="primary"
        className={curStyle.btnSky}
        shape="round"
        block
        onClick={() => updateRecord("10")}
      >
        <UploadOutlined /> SUMINISTRAR ALIMENTO XML
      </Button>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Button
        type="primary"
        className={curStyle.btnWarning}
        shape="round"
        block
        onClick={() => updateRecord("3")}
      >
        <UploadOutlined /> SUMINISTRAR ANTIPARASITARIO
      </Button>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Button
        type="primary"
        className={curStyle.btnDanger}
        shape="round"
        block
        onClick={() => updateRecord("4")}
      >
        <UploadOutlined /> SUMINISTRAR VACUNAS
      </Button>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Button
        type="primary"
        className={curStyle.btnSuccess}
        shape="round"
        block
        onClick={() => updateRecord("5")}
      >
        <UploadOutlined /> REGISTRAR SALIDA POR VENTAS
      </Button>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Button
        type="primary"
        className={curStyle.btnInverse}
        shape="round"
        block
        onClick={() => updateRecord("7")}
      >
        <UploadOutlined /> REGISTRAR SALIDA POR MUERTE
      </Button>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Button
        type="primary"
        className={curStyle.btnInfo}
        shape="round"
        block
        onClick={() => updateRecord("6")}
      >
        <UploadOutlined /> LIQUIDAR ESTANCIA EN CAMPO
      </Button>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Button
        type="primary"
        className={curStyle.btnSecundary}
        shape="round"
        block
        onClick={() => updateRecord("8")}
      >
        <UploadOutlined /> AGREGAR ANIMALES AL CAMPO
      </Button>
    </div>
  );
}
