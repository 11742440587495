import React, { useState, Suspense, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import {
  Layout,
  Row,
  Col,
  Drawer,
  Popconfirm,
  Select,
  Typography,
  Tabs,
  message,
  Divider,
  Tooltip,
  Button,
  Card,
  Menu,
  Statistic,
} from "antd";

//Creaciones de ID y Fecha
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import Icon from "@mdi/react";
import {
  mdiTruckCheckOutline,
  mdiTruckFastOutline,
  mdiBank,
  mdiAccountClockOutline,
  mdiGrass,
  mdiCow,
  mdiCalendarAccountOutline,
  mdiClipboardListOutline,
  mdiCashMultiple,
  mdiPenLock,
  mdiBarn,
  mdiCalendarStart,
  mdiCalendarClock,
  mdiWeightKilogram,
  mdiPill,
  mdiNeedle,
} from "@mdi/js";

//Componentes adicionales
import { TitlePage } from "../../components/TitlePage";
import MainHeader from "../../components/Farm/Header";
import ModalApp from "../../components/Modal";
import MainFooter from "../../components/Footer";
import FrmAgenda from "../../components/Farm/FrmAgenda";
import InformesDay from "../../components/Farm/InformesDay";
import EntradasMng from "../../components/Farm/Entradas/EntradasMng";
import Inventario from "../../components/Farm/Inventario";
import CambiosLoteMng from "../../components/Farm/CambiosLote/CambiosLoteMng";
import CjbOrdenesFrm from "../../components/Farm/Procesos/CjbGastosFrm/CjbOrdenesFrm";
import ChangePass from "../../components/ChangePass";
import DetalleGenerados from "../../components/Farm/Informes/Gerencial/DetalleGenerados";
import DetalleAlimentos from "../../components/Farm/Informes/Gerencial/DetalleAlimentos";

//Funciones y componentes propios
import { showNotification } from "../../utils/ShowNotification";
import TileIcon from "../../components/TileIcon";
import CST from "../../utils/CustomSettings";
import { downloadDataFrm } from "../../utils/ReloadEnv";
import FRM from "../../utils/FrmService";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";

//Actualizar entorno
import { updateEnviromentAction } from "../../actions/enviromentActions";
import { updateRolEnviromentAction } from "../../actions/rolEnviromentActions";
import { updateLoguedAction } from "../../actions/isLoguedActions";

//Funciones para administrar el carrito
import { myNumber, nombreMes } from "../../utils/ArrayFunctions";

//LeftMenu UserOptions
import LeftMenu from "../../components/LeftMenu";

//Estilos
import "./frmboard.scss";
import curStyle from "../../css/GlobalCmp.module.css";
import { colorSec, colorPrm } from "../../constants";

const MainCalendar = React.lazy(() => import("../../components/MainCalendar"));
const MainAgenda = React.lazy(() => import("../../components/MainAgenda"));

export default function FarmAuxiliar(props) {
  const { setIsLoading } = props;
  const { Footer } = Layout;
  const [activeKey, setActiveKey] = useState("1");
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [tipoModal, setTipoModal] = useState(1);
  const [widthModal, setWidthModal] = useState("700px");
  const [titleModal, setTitleModal] = useState();
  const [placeDrawer, setPlaceDrawer] = useState(1);
  const [widthDrawer, setWidthDrawer] = useState(700);
  const [isDrawer, setIsDrawer] = useState(false);
  const [drwDetalle, setDrwDetalle] = useState(1);
  const [showBoard, setShowBoard] = useState(Math.random());
  const [detalleModal, setDetalleModal] = useState([]);
  const [detalleFrn, setDetalleFrn] = useState([]);
  const [listadoDet, setListadoDet] = useState([]);

  // utilizar use dispatch y te crea una función
  const dispatch = useDispatch();
  //Actuliazar entorno
  const updateEnviroment = (state) => dispatch(updateEnviromentAction(state));
  const updateRolEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));
  const updateLogued = (state) => dispatch(updateLoguedAction(state));

  //useSelector para acceder al store
  //Validacion del logueo de usuario
  const isLogued = useSelector((state) => state.isLogued.isLogued);

  //Datos generales del usuario
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const userOptions = useSelector(
    (state) => state.currentUser.currentUser.user_options
  );

  const gstOpciones = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.gst_opciones
  );

  const userModules = useSelector(
    (state) => state.currentUser.currentUser.user_modules
  );

  //Monedas
  const curCurrency = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );

  //Resumen de Cabezas y Kilos
  const gstResumen = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.resumen
  );

  //Resumen de Cabezas y Kilos
  const gstTiles = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.resumen_gen
  );

  //Actualizacion de datos
  const downData = async () => {
    setIsLoading(true);
    let reLoad = await downloadDataFrm(currentUser);
    if (reLoad) {
      updateRolEnviroment(reLoad.rol_entorno);
      updateEnviroment(reLoad.entorno);
      showNotification(
        "topRight",
        4,
        "SINCRONIZAR",
        "DATOS DESCARGADOS CON EXITO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "SINCRONIZAR",
        "ERROR DE CONEXION... INTENTE MAS TARDE"
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      message.success("DATOS ACTUALIZADOS", 1);
    })();
  }, [showBoard]);

  //Defibiciones adicioneles Antd
  const { Option } = Select;
  const { Title } = Typography;
  const { TabPane } = Tabs;

  //Se controlan los cambios de Tab
  const changeTab = (activeKey) => {
    setActiveKey(activeKey);
  };

  // MODALES/////////////////////
  const openModal = (title, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setIsVisibleModal(true);
    setTitleModal(title);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  //Abre una modal dependiendo del tile
  const modalTile = async (opt) => {
    if (opt > 0) {
      let datos = await FRM.getTilesDet(currentUser, opt);
      if (datos.type && datos.type > 0) {
        setDetalleModal(datos.listado);
        setDetalleFrn(datos.listado_frn);
        setListadoDet(datos.listado_det);
        openModal("Probando", opt, 1000);
      } else {
        showNotification(
          "topRight",
          2,
          "VER DETALLE TILE",
          "No se encontraron datos"
        );
      }
    } else {
      message.info("SIN DETALLES A MOSTRAR...", 1);
    }
  };
  //FIN MODLAES////////////////

  //Abre el Drawer
  const openDrawer = (drwTipo, drwPalce, drwWidth) => {
    setPlaceDrawer(drwPalce);
    setWidthDrawer(drwWidth);
    setDrwDetalle(drwTipo);
    setIsDrawer(!isDrawer);
  };
  //Cierra el Drawer
  const closeDrawer = () => {
    setIsDrawer(false);
  };

  return !isLogued ? (
    <Redirect to="/" />
  ) : (
    <Layout style={{ minHeight: "100vh" }}>
      <TitlePage title="Farm System" />
      <div style={{ height: "50", marginBottom: 10 }}>
        <MainHeader
          setIsLoading={setIsLoading}
          currentUser={currentUser}
          userOptions={userOptions}
          openModal={openModal}
          openDrawer={openDrawer}
          nombreMes={nombreMes}
          updateRolEnviroment={updateRolEnviroment}
          updateEnviroment={updateEnviroment}
        />
      </div>

      <div
        className="site-drawer-render-in-current-wrapper"
        style={{
          //maxHeight: window.innerHeight - 125,
          backgroundColor: "transparent",
          marginBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <TileIcon
              clrH={CST.lilaHdr}
              clrB={CST.lilaBdy}
              clrF={CST.lilaFoo}
              title={"Cabezas de Ganado"}
              texto={myNumber(1, Number.parseInt(gstResumen.cabezas))}
              icono={mdiCow}
              pie={myNumber(1, Number.parseFloat(gstResumen.kilos)) + " Kg"}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <TileIcon
              clrH={CST.blueHdr}
              clrB={CST.blueBdy}
              clrF={CST.blueFoo}
              title={"Kilos Generados"}
              texto={myNumber(1, Number.parseInt(gstTiles.kilos_gnr))}
              icono={mdiWeightKilogram}
              pie={gstTiles.inicio + " - " + gstTiles.final}
              funcion={modalTile}
              opcion={1}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <TileIcon
              clrH={CST.darkHdr}
              clrB={CST.darkBdy}
              clrF={CST.darkFoo}
              title={"Inventario de Alimentos"}
              texto={
                "$" +
                myNumber(1, Number.parseFloat(gstTiles.total_alm) / 1000) +
                "K"
              }
              icono={mdiBarn}
              pie={
                myNumber(1, Number.parseFloat(gstTiles.total_kgh) / 1000) +
                "K Kg Humedos"
              }
              funcion={modalTile}
              opcion={2}
            />
          </Col>
          <Divider
            style={{
              marginTop: 10,
              marginBottom: 2,
              padding: 0,
            }}
          />
          <Suspense
            fallback={
              <Loader
                type="Watch"
                color={colorPrm}
                height={100}
                width={100}
                timeout={3000} //3 secs
              />
            }
          >
            <div
              className="main-tabs"
              style={{
                height: window.innerHeight - 135,
                width: "100%",
                backgroundColor: "#ffffff",
                marginLeft: 15,
                marginRight: 15,
                overflow: "scroll",
              }}
            >
              <Tabs defaultActiveKey={activeKey} onChange={changeTab} centered>
                <TabPane
                  tab={
                    <span style={{ display: "flex", flexDirection: "row" }}>
                      <Icon
                        path={mdiCalendarAccountOutline}
                        size={"20"}
                        color={activeKey === "1" ? colorPrm : "#c2c2c2"}
                      />
                      <Title
                        className="h5-tile"
                        level={4}
                        style={{
                          fontSize: 16,
                          marginLeft: 10,
                          marginBottom: 0,
                          color: activeKey === "1" ? colorPrm : "#c2c2c2",
                        }}
                      >
                        PLANEACION
                      </Title>
                    </span>
                  }
                  key="1"
                >
                  <FrmAgenda
                    setIsLoading={setIsLoading}
                    nombreMes={nombreMes}
                    downData={downData}
                    showActs={Math.random()}
                    setShowBoard={setShowBoard}
                    heightCale={250}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <span style={{ display: "flex", flexDirection: "row" }}>
                      <Icon
                        path={mdiCow}
                        size={"20"}
                        color={activeKey === "2" ? CST.lilaBdy : "#c2c2c2"}
                      />
                      <Title
                        className="h5-tile"
                        level={4}
                        style={{
                          fontSize: 16,
                          marginLeft: 10,
                          marginBottom: 0,
                          color: activeKey === "2" ? CST.lilaBdy : "#c2c2c2",
                        }}
                      >
                        INVENTARIO
                      </Title>
                    </span>
                  }
                  key="2"
                >
                  <Inventario
                    setIsLoading={setIsLoading}
                    nombreMes={nombreMes}
                    gstResumen={gstResumen}
                    downData={downData}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <span style={{ display: "flex", flexDirection: "row" }}>
                      <Icon
                        path={mdiTruckCheckOutline}
                        size={"20"}
                        color={activeKey === "3" ? CST.blueBdy : "#c2c2c2"}
                      />
                      <Title
                        className="h5-tile"
                        level={4}
                        style={{
                          fontSize: 16,
                          marginLeft: 10,
                          marginBottom: 0,
                          color: activeKey === "3" ? CST.blueBdy : "#c2c2c2",
                        }}
                      >
                        ENTRADAS
                      </Title>
                    </span>
                  }
                  key="3"
                >
                  <EntradasMng
                    setIsLoading={setIsLoading}
                    myNumber={myNumber}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <span style={{ display: "flex", flexDirection: "row" }}>
                      <Icon
                        path={mdiGrass}
                        size={"20"}
                        color={activeKey === "6" ? CST.darkBdy : "#c2c2c2"}
                      />
                      <Title
                        className="h5-tile"
                        level={4}
                        style={{
                          fontSize: 16,
                          marginLeft: 10,
                          marginBottom: 0,
                          color: activeKey === "6" ? CST.darkBdy : "#c2c2c2",
                        }}
                      >
                        LOTES ACTIVOS
                      </Title>
                    </span>
                  }
                  key="6"
                >
                  <CambiosLoteMng
                    setIsLoading={setIsLoading}
                    myNumber={myNumber}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <span style={{ display: "flex", flexDirection: "row" }}>
                      <Icon
                        path={mdiClipboardListOutline}
                        size={"20"}
                        color={activeKey === "8" ? CST.greenBdy : "#c2c2c2"}
                      />
                      <Title
                        className="h5-tile"
                        level={4}
                        style={{
                          fontSize: 16,
                          marginLeft: 10,
                          marginBottom: 0,
                          color: activeKey === "8" ? CST.greenBdy : "#c2c2c2",
                        }}
                      >
                        INFORMES
                      </Title>
                    </span>
                  }
                  key="8"
                >
                  <InformesDay setIsLoading={setIsLoading} />
                </TabPane>
                <TabPane
                  tab={
                    <span style={{ display: "flex", flexDirection: "row" }}>
                      <Icon
                        path={mdiClipboardListOutline}
                        size={"20"}
                        color={activeKey === "4" ? CST.yellowBdy : "#c2c2c2"}
                      />
                      <Title
                        className="h5-tile"
                        level={4}
                        style={{
                          fontSize: 16,
                          marginLeft: 10,
                          marginBottom: 0,
                          color: activeKey === "4" ? CST.yellowBdy : "#c2c2c2",
                        }}
                      >
                        ORDENES C Y S
                      </Title>
                    </span>
                  }
                  key="4"
                >
                  <CjbOrdenesFrm setIsLoading={setIsLoading} tipoGastos={8} />
                </TabPane>
              </Tabs>
            </div>
          </Suspense>
          <Drawer
            width={widthDrawer}
            placement={placeDrawer === 1 ? "left" : "right"}
            closable={false}
            visible={isDrawer}
            onClose={closeDrawer}
            getContainer={false}
            style={{ position: "absolute" }}
          >
            {drwDetalle === 1 ? (
              <LeftMenu
                userModules={userModules}
                userOptions={userOptions}
                gstOpciones={gstOpciones}
                currentUser={currentUser}
                closeDrawer={closeDrawer}
              />
            ) : null}
          </Drawer>
        </Row>
      </div>
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 7 ? (
          <ChangePass
            closeModal={closeModal}
            currentUser={currentUser}
            varEffec={Math.random()}
          />
        ) : tipoModal === 1 ? (
          <DetalleGenerados
            closeModal={closeModal}
            currentUser={currentUser}
            detalleModal={detalleModal}
            myNumber={myNumber}
          />
        ) : (
          <DetalleAlimentos
            closeModal={closeModal}
            currentUser={currentUser}
            detalleModal={detalleModal}
            myNumber={myNumber}
          />
        )}
      </ModalApp>
      <Footer
        style={{
          textAlign: "center",
          bottom: 0,
          maxHeight: 50,
          paddingBottom: 15,
          paddingTop: 15,
          backgroundColor: "#ffffff",
        }}
      >
        <MainFooter setIsLoading={setIsLoading} curFecha={new Date()} />
      </Footer>
    </Layout>
  );
}
