import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Typography,
  Select,
  Row,
  Col,
  InputNumber,
  message,
  Statistic,
  Divider,
  Popconfirm,
} from "antd";
import { CalendarOutlined, SaveOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";

//Styles
import "./EditEntrada.scss";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";

export default function EditEntrada(props) {
  const {
    currentUser,
    closeModal,
    setIsLoading,
    myNumber,
    detalleCarga,
    opcionCarga,
    detalleList,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [idComisionista, setIdComisionista] = useState(
    Number.parseInt(detalleCarga.trc_comision)
  );
  const [idTransportador, setIdTransportador] = useState(
    Number.parseInt(detalleCarga.trc_transporte)
  );
  const [balanza, setBalanza] = useState(
    Number.parseFloat(detalleCarga.ent_kilos_balanza)
  );
  const [destare, setDestare] = useState(
    Number.parseFloat(detalleCarga.ent_destare)
  );
  const [precioKg, setPrecioKg] = useState(
    Number.parseFloat(detalleCarga.ent_costo_kg)
  );
  const [comision, setComision] = useState(
    Number.parseFloat(detalleCarga.ent_comision)
  );
  const [transporte, setTransporte] = useState(
    Number.parseFloat(detalleCarga.ent_transporte)
  );
  const [dataDef, setDataDef] = useState([]);

  const { Option } = Select;

  //Listado de proveedores
  const comisonList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.comisionistas
  );
  const transportList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.trasportadores
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  useEffect(() => {
    setDataDef(detalleList);
  }, [detalleCarga]);

  const { Title } = Typography;

  //Se actualiza el comisionista
  const updateComisionista = async (value) => {
    setIdComisionista(value);
  };

  //Se actualiza el nombre
  const updateTransportador = async (value) => {
    setIdTransportador(value);
  };

  const onChangeBalanza = (value) => {
    if (Number.parseInt(value) >= 0) {
      setBalanza(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeDestare = (value) => {
    if (Number.parseInt(value) >= 0) {
      setDestare(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangePrecio = (value) => {
    if (Number.parseInt(value) >= 0) {
      setPrecioKg(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeComision = (value) => {
    if (Number.parseInt(value) >= 0) {
      setComision(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeTransporte = (value) => {
    if (Number.parseInt(value) >= 0) {
      setTransporte(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const updateEntrada = async () => {
    if (!balanza || Number.parseFloat(balanza) === 0) {
      showNotification(
        "topRight",
        2,
        "CONFIRMAR ENTRADA",
        "LOS KILOS TOTALES SON OBLIGATORIOS"
      );
      return false;
    }

    if (!precioKg || Number.parseFloat(precioKg) === 0) {
      showNotification(
        "topRight",
        2,
        "CONFIRMAR ENTRADA",
        "EL PRECIO POR KILO ES OBLIGADO"
      );
      return false;
    }

    if (
      Number.parseFloat(comision) > 0 &&
      Number.parseInt(idComisionista) === 0
    ) {
      showNotification(
        "topRight",
        2,
        "CONFIRMAR ENTRADA",
        "EL COMISIONISTA ES NECESARIO"
      );
      return false;
    }

    if (
      Number.parseFloat(transporte) > 0 &&
      Number.parseInt(idTransportador) === 0
    ) {
      showNotification(
        "topRight",
        2,
        "CONFIRMAR ENTRADA",
        "EL TRANSPORTADOR ES NECESARIO"
      );
      return false;
    }

    setLoadingButton(true);
    if (Number.parseInt(detalleCarga.ent_estado) > 1) {
      showNotification(
        "topRight",
        2,
        "CONFIRMAR ENTRADA",
        "LA ENTRADA YA FUE CONFIRMADA"
      );
    } else {
      let record = {
        ent_id: detalleCarga.ent_numero,
        cmp_id: detalleCarga.cmp_id,
        cmb_area: detalleCarga.cmb_area,
        usr_id: detalleCarga.usr_id,
        ent_fecha: detalleCarga.ent_fecha,
        ent_kilos: detalleCarga.ent_kilos,
        ent_kilos_neto: detalleCarga.ent_kilos_neto,
        ent_kilos_balanza: Number.parseFloat(balanza),
        ent_destare: Number.parseFloat(destare),
        ent_costo_kg: Number.parseFloat(precioKg),
        ent_comision: Number.parseFloat(comision),
        ent_transporte: Number.parseFloat(transporte),
        trc_comision: Number.parseInt(idComisionista),
        trc_transporte: Number.parseInt(idTransportador),
        numero: dataDef.length,
        ent_option: opcionCarga,
      };
      console.log(record);
      var val_inventory = await FRM.confirmCarga(currentUser, record);
      console.log(val_inventory);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "CONFIRMAR CARAVANA",
          "CARAVANA CONFIRMADA CON EXITO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CONFIRMAR CARAVANA",
          val_inventory.message
        );
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "DISPOSITIVO", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
      width: "8%",
    },
    {
      title: "RAZA",
      dataIndex: "grp_codigo",
      key: "grp_codigo",
      width: "2%",
    },
    {
      title: "CRUCE",
      dataIndex: "sbg_codigo",
      key: "sbg_codigo",
      width: "2%",
    },
    {
      title: "SEXO",
      dataIndex: "anm_sexo",
      key: "anm_sexo",
      width: "5%",
      render: (text, row, index) => {
        if (row.anm_sexo === 1) {
          return "MACHO";
        } else {
          return "HEMBRA";
        }
      },
    },
    {
      title: "CATEGORIA",
      dataIndex: "ctg_nombre",
      key: "ctg_nombre",
      width: "12%",
    },
    {
      title: "MESES",
      dataIndex: "anm_meses",
      key: "anm_meses",
      align: "left",
      width: "4%",
    },
    {
      title: "DIAS",
      dataIndex: "anm_dias",
      key: "anm_dias",
      align: "left",
      width: "4%",
    },
    {
      title: "UBICACION SNIG",
      dataIndex: "anm_ubica",
      key: "anm_ubica",
      width: "5%",
      align: "left",
    },
    {
      title: "Kg Bruto",
      dataIndex: "anm_peso_bruto",
      key: "anm_peso_bruto",
      width: "5%",
      align: "right",
    },
    {
      title: "Kg Neto",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      width: "5%",
      align: "right",
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"COMPLETAR Y CERRAR LA ENTRADA"}
      </Title>
      <Row gutter={8}>
        <Col span={4}>
          <Statistic
            title="Fecha"
            value={detalleCarga.ent_fecha}
            prefix={<CalendarOutlined />}
          />
        </Col>
        <Col span={8}>
          <Statistic title="Proveedor" value={detalleCarga.trc_nombre} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Origen"
            value={detalleCarga.ent_origen}
            groupSeparator=""
          />
        </Col>
        <Col span={6}>
          <Statistic title="Destino" value={detalleCarga.cmp_nombre} />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Row gutter={16}>
        <Col span={6}>
          <Title
            level={4}
            style={{
              padding: 2,
              margin: 2,
              fontWeight: 600,
              fontSize: 16,
              textAlign: "center",
            }}
          >
            KG BALANZA EN RUTA:
          </Title>
        </Col>
        <Col span={2}>
          <InputNumber
            style={{ width: "100%", textAlign: "right" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
            defaultValue={balanza}
            value={balanza}
            onChange={onChangeBalanza}
            placeholder="Kg Balanza"
          />
        </Col>
        <Col span={6}>
          <Title
            level={4}
            style={{
              padding: 2,
              margin: 2,
              fontWeight: 600,
              fontSize: 16,
              textAlign: "center",
            }}
          >
            % DE DESTARE:
          </Title>
        </Col>
        <Col span={2}>
          <InputNumber
            style={{ width: "100%", textAlign: "right" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{2})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
            defaultValue={destare}
            value={destare}
            onChange={onChangeDestare}
            placeholder="% Destare"
          />
        </Col>
        <Col span={6}>
          <Title
            level={4}
            style={{
              padding: 2,
              margin: 2,
              fontWeight: 600,
              fontSize: 16,
              textAlign: "center",
            }}
          >
            PRECIO KILOGRAMO:
          </Title>
        </Col>
        <Col span={2}>
          <InputNumber
            style={{ width: "100%", textAlign: "right" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{4})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
            defaultValue={precioKg}
            value={precioKg}
            onChange={onChangePrecio}
            placeholder="Precio x Kg"
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Row gutter={16}>
        <Col span={8}>
          <Select
            showSearch
            showArrow
            style={{ width: "100%" }}
            defaultValue={
              idComisionista > 0 ? idComisionista : "SELECCIONE COMISIONISTA"
            }
            placeholder="COMSIONISTA"
            onChange={updateComisionista}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {comisonList.map((proveedor) => (
              <Option key={proveedor.trc_id} value={proveedor.trc_id}>
                {proveedor.trc_nombre}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <InputNumber
            style={{ width: "100%", textAlign: "right" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
            defaultValue={comision}
            value={comision}
            onChange={onChangeComision}
            placeholder="% Comision"
          />
        </Col>
        <Col span={8}>
          <Select
            showSearch
            showArrow
            style={{ width: "100%" }}
            defaultValue={
              idTransportador > 0 ? idTransportador : "SELECCIONE TRANSPORTADOR"
            }
            placeholder="COMSIONISTA"
            onChange={updateTransportador}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {transportList.map((transport) => (
              <Option key={transport.trc_id} value={transport.trc_id}>
                {transport.trc_nombre}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <InputNumber
            style={{ width: "100%", textAlign: "right" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            min={0}
            defaultValue={transporte}
            value={transporte}
            onChange={onChangeTransporte}
            placeholder="$ Transporte"
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Table
        columns={header}
        dataSource={dataDef}
        bordered
        size="middle"
        scroll={{ x: "calc(700px + 50%)", y: 640 }}
      />
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <Statistic value={"Nota: " + detalleCarga.ent_nota} />
        </Col>
      </Row>

      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => updateEntrada()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={curStyle.btnOrange}
            shape="round"
            block
            loading={loadingButton}
          >
            <SaveOutlined /> ACTUAIZAR Y CERRAR LA ENTRADA
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
