import React from "react";
import { Card, Statistic, Skeleton } from "antd";
import Icon from "@mdi/react";

const TileIcon = ({
  clrH,
  clrB,
  clrF,
  title,
  texto,
  icono,
  pie,
  funcion = null,
  opcion = 0,
  chgTile = false
}) => {
  return (
    <Skeleton loading={chgTile} active>
      <Card
        style={{
          backgroundColor: "#fff",
        }}
        bordered={false}
        onClick={funcion ? () => funcion(opcion) : console.log("No funcion")}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: clrH,
            padding: 6,
            paddingLeft: 12,
            paddingRight: 12,
          }}
        >
          <Statistic
            value={title}
            valueStyle={{ color: "white", fontSize: 15 }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: clrB,
            padding: 6,
            paddingLeft: 12,
            paddingRight: 12,
          }}
        >
          <Statistic
            value={texto}
            valueStyle={{ color: "white", fontSize: 25 }}
          />
          <Icon path={icono} size={"35px"} color="white" className="icon-tile" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: clrF,
            padding: 6,
            paddingLeft: 12,
            paddingRight: 12,
          }}
        >
          <Statistic value={pie} valueStyle={{ color: "white", fontSize: 15 }} />
        </div>
      </Card>
    </Skeleton>
  );
};

export default TileIcon;
