import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  message,
  Tag,
  Modal as ModalB,
} from "antd";
import Highlighter from "react-highlight-words";

import Fab from "@material-ui/core/Fab";
import { Add } from "@material-ui/icons";

//Iconos y constantes
import {
  SearchOutlined,
  FilePdfOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Icon from "@mdi/react";

import {
  mdiCalendarTextOutline,
  mdiCalendarClock,
  mdiCalendarCheck,
  mdiAccountCashOutline,
} from "@mdi/js";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../../Modal";
import CST from "../../../../utils/CustomSettings";
import CjbGastosLcl from "./CjbGastosLcl";
import CjbGastosFrn from "./CjbGastosFrn";
import DetalleGastos from "./DetalleGastos";
import CjbPagosLcl from "./CjbPagosLcl";
import CjbPagosFrn from "./CjbPagosFrn";
import CjbPagosLclGrp from "./CjbPagosLclGrp";
import CjbPagosFrnGrp from "./CjbPagosFrnGrp";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../../utils/ShowNotification";
import FRM from "../../../../utils/FrmService";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { myNumber } from "../../../../utils/ArrayFunctions";
import { URL_FILES } from "../../../../constants";

//Estilos
import "./CjbGastosFrm.scss";

export default function CjbGastosPen(props) {
  const { setIsLoading, tipoGastos } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [listGastos, setListGastos] = useState([]);
  const [totalGastos, setTotalGastos] = useState([]);
  const [numGastos, setNumGastos] = useState([]);
  const [stateInf, setStateInf] = useState(Math.random());

  const { confirm } = ModalB;

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const currentSetup = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.utlsetup
  );

  const currentMoneda = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      let datos = await FRM.getGastos(currentUser, tipoGastos);
      if (datos.type && datos.type > 0) {
        setListGastos(datos.listado);
        setTotalGastos(datos.total_rsm);
        setNumGastos(datos.num_rsm);
      } else {
        showNotification(
          "topRight",
          1,
          "LISTADO DE GASTOS",
          "No hay GASTOS REGISTRADOS"
        );
      }
      setIsLoading(false);
    })();
  }, [stateInf]);

  //Aplazar gastos
  const aplGastos = async (gasto) => {
    //setIsLoading(true);
    message.info("ACTIVANDO EL GASTO", 3);
    let record = {
      tipoMov: 6,
      meg_estado: tipoGastos < 4 ? 3 : 1,
      meg_id: gasto.meg_id,
      meg_numero: gasto.meg_numero,
      meg_moneda: gasto.meg_moneda,
      usr_apr: gasto.usr_apr,
    };
    var val_gasto = await FRM.sendGasto(currentUser, record);
    if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
      //setStateInf(Math.random());
      showNotification("topRight", 4, "APLAZAR GASTOS", "MOVIMIENTO EXITOSO");
      setStateInf(Math.random());
    } else {
      showNotification("topRight", 2, "APLAZAR GASTOS", val_gasto.message);
    }
    //setIsLoading(false);
  };

  const deleRecord = async (record) => {
    confirm({
      title: "EILIMINAR PAGO A " + record.trc_nombre,
      icon: <ExclamationCircleOutlined />,
      okText: "ELIMINAR",
      cancelText: "Cancelar",
      content: "DESEA ELIMINAR EL GASTO?",
      onOk() {
        deleGastos(record);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //Eliminar gastos
  const deleGastos = async (gasto) => {
    setIsLoading(true);
    let record = {
      tipoMov: 9,
      meg_estado: 5,
      meg_id: gasto.meg_id,
      meg_numero: gasto.meg_numero,
      meg_moneda: gasto.meg_moneda,
      usr_apr: gasto.usr_apr,
    };
    var val_gasto = await FRM.sendGasto(currentUser, record);
    if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
      setStateInf(Math.random());
      showNotification("topRight", 4, "ELIMINAR GASTOS", "MOVIMIENTO EXITOSO");
    } else {
      showNotification("topRight", 2, "ELIMINAR GASTOS", val_gasto.message);
    }
    setIsLoading(false);
  };

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const openAdjunto = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/gastos/" + record.meg_adjunto;
    window.open(`${url_temp}`);
  };

  //Nuevas funciones de busqueda de columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //Fin de nuevas funciones de busqueda

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={
            record.meg_moneda === 1 ? curStyle.btnDanger : curStyle.btnSuccess
          }
          size="large"
          shape="circle"
        >
          {record.meg_numero}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "meg_fecha",
      key: "meg_fecha",
      width: 180,
      ...getColumnSearchProps("meg_fecha"),
    },
    {
      title: "BENEFICIARIO", //10%
      dataIndex: "trc_nombre",
      key: "trc_nombre",
      width: 350,
      ...getColumnSearchProps("trc_nombre"),
    },
    {
      title: "CONCEPTO",
      dataIndex: "cnc_nombre",
      key: "cnc_nombre",
      width: 350,
    },
    {
      title: "AUTORIZA",
      dataIndex: "autoriza",
      key: "autoriza",
      width: 350,
    },
    {
      title: "ESTADO",
      dataIndex: "meg_estado",
      key: "meg_estado",
      width: 100,
      render: (text, row, index) => {
        return row.meg_estado === 0 ? (
          <Tag color="green">PENDIENTE</Tag>
        ) : row.meg_estado === 1 ? (
          <Tag color="geekblue">APROBADO</Tag>
        ) : row.meg_estado === 2 ? (
          <Tag color="volcano">CANCELADO</Tag>
        ) : (
          <Tag color="purple">APLAZADO</Tag>
        );
      },
    },
    {
      title: "F. APRUEBA",
      dataIndex: "meg_faprueba",
      key: "meg_faprueba",
      width: 180,
      render: (text, row, index) => {
        if (row.meg_faprueba) {
          return row.meg_faprueba.toString().substr(0, 10);
        } else {
          return "...";
        }
      },
    },
    {
      title: "MONTO",
      dataIndex: "meg_importe",
      key: "meg_importe",
      align: "right",
      width: 150,
      render: (text, row, index) => {
        return row.meg_moneda === 1
          ? currentMoneda.simbolo + myNumber(1, row.meg_importe)
          : currentMoneda.simbolo_frn + myNumber(1, row.meg_importe);
      },
    },
    {
      title: "MONEDA",
      dataIndex: "meg_moneda",
      key: "meg_moneda",
      width: 150,
      render: (text, row, index) => {
        return row.meg_moneda === 1 ? (
          <Tag color="green">{currentMoneda.moneda}</Tag>
        ) : (
          <Tag color="volcano">{currentMoneda.moneda_frn}</Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="large"
          shape="circle"
          icon={<FilePdfOutlined />}
          onClick={() =>
            record.meg_adjunto
              ? openAdjunto(record)
              : message.error("NO HAY ARCHIVO ADJUNTO", 1)
          }
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => openModal(record, 3, "900px")}
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnWarning}
          size="large"
          shape="circle"
          onClick={() =>
            record.meg_estado === 1 || record.meg_estado === 3
              ? aplGastos(record)
              : message.error("EL GASTO YA ESTA APLICADO", 1)
          }
        >
          <Icon path={mdiCalendarClock} size={"23"} />
        </Button>
      ),
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={
            record.meg_moneda === 1 ? curStyle.btnDanger : curStyle.btnSuccess
          }
          size="large"
          shape="circle"
          onClick={() =>
            record.meg_moneda === 1 && record.meg_estado === 1
              ? openModal(record, 1, "1000px")
              : record.meg_moneda === 2 && record.meg_estado === 1
                ? openModal(record, 2, "1000px")
                : message.info("EL GASTO ESTA APLAZADO... VERIFIQUE")
          }
        >
          <Icon path={mdiAccountCashOutline} size={"23"} />
        </Button>
      ),
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnLila}
          size="large"
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() =>
            record.meg_estado === 3
              ? deleRecord(record)
              : message.error("EL GASTO YA ESTA APROBADO", 1)
          }
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listGastos}
        scroll={{ x: 1500 }}
        style={{ padding: 3, marginTop: 5 }}
      />
      {Number.parseInt(tipoGastos) < 4 ? (
        <Fab
          color="primary"
          aria-label="add"
          style={{
            background: tipoGastos === 2 ? CST.orangeBdy : CST.greenBdy,
            position: "absolute",
            left: 50,
            bottom: 20,
          }}
          onClick={() =>
            tipoGastos === 2
              ? openModal(null, 4, "900px")
              : openModal(null, 5, "900px")
          }
        >
          <Add />
        </Fab>
      ) : null}
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 1 ? (
          <CjbPagosLcl
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            currentMoneda={currentMoneda}
          />
        ) : tipoModal === 2 ? (
          <CjbPagosFrn
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            currentMoneda={currentMoneda}
          />
        ) : tipoModal === 3 ? (
          <DetalleGastos
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            currentMoneda={currentMoneda}
          />
        ) : tipoModal === 4 ? (
          <CjbPagosLclGrp
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            totalGastos={totalGastos}
            numGastos={numGastos}
            currentMoneda={currentMoneda}
          />
        ) : (
          <CjbPagosFrnGrp
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            totalGastos={totalGastos}
            numGastos={numGastos}
            currentMoneda={currentMoneda}
          />
        )}
      </ModalApp>
    </div>
  );
}
