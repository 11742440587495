import React, { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  Divider,
  Statistic,
  Tag,
  message,
  Table,
  Tabs,
  DatePicker,
  InputNumber,
} from "antd";
import {
  UploadOutlined,
  CalendarOutlined,
  FilePdfOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../../actions/rolEnviromentActions";
//Styles
import "./CjbGastosFrm.scss";

// componentes
import FRM from "../../../../utils/FrmService";
import { reloadData } from "../../../../utils/ReloadEnv";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { URL_FILES } from "../../../../constants";
import CST from "../../../../utils/CustomSettings";

export default function CjbPagosLcl(props) {
  const {
    currentUser,
    closeModal,
    setStateInf,
    currentSetup,
    myNumber,
    detalleModal,
    currentMoneda,
    hijosDet,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [idCnc, setIdCnc] = useState(0);
  const [detalleCnc, setDetalleCnc] = useState("");
  const [cantidad, setCantidad] = useState(0);
  const [nota, setNota] = useState("");
  const [fechaGto, setFechaGto] = useState(null);

  const { Option } = Select;
  const [form] = Form.useForm();
  const { TabPane } = Tabs;

  const openAdjunto = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/gastos/" + record.meg_adjunto;
    window.open(`${url_temp}`);
  };

  const openAttach = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/notascrd/" + record.ntc_adjunto;
    window.open(`${url_temp}`);
  };

  //Listado de conceptos
  const listaCnc = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.conceptos_gto
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaGto(dateString);
  }

  //Se actualiza el concepto
  const updateCnc = async (value) => {
    setIdCnc(value);
  };

  const onChangeDeta = (e) => {
    const detaCnc = e.target.value;
    setDetalleCnc(detaCnc.toUpperCase());
  };

  const onChangeCtd = (value) => {
    if (
      Number.parseInt(value) >= 0 &&
      Number.parseInt(value) <= Number.parseInt(detalleModal.meg_cantidad)
    ) {
      setCantidad(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  //GESTION DE CARGAR DE ARCHIVOS PDF
  // specify upload params and url for your files
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    body.append("file", file);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return { url: "https://api.aal-erp.com/frm/api/712", body, headers };
  };

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const registrarGasto = async () => {
    if (
      Number.parseInt(idCnc) === 0 ||
      detalleCnc.length === 0 ||
      fechaGto.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "REGISTRAR GASTOS",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      setLoadingButton(true);
      let record = {
        tipoMov: 21,
        idMov: 0,
        numero_anm: 0,
        meg_moneda: detalleModal.meg_moneda,
        meg_estado: 4,
        meg_fecha: fechaGto,
        meg_nota: nota,
        cnc_id: idCnc,
        trc_id: detalleModal.trc_id,
        meg_detalle: detalleCnc,
        meg_bruto:
          Number.parseFloat(
            detalleModal.meg_importe / detalleModal.meg_cantidad
          ) * Number.parseInt(cantidad),
        meg_credito: 0,
        meg_importe:
          Number.parseFloat(
            detalleModal.meg_importe / detalleModal.meg_cantidad
          ) * Number.parseInt(cantidad),
        meg_inicial:
          Number.parseFloat(
            detalleModal.meg_importe / detalleModal.meg_cantidad
          ) * Number.parseInt(cantidad),
        meg_factura: detalleModal.meg_factura,
        meg_efectivo: 0,
        meg_transfer: 0,
        usr_id: currentUser.id,
        usr_apr: detalleModal.usr_apr,
        meg_faprueba: detalleModal.meg_faprueba,
        meg_nota_aprueba:
          "SE GENERA COMO ASOCIADO DESDE EL EGRESO " + detalleModal.meg_numero,
        meg_fdesembolso: detalleModal.meg_fdesembolso,
        meg_fpago: detalleModal.meg_fpago,
        usr_pago: detalleModal.usr_pago,
        meg_adjunto: detalleModal.meg_adjunto,

        meg_pago: detalleModal.meg_pago,
        meg_padre: detalleModal.meg_numero,
        meg_cantidad: cantidad,
        ord_id: 0,
        old_egreso: detalleModal.meg_numero,
      };

      var val_gasto = await FRM.sendGasto(currentUser, record);
      if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
        updateEnviroment(val_gasto.datos.rol_entorno);
        setStateInf(Math.random());
        showNotification(
          "topRight",
          4,
          "APROBACION DE PAGO",
          "PROCESO REALIZADO DE FORMA EXITOSA"
        );
      } else {
        showNotification("topRight", 2, "APROBAR PAGOS", val_gasto.message);
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, row, index) => {
        return row.meg_moneda === 1 ? (
          <Tag color="green" style={{ fontSize: 15 }}>
            {row.meg_numero}
          </Tag>
        ) : (
          <Tag color="orange" style={{ fontSize: 15 }}>
            {row.meg_numero}
          </Tag>
        );
      },
    },
    {
      title: "FECHA", //10%
      dataIndex: "meg_fecha",
      key: "meg_fecha",
    },
    {
      title: "CONCEPTO",
      dataIndex: "cnc_nombre",
      key: "cnc_nombre",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 15 }}>
            {row.cnc_nombre + " - " + row.cls_nombre}
          </Tag>
        );
      },
    },
    {
      title: "CANTD",
      dataIndex: "meg_cantidad",
      key: "meg_cantidad",
      align: "right",
      render: (text, row, index) => {
        return row.meg_moneda === 1 ? (
          <Tag color="green" style={{ fontSize: 15 }}>
            {myNumber(1, row.meg_cantidad)}
          </Tag>
        ) : (
          <Tag color="orange" style={{ fontSize: 15 }}>
            {myNumber(1, row.meg_cantidad)}
          </Tag>
        );
      },
    },
    {
      title: "MONTO",
      dataIndex: "meg_importe",
      key: "meg_importe",
      align: "right",
      render: (text, row, index) => {
        return row.meg_moneda === 1 ? (
          <Tag color="green" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo + myNumber(1, row.meg_importe)}
          </Tag>
        ) : (
          <Tag color="orange" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo_frn + myNumber(1, row.meg_importe)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="medium"
          shape="circle"
          icon={<FilePdfOutlined />}
          onClick={() =>
            record.meg_adjunto
              ? openAdjunto(record)
              : message.error("NO HAY ARCHIVO ADJUNTO", 1)
          }
        />
      ),
    },
  ];

  return (
    <div className="upload-panel">
      <Divider
        style={{
          marginTop: 15,
          paddingTop: 10,
          marginBottom: 20,
          color: detalleModal.meg_moneda === 2 ? CST.orangeBdy : CST.greenBdy,
          fontSize: 20,
        }}
      >
        {detalleModal.meg_moneda === 1
          ? "DATOS GENERALES DEL GASTO EN DOLARES"
          : "DATOS GENERALES DEL GASTO EN PESOS UYU"}
      </Divider>
      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col span={4}>
            <Statistic
              title="Fecha Generacion"
              value={detalleModal.meg_fecha}
              prefix={<CalendarOutlined />}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={4}>
            <Statistic
              title="Fecha Desembolso"
              value={detalleModal.meg_fdesembolso}
              prefix={<CalendarOutlined />}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Usuario que Solicita"
              value={detalleModal.registro}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Usuario que Autoriza"
              value={detalleModal.autoriza}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={16}>
            <Statistic
              title="Beneficiario"
              value={detalleModal.trc_nombre}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={2}>
            <Statistic
              title="Cantidad"
              value={myNumber(1, detalleModal.meg_cantidad)}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Saldo Actual"
              value={
                detalleModal.meg_moneda === 1
                  ? currentMoneda.simbolo +
                    myNumber(1, detalleModal.meg_importe)
                  : currentMoneda.simbolo_frn +
                    myNumber(1, detalleModal.meg_importe)
              }
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <div
          style={{
            display:
              currentUser.id === 3 && detalleModal.meg_estado === 4
                ? "block"
                : "none",
          }}
        >
          <Divider
            style={{
              marginTop: 5,
              paddingTop: 5,
              marginBottom: 5,
            }}
          >
            {" "}
            Crear Nuevo Asociado{" "}
          </Divider>
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item label="Fecha de Emision">
                <DatePicker
                  onChange={onChangeFecha}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item label="Concepto a Imputar">
                <Select
                  showSearch
                  showArrow
                  style={{ width: "100%" }}
                  defaultValue={idCnc > 0 ? idCnc : "ELIJA CONCEPTO"}
                  placeholder="CONCEPTO"
                  onChange={updateCnc}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {listaCnc.map((conceptos) => (
                    <Option key={conceptos.cnc_id} value={conceptos.cnc_id}>
                      {conceptos.cnc_describe}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Cantidad">
                <InputNumber
                  style={{ width: "100%", textAlign: "right" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{0})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  min={0}
                  defaultValue={cantidad}
                  value={cantidad}
                  onChange={onChangeCtd}
                  placeholder="Valor"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Detalle del Concepto">
                <Input
                  placeholder="Detalle Concepto"
                  defaultValue={""}
                  style={{ width: "100%" }}
                  value={detalleCnc}
                  onChange={onChangeDeta}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <TextArea
                rows={2}
                value={nota}
                placeholder={"Observaciones al Aprobar"}
                onChange={onChangeNota}
                allowClear
              />
            </Col>
          </Row>
        </div>
      </Form>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }}>
        {" "}
        Gastos Asociados{" "}
      </Divider>
      <Table
        columns={columns}
        dataSource={hijosDet}
        style={{ padding: 3, marginTop: 5 }}
      />

      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => registrarGasto()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={
              detalleModal.meg_moneda === 2
                ? curStyle.btnOrange
                : curStyle.btnSuccess
            }
            shape="round"
            block
            style={{
              display:
                currentUser.id === 3 && detalleModal.meg_estado === 4
                  ? "block"
                  : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> CREAR GASTO ASOCIADO
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
