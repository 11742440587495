import React, { useState } from "react";
import {
  Form,
  Modal,
  Button,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  InputNumber,
  Switch,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../../actions/rolEnviromentActions";
//Styles
import "./CjbGastosFrm.scss";

// componentes
import FRM from "../../../../utils/FrmService";
import { reloadData } from "../../../../utils/ReloadEnv";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { URL_FILES } from "../../../../constants";
import CST from "../../../../utils/CustomSettings";

export default function CjbGastosLcl(props) {
  const {
    currentUser,
    closeModal,
    setStateInf,
    currentSetup,
    myNumber,
    detalleModal,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [usrId, setUsrId] = useState(currentUser.id);
  const [idProveedor, setIdProveedor] = useState(detalleModal.trc_id);
  const [idCnc, setIdCnc] = useState(0);
  const [detalleCnc, setDetalleCnc] = useState(detalleModal.ord_detalle);
  const [valorGto, setValorGto] = useState(
    Number.parseFloat(detalleModal.ord_importe)
  );
  const [valorCrd, setValorCrd] = useState(0);
  const [valorNto, setValorNto] = useState(
    Number.parseFloat(detalleModal.ord_importe)
  );
  const [facturaGto, setFacturaGto] = useState("");
  const [idEfe, setIdEfe] = useState(0);
  const [valorEfe, setValorEfe] = useState(0);
  const [estadoGto, setEstadoGto] = useState(3);
  const [idBnc, setIdBnc] = useState(0);
  const [valorBnc, setValorBnc] = useState(0);
  const [nota, setNota] = useState(detalleModal.ord_nota);
  const [fechaGto, setFechaGto] = useState(null);
  const [idAutoriza, setIdAutoriza] = useState(0);
  const [nameAdjunto, setNameAdjunto] = useState("");
  const [cantidad, setCantidad] = useState(1);

  const { Option } = Select;
  const [form] = Form.useForm();

  //Listado de proveedores
  const suppliersList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.servicios
  );

  //Listado de conceptos
  const listaCnc = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.conceptos_gto
  );

  //Listado de conceptos
  const cuentasLcl = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.cuentas_lcl
  );

  const autoriza = useSelector(
    (state) => state.enviroment.enviroment.gst_users_aut
  );

  const openAdjunto = (pdf_file) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp = URL_FILES + currentUser.dominio + "/temporal/" + pdf_file;
    window.open(`${url_temp}`);
  };

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaGto(dateString);
  }
  //Se actualiza el nombre
  const updateProveedor = async (value) => {
    setIdProveedor(value);
  };

  //Se actualiza el concepto
  const updateCnc = async (value) => {
    setIdCnc(value);
  };

  const onChangeDeta = (e) => {
    const detaCnc = e.target.value;
    setDetalleCnc(detaCnc.toUpperCase());
  };

  const updateAutoriza = async (value) => {
    setIdAutoriza(value);
  };

  //Se actualiza cuenta de banco
  const updateBnc = async (value) => {
    setIdBnc(value);
    setValorBnc(0);
  };

  const onChangeGto = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setValorGto(value);
      setValorNto(Number.parseFloat(value) - Number.parseFloat(valorCrd));
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeCrd = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setValorCrd(value);
      setValorNto(Number.parseFloat(valorGto) - Number.parseFloat(value));
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeSta = (checked) => {
    setEstadoGto(checked ? 1 : 3);
  };

  const onChangeFact = (e) => {
    const facCnc = e.target.value;
    setFacturaGto(facCnc.toUpperCase());
  };

  const onChangeBnc = (value) => {
    if (Number.parseFloat(value) >= 0 && Number.parseInt(idBnc) > 0) {
      setValorBnc(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  const onChangeCtd = (value) => {
    if (Number.parseInt(value) > 0) {
      setCantidad(value);
    } else {
      setCantidad(1);
    }
  };

  //GESTION DE CARGAR DE ARCHIVOS PDF
  // specify upload params and url for your files
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    body.append("file", file);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return { url: "https://api.aal-erp.com/frm/api/712", body, headers };
  };

  // called every time a file's `status` changes
  //const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }
  const handleChangeStatus = ({ meta, remove }, status) => {
    console.log(meta);
    if (status === "headers_received") {
      setNameAdjunto(meta.name);
      message.success("CARGA DE ADJUNTO EXITOSA", 1);
      //alert(`${meta.name} uploaded!`);
      remove();
    } else if (status === "aborted") {
      message.error("CARGA DE ADJUNTO FALLIDA", 2);
    }
  };
  //FIN GESTION DE CARGA DE ARCHIVOS

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const registrarGasto = async () => {
    if (
      Number.parseInt(idProveedor) === 0 ||
      Number.parseInt(idCnc) === 0 ||
      detalleCnc.length === 0 ||
      fechaGto.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "REGISTRAR GASTOS",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      if (Number.parseFloat(valorGto) <= Number.parseFloat(valorCrd)) {
        showNotification(
          "topRight",
          2,
          "REGISTRAR GASTOS",
          "EL VALOR DEL GASTO NO PUEDE SER CERO"
        );
      } else {
        setLoadingButton(true);
        let record = {
          tipoMov: 2,
          idMov: 0,
          numero_anm: 0,
          usr_id: currentUser.id,
          usr_apr: idAutoriza,
          trc_id: idProveedor,
          cnc_id: idCnc,
          efe_id: 0,
          bnc_id: idBnc,
          meg_detalle: detalleCnc,
          meg_cantidad: cantidad,
          meg_bruto: Number.parseFloat(valorGto),
          meg_credito: Number.parseFloat(valorCrd),
          meg_importe: Number.parseFloat(valorNto),
          meg_factura: facturaGto,
          meg_estado: Number.parseInt(estadoGto),
          meg_efectivo: 0,
          meg_transfer: Number.parseFloat(valorBnc),
          meg_nota: nota,
          meg_fecha: fechaGto,
          meg_adjunto: nameAdjunto.length === 0 ? "NA" : nameAdjunto,
          meg_moneda: detalleModal.ord_moneda,
          ord_id: detalleModal.ord_id,
          nameAdj: detalleModal.ord_adjunto,
        };

        var val_gasto = await FRM.sendGasto(currentUser, record);
        if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
          updateEnviroment(val_gasto.datos.rol_entorno);
          setStateInf(Math.random());
          showNotification(
            "topRight",
            4,
            "CARGAR GASTOS",
            "REGISTRO DE GASTO EXITOSO"
          );
        } else {
          showNotification("topRight", 2, "CARGAR GASTOS", val_gasto.message);
        }
        setLoadingButton(false);
        closeModal();
      }
    }
  };

  return (
    <div className="upload-panel">
      <Divider
        style={{
          marginTop: 15,
          paddingTop: 10,
          marginBottom: 20,
          color: detalleModal.ord_moneda === 1 ? CST.orangeBdy : CST.greenBdy,
          fontSize: 20,
        }}
      >
        {detalleModal.ord_moneda === 1
          ? "PAGAR ORDEN EN DOLARES A " + detalleModal.trc_nombre
          : "PAGAR ORDEN EN PESOS UYU A " + detalleModal.trc_nombre}
      </Divider>
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Fecha de Emision">
              <DatePicker onChange={onChangeFecha} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="Usuario que Autoriza">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={
                  idAutoriza === 0
                    ? "SELECCIONE USUARIO QUE AUTORIZA"
                    : idAutoriza
                }
                placeholder="USUARIO AUTORIZA"
                onChange={updateAutoriza}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {autoriza.map((autor) => (
                  <Option key={autor.id} value={autor.id}>
                    {autor.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Concepto a Imputar">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={idCnc > 0 ? idCnc : "ELIJA CONCEPTO"}
                placeholder="CONCEPTO"
                onChange={updateCnc}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {listaCnc.map((conceptos) => (
                  <Option key={conceptos.cnc_id} value={conceptos.cnc_id}>
                    {conceptos.cnc_describe}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Detalle del Concepto">
              <Input
                placeholder="Detalle Concepto"
                defaultValue={""}
                style={{ width: "100%" }}
                value={detalleCnc}
                onChange={onChangeDeta}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={5}>
            <Form.Item label="Numero Factura">
              <Input
                placeholder="Factura"
                defaultValue={""}
                style={{ width: "100%" }}
                value={facturaGto}
                onChange={onChangeFact}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Cantidad">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{0})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={cantidad}
                value={cantidad}
                onChange={onChangeCtd}
                placeholder="Valor"
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Monto Bruto">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={valorGto}
                value={valorGto}
                onChange={onChangeGto}
                placeholder="Valor"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Valor N. CRD">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={valorCrd}
                value={valorCrd}
                onChange={onChangeCrd}
                placeholder="Valor"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Valor Neto">
              <InputNumber
                style={{
                  width: "100%",
                  textAlign: "right",
                  color: "green",
                  fontWeight: "bold",
                }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={valorNto}
                value={valorNto}
                placeholder="Valor"
                readOnly
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Aprobar">
              <Switch
                onChange={onChangeSta}
                checkedChildren="ENVIAR"
                unCheckedChildren="APLAZAR"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <TextArea
              rows={2}
              value={nota}
              placeholder={"Observaciones"}
              onChange={onChangeNota}
              allowClear
            />
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
        {"Comprobante de respaldo"}
      </Divider>
      {nameAdjunto.length > 0 ? (
        <Button
          type="link"
          size="large"
          onClick={() => openAdjunto(nameAdjunto)}
        >
          {nameAdjunto}
        </Button>
      ) : null}
      <div
        style={{
          textAlign: "center",
          marginTop: 20,
          border: 0,
        }}
      >
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          autoUpload={true}
          maxFiles={1}
          multiple={false}
          canCancel={false}
          accept=".pdf"
          inputContent={"Arrastre o seleccione el PDF a adjuntar"}
          inputWithFilesContent={"Agregar Archivos"}
          submitButtonContent={"Subir Archivos"}
        />
      </div>

      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => registrarGasto()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={curStyle.btnOrange}
            shape="round"
            block
            style={{
              display: Number.parseFloat(valorGto) > 0 ? "block" : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> REGISTRAR EL GASTO
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}

//https://pastebin.com/yKpF4A2h

function info(text) {
  Modal.info({
    title: "OBSERVACIONES",
    content: (
      <div>
        <p>{text}</p>
      </div>
    ),
    onOk() {},
  });
}
