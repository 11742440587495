import React, { useState, useEffect } from "react";
import { Table, Input, Button, Space, Typography, message } from "antd";
import Highlighter from "react-highlight-words";

//Floating Buttons
import FloatingButtons from "react-floating-buttons";
import entra from "../../../assets/icons/truck-check-outline.svg";
import sale from "../../../assets/icons/truck-fast-outline.svg";
import antipa from "../../../assets/icons/pill.svg";
import vacuna from "../../../assets/icons/needle.svg";
import actpend from "../../../assets/icons/calendar-question.svg";
import actclose from "../../../assets/icons/calendar-check-outline.svg";
import grass from "../../../assets/icons/grass.svg";
import cowboy from "../../../assets/icons/account-cowboy-hat.svg";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../Modal";
import CST from "../../../utils/CustomSettings";
import CreateActivity from "./CreateActivity";
import CloseActivity from "./CloseActivity";
import ApproveActivity from "./ApproveActivity";
import DetalleActivity from "./DetalleActivity";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";

//Estilos
import "./FrmAgenda.scss";
import { myNumber } from "../../../utils/ArrayFunctions";

//Importar componente de agenda
const MainAgenda = React.lazy(() => import("../../MainAgenda"));

export default function FrmAgenda(props) {
  const {
    setIsLoading,
    nombreMes,
    downData,
    showActs,
    setShowBoard,
    heightCale,
  } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [stateAgn, setStateAgn] = useState(Math.random());
  const [detalleModal, setDetalleModal] = useState({});
  const [listTareas, setListTareas] = useState([]);
  const [tipoAgenda, setTipoAgenda] = useState(1);

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      let datos = await FRM.getAgenda(currentUser, tipoAgenda);
      if (datos.type && datos.type > 0) {
        const newTask = [];
        datos.listado.forEach((task) => {
          let color_atv =
            task.pai_id === 1
              ? CST.blueBdy
              : task.pai_id === 2
              ? CST.greenBdy
              : task.pai_id === 3
              ? CST.darkBdy
              : task.tac_color;
          let reg_atv =
            task.pai_id === 1
              ? "LEO"
              : task.pai_id === 2
              ? "NACHO"
              : task.pai_id === 3
              ? "AILTON"
              : task.tac_color;
          let estado =
            task.atv_estado === 0
              ? " (PEN - " + reg_atv + ")"
              : task.atv_estado === 1
              ? " (APR - " + reg_atv + ")"
              : " (FIN - " + reg_atv + ")";
          let newdata = {
            id: task.atv_id,
            id_atv: task.id,
            title:
              Number.parseInt(task.atv_estado) === 0 &&
              Number.parseInt(task.usr_apr) === Number.parseInt(currentUser.id)
                ? task.tac_nombre + estado + "(*)"
                : task.tac_nombre + estado,
            allDay: false,
            start: new Date(
              Number.parseInt(task.atv_ano_ini),
              Number.parseInt(task.atv_mes_ini) - 1,
              Number.parseInt(task.atv_dia_ini),
              Number.parseInt(task.atv_hora_ini),
              Number.parseInt(task.atv_min_ini),
              0
            ),
            end: new Date(
              Number.parseInt(task.atv_ano_fin),
              Number.parseInt(task.atv_mes_fin) - 1,
              Number.parseInt(task.atv_dia_fin),
              Number.parseInt(task.atv_hora_fin),
              Number.parseInt(task.atv_min_fin),
              0
            ),
            desc: task.atv_nota,
            color: color_atv,
            registro: task.registro,
            asigna: task.asignada,
            autoriza: task.autoriza ? task.autoriza : "...",
            faprueba: task.atv_faprueba
              ? "El " + task.atv_faprueba.toString().substr(0, 10)
              : "Observaciones al aprobar",
            haprueba: task.atv_faprueba
              ? " a las " +
                task.atv_faprueba.toString().substr(11, 5) +
                " se aprueba y anota:"
              : ":",
            fcierre: task.atv_cierre
              ? "El " + task.atv_cierre.toString().substr(0, 10)
              : "Observaciones al cerrar",
            hcierre: task.atv_cierre
              ? " a las " +
                task.atv_cierre.toString().substr(11, 5) +
                " se cierra y anota:"
              : ":",
            nota_aprueba: task.atv_nota_aprueba ? task.atv_nota_aprueba : "...",
            nota_cierre: task.atv_nota_crr ? task.atv_nota_crr : "...",
            usr_rgs: task.usr_rgs,
            usr_asg: task.usr_asg,
            usr_apr: task.usr_apr,
            tac_id: task.tac_id,
            ano_ini: task.atv_ano_ini,
            mes_ini: task.atv_mes_ini,
            dia_ini: task.atv_dia_ini,
            hora_ini: task.atv_hora_ini,
            min_ini: task.atv_min_ini,
            ano_fin: task.atv_ano_fin,
            mes_fin: task.atv_mes_fin,
            dia_fin: task.atv_dia_fin,
            hora_fin: task.atv_hora_fin,
            min_fin: task.atv_min_fin,
            atv_estado: task.atv_estado,
            atv_imagen1: task.atv_imagen1 ? task.atv_imagen1 : "default.png",
            atv_imagen2: task.atv_imagen2 ? task.atv_imagen2 : "default.png",
            atv_imagen3: task.atv_imagen3 ? task.atv_imagen3 : "default.png",
            atv_imagen4: task.atv_imagen4 ? task.atv_imagen4 : "default.png",
            atv_padre: task.atv_padre,
            atv_repetir: task.atv_repetir,
            atv_fotos: task.atv_fotos,
          };
          newTask.push(newdata);
        });
        setListTareas(newTask);
      } else {
        showNotification(
          "topRight",
          1,
          "CARGAR AGENDA",
          "No se encontraron actividades!"
        );
      }
      setIsLoading(false);
    })();
  }, [stateAgn]);

  //const { confirm } = Modal;
  const { Title } = Typography;

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  //Funcion para editar la tarea dependiendo del rol
  const editTarea = (evento) => {
    //Opcion para autorizar una actividad
    if (
      Number.parseInt(evento.atv_estado) === 0 &&
      Number.parseInt(evento.usr_apr) === Number.parseInt(currentUser.id)
    ) {
      openModal(evento, 8, "900px");
    }

    //Opcion para cerrar una actividad
    if (
      Number.parseInt(evento.atv_estado) === 1 &&
      Number.parseInt(evento.usr_asg) === Number.parseInt(currentUser.id)
    ) {
      openModal(evento, 7, "900px");
    }

    //Opcion para consultar una actividad
    if (
      Number.parseInt(evento.atv_estado) === 0 &&
      Number.parseInt(evento.usr_asg) === Number.parseInt(currentUser.id)
    ) {
      openModal(evento, 9, "900px");
    }

    //Opcion para consultar una actividad
    if (
      Number.parseInt(evento.atv_estado) === 1 &&
      Number.parseInt(evento.usr_apr) === Number.parseInt(currentUser.id)
    ) {
      openModal(evento, 9, "900px");
    }

    //Opcion para consultar una actividad
    if (
      Number.parseInt(evento.usr_apr) !== Number.parseInt(currentUser.id) &&
      Number.parseInt(evento.usr_asg) !== Number.parseInt(currentUser.id)
    ) {
      openModal(evento, 9, "900px");
    }

    //Opcion para consultar una actividad
    if (Number.parseInt(evento.atv_estado) === 2) {
      openModal(evento, 9, "900px");
    }
  };

  //Funcion para editar la tarea dependiendo del rol
  const moveTarea = (evento, start, end) => {
    console.log(start);
    //message.success(start);
  };

  //Funcion para refrescar la agenda de tareas
  const changeAgenda = async () => {
    if (tipoAgenda === 1) {
      setTipoAgenda(2);
      setStateAgn(Math.random());
      message.success("procesado 1");
    } else {
      setTipoAgenda(1);
      setStateAgn(Math.random());
      message.success("procesado 2");
    }
  };

  //Definicion de los floating
  const buttonsList = [
    {
      onClick: () => openModal(null, 1, "600px"),
      src: entra,
    },
    {
      onClick: () => openModal(null, 2, "600px"),
      src: grass,
    },
    {
      onClick: () => openModal(null, 3, "600px"),
      src: antipa,
    },
    {
      onClick: () => openModal(null, 4, "600px"),
      src: vacuna,
    },
    {
      onClick: () => openModal(null, 5, "600px"),
      src: sale,
    },
    {
      onClick: () => openModal(null, 6, "600px"),
      src: cowboy,
    },
    {
      onClick: () => changeAgenda(),
      src: tipoAgenda === 1 ? actclose : actpend,
    },
  ];
  //Fin floating

  //Renderizacion del componente
  return (
    <div className="main-content">
      <div
        style={{
          padding: 10,
          height: window.innerHeight - heightCale,
          overflow: "auto",
        }}
      >
        {listTareas.length > 0 ? (
          <MainAgenda
            editTarea={editTarea}
            moveTarea={moveTarea}
            listTareas={listTareas}
            refres={Math.random()}
          />
        ) : (
          <MainAgenda
            editTarea={editTarea}
            moveTarea={moveTarea}
            listTareas={listTareas}
            refres={Math.random()}
          />
        )}
      </div>
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 0 ? (
          <CreateActivity
            currentUser={currentUser}
            closeModal={closeModal}
            tipoModal={tipoModal}
            setStateAgn={setStateAgn}
            setShowBoard={setShowBoard}
          />
        ) : tipoModal === 7 ? (
          <CloseActivity
            currentUser={currentUser}
            closeModal={closeModal}
            tipoModal={tipoModal}
            setStateAgn={setStateAgn}
            setShowBoard={setShowBoard}
            detalleModal={detalleModal}
            tipoAction={1}
          />
        ) : tipoModal === 8 ? (
          <ApproveActivity
            currentUser={currentUser}
            closeModal={closeModal}
            tipoModal={tipoModal}
            setStateAgn={setStateAgn}
            setShowBoard={setShowBoard}
            detalleModal={detalleModal}
          />
        ) : tipoModal === 9 ? (
          <DetalleActivity
            detalleModal={detalleModal}
            currentUser={currentUser}
          />
        ) : (
          <CreateActivity
            currentUser={currentUser}
            closeModal={closeModal}
            tipoModal={tipoModal}
            setStateAgn={setStateAgn}
            setShowBoard={setShowBoard}
          />
        )}
      </ModalApp>
      <FloatingButtons
        buttonType="plus"
        buttonColor={"white"}
        backgroundColor={CST.lilaBdy}
        itemBackgroundColor={"white"}
        dimension={55}
        buttonsList={buttonsList}
        top={"calc(96% - 30px)"}
        left={20}
        direction="right"
        degree={0}
        distance={70}
        style={{ zIndex: 10 }}
      />
    </div>
  );
}
