import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  message,
  DatePicker,
  Divider,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./CreateBaja.scss";

// Excel Render
import { ExcelRenderer } from "react-excel-renderer";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";

export default function CreateBaja(props) {
  const {
    currentUser,
    closeModal,
    setIsLoading,
    myNumber,
    detalleCarga,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [idOrigen, setIdOrigen] = useState(detalleCarga.cmp_id);
  const [idMtvm, setIdMtvm] = useState(0);
  const [animales, setAnimales] = useState("");
  const [nota, setNota] = useState("");
  const [fechaEntr, setFechaEntr] = useState(null);
  const [data, setData] = useState([]);
  const [dataDef, setDataDef] = useState([]);
  const [data2, setData2] = useState([]);
  const [dataCmp, setDataCmp] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [tipoCarga, setTipoCarga] = useState(0);
  const [stateFld, setStateFld] = useState(Math.random());
  const [caravana, setCaravana] = useState("");

  const { Option } = Select;
  const [form] = Form.useForm();

  const motivosList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.motivos
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  useEffect(() => {
    (async () => {
      setIdOrigen(detalleCarga.cmp_id);
      getDetalleCampo(detalleCarga.cmp_id, detalleCarga.cmb_numero);
    })();
  }, [stateFld]);

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaEntr(dateString);
  }

  //Se actualiza el nombre
  const updateMotivo = async (value) => {
    setIdMtvm(value);
  };

  const onChangeCaravana = (e) => {
    let dato = e.target.value;
    setCaravana(dato.toUpperCase());
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  //////////////////////////////////
  //Carga de los animales en campo
  const getDetalleCampo = async (idOrg, idCmb) => {
    if (idOrg === 0) {
      showNotification(
        "topRight",
        2,
        "CARGAR ANIMALES",
        "DEBE REGISTRAR EL CAMPO DE ORIGEN"
      );
      setDataDef([]);
      setData2([]);
      setAnimales(0);
      setData([]);
      setDataCmp([]);
      setTipoCarga(0);
      return false;
    }
    setLoadingButton(true);
    let datos = await FRM.getCampoDet(currentUser, idOrg, idCmb);
    if (datos.type && datos.type > 0) {
      setDataCmp(datos.listado);
      setDataDef([]);
      setData2([]);
      setAnimales(0);
      setData([]);
      setTipoCarga(0);
    } else {
      showNotification(2, "ERROR DE CONEXION", "Intente mas Tarde");
    }
    setLoadingButton(false);
  };
  /////////////////////////////////

  // ENVIO A WEBSERVICES
  const uploadLotWS = async () => {
    setLoadingButton(true);

    if (
      Number.parseInt(idOrigen) === 0 ||
      Number.parseInt(idMtvm) === 0 ||
      Number.parseInt(caravana.length) === 0 ||
      fechaEntr.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "BAJAS POR MUERTE",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      let record = {
        usr_id: currentUser.id,
        cmb_id: detalleCarga.cmb_numero,
        cmp_id: idOrigen,
        mtm_id: idMtvm,
        sal_cantidad: 1,
        sal_animales: 1,
        sal_nota: nota,
        sal_fecha: fechaEntr,
        record_def: dataDef,
        sal_tipo: 2,
        sal_caravana: caravana,
      };
      var val_inventory = await FRM.sendSalida(currentUser, record, 28);
      console.log(val_inventory);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "CARGAR CARAVANA",
          "REGISTRO DE CARAVANA EXITOSO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CARGAR CARAVANA",
          val_inventory.message
        );
      }
    }
    setData([]);
    setFileList([]);
    setLoadingButton(false);
    closeModal();
  };

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"MUERTE DE ANIMALES DE " + detalleCarga.cmp_nombre}
      </Title>
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Fecha Proceso">
              <DatePicker onChange={onChangeFecha} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Causa de Muerte">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={idMtvm > 0 ? idMtvm : "CAUSA DE MUERTE"}
                placeholder="CAUSA"
                onChange={updateMotivo}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {motivosList.map((campo) => (
                  <Option key={campo.id} value={campo.id}>
                    {campo.mtm_nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Caravana">
              <Input
                placeholder="Caravana"
                defaultValue={caravana}
                style={{ width: "100%" }}
                value={caravana}
                onChange={onChangeCaravana}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <TextArea
              rows={2}
              value={nota}
              placeholder={"Observaciones"}
              onChange={onChangeNota}
              allowClear
            />
          </Col>
        </Row>
      </Form>

      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />

      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => uploadLotWS()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            block
            style={{
              display: caravana.length > 0 ? "block" : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> PROCESAR LA SALIDA POR MUERTE
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
