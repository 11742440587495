import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Typography,
  Row,
  Col,
  Popconfirm,
  Statistic,
  Divider,
  Space,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./DetalleEntrada.scss";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";
import CST from "../../../utils/CustomSettings";

export default function DetalleEntrada(props) {
  const {
    currentUser,
    closeModal,
    detalleCarga,
    opcionCarga,
    detalleList,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataDef, setDataDef] = useState([]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  useEffect(() => {
    setDataDef(detalleList);
  }, [detalleCarga]);

  const { Title } = Typography;

  // ENVIO A WEBSERVICES
  const uploadDspWS = async () => {
    setLoadingButton(true);
    if (Number.parseInt(currentUser.tipo) !== 2) {
      showNotification(
        "topRight",
        2,
        "AGREGAR ANIMALES",
        "NO ESTAS AUTORIZADO A PROCESAR ESTA OPCION"
      );
      setLoadingButton(false);
      return false;
    }

    if (Number.parseInt(detalleList.length) === 0) {
      showNotification(
        "topRight",
        2,
        "AGREGAR ANIMALES",
        "NO HAY ANIMALES PARA AGREGAR"
      );
    } else {
      let record = {
        tipoMov: 2,
        usr_id: currentUser.id,
        ent_id: detalleCarga.ent_numero,
        ent_estado: detalleCarga.ent_estado,
        ent_fecha: detalleCarga.ent_fecha,
        cmp_id: detalleCarga.cmp_id,
        cmb_id: detalleCarga.cmb_id,
        ent_cantidad: Number.parseInt(detalleList.length),
        record_def: detalleList,
      };
      var val_inventory = await FRM.sendAdicion(currentUser, record);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "ADICION DE ANIMALES",
          "PROCESO DE REGISTRO EXITOSO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "ADICION DE ANIMALES",
          val_inventory.message
        );
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "DISPOSITIVO", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
    },
    {
      title: "PESO", //10%
      dataIndex: "anm_peso",
      key: "anm_peso",
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={4} style={{ color: "#6A3E98", textAlign: "center" }}>
        FALTANTE(S)
      </Title>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Table
        columns={header}
        dataSource={dataDef}
        bordered
        size="middle"
        scroll={{ y: 640 }}
      />
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => uploadDspWS()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={curStyle.btnSecundary}
            shape="round"
            block
            loading={loadingButton}
          >
            <UploadOutlined /> PROCESAR LA ADICION
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
