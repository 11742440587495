import React, { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Select,
  Input,
  Row,
  Col,
  InputNumber,
  Popconfirm,
  message,
  DatePicker,
  Divider,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

//Api para mapas
/*AIzaSyCadN8K3_yCdcm9vjrngpEBHMvdydLPt0E*/

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./CambiosLote.scss";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";
import CST from "../../../utils/CustomSettings";

export default function CountAnimals(props) {
  const { currentUser, closeModal, myNumber, detalleCarga } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [cantidad, setCantidad] = useState(detalleCarga.cmb_conteo);

  const { Option } = Select;
  const [form] = Form.useForm();

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;

  const onChangeUnd = (value) => {
    if (Number.parseInt(value) >= 0) {
      setCantidad(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  // ENVIO A WEBSERVICES
  const uploadDspWS = async () => {
    setLoadingButton(true);

    let record = {
      tipoMov: 3,
      usr_id: currentUser.id,
      cmb_numero: detalleCarga.cmb_numero,
      cmb_conteo: cantidad,
    };
    var val_inventory = await FRM.sendAdicion(currentUser, record);
    if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
      updateEnviroment(val_inventory.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "REGISTRAR CONTEO",
        "PROCESO DE REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "REGISTRAR CONTEO",
        val_inventory.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {detalleCarga.cmp_nombre}
      </Title>
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Nº DE ANIMALES CONTADOS">
              <InputNumber
                style={{
                  width: "100%",
                  textAlign: "right",
                  color: "blue",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={cantidad}
                value={cantidad}
                onChange={onChangeUnd}
                placeholder="Animales"
              />{" "}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR?"
          onConfirm={() => uploadDspWS()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={curStyle.btnSecundary}
            shape="round"
            block
            loading={loadingButton}
          >
            <UploadOutlined /> PROCESAR CONTEO
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
