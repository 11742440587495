import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Typography,
  Select,
  Row,
  Col,
  InputNumber,
  message,
  Statistic,
  Divider,
  Popconfirm,
  Tabs,
} from "antd";
import ReactExport from "react-export-excel";
import {
  CalendarOutlined,
  SaveOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";

//Styles
import "./ConfirmEntrada.scss";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";

export default function ConfirmEntrada(props) {
  const {
    currentUser,
    closeModal,
    setIsLoading,
    myNumber,
    detalleCarga,
    opcionCarga,
    detalleList,
    detalleRepeat,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataDef, setDataDef] = useState([]);
  const [downRepeat, setDownRepeat] = useState(false);

  const { TabPane } = Tabs;

  //Para uso de excel
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  useEffect(() => {
    setDataDef(detalleList);
  }, [detalleCarga]);

  const { Title } = Typography;

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const updateEntrada = async () => {
    setLoadingButton(true);
    if (Number.parseInt(detalleCarga.ent_estado) > 0) {
      showNotification(
        "topRight",
        2,
        "CONFIRMAR ENTRADA",
        "LA ENTRADA YA FUE CONFIRMADA"
      );
    } else {
      let record = {
        ent_id: detalleCarga.ent_numero,
        cmp_id: detalleCarga.cmp_id,
        cmb_area: detalleCarga.cmb_area,
        usr_id: detalleCarga.usr_id,
        ent_fecha: detalleCarga.ent_fecha,
        ent_kilos: detalleCarga.ent_kilos,
        ent_kilos_neto: detalleCarga.ent_kilos_neto,
        numero: dataDef.length,
        ent_option: opcionCarga,
      };
      var val_inventory = await FRM.confirmEntra(currentUser, record);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "CONFIRMAR CARAVANA",
          "CARAVANA CONFIRMADA CON EXITO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CONFIRMAR CARAVANA",
          val_inventory.message
        );
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  const depuraEntrada = async () => {
    setLoadingButton(true);
    if (Number.parseInt(detalleRepeat.length) === 0) {
      showNotification(
        "topRight",
        2,
        "DEPURAR ENTRADA",
        "LA ENTRADA YA FUE DEPURADA"
      );
    } else {
      let record = {
        ent_id: detalleCarga.ent_numero,
        numero: detalleRepeat.length,
      };
      var val_inventory = await FRM.depurarEntra(currentUser, record);
      console.log(val_inventory);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "DEPURAR CARAVANA",
          "CARAVANA DEPURADA CON EXITO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "DEPURAR CARAVANA",
          val_inventory.message
        );
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  const downDifs = () => {
    if (detalleRepeat.length > 0) {
      setDownRepeat(true);
      setTimeout(function () {
        setDownRepeat(false);
      }, 1000);
    } else {
      message.error("NO HAY DATOS PARA EXPORTAR", 1);
    }
  };

  // Encabezados de la tabla de Animales Existentes
  const repiten = [
    {
      title: "DISPOSITIVO", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
      width: "8%",
    },
    {
      title: "RAZA",
      dataIndex: "grp_codigo",
      key: "grp_codigo",
      width: "2%",
    },
    {
      title: "CRCE",
      dataIndex: "sbg_codigo",
      key: "sbg_codigo",
      width: "2%",
    },
    {
      title: "SEXO",
      dataIndex: "anm_sexo",
      key: "anm_sexo",
      width: "5%",
      render: (text, row, index) => {
        if (row.anm_sexo === 1) {
          return "MACHO";
        } else {
          return "HEMBRA";
        }
      },
    },
    {
      title: "F. ENTRADA",
      dataIndex: "anm_entrada",
      key: "anm_entrada",
      align: "left",
      width: "5%",
    },
    {
      title: "UBICACION",
      dataIndex: "cmp_ubicacion",
      key: "cmp_ubicacion",
      align: "left",
      width: "12%",
    },
    {
      title: "Kg Bruto",
      dataIndex: "anm_peso_bruto",
      key: "anm_peso_bruto",
      width: "5%",
      align: "right",
    },
    {
      title: "Kg Neto",
      dataIndex: "peso_entra",
      key: "peso_entra",
      width: "5%",
      align: "right",
    },
  ];

  // Encabezados de la tabla de Animales Nuevos
  const header = [
    {
      title: "DISPOSITIVO", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
      width: "8%",
    },
    {
      title: "RAZA",
      dataIndex: "grp_codigo",
      key: "grp_codigo",
      width: "2%",
    },
    {
      title: "CRCE",
      dataIndex: "sbg_codigo",
      key: "sbg_codigo",
      width: "2%",
    },
    {
      title: "SEXO",
      dataIndex: "anm_sexo",
      key: "anm_sexo",
      width: "5%",
      render: (text, row, index) => {
        if (row.anm_sexo === 1) {
          return "MACHO";
        } else {
          return "HEMBRA";
        }
      },
    },
    {
      title: "CATEGORIA",
      dataIndex: "ctg_nombre",
      key: "ctg_nombre",
      width: "12%",
    },
    {
      title: "MESES",
      dataIndex: "anm_meses",
      key: "anm_meses",
      align: "left",
      width: "4%",
    },
    {
      title: "DIAS",
      dataIndex: "anm_dias",
      key: "anm_dias",
      align: "left",
      width: "4%",
    },
    {
      title: "UBICACION SNIG",
      dataIndex: "anm_ubica",
      key: "anm_ubica",
      width: "5%",
      align: "left",
    },
    {
      title: "Kg Bruto",
      dataIndex: "anm_peso_bruto",
      key: "anm_peso_bruto",
      width: "5%",
      align: "right",
    },
    {
      title: "Kg Neto",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      width: "5%",
      align: "right",
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ textAlign: "center" }}>
        {"CONFIRMAR LA ENTRADA AL LOTE"}
      </Title>
      <Row gutter={8}>
        <Col span={4}>
          <Statistic
            title="Fecha"
            value={detalleCarga.ent_fecha}
            prefix={<CalendarOutlined />}
          />
        </Col>
        <Col span={8}>
          <Statistic title="Proveedor" value={detalleCarga.trc_nombre} />
        </Col>
        <Col span={6}>
          <Statistic
            title="Origen"
            value={detalleCarga.ent_origen}
            groupSeparator=""
          />
        </Col>
        <Col span={6}>
          <Statistic title="Destino" value={detalleCarga.cmp_nombre} />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Tabs defaultActiveKey="1">
        <TabPane tab="NUEVOS ANIMALES" key="1">
          <Table
            columns={header}
            dataSource={dataDef}
            bordered
            size="middle"
            scroll={{ x: "calc(700px + 50%)", y: 640 }}
          />
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
            <Popconfirm
              placement="top"
              title="CONFIRMAR EL MOVIMIENTO?"
              onConfirm={() => updateEntrada()}
              okText="Si"
              cancelText="No"
            >
              <Button
                type="primary"
                className={curStyle.btnDark}
                shape="round"
                block
                loading={loadingButton}
              >
                <SaveOutlined /> CONFIRMAR LA ENTRADA AL LOTE
              </Button>
            </Popconfirm>
          </div>
        </TabPane>
        <TabPane tab="ANIMALES EXISTENTES" key="2">
          <Table
            columns={repiten}
            dataSource={detalleRepeat}
            bordered
            size="middle"
            scroll={{ x: "calc(700px + 50%)", y: 640 }}
          />
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
            <Popconfirm
              placement="top"
              title="CONFIRMAR LA DEPURACION?"
              onConfirm={() => depuraEntrada()}
              okText="Si"
              cancelText="No"
            >
              <Button
                type="primary"
                className={curStyle.btnDanger}
                shape="round"
                block
                loading={loadingButton}
              >
                <DeleteOutlined /> ELIMINAR ANIMALES REPETIDOS
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              shape="round"
              className={curStyle.btnSuccess}
              onClick={() => downDifs()}
              loading={loadingButton}
              style={{
                marginLeft: 40,
              }}
            >
              <DownloadOutlined /> DESCARGAR ARCHIVO
            </Button>
          </div>
          {downRepeat ? (
            <ExcelFile filename={"Caravanas_Existentes"} hideElement={true}>
              <ExcelSheet data={detalleRepeat} name={"REPETIDOS"}>
                <ExcelColumn label="DISPOSITIVO" value="anm_codigo" />
                <ExcelColumn label="PESO" value="peso_entra" />
                <ExcelColumn label="UBICACION" value="cmp_ubicacion" />
              </ExcelSheet>
            </ExcelFile>
          ) : null}
        </TabPane>
      </Tabs>
    </div>
  );
}
