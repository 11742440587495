import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Typography,
  Row,
  Col,
  Popconfirm,
  Statistic,
  Divider,
  Tabs,
  Tag,
} from "antd";

//Styles
import "./SalidasAnm.scss";

export default function DetalleCxp(props) {
  const { myNumber, detalleModal, detalleList } = props;

  const [dataDef, setDataDef] = useState([]);

  useEffect(() => {
    setDataDef(detalleList);
  }, [detalleModal]);

  const { Title, Text } = Typography;

  // Encabezados de la tabla de Historico de Alimentos
  const header = [
    {
      title: "COMPR", //10%
      dataIndex: "cte_tipomov",
      key: "cte_tipomov",
    },
    {
      title: "NUMERO", //10%
      dataIndex: "cte_numero",
      key: "cte_numero",
    },
    {
      title: "FECHA", //10%
      dataIndex: "cte_fecha",
      key: "cte_fecha",
    },
    {
      title: "DEBITOS",
      dataIndex: "cte_saldo",
      key: "cte_saldo",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.cte_tipofac === 1 ? row.cte_saldo : 0, 2)}
          </Tag>
        );
      },
    },
    {
      title: "CREDITO",
      dataIndex: "cte_saldo",
      key: "cte_saldo",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.cte_tipofac === 1 ? 0 : row.cte_saldo, 2)}
          </Tag>
        );
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={4} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"DETALLES CXC " + detalleModal.trc_nombre}
      </Title>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Table
        columns={header}
        dataSource={dataDef}
        pagination={false}
        bordered
        size="small"
        scroll={{ y: 640 }}
      />
    </div>
  );
}
