import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { Table, Input, Button, Space, message, Tooltip } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import { TitlePage } from "../../TitlePage";

//Floating Buttons
import FloatingButtons from "react-floating-buttons";
import newRec from "../../../assets/icons/file-edit-outline.svg";
import backHome from "../../../assets/icons/home-outline.svg";
import mapMark from "../../../assets/icons/map-marker-plus-outline.svg";

//Importacion del form
import CamposForm from "./CamposForm";
import GoogleApiWrapper from "../../ShowMaps";
import PointsMap from "../../ShowMaps/PointsMap";

//Importaciones propias
import ModalApp from "../../Modal";
import { colorPrm } from "../../../constants";

/**
 * Campos la Lista que está seteada en el State
 */
export default function Campos() {
  const [tipoModal, setTipoModal] = useState(1);
  const [widthModal, setWidthModal] = useState("700");
  const [puntos, setPuntos] = useState([]);
  const [latCen, setLatCen] = useState("");
  const [lngCen, setLngCen] = useState("");
  const campos = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.campos
  );
  const proveedores = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.proveedores
  );
  const tiposCampo = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.tipocampo
  );
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [record, setRecord] = useState(null);
  const history = useHistory();

  //Definicion de los floating
  const buttonsList = [
    {
      onClick: () => history.push(`/${currentUser.pagina}`),
      src: backHome,
      style: { color: "red" },
    },
    {
      onClick: () => createPuntos(),
      src: mapMark,
    },
    {
      onClick: () => openModal(null, 1),
      src: newRec,
    },
  ];
  //Fin floating

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "5%",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Cod. SNIG",
      dataIndex: "cmp_codigo",
      key: "cmp_codigo",
      width: "10%",
      ...getColumnSearchProps("cmp_codigo"),
    },
    {
      title: "Nombre",
      dataIndex: "cmp_nombre",
      key: "cmp_nombre",
      width: "25%",
      ...getColumnSearchProps("cmp_nombre"),
    },
    {
      title: "Ubicación",
      dataIndex: "cmp_ubicacion",
      key: "cmp_ubicacion",
      width: "15%",
      ...getColumnSearchProps("cmp_ubicacion"),
    },
    {
      title: "Latitud",
      dataIndex: "cmp_latitud",
      key: "cmp_latitud",
    },
    {
      title: "Longitud",
      dataIndex: "cmp_longitud",
      key: "cmp_longitud",
    },
    {
      title: "Tipo",
      dataIndex: "tcp_nombre",
      key: "tcp_nombre",
      width: "25%",
      ...getColumnSearchProps("tcp_nombre"),
    },
    {
      title: "+",
      key: "action",
      width: "5%",
      render: (text, record) => (
        <Button
          type="primary"
          size="large"
          shape="circle"
          onClick={() => openModal(record, 1)}
        >
          <EditOutlined />
        </Button>
      ),
    },
  ];

  //Funcion para crear los puntos del Mapa con los campos
  const createPuntos = async () => {
    const new_array = [];
    campos.forEach((def) => {
      if (
        Number.parseFloat(def.cmp_latitud) < 0 &&
        Number.parseFloat(def.cmp_longitud) < 0
      ) {
        let newdata = {
          title: def.cmp_codigo,
          desc: "",
          url: "https://aal-team.com",
          coordinates: {
            lat: def.cmp_latitud,
            lng: def.cmp_longitud,
          },
          content:
            "<div>" + def.cmp_nombre + " - " + def.cmp_ubicacion + "</div>",
          id: def.id,
        };
        new_array.push(newdata);
        setLatCen(def.cmp_latitud);
        setLngCen(def.cmp_longitud);
      }
    });
    setPuntos(new_array);
    openModal(null, 2);
  };

  // MODALES/////////////////////
  const openModal = (item, tipo) => {
    setTipoModal(tipo);
    if (item) {
      setRecord(item);
    } else {
      let newRecord = {
        grp_id: 0,
      };
      setRecord(newRecord);
    }
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };
  //FIN MODLAES////////////////

  return (
    <div style={{ margin: 15 }}>
      <TitlePage title="ABM Campos" />
      <Table
        columns={columns}
        dataSource={campos}
        pagination={{ pageSize: 45 }}
        scroll={{ y: window.innerHeight - 230 }}
      />
      <FloatingButtons
        buttonType="plus"
        buttonColor={"#ffffff"}
        backgroundColor={colorPrm}
        dimension={60}
        buttonsList={buttonsList}
        top={"calc(85% - 25px)"}
        left={"calc(90% - 25px)"}
        direction="circular"
        degree={-180}
        distance={100}
      />
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={tipoModal === 1 ? 700 : 1307}
      >
        {tipoModal === 2 ? (
          <PointsMap puntos={puntos} latCen={latCen} lngCen={lngCen} />
        ) : (
          <CamposForm
            record={record}
            proveedores={proveedores}
            tiposCampo={tiposCampo}
            closeModal={closeModal}
            currentUser={currentUser}
            varEffec={Math.random()}
          />
        )}
      </ModalApp>
    </div>
  );
}
