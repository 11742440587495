import React, { useState } from "react";
import {
  Button,
  Table,
  Upload,
  Typography,
  Select,
  Row,
  Col,
  Tabs,
  Popconfirm,
  Divider,
  Space,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./CreateCatChg.scss";

// Excel Render
import { ExcelRenderer } from "react-excel-renderer";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorWar, colorGrn } from "../../../constants";

export default function UpdateCategory(props) {
  const { currentUser, closeModal, setIsLoading, myNumber } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [idOrigen, setIdOrigen] = useState(0);
  const [idCategoria, setIdCategoria] = useState(0);
  const [data, setData] = useState([]);
  const [dataDef, setDataDef] = useState([]);
  const [dataFld, setDataFld] = useState([]);
  const [data2, setData2] = useState("");
  const [fileList, setFileList] = useState([]);
  const [dataErr, setDataErr] = useState([]);

  const { Option } = Select;

  const origenList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.origenes
  );
  const fieldsList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.categorias_mnl
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TabPane } = Tabs;

  //Se actualiza el nombre
  const updateCategoria = async (value) => {
    setIdCategoria(value);
  };

  //Se actualiza el nombre y se trae el detalle
  const updateOrigen = async (value) => {
    setIdOrigen(value);
    getAnimalesCampo(value);
  };

  const propis = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (fileList.length > 0) {
        setFileList([file]);
      } else {
        setFileList([...fileList, file]);
      }
      UploadExcel(file);
      return false;
    },
    fileList,
  };

  const UploadExcel = async (file) => {
    //Obtengo la informacion del Input File
    let fileObj = file;

    //Render del Excel
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        // Elimino el encabezado del archivo
        var excel = resp.rows.filter(function (item, index, arr) {
          return item[0] !== "Fecha Lectura";
        });
        const bh_excel2 = [];
        excel.forEach(function (item, index, arr) {
          let cod = item[1].toString();
          let codigo = cod.split(" ");
          let dispositivo = codigo[0].substr(-8, 8);
          let key = index;
          let anm_codigo = dispositivo.trim();
          let anm_peso_neto = item[2];

          let exceldata = {
            key: key,
            anm_codigo: anm_codigo,
            anm_peso_neto: anm_peso_neto,
          };

          bh_excel2.push(exceldata);
        });
        setData(bh_excel2);
        completaDatos(bh_excel2);
      }
    });
  };

  //////////////////////////////////
  //Carga de los animales en campo boton azul
  const getDetalleCampo = async () => {
    if (idOrigen === 0) {
      showNotification(
        "topRight",
        2,
        "CARGAR ANIMALES",
        "DEBE REGISTRAR EL CAMPO DE ORIGEN"
      );
      return false;
    }
    setLoadingButton(true);
    let datos = await FRM.getCampoDet(currentUser, idOrigen, 0);
    if (datos.type && datos.type > 0) {
      setDataDef(datos.listado);
      setData2(datos.listado);
      setData([]);
    } else {
      showNotification(
        "topRight",
        2,
        "CARGANDO DETALLE",
        "No se encontraron datos"
      );
    }
    setLoadingButton(false);
  };

  //Al cambiar el campo descargo animales
  const getAnimalesCampo = async (origen) => {
    if (origen === 0) {
      showNotification(
        "topRight",
        2,
        "CARGAR ANIMALES",
        "DEBE REGISTRAR EL CAMPO DE ORIGEN"
      );
      return false;
    }
    setLoadingButton(true);
    let datos = await FRM.getCampoDet(currentUser, origen, 0);
    if (datos.type && datos.type > 0) {
      setDataFld(datos.listado);
      setData2(datos.listado);
      setData([]);
    } else {
      showNotification(
        "topRight",
        2,
        "CARGANDO DETALLE",
        "No se encontraron datos"
      );
    }
    setLoadingButton(false);
  };

  // ENVIO A WEBSERVICES
  const uploadLotWS = async () => {
    setLoadingButton(true);
    if (
      Number.parseInt(idOrigen) === 0 ||
      Number.parseInt(idCategoria) === 0 ||
      Number.parseInt(dataDef.length) === 0
    ) {
      showNotification(
        "topRight",
        2,
        "CAMBIO DE CATEGORIA",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      let record = {
        usr_id: currentUser.id,
        cmp_id: idOrigen,
        ctg_id: idCategoria,
        cmb_cantidad: dataDef.length,
        record_def: dataDef,
        cmb_cambio: 1,
      };
      var val_inventory = await FRM.sendCambioCat(currentUser, record);
      console.log(val_inventory);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "CAMBIAR CATEGORIA EXITOSO",
          val_inventory.message
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CAMBIAR CATEGORIA",
          val_inventory.message
        );
      }
    }
    setData([]);
    setFileList([]);
    setLoadingButton(false);
    closeModal();
  };

  //Funcion para completar los datos de la caravana
  const completaDatos = async (datosXls) => {
    const new_array = [];
    const validaErr = [];
    datosXls.forEach((row) => {
      let anmId = estaEnArray(row.anm_codigo, row.anm_peso_neto, dataFld);
      if (anmId.key > 0) {
        new_array.push(anmId);
      } else {
        let newerr = {
          anm_codigo: row.anm_codigo,
          anm_peso_neto: row.anm_peso_neto,
        };
        validaErr.push(newerr);
      }
    });
    setDataDef(new_array);
    setDataErr(validaErr);
    setLoadingButton(false);
  };

  //Validar la existencia de un animal
  const estaEnArray = (value, peso, array) => {
    let count = 0;
    let idAnm = {
      key: 0,
      anm_id: 0,
      anm_codigo: "0",
      anm_sexo: 0,
      ctg_nombre: "",
      anm_dias: 0,
      dias: 0,
      anm_peso_neto: 0,
      anm_peso_ant: 0,
      ctg_ant: 0,
    };
    array.forEach((def) => {
      if (Number.parseInt(def.anm_codigo) === Number.parseInt(value)) {
        count += 1;
        idAnm = {
          key: def.id,
          anm_id: def.anm_id,
          anm_codigo: def.anm_codigo,
          anm_sexo: def.anm_sexo,
          ctg_nombre: def.ctg_nombre,
          anm_dias: def.anm_dias,
          dias: def.dias,
          anm_peso_neto: peso,
          anm_peso_ant: def.anm_peso_neto,
          ctg_ant: def.ctg_id,
        };
      }
    });
    return count > 0 ? idAnm : idAnm;
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "CARAVANA", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
    },
    {
      title: "CATEGORIA", //10%
      dataIndex: "ctg_nombre",
      key: "ctg_nombre",
    },
    {
      title: "SEXO",
      dataIndex: "anm_sexo",
      key: "anm_sexo",
      render: (text, row, index) => {
        if (row.anm_sexo === 1) {
          return "MACHO";
        } else {
          return "HEMBRA";
        }
      },
    },
    {
      title: "DIAS",
      dataIndex: "anm_dias",
      key: "anm_dias",
      align: "right",
      render: (text, row, index) => {
        return myNumber(
          1,
          Number.parseInt(row.anm_dias) + Number.parseInt(row.dias)
        );
      },
    },
    {
      title: "PESO Kg",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      align: "right",
    },
  ];

  //Encabezado Errores
  const lerror = [
    {
      title: "CARAVANA", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
    },
    {
      title: "PESO Kg",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      width: "20%",
      align: "right",
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"REGISTRAR CAMBIO DE CATEGORIA"}
      </Title>
      <Row gutter={16}>
        <Col span={4}>
          <Title
            level={4}
            style={{
              padding: 2,
              margin: 2,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            UBICACION:
          </Title>
        </Col>
        <Col span={8}>
          <Select
            showSearch
            showArrow
            style={{ width: "100%" }}
            defaultValue={idOrigen === 0 ? "SELECCIONE EL CAMPO" : idOrigen}
            placeholder="CAMPO DE ORIGEN"
            onChange={updateOrigen}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {origenList.map((campo) => (
              <Option key={campo.cmp_id} value={campo.cmp_id}>
                {campo.cmp_nombre}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <Title
            level={4}
            style={{
              padding: 2,
              margin: 2,
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            CATEGORIA:
          </Title>
        </Col>
        <Col span={8}>
          <Select
            showSearch
            showArrow
            style={{ width: "100%" }}
            defaultValue={idCategoria === 0 ? "NUEVA CATEGORIA" : idCategoria}
            placeholder="CATEGORIA"
            onChange={updateCategoria}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {fieldsList.map((categoria) => (
              <Option key={categoria.id} value={categoria.id}>
                {categoria.ctg_nombre}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <div
        style={{
          display: "flex",
          alignContent: "left",
          marginBottom: 15,
          marginTop: 15,
        }}
      >
        <Button
          type="primary"
          shape="round"
          className={curStyle.btnBlueLight}
          loading={loadingButton}
          onClick={() => getDetalleCampo()}
        >
          <UploadOutlined /> ANIMALES EN CAMPO
        </Button>

        <Upload {...propis}>
          <Button
            type="primary"
            shape="round"
            loading={loadingButton}
            className={curStyle.btnInfo}
            style={{
              marginLeft: 20,
              display: idOrigen > 0 ? "block" : "none",
            }}
          >
            <UploadOutlined /> ARCHIVO DEL BASTON
          </Button>
        </Upload>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="COINCIDENCIAS" key="1">
          <Table columns={header} dataSource={dataDef} bordered size="middle" />
        </TabPane>
        <TabPane tab="ERRORES" key="2">
          <Table columns={lerror} dataSource={dataErr} bordered size="middle" />
        </TabPane>
      </Tabs>
      <Space>
        <Title
          level={4}
          style={{ color: colorSec, textAlign: "left", marginTop: 15 }}
        >
          {"Animales en CAMPO: " + myNumber(1, data2.length)}
        </Title>
        <Title
          level={4}
          style={{
            color: colorGrn,
            textAlign: "left",
            marginLeft: 30,
            marginTop: 15,
          }}
        >
          {"Registros BASTON: " + myNumber(1, data.length)}
        </Title>
        <Title
          level={4}
          style={{
            color: colorWar,
            textAlign: "left",
            marginLeft: 30,
            marginTop: 15,
          }}
        >
          {"Registros VALIDOS: " + myNumber(1, dataDef.length)}
        </Title>
      </Space>
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => uploadLotWS()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            block
            style={{
              display: dataDef.length > 0 ? "block" : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> PROCESAR EL CAMBIO DE CATEGORIA
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
