import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Typography,
  Row,
  Col,
  Popconfirm,
  Statistic,
  Divider,
  Space,
} from "antd";
import { CalendarOutlined, DeleteOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./DetalleEntrada.scss";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";
import CST from "../../../utils/CustomSettings";

export default function DetalleEntrada(props) {
  const {
    currentUser,
    closeModal,
    setIsLoading,
    myNumber,
    detalleCarga,
    opcionCarga,
    detalleList,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataDef, setDataDef] = useState([]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  useEffect(() => {
    setDataDef(detalleList);
  }, [detalleCarga]);

  const { Title } = Typography;

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const deleteEntrada = async () => {
    setLoadingButton(true);
    if (Number.parseInt(detalleCarga.ent_estado) > 0) {
      showNotification(
        "topRight",
        2,
        "ELIMINAR CARAVANA",
        "LA ENTRADA YA FUE CONFIRMADA"
      );
    } else {
      let record = {
        ent_id: detalleCarga.ent_numero,
        ent_option: opcionCarga,
      };
      var val_inventory = await FRM.updateCarga(currentUser, record);
      console.log(val_inventory);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "ELIMINAR CARAVANA",
          "CARAVANA ELIMINADA CON EXITOSO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CARGAR CARAVANA",
          val_inventory.message
        );
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "DISPOSITIVO", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
      width: "8%",
    },
    {
      title: "RAZA",
      dataIndex: "grp_codigo",
      key: "grp_codigo",
      width: "3%",
    },
    {
      title: "CRUCE",
      dataIndex: "sbg_codigo",
      key: "sbg_codigo",
      width: "3%",
    },
    {
      title: "SEXO",
      dataIndex: "anm_sexo",
      key: "anm_sexo",
      width: "5%",
      render: (text, row, index) => {
        if (row.anm_sexo === 1) {
          return "MACHO";
        } else {
          return "HEMBRA";
        }
      },
    },
    {
      title: "MESES",
      dataIndex: "anm_meses",
      key: "anm_meses",
      align: "left",
      width: "4%",
    },
    {
      title: "DIAS",
      dataIndex: "anm_dias",
      key: "anm_dias",
      align: "left",
      width: "4%",
    },
    {
      title: "UBICACION SNIG",
      dataIndex: "anm_ubica",
      key: "anm_ubica",
      width: "5%",
      align: "left",
    },
    {
      title: "Peso",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      width: "5%",
      align: "right",
      render: (text, row, index) => {
        return Number.parseFloat(row.anm_peso_neto).toFixed(2);
      },
    },
    {
      title: "Costo",
      dataIndex: "anm_costo_cif",
      key: "anm_costo_cif",
      width: "5%",
      align: "right",
      render: (text, row, index) => {
        return Number.parseFloat(row.anm_costo_cif).toFixed(2);
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        DETALLE DE ENTRADA
      </Title>
      <Row gutter={8}>
        <Col span={4}>
          <Statistic
            title="Fecha"
            value={detalleCarga.ent_fecha}
            prefix={<CalendarOutlined />}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Proveedor"
            value={detalleCarga.trc_nombre}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="Origen"
            value={detalleCarga.ent_origen}
            groupSeparator=""
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Destino"
            value={detalleCarga.cmp_nombre}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Row gutter={16}>
        <Col span={8}>
          <Statistic
            title="Comisionista"
            value={
              detalleCarga.trc_comision > 0 ? detalleCarga.comisionista : "..."
            }
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="Comisión"
            value={
              "(" +
              myNumber(
                1,
                detalleCarga.trc_comision > 0
                  ? Number.parseFloat(detalleCarga.ent_prc_comision)
                  : 0.0
              ) +
              "%) " +
              myNumber(
                1,
                detalleCarga.trc_comision > 0
                  ? Number.parseFloat(detalleCarga.ent_comision)
                  : 0.0
              )
            }
            valueStyle={{
              fontSize: 18,
              color: CST.lilaBdy,
              fontWeight: "bold",
            }}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Transportador"
            value={
              detalleCarga.trc_transporte > 0
                ? detalleCarga.transportador
                : "..."
            }
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="$ Transporte"
            value={myNumber(
              1,
              detalleCarga.trc_transporte > 0
                ? Number.parseFloat(detalleCarga.ent_transporte)
                : 0.0
            )}
            valueStyle={{
              fontSize: 18,
              color: CST.lilaBdy,
              fontWeight: "bold",
            }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Row gutter={8}>
        <Col span={4}>
          <Statistic
            title="Kg Balanza Ruta"
            value={myNumber(1, detalleCarga.ent_kilos_balanza)}
            valueStyle={{
              fontSize: 20,
              fontWeight: "bold",
              color: CST.greenBdy,
            }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="% Destare"
            value={myNumber(1, detalleCarga.ent_destare)}
            valueStyle={{ fontSize: 20, color: CST.redBdy, fontWeight: "bold" }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="Kg Brutos"
            value={myNumber(1, detalleCarga.ent_kilos)}
            valueStyle={{
              color: CST.blueBdy,
              fontSize: 20,
              fontWeight: "bold",
            }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="$ Precio Kilo Bruto"
            value={myNumber(1, detalleCarga.ent_costo_kg)}
            valueStyle={{
              fontSize: 20,
              color: CST.greenBdy,
              fontWeight: "bold",
            }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="Kg Netos"
            value={myNumber(1, detalleCarga.ent_kilos_neto)}
            valueStyle={{
              fontSize: 20,
              color: CST.blueFoo,
              fontWeight: "bold",
            }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="$ Precio Kilo Neto"
            value={myNumber(1, detalleCarga.ent_costo_neto)}
            valueStyle={{
              fontSize: 20,
              color: CST.redBdy,
              fontWeight: "bold",
            }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Table
        columns={header}
        dataSource={dataDef}
        bordered
        size="middle"
        scroll={{ x: "calc(700px + 50%)", y: 640 }}
      />
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <Statistic value={detalleCarga.ent_nota} />
        </Col>
      </Row>
      <Space>
        <Title
          level={4}
          style={{ color: colorSec, textAlign: "left", marginTop: 15 }}
        >
          {detalleCarga.ent_estado === 0
            ? "PENDIENTE DE CONFIRMAR"
            : "CONFIRMADA"}
        </Title>
        <Title
          level={4}
          style={{
            color: colorGrn,
            textAlign: "left",
            marginLeft: 30,
            marginTop: 15,
          }}
        >
          {"REGISTROS: " + myNumber(1, dataDef.length)}
        </Title>
      </Space>
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => deleteEntrada()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            block
            style={{
              display:
                dataDef.length > 0 &&
                detalleCarga.ent_estado === 0 &&
                opcionCarga === 3
                  ? "block"
                  : "none",
            }}
            loading={loadingButton}
          >
            <DeleteOutlined /> DESCARTAR LA ENTRADA
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
