import React from "react";
import { Button, Table, Tag, message, Typography, Divider } from "antd";
import Icon from "@mdi/react";

import {
  ReloadOutlined,
  HomeOutlined,
  SearchOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

import curStyle from "../../../css/GlobalCmp.module.css";
import CST from "../../../utils/CustomSettings";
import { URL_FILES } from "../../../constants";
import { myNumber } from "../../../utils/ArrayFunctions";

const TableGasto = ({
  tipoMoneda,
  openModal,
  openAdjunto,
  listGastos,
  tableTitle,
}) => {
  const { Title, Text } = Typography;
  //Columnas de Gastos
  const gastosHdr = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="middle"
          shape="round"
        >
          {record.meg_id}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "meg_fecha",
      key: "meg_fecha",
    },
    {
      title: "F. APRUEBA",
      dataIndex: "meg_faprueba",
      key: "meg_faprueba",
      render: (text, row, index) => {
        if (row.meg_faprueba) {
          return row.meg_faprueba.toString().substr(0, 10);
        } else {
          return "...";
        }
      },
    },
    {
      title: "F. PAGO",
      dataIndex: "meg_fdesembolso",
      key: "meg_fdesembolso",
      render: (text, row, index) => {
        if (row.meg_fpago) {
          return row.meg_fdesembolso.toString().substr(0, 10);
        } else {
          return "...";
        }
      },
    },
    {
      title: "BENEFICIARIO", //10%
      dataIndex: "trc_nombre",
      key: "trc_nombre",
    },
    {
      title: "CONCEPTO",
      dataIndex: "cnc_nombre",
      key: "cnc_nombre",
    },
    {
      title: "AUTORIZA",
      dataIndex: "autoriza",
      key: "autoriza",
    },
    {
      title: "MONTO",
      dataIndex: "meg_moneda",
      key: "meg_moneda",
      align: "right",
      render: (text, row, index) => {
        return row.meg_moneda === 1 ? (
          <Tag color="green">{tipoMoneda + myNumber(1, row.meg_importe)}</Tag>
        ) : (
          <Tag color="volcano">{tipoMoneda + myNumber(1, row.meg_importe)}</Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="large"
          shape="circle"
          icon={<FilePdfOutlined />}
          onClick={() =>
            record.meg_adjunto
              ? openAdjunto(record)
              : message.error("NO HAY ARCHIVO ADJUNTO", 1)
          }
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => openModal(record, 1, "900px")}
        />
      ),
    },
  ];
  return (
    <>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
        {
          <Title level={4} style={{ color: CST.lilaBdy, textAlign: "center" }}>
            {tableTitle}
          </Title>
        }
      </Divider>
      <Table
        columns={gastosHdr}
        dataSource={listGastos}
        pagination={false}
        style={{ padding: 3, marginTop: 5 }}
        summary={(pageData) => {
          let totalGto = 0;

          pageData.forEach(({ meg_importe }) => {
            totalGto += Number.parseFloat(meg_importe);
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={8}>
                  <Title level={4}>{"TOTAL"}</Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Title level={4} style={{ textAlign: "right" }}>
                    {tipoMoneda + myNumber(1, totalGto)}
                  </Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={2}>
                  <Text>...</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </>
  );
};

export default TableGasto;
