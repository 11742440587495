import React, { useState } from "react";
import {
    Form,
    Button,
    Table,
    Tabs,
    Tag,
    Typography,
    Select,
    Row,
    Col,
    message,
    DatePicker,
    Divider,
    Space,
    InputNumber,
    Statistic,
    Alert
} from "antd";
import { useHistory } from "react-router-dom";
import ReactExport from "react-export-excel";

import {
    ReloadOutlined,
    HomeOutlined,
    SearchOutlined,
    DownloadOutlined
} from "@ant-design/icons";

import moment from "moment";

//Componentes Propios
import ModalApp from "../../../Modal";
import FichaAnimal from "./FichaAnimal";
import DetalleSalida from "../../SalidasAnm/DetalleSalida";

// componentes
import FRM from "../../../../utils/FrmService";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import CST from "../../../../utils/CustomSettings";
import { URL_FILES } from "../../../../constants";


export default function CjbMovBanco(props) {
    const {
        myNumber,
        currentUser,
        currentSetup,
        setIsLoading,
        currentMoneda,
        listBancos,
    } = props;
    const [movimientos, setMovimientos] = useState([]);
    const [resumen, setResumen] = useState({
        peso_neto: 0,
        kilos_gen: 0,
        kilos_dia: 0,
        conversion: 0,
        kg_secos: 0,
        alimento: 0
    });
    //Constantes para modales
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [widthModal, setWidthModal] = useState("70%");
    const [tipoModal, setTipoModal] = useState(1);
    const [detalleModal, setDetalleModal] = useState({});
    const [detalleList, setDetalleList] = useState({});
    const [idInfo, setIdInfo] = useState(0);
    const [idEstado, setIdEstado] = useState(3);
    const [valorIni, setValorIni] = useState(0);
    const [valorFin, setValorFin] = useState(0);
    const [detalleField, setDetalleField] = useState([]);
    const [detalleSale, setDetalleSale] = useState([]);
    const [detalleCome, setDetalleCome] = useState([]);
    const [detallePeso, setDetallePeso] = useState([]);
    const [detalleAnimal, setDetalleAnimal] = useState({});
    const [nameList, setNameList] = useState('...');
    const [downExcel, setDownExcel] = useState(false);
    const [isGetting, setIsGetting] = useState(false)
    const [fechaCnc, setFechaCnc] = useState("");
    const [tipoInf, setTipoInf] = useState(1);
    const [listaKgm, setListaKgm] = useState([]);
    const [listaMte, setListaMte] = useState([]);
    const [totalKgm, setTotalKgm] = useState(0);
    const [totalPpo, setTotalPpo] = useState(0);
    const [totalMte, setTotalMte] = useState(0);

    const history = useHistory();
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const { Title, Text } = Typography;
    const { Option } = Select;
    const { TabPane } = Tabs;

    //Para uso de excel
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    //Cambio de fecha
    function onChangeCncDate(value, dateString) {
        setFechaCnc(dateString.toString());
        message.info(dateString.toString(), 3);
    }


    const updateTipoInf = async (value) => {
        setIdInfo(value);
        setNameList(value === 1 ? 'LISTADO DE ANIMALES POR KILOS GENERADOS' : value === 2 ? 'LISTADO DE ANIMALES POR KILOS GENERADOS POR DIA' : value === 3 ? 'LISTADO DE ANIMALES POR CONVERSION' : 'LISTADO DE ANIMALES POR KILO');
    };

    const updateEstadoInf = async (value) => {
        setIdEstado(value);
    };

    const onChangeIni = (value) => {
        if (Number.parseFloat(value) >= 0) {
            setValorIni(value);
        } else {
            message.error("VALOR INCORRECTO!", 1);
            return false;
        }

    };

    const onChangeFin = (value) => {
        if (Number.parseFloat(value) >= 0) {
            setValorFin(value);
        } else {
            setValorFin(valorIni);
            message.error("VALOR INCORRECTO!", 1);
            return false;
        }

    };

    const getDetalle = async (record, opcion) => {
        let datos = await FRM.getInventario(currentUser, record.id, 0, null);
        if (datos.type && datos.type > 0) {
            setDetalleField(datos.listado);
            setDetalleSale(datos.sale);
            setDetalleCome(datos.alimento);
            setDetallePeso(datos.pesos);
            openModal(record, opcion, "1000px");
        } else {
            showNotification(
                "topRight",
                2,
                "VER FICHA ANIMAL",
                "No se encontraron datos"
            );
        }
    };

    const getDetalleSal = async (record, opcion) => {
        let datos = await FRM.getLoteDet(currentUser, record.sal_numero, 2);
        if (datos.type && datos.type > 0) {
            setDetalleList(datos.listado);
            openModal(record, opcion, "1000px");
        } else {
            showNotification(
                "topRight",
                2,
                "DETALLE SALIDA",
                "No hay Datos registrados"
            );
        }
    };

    const downAnimals = async (curArray) => {
        if (curArray.length > 0) {
            setDownExcel(true);
            setTimeout(function () {
                setDownExcel(false);
            }, 1000);
        } else {
            message.error("NO HAY DATOS PARA EXPORTAR", 1);
        }
    };

    const openModal = (record, tipo, ancho) => {
        setTipoModal(tipo);
        setWidthModal(ancho);
        setDetalleModal(record);
        setDetalleAnimal(record);
        setIsVisibleModal(true);
    };

    const closeModal = () => {
        setIsVisibleModal(false);
    };


    // TRAER MOVIMIENTOS
    const downloadCaja = async () => {
        if (Number.parseInt(valorFin) < Number.parseInt(valorIni) || Number.parseInt(idInfo) === 0) {
            showNotification(
                "topRight",
                2,
                "INFORMES DE GESTION",
                "LOS DATOS ESTAN INCOMPLETOS PARA EL INFORME"
            );
        } else {
            setIsGetting(true);
            let record = {
                tipoMov: Number.parseInt(idEstado),
                valorIni: valorIni,
                valorFin: valorFin,
                idInfo: idInfo,
            };
            let val_movcaja = await FRM.getInventario(currentUser, 0, idInfo, record);
            console.log(val_movcaja);
            if (val_movcaja.type && Number.parseInt(val_movcaja.type) > 0) {
                setMovimientos(val_movcaja.listado);
                setResumen(val_movcaja.resumen);
                showNotification(
                    "topRight",
                    4,
                    "INFORMES DE GESTION",
                    val_movcaja.message
                );
            } else {
                showNotification(
                    "topRight",
                    2,
                    "INOFORMES DE GESTION",
                    val_movcaja.message
                );
            }
        }
        setIsGetting(false);
    };

    // TRAER MOVIMIENTOS
    const downloadCnc = async () => {
        if (fechaCnc.length === 0) {
            showNotification(
                "topRight",
                2,
                "GESTION RESUMIDA",
                "LOS DATOS ESTAN INCOMPLETOS PARA EL INFORME"
            );
        } else {
            setIsGetting(true);
            let record = {
                tipoMov: 7,
                usr_rgs: currentUser.id,
                meg_fecha: fechaCnc,
                cnc_id: 1,
            };
            var val_movcaja = await FRM.getMovCaja(currentUser, record);
            if (val_movcaja.type && Number.parseInt(val_movcaja.type) > 0) {
                setListaKgm(val_movcaja.lista_pso);
                setListaMte(val_movcaja.lista_mte);
                setTotalKgm(val_movcaja.kilos_gen);
                setTotalPpo(val_movcaja.kilos_ppo);
                setTotalMte(val_movcaja.kilos_mte);
                showNotification(
                    "topRight",
                    4,
                    "GESTION RESUMIDA",
                    "PROCESO DE CONSULTA EXITOSO"
                );
            } else {
                showNotification(
                    "topRight",
                    2,
                    "GESTION RESUMIDA",
                    val_movcaja.message
                );
            }
        }
        setIsGetting(false);
    };

    //Columnas Kilos
    const columns_kg = [
        {
            title: "CARAVANA", //10%
            dataIndex: "anm_codigo",
            key: "anm_codigo",
        },
        {
            title: "ESTADO",
            dataIndex: "anm_estado_nm",
            key: "anm_estado_nm",
            align: "left",
            render: (text, row, index) => {
                return row.anm_estado === 0 ? (
                    <Tag color="blue">{row.anm_estado_nm}</Tag>
                ) : row.anm_estado === 1 ? (
                    <Tag color="green">{row.anm_estado_nm}</Tag>
                ) : (
                    <Tag color="red">{row.anm_estado_nm}</Tag>
                );
            },
        },
        {
            title: "SEXO",
            dataIndex: "anm_sexo",
            key: "anm_sexo",
            render: (text, row, index) => {
                if (row.anm_sexo === 1) {
                    return "MACHO";
                } else {
                    return "HEMBRA";
                }
            },
        },
        {
            title: "CATEGORIA",
            dataIndex: "ctg_nombre",
            key: "ctg_nombre",
        },
        {
            title: "ENTRADA",
            dataIndex: "anm_entrada",
            key: "anm_entrada",
            render: (text, row, index) => {
                return (
                    <Tag style={{ fontSize: 15 }} color="geekblue">{row.anm_entrada}</Tag>
                );
            },
        },
        {
            title: "PESO ENTRA",
            dataIndex: "anm_peso_ent",
            key: "anm_peso_ent",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag style={{ fontSize: 15 }} color="green">{myNumber(
                        1,
                        Number.parseFloat(row.anm_peso_ent))}</Tag>
                );
            },
        },
        {
            title: "PESO INI",
            dataIndex: "anm_fecha_info_ini",
            key: "anm_fecha_info_ini",
            render: (text, row, index) => {
                return (
                    <Tag style={{ fontSize: 15 }} color="cyan">{row.anm_fecha_info_ini}</Tag>
                );
            },
        },
        {
            title: "PESO INICIAL",
            dataIndex: "anm_peso_info_ini",
            key: "anm_peso_info_ini",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag style={{ fontSize: 15 }} color="orange">{myNumber(
                        1,
                        Number.parseFloat(row.anm_peso_info_ini))}
                    </Tag>
                );
            },

        },
        {
            title: "ULT PESAJE",
            dataIndex: "anm_fecha_info_fin",
            key: "anm_fecha_info_fin",
            render: (text, row, index) => {
                return (
                    <Tag style={{ fontSize: 15 }} color="cyan">{row.anm_fecha_info_fin}</Tag>
                );
            },
        },
        {
            title: "PESO FINAL",
            dataIndex: "anm_peso_info_fin",
            key: "anm_peso_info_fin",
            align: 'right',
            render: (text, row, index) => {
                return (
                    <Tag style={{ fontSize: 15 }} color="magenta">{myNumber(
                        1,
                        Number.parseFloat(row.anm_peso_info_fin))}</Tag>
                );
            },
        },
        {
            title: "KG GEN",
            dataIndex: "peso_act",
            key: "peso_act",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag style={{ fontSize: 15 }} color="blue">{myNumber(
                        1,
                        Number.parseFloat(row.anm_peso_info_fin - row.anm_peso_info_ini))}
                    </Tag>
                );
            },
        },
        {
            title: "+",
            key: "action",
            render: (text, record) => (
                <Button
                    type="primary"
                    size="large"
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={() => getDetalle(record, 1)}
                />
            ),
        },
    ];


    //Columnas de la tabla
    const columns_mte = [
        {
            title: "No",
            key: "action",
            width: "2%",
            render: (text, record) => (
                <Button
                    type="primary"
                    className={curStyle.btnDanger}
                    size="large"
                    shape="circle"
                >
                    {record.sal_numero}
                </Button>
            ),
        },
        {
            title: "FECHA", //10%
            dataIndex: "sal_fecha",
            key: "sal_fecha",
        },
        {
            title: "CAMPO", //10%
            dataIndex: "cmp_nombre",
            key: "cmp_nombre",
        },
        {
            title: "CAUSA O MOTIVO",
            dataIndex: "mtm_nombre",
            key: "mtm_nombre",
        },
        {
            title: "DETALLE",
            dataIndex: "sal_nota",
            key: "sal_nota",
        },
        {
            title: "CANTD",
            dataIndex: "sal_cantidad",
            key: "sal_cantidad",
            align: "right",
            render: (text, row, index) => {
                return myNumber(1, row.sal_cantidad);
            },
        },
        {
            title: "KILOS",
            dataIndex: "sal_kilos",
            key: "sal_kilos",
            align: "right",
            render: (text, row, index) => {
                return myNumber(1, row.sal_kilos, 2);
            },
        },
        {
            title: "+",
            key: "action",
            width: "2%",
            render: (text, record) => (
                <Button
                    type="primary"
                    className={curStyle.btnSky}
                    size="large"
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={() => getDetalleSal(record, 2)}
                />
            ),
        }
    ];

    //Columnas de la tabla
    const columns = [
        {
            title: "CARAVANA", //10%
            dataIndex: "anm_codigo",
            key: "anm_codigo",
        },
        {
            title: "SNIG",
            dataIndex: "val_snig",
            key: "val_snig",
            align: "left",
            render: (text, row, index) => {
                return row.anm_snig === 0 ? (
                    <Tag color="cyan">{row.val_snig}</Tag>
                ) : (
                    <Tag color="gold">{row.val_snig}</Tag>
                );
            },
        },
        {
            title: "ESTADO",
            dataIndex: "anm_estado_nm",
            key: "anm_estado_nm",
            align: "left",
            render: (text, row, index) => {
                return row.anm_estado === 0 ? (
                    <Tag color="blue">{row.anm_estado_nm}</Tag>
                ) : row.anm_estado === 1 ? (
                    <Tag color="green">{row.anm_estado_nm}</Tag>
                ) : (
                    <Tag color="red">{row.anm_estado_nm}</Tag>
                );
            },
        },
        {
            title: "UBICACION",
            dataIndex: "anm_ubica",
            key: "anm_ubica",
        },
        {
            title: "RAZA",
            dataIndex: "rza_nombre",
            key: "rza_nombre",
        },
        {
            title: "SEXO",
            dataIndex: "anm_sexo",
            key: "anm_sexo",
            render: (text, row, index) => {
                if (row.anm_sexo === 1) {
                    return "MACHO";
                } else {
                    return "HEMBRA";
                }
            },
        },
        {
            title: "CATEGORIA",
            dataIndex: "ctg_nombre",
            key: "ctg_nombre",
        },
        {
            title: "A FEEDLOT",
            dataIndex: "anm_fecha_alimento",
            key: "anm_fecha_alimento",
            render: (text, row, index) => {
                return (
                    <Tag color="geekblue">{row.anm_fecha_alimento}</Tag>
                );
            },
        },
        {
            title: "PESO ENTRA",
            dataIndex: "anm_peso_alimento",
            key: "anm_peso_alimento",
            align: "right",
        },
        {
            title: "F. PESAJE",
            dataIndex: "anm_fecha_peso",
            key: "anm_fecha_peso",
            render: (text, row, index) => {
                return (
                    <Tag color="gold">{row.anm_fecha_peso}</Tag>
                );
            },
        },
        {
            title: "PESO ACT",
            dataIndex: "anm_peso_neto",
            key: "anm_peso_neto",
            align: "right",
            sorter: (a, b) => a.anm_peso_neto - b.anm_peso_neto,
        },
        {
            title: "KG GEN",
            dataIndex: "anm_kilos_gen",
            key: "anm_kilos_gen",
            align: "right",
            sorter: (a, b) => a.anm_kilos_gen - b.anm_kilos_gen,
            render: (text, row, index) => {
                return (
                    row.anm_peso_alimento > 0 ? (
                        <Tag color="blue">{myNumber(
                            1,
                            Number.parseFloat(row.anm_kilos_gen))}
                        </Tag>
                    ) : (
                        <Tag color="gold">{myNumber(
                            1,
                            Number.parseFloat(0))}
                        </Tag>
                    )
                );
            },
        },
        {
            title: "DIAS",
            dataIndex: "anm_dias_peso",
            key: "anm_dias_peso",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="geekblue">{myNumber(
                        1,
                        Number.parseInt(row.anm_dias_peso))}
                    </Tag>);
            },
        },
        {
            title: "Kg/Dia",
            dataIndex: "anm_kilos_dia",
            key: "anm_kilos_dia",
            align: "right",
            sorter: (a, b) => a.anm_kilos_dia - b.anm_kilos_dia,
            render: (text, row, index) => {
                return (
                    row.anm_peso_alimento > 0 ? (
                        <Tag color="volcano">{myNumber(
                            1,
                            Number.parseFloat(row.anm_kilos_dia), 2)}
                        </Tag>
                    ) : (
                        <Tag color="volcano">{myNumber(
                            1,
                            Number.parseFloat(0))}
                        </Tag>
                    )
                );
            },
        },
        {
            title: "KG-SEC",
            dataIndex: "anm_kilos_peso",
            key: "anm_kilos_peso",
            align: "right",
            sorter: (a, b) => a.anm_kilos_peso - b.anm_kilos_peso,
            render: (text, row, index) => {
                return (
                    <Tag color="magenta">{myNumber(
                        1,
                        Number.parseFloat(row.anm_kilos_peso))}
                    </Tag>);
            },
        },
        {
            title: "CONV.",
            dataIndex: "anm_conversion",
            key: "anm_conversion",
            align: "right",
            sorter: (a, b) => a.anm_conversion - b.anm_conversion,
            render: (text, row, index) => {
                return (row.anm_dias_peso > 0 ? (
                    <Tag color="purple">{myNumber(
                        1,
                        Number.parseFloat(row.anm_conversion))}
                    </Tag>
                ) : (
                    <Tag color="#f50">{0}
                    </Tag>
                )
                );
            },
        },
        {
            title: "$ ALM",
            dataIndex: "anm_alimento",
            key: "anm_alimento",
            align: "right",
            sorter: (a, b) => a.anm_alimento - b.anm_alimento,
            render: (text, row, index) => {
                return (
                    <Tag color="cyan">{myNumber(
                        1,
                        Number.parseFloat(row.anm_alimento))}
                    </Tag>);
            },
        },
        {
            title: "+",
            key: "action",
            render: (text, record) => (
                <Button
                    type="primary"
                    size="large"
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={() => getDetalle(record, 1)}
                />
            ),
        },
    ];

    return (
        <div className="upload-panel">
            <Tabs defaultActiveKey="1">
                <TabPane tab="GESTION DETALLADA POR RANGO DE FECHA" key="1">
                    <Form layout="vertical" form={form}>
                        <Row gutter={8} style={{ paddingLeft: 15 }}>
                            <Col span={8}>
                                <Form.Item label="PERIODO A CONSULTAR">
                                    <RangePicker
                                        size="large"
                                        ranges={{
                                            Hoy: [moment(), moment()],
                                            "Este Mes": [
                                                moment().startOf("month"),
                                                moment().endOf("month"),
                                            ],
                                        }}
                                        onChange={onChangeCncDate}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label=".">
                                    <Space>
                                        <Button
                                            type="primary"
                                            className={curStyle.btnInfo}
                                            shape="round"
                                            icon={<ReloadOutlined />}
                                            size="large"
                                            onClick={() => downloadCnc()}
                                            loading={isGetting}
                                        >
                                            Generar
                                        </Button>
                                        <Button
                                            type="primary"
                                            className={curStyle.btnSky}
                                            shape="round"
                                            icon={<HomeOutlined />}
                                            size="large"
                                            onClick={() => history.push(`/${currentUser.pagina}`)}
                                        >
                                            Volver
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Statistic title="Kg Animales Propios" value={totalPpo} precision={2} />
                            </Col>
                            <Col span={3}>
                                <Statistic title="Kg Animales Comprados" value={totalKgm} precision={2} />
                            </Col>
                            <Col span={3}>
                                <Statistic valueStyle={{ color: CST.colorGrn, fontWeight: 'bold' }} title="Total Kg Generados" value={Number.parseFloat(totalPpo) + Number.parseFloat(totalKgm)} precision={2} />
                            </Col>
                            <Col span={3}>
                                <Statistic title="Kg Perdidos por Muerte" value={totalMte} precision={2} />
                            </Col>
                        </Row>
                    </Form>
                    <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="KILOS GENERADOS" key="3">
                            <Table
                                columns={columns_kg}
                                dataSource={listaKgm}
                                style={{ padding: 3, marginTop: 5 }}
                            />
                        </TabPane>
                        <TabPane tab="ANIMALES MUERTOS" key="4">
                            <Table
                                columns={columns_mte}
                                dataSource={listaMte}
                                style={{ padding: 3, marginTop: 5 }}
                            />
                        </TabPane>
                    </Tabs>
                </TabPane>
                <TabPane tab="GESTION DETALLADA POR RANGOS DE VALORES" key="2">
                    <Form layout="vertical" form={form}>
                        <Row gutter={8}>
                            <Col span={2}>
                                <Form.Item label="VR INICIAL">
                                    <InputNumber
                                        style={{
                                            width: "100%",
                                            textAlign: "right",
                                            color: "orangered",
                                            fontWeight: "bold",
                                            fontSize: 16,
                                        }}
                                        formatter={(value) =>
                                            `${value}`.replace(/\B(?=(\d{0})+(?!\d))/g, ",")
                                        }
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                        min={0}
                                        defaultValue={valorIni}
                                        value={valorIni}
                                        onChange={onChangeIni}
                                        placeholder="Inicial"
                                        size="large"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item label="VR FINAL">
                                    <InputNumber
                                        style={{
                                            width: "100%",
                                            textAlign: "right",
                                            color: "orangered",
                                            fontWeight: "bold",
                                            fontSize: 16,
                                        }}
                                        formatter={(value) =>
                                            `${value}`.replace(/\B(?=(\d{0})+(?!\d))/g, ",")
                                        }
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                        min={0}
                                        defaultValue={valorFin}
                                        value={valorFin}
                                        onChange={onChangeFin}
                                        placeholder="Final"
                                        size="large"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="ESTADO DE ANIMALES">
                                    <Select
                                        showSearch
                                        size="large"
                                        showArrow
                                        style={{ width: "100%" }}
                                        defaultValue={idEstado}
                                        placeholder="ESTADO"
                                        onChange={updateEstadoInf}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        <Option key={3} value={3}>
                                            TODOS LOS ANIMALES
                                        </Option>
                                        <Option key={0} value={0}>
                                            ANIMALES ACTIVOS
                                        </Option>
                                        <Option key={1} value={1}>
                                            ANIMALES VENDIDOS
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="LISTADO A GENERAR">
                                    <Select
                                        showSearch
                                        size="large"
                                        showArrow
                                        style={{ width: "100%" }}
                                        defaultValue={idInfo > 0 ? idInfo : "SELECCIONE EL LISTADO"}
                                        placeholder="LISTADO"
                                        onChange={updateTipoInf}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        <Option key={1} value={1}>
                                            LISTADO KILOS GENERADOS
                                        </Option>
                                        <Option key={2} value={2}>
                                            LISTADO DE KILOS POR DIA
                                        </Option>
                                        <Option key={3} value={3}>
                                            LISTADO DE CONVERSIONES
                                        </Option>
                                        <Option key={4} value={4}>
                                            LISTADO DE ANIMALES POR KILO
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label=".">
                                    <Space>
                                        <Button
                                            type="primary"
                                            className={curStyle.btnLila}
                                            shape="round"
                                            icon={<ReloadOutlined />}
                                            size="large"
                                            onClick={() => downloadCaja()}
                                        >
                                            Generar
                                        </Button>
                                        <Button
                                            type="primary"
                                            className={curStyle.btnSuccess}
                                            shape="round"
                                            icon={<DownloadOutlined />}
                                            size="large"
                                            style={{ display: movimientos.length > 0 ? 'inline' : 'none' }}
                                            onClick={() => downAnimals(movimientos)}
                                        >
                                            Descargar
                                        </Button>
                                        <Button
                                            type="primary"
                                            className={curStyle.btnSky}
                                            shape="round"
                                            icon={<HomeOutlined />}
                                            size="large"
                                            onClick={() => history.push(`/${currentUser.pagina}`)}
                                        >
                                            Volver
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
                    <div
                        style={{
                            height: window.innerHeight,
                            overflow: "scroll",
                            paddingBottom: 250,
                            marginBottom: 250,
                        }}
                    >
                        <div>
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Divider
                                        style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
                                    >
                                        {
                                            <Title
                                                level={4}
                                                style={{ color: CST.lilaBdy, textAlign: "center" }}
                                            >
                                                {nameList}
                                            </Title>
                                        }
                                    </Divider>
                                    <Table
                                        columns={columns}
                                        dataSource={movimientos}
                                        style={{ padding: 3, marginTop: 5 }}
                                        summary={(pageData) => {
                                            let totalGto = 0;

                                            pageData.forEach(({ meg_importe }) => {
                                                totalGto += Number.parseFloat(meg_importe);
                                            });

                                            return (
                                                <>
                                                    <Table.Summary.Row>
                                                        <Table.Summary.Cell colSpan={10}>
                                                            <Title level={4}>{"PROMEDIOS RESULTANTES"}</Title>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <Title level={4} style={{ textAlign: "right" }}>
                                                                {myNumber(1, resumen.peso_neto)}
                                                            </Title>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <Title level={4} style={{ textAlign: "right" }}>
                                                                {myNumber(1, resumen.kilos_gen)}
                                                            </Title>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <Title level={4} style={{ textAlign: "right" }}>
                                                                {'...'}
                                                            </Title>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <Title level={4} style={{ textAlign: "right" }}>
                                                                {myNumber(1, resumen.kilos_dia)}
                                                            </Title>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <Title level={4} style={{ textAlign: "right" }}>
                                                                {myNumber(1, resumen.kg_secos)}
                                                            </Title>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <Title level={4} style={{ textAlign: "right" }}>
                                                                {myNumber(1, resumen.conversion)}
                                                            </Title>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell>
                                                            <Title level={4} style={{ textAlign: "right" }}>
                                                                {myNumber(1, resumen.alimento)}
                                                            </Title>
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell colSpan={1}>
                                                            <Text>...</Text>
                                                        </Table.Summary.Cell>
                                                    </Table.Summary.Row>
                                                </>
                                            );
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
            <ModalApp
                isVisibleModal={isVisibleModal}
                closeModal={closeModal}
                widthModal={tipoModal === 4 ? 1307 : widthModal}
            >
                {tipoModal === 1 ? (
                    <FichaAnimal
                        currentUser={currentUser}
                        closeModal={closeModal}
                        detalleAnimal={detalleAnimal}
                        detalleField={detalleField}
                        detalleSale={detalleSale}
                        detalleVacuna={[]}
                        detalleDespa={[]}
                        detalleCome={detalleCome}
                        detallePeso={detallePeso}
                        myNumber={myNumber}
                    />
                ) : (
                    <DetalleSalida
                        myNumber={myNumber}
                        detalleModal={detalleModal}
                        detalleList={detalleList}
                        totalKg={detalleList[0].anm_peso_neto}
                    />
                )
                }
            </ModalApp>

            {downExcel ? (
                <ExcelFile
                    filename={
                        "LISTADO DE ANIMALES"
                    }
                    hideElement={true}
                >
                    <ExcelSheet
                        data={movimientos}
                        name={nameList}
                    >
                        <ExcelColumn label="CODIGO" value="anm_codigo" />
                        <ExcelColumn label="ESTADO" value="anm_estado_nm" />
                        <ExcelColumn label="UBICACION" value="anm_ubica" />
                        <ExcelColumn label="RAZA" value="rza_nombre" />
                        <ExcelColumn label="SEXO" value={(col) => col.anm_sexo === 1 ? "MACHO" : "HEMBRA"} />
                        <ExcelColumn label="CATEGORIA" value="ctg_nombre" />
                        <ExcelColumn label="A FEEDLOT" value="anm_fecha_alimento" />
                        <ExcelColumn label="PESO ENTRADA" value="anm_peso_alimento" />
                        <ExcelColumn label="F. PESAJE" value="anm_fecha_peso" />
                        <ExcelColumn label="KG PESAJE" value="anm_peso_neto" />
                        <ExcelColumn label="KG GENERADOS" value="anm_kilos_gen" />
                        <ExcelColumn label="DIAS" value="anm_dias_peso" />
                        <ExcelColumn label="KG DIA" value="anm_kilos_dia" />
                        <ExcelColumn label="KG SECOS" value="anm_kilos_peso" />
                        <ExcelColumn label="CONVERSION" value="anm_conversion" />
                        <ExcelColumn label="ALIMENTO" value="anm_alimento" />
                    </ExcelSheet>
                </ExcelFile>
            ) : null}
        </div>
    );
}
