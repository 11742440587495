import React from "react";
import { Modal } from "antd";

//Styles
import "./Modal.scss";

export default function ShowModal(props) {
  const { isVisibleModal, closeModal, widthModal, children } = props;

  return (
    <Modal
      visible={isVisibleModal}
      onOk={() => closeModal()}
      onCancel={() => closeModal()}
      width={widthModal === 1307 ? "80%" : widthModal}
      closable={false}
      footer={false}
      destroyOnClose={true}
    >
      {widthModal === 1307 ? (
        <div style={{ minHeight: window.innerHeight - 200 }}>{children}</div>
      ) : (
        <div>{children}</div>
      )}
    </Modal>
  );
}
