import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Button,
  Table,
  Tag,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  Space,
  InputNumber,
} from "antd";
import { useHistory } from "react-router-dom";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

import {
  ReloadOutlined,
  HomeOutlined,
  SearchOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

import moment from "moment";

//Componentes Propios
import ModalApp from "../../../Modal";
import DetalleGastos from "../../Procesos/CjbGastosFrm/DetalleGastos";
import DetalleEntrada from "../../DetalleEntrada";

// componentes
import FRM from "../../../../utils/FrmService";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import CST from "../../../../utils/CustomSettings";
import { URL_FILES } from "../../../../constants";
import TableGastos from "../../TableGastos";

export default function CjbMovBanco(props) {
  const {
    myNumber,
    currentUser,
    currentSetup,
    setIsLoading,
    currentMoneda,
    listBancos,
  } = props;
  const [fechaEntr, setFechaEntr] = useState("");
  const [totalAnterior, setTotalAnterior] = useState({});
  const [totalFinal, setTotalFinal] = useState({});
  const [movimientos, setMovimientos] = useState([]);
  //Constantes para modales
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [detalleList, setDetalleList] = useState({});
  const [idBanco, setIdBanco] = useState(0);

  const history = useHistory();
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const { Title, Text } = Typography;
  const { Option } = Select;

  //Cambio de fecha
  function onChangeFecha(value, dateString) {
    setFechaEntr(dateString.toString());
  }

  const updateBanco = async (value) => {
    setIdBanco(value);
  };

  const openAdjunto = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/gastos/" + record.meg_adjunto;
    window.open(`${url_temp}`);
  };

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  // TRAER MOVIMIENTOS
  const downloadCaja = async () => {
    if (fechaEntr.length === 0 || Number.parseInt(idBanco) === 0) {
      showNotification(
        "topRight",
        2,
        "MOVIMIENTOS DE CAJA",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL INFORME"
      );
    } else {
      //setIsLoading(true);
      let record = {
        tipoMov: 2,
        usr_rgs: currentUser.id,
        mov_fecha: fechaEntr,
        cue_id: idBanco,
      };
      var val_movcaja = await FRM.getMovCaja(currentUser, record);
      console.log(val_movcaja);
      if (val_movcaja.type && Number.parseInt(val_movcaja.type) > 0) {
        setTotalAnterior(val_movcaja.totalAnterior);
        setTotalFinal(val_movcaja.totalFinal);
        setMovimientos(val_movcaja.movimientos);
        showNotification(
          "topRight",
          4,
          "MOVIMIENTOS DE BANCOS",
          "PROCESO DE CONSULTA EXITOSO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "MOVIMIENTOS DE BANCOS",
          val_movcaja.message
        );
      }
    }
    //setIsLoading(false);
  };

  //Columnas resumen caja
  const resCuentas = [
    {
      title: "ID", //10%
      dataIndex: "mov_id",
      key: "mov_id",
      width: "2%",
    },
    {
      title: "FECHA",
      dataIndex: "mov_fecha",
      key: "mov_fecha",
    },
    {
      title: "CLASE",
      dataIndex: "mov_clase",
      key: "mov_clase",
    },
    {
      title: "NUMERO",
      dataIndex: "mov_numero",
      key: "mov_numero",
    },
    {
      title: "DETALLE",
      dataIndex: "mov_detalle",
      key: "mov_detalle",
    },
    {
      title: "ENTRA US$",
      dataIndex: "mov_debito",
      key: "mov_debito",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.mov_debito));
      },
    },
    {
      title: "SALE US$",
      dataIndex: "mov_credito",
      key: "mov_credito",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.mov_credito));
      },
    },
    {
      title: "ENTRA UYU$",
      dataIndex: "mov_debito_fr",
      key: "mov_debito_fr",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.mov_debito_fr));
      },
    },
    {
      title: "SALE UYU$",
      dataIndex: "mov_credito_fr",
      key: "mov_credito_fr",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.mov_credito_fr));
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col span={4}>
            <Form.Item label="PERIODO A CONSULTAR">
              <RangePicker
                size="large"
                ranges={{
                  Hoy: [moment(), moment()],
                  "Este Mes": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onChange={onChangeFecha}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="CUENTA BANCARIA">
              <Select
                showSearch
                size="large"
                showArrow
                style={{ width: "100%" }}
                defaultValue={idBanco > 0 ? idBanco : "SELECCIONE LA CUENTA"}
                placeholder="CUENTA BANCARIA"
                onChange={updateBanco}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {listBancos.map((banco) => (
                  <Option key={banco.cue_id} value={banco.cue_id}>
                    {banco.cue_nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item label=".">
              <Space>
                <Button
                  type="primary"
                  className={curStyle.btnLila}
                  shape="round"
                  icon={<ReloadOutlined />}
                  size="large"
                  onClick={() => downloadCaja()}
                >
                  Generar
                </Button>
                <Button
                  type="primary"
                  className={curStyle.btnSky}
                  shape="round"
                  icon={<HomeOutlined />}
                  size="large"
                  onClick={() => history.push(`/${currentUser.pagina}`)}
                >
                  Volver
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <div
        style={{
          height: window.innerHeight,
          overflow: "scroll",
          paddingBottom: 250,
          marginBottom: 250,
        }}
      >
        <div>
          <Row gutter={8}>
            <Col span={24}>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                {
                  <Title
                    level={4}
                    style={{ color: CST.lilaBdy, textAlign: "center" }}
                  >
                    {totalAnterior.inicial_lcl
                      ? "MOVIMIENTOS DEL PERIODO (SALDO ANTERIOR " +
                        myNumber(
                          1,
                          Number.parseFloat(totalAnterior.inicial_lcl) +
                            Number.parseFloat(totalAnterior.inicial_frn)
                        ) +
                        ")"
                      : "MOVIMIENTOS DEL PERIDO"}
                  </Title>
                }
              </Divider>
              <Table
                columns={resCuentas}
                dataSource={movimientos}
                style={{ padding: 3, marginTop: 5 }}
              />
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                {
                  <Title
                    level={4}
                    style={{ color: CST.lilaBdy, textAlign: "center" }}
                  >
                    {totalFinal.final_lcl
                      ? "SALDO ACTUAL (" +
                        myNumber(
                          1,
                          Number.parseFloat(totalFinal.final_lcl) +
                            Number.parseFloat(totalFinal.final_frn)
                        ) +
                        ")"
                      : "..."}
                  </Title>
                }
              </Divider>
            </Col>
          </Row>
        </div>
        <ModalApp
          isVisibleModal={isVisibleModal}
          closeModal={closeModal}
          widthModal={tipoModal === 4 ? 1307 : widthModal}
        >
          {tipoModal === 1 ? (
            <DetalleGastos
              currentUser={currentUser}
              closeModal={closeModal}
              setStateInf={Math.random()}
              currentSetup={currentSetup}
              myNumber={myNumber}
              detalleModal={detalleModal}
              currentMoneda={currentMoneda}
            />
          ) : tipoModal === 2 ? (
            <DetalleEntrada
              currentUser={currentUser}
              closeModal={closeModal}
              setIsLoading={setIsLoading}
              myNumber={myNumber}
              detalleCarga={detalleModal}
              opcionCarga={3}
              detalleList={detalleList}
            />
          ) : (
            <DetalleGastos
              currentUser={currentUser}
              closeModal={closeModal}
              setStateInf={Math.random()}
              currentSetup={currentSetup}
              myNumber={myNumber}
              detalleModal={detalleModal}
              currentMoneda={currentMoneda}
            />
          )}
        </ModalApp>
      </div>
    </div>
  );
}
