import React, { useState } from "react";
import {
    Form,
    Modal,
    Button,
    Table,
    Tabs,
    Typography,
    Select,
    Input,
    Row,
    Col,
    Popconfirm,
    message,
    DatePicker,
    Divider,
    Space,
    InputNumber,
} from "antd";
import { useHistory } from "react-router-dom";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

import {
    ReloadOutlined,
    HomeOutlined,
    SearchOutlined,
    FilePdfOutlined,
} from "@ant-design/icons";

import moment from "moment";

//Componentes Propios
import ModalApp from "../../../Modal";
import DetalleGastos from "../../Procesos/CjbGastosFrm/DetalleGastos";
import DetalleEntrada from "../../DetalleEntrada";

// componentes
import FRM from "../../../../utils/FrmService";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import CST from "../../../../utils/CustomSettings";
import { URL_FILES } from "../../../../constants";
import TableGastos from "../../TableGastos";

export default function CjbLstGastos(props) {
    const {
        myNumber,
        currentUser,
        currentSetup,
        setIsLoading,
        currentMoneda,
        listaCnc,
        suppliersList
    } = props;
    const [fechaCnc, setFechaCnc] = useState("");
    const [fechaPrv, setFechaPrv] = useState("");
    const [gastosTp1, setGastosTp1] = useState([]);
    const [gastosTp2, setGastosTp2] = useState([]);
    //Constantes para modales
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [widthModal, setWidthModal] = useState("70%");
    const [tipoModal, setTipoModal] = useState(1);
    const [detalleModal, setDetalleModal] = useState({});
    const [detalleList, setDetalleList] = useState({});
    const [idConcepto, setIdConcepto] = useState(0);
    const [idProvee, setIdProvee] = useState(0);
    const [tipoInf, setTipoInf] = useState(1);
    const [isGetting, setIsGetting] = useState(false)

    const history = useHistory();
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const { Title, Text } = Typography;
    const { Option } = Select;

    //Cambio de fecha
    function onChangeCncDate(value, dateString) {
        setFechaCnc(dateString.toString());
        message.info(dateString.toString(), 3);
    }
    function onChangePrvDate(value, dateString) {
        setFechaPrv(dateString.toString());
        message.info(dateString.toString(), 3);
    }

    const updateConcepto = async (value) => {
        setIdConcepto(value);
    };

    const updateProvee = async (value) => {
        setIdProvee(value);
    };

    const openAdjunto = (record) => {
        //Url para visaulizacion del PDF Temporal
        const url_temp =
            URL_FILES + currentUser.dominio + "/gastos/" + record.meg_adjunto;
        window.open(`${url_temp}`);
    };

    const openModal = (record, tipo, ancho) => {
        setTipoModal(tipo);
        setWidthModal(ancho);
        setDetalleModal(record);
        setIsVisibleModal(true);
    };

    const closeModal = () => {
        setIsVisibleModal(false);
    };

    // TRAER MOVIMIENTOS
    const downloadCnc = async () => {
        setTipoInf(1);
        if (fechaCnc.length === 0 || Number.parseInt(idConcepto) === 0) {
            showNotification(
                "topRight",
                2,
                "GASTOS POR CONCEPTO",
                "LOS DATOS ESTAN INCOMPLETOS PARA EL INFORME"
            );
        } else {
            setIsGetting(true);
            let record = {
                tipoMov: 5,
                usr_rgs: currentUser.id,
                meg_fecha: fechaCnc,
                cnc_id: idConcepto,
            };
            var val_movcaja = await FRM.getMovCaja(currentUser, record);
            if (val_movcaja.type && Number.parseInt(val_movcaja.type) > 0) {
                setGastosTp1(val_movcaja.gastosTp1);
                setGastosTp2(val_movcaja.gastosTp2);
                showNotification(
                    "topRight",
                    4,
                    "LISTADO DE GASTOS",
                    "PROCESO DE CONSULTA EXITOSO"
                );
            } else {
                showNotification(
                    "topRight",
                    2,
                    "LISTADO DE GASTOS",
                    val_movcaja.message
                );
            }
        }
        setIsGetting(false);
    };

    const downloadPrv = async () => {
        if (fechaPrv.length === 0 || Number.parseInt(idProvee) === 0) {
            showNotification(
                "topRight",
                2,
                "GASTOS POR PROVEEDOR",
                "LOS DATOS ESTAN INCOMPLETOS PARA EL INFORME"
            );
        } else {
            setIsGetting(true);
            let record = {
                tipoMov: 6,
                usr_rgs: currentUser.id,
                meg_fecha: fechaPrv,
                trc_id: idProvee,
            };
            var val_movcaja = await FRM.getMovCaja(currentUser, record);
            if (val_movcaja.type && Number.parseInt(val_movcaja.type) > 0) {
                setGastosTp1(val_movcaja.gastosTp1);
                setGastosTp2(val_movcaja.gastosTp2);
                showNotification(
                    "topRight",
                    4,
                    "LISTADO DE GASTOS",
                    "PROCESO DE CONSULTA EXITOSO"
                );
            } else {
                showNotification(
                    "topRight",
                    2,
                    "LISTADO DE GASTOS",
                    val_movcaja.message
                );
            }
        }
        setIsGetting(false);
    };


    const { TabPane } = Tabs;

    return (
        <div className="upload-panel">
            <Tabs defaultActiveKey="1">
                <TabPane tab="LISTADO DE GASTOS POR CONCEPTO" key="1">
                    <Form layout="vertical" form={form}>
                        <Row gutter={8}>
                            <Col span={4}>
                                <Form.Item label="PERIODO A CONSULTAR">
                                    <RangePicker
                                        size="large"
                                        ranges={{
                                            Hoy: [moment(), moment()],
                                            "Este Mes": [
                                                moment().startOf("month"),
                                                moment().endOf("month"),
                                            ],
                                        }}
                                        onChange={onChangeCncDate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="CONCEPTO DE GASTO">
                                    <Select
                                        showSearch
                                        size="large"
                                        showArrow
                                        style={{ width: "100%" }}
                                        defaultValue={idConcepto > 0 ? idConcepto : "SELECCIONE EL CONCEPTO"}
                                        placeholder="CONCEPTO DE SERGIO"
                                        onChange={updateConcepto}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {listaCnc.map((concepto) => (
                                            <Option key={concepto.cnc_id} value={concepto.cnc_id}>
                                                {concepto.cnc_describe}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={14}>
                                <Form.Item label=".">
                                    <Space>
                                        <Button
                                            type="primary"
                                            className={curStyle.btnInfo}
                                            shape="round"
                                            icon={<ReloadOutlined />}
                                            size="large"
                                            onClick={() => downloadCnc()}
                                            loading={isGetting}
                                        >
                                            Generar
                                        </Button>
                                        <Button
                                            type="primary"
                                            className={curStyle.btnSky}
                                            shape="round"
                                            icon={<HomeOutlined />}
                                            size="large"
                                            onClick={() => history.push(`/${currentUser.pagina}`)}
                                        >
                                            Volver
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
                <TabPane tab="LISTADO DE GASTOS POR BENEFICIARIO" key="2">
                    <Form layout="vertical" form={form}>
                        <Row gutter={8}>
                            <Col span={4}>
                                <Form.Item label="PERIODO A CONSULTAR">
                                    <RangePicker
                                        size="large"
                                        ranges={{
                                            Hoy: [moment(), moment()],
                                            "Este Mes": [
                                                moment().startOf("month"),
                                                moment().endOf("month"),
                                            ],
                                        }}
                                        onChange={onChangePrvDate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="PROVEEDOR A CONSULTAR">
                                    <Select
                                        showSearch
                                        size="large"
                                        showArrow
                                        style={{ width: "100%" }}
                                        defaultValue={
                                            idProvee > 0 ? idProvee : "SELECCIONE EL PROVEEDOR"
                                        }
                                        placeholder="CUENTA BANCARIA"
                                        onChange={updateProvee}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {suppliersList.map((provee) => (
                                            <Option key={provee.trc_id} value={provee.trc_id}>
                                                {provee.trc_nombre}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={14}>
                                <Form.Item label=".">
                                    <Space>
                                        <Button
                                            type="primary"
                                            className={curStyle.btnLila}
                                            shape="round"
                                            icon={<ReloadOutlined />}
                                            size="large"
                                            onClick={() => downloadPrv()}
                                            loading={isGetting}
                                        >
                                            Generar
                                        </Button>
                                        <Button
                                            type="primary"
                                            className={curStyle.btnSky}
                                            shape="round"
                                            icon={<HomeOutlined />}
                                            size="large"
                                            onClick={() => history.push(`/${currentUser.pagina}`)}
                                        >
                                            Volver
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
            </Tabs>

            <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
            <div
                style={{
                    height: window.innerHeight,
                    overflow: "scroll",
                    paddingBottom: 250,
                    marginBottom: 250,
                }}
            >
                <div>
                    <Row gutter={8}>
                        <Col span={24}>
                            <TableGastos
                                tipoMoneda={currentMoneda.simbolo}
                                openModal={openModal}
                                openAdjunto={openAdjunto}
                                listGastos={gastosTp1}
                                tableTitle={
                                    "GASTOS EN DOLARES"
                                }
                            />
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col span={24}>
                            <TableGastos
                                tipoMoneda={currentMoneda.simbolo_frn}
                                openModal={openModal}
                                openAdjunto={openAdjunto}
                                listGastos={gastosTp2}
                                tableTitle={
                                    "GASTOS EN PESOS URUGUAYOS"
                                }
                            />
                        </Col>
                    </Row>
                </div>
                <ModalApp
                    isVisibleModal={isVisibleModal}
                    closeModal={closeModal}
                    widthModal={tipoModal === 4 ? 1307 : widthModal}
                >
                    {tipoModal === 1 ? (
                        <DetalleGastos
                            currentUser={currentUser}
                            closeModal={closeModal}
                            setStateInf={Math.random()}
                            currentSetup={currentSetup}
                            myNumber={myNumber}
                            detalleModal={detalleModal}
                            currentMoneda={currentMoneda}
                        />
                    ) : tipoModal === 2 ? (
                        <DetalleEntrada
                            currentUser={currentUser}
                            closeModal={closeModal}
                            setIsLoading={setIsLoading}
                            myNumber={myNumber}
                            detalleCarga={detalleModal}
                            opcionCarga={3}
                            detalleList={detalleList}
                        />
                    ) : (
                        <DetalleGastos
                            currentUser={currentUser}
                            closeModal={closeModal}
                            setStateInf={Math.random()}
                            currentSetup={currentSetup}
                            myNumber={myNumber}
                            detalleModal={detalleModal}
                            currentMoneda={currentMoneda}
                        />
                    )}
                </ModalApp>
            </div>
        </div>
    );
}
