import React, { useState } from "react";
import {
  Form,
  Modal,
  Button,
  Table,
  Upload,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  Space,
  InputNumber,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../../actions/rolEnviromentActions";
//Styles
import "./CjbGastosLcl.scss";

// componentes
import FRM from "../../../../utils/FrmService";
import { reloadData } from "../../../../utils/ReloadEnv";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../../constants";

export default function CjbGastosLcl(props) {
  const { myNumber, currentUser, currentSetup } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [usrId, setUsrId] = useState(currentUser.id);
  const [idProveedor, setIdProveedor] = useState(0);
  const [idCnc, setIdCnc] = useState(0);
  const [detalleCnc, setDetalleCnc] = useState("");
  const [valorGto, setValorGto] = useState(0);
  const [idEfe, setIdEfe] = useState(0);
  const [valorEfe, setValorEfe] = useState(0);
  const [idBnc, setIdBnc] = useState(0);
  const [valorBnc, setValorBnc] = useState(0);
  const [nota, setNota] = useState("");
  const [fechaGto, setFechaGto] = useState(null);
  const [idAutoriza, setIdAutoriza] = useState(0);

  const { Option } = Select;
  const [form] = Form.useForm();

  //Listado de proveedores
  const suppliersList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.servicios
  );

  //Listado de conceptos
  const listaCnc = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.conceptos_gto
  );

  //Listado de conceptos
  const cuentasLcl = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.cuentas_lcl
  );

  const autoriza = useSelector(
    (state) => state.enviroment.enviroment.gst_users
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaGto(dateString);
  }
  //Se actualiza el nombre
  const updateProveedor = async (value) => {
    setIdProveedor(value);
  };

  //Se actualiza el concepto
  const updateCnc = async (value) => {
    setIdCnc(value);
  };

  const onChangeDeta = (e) => {
    const detaCnc = e.target.value;
    setDetalleCnc(detaCnc.toUpperCase());
  };

  const updateAutoriza = async (value) => {
    setIdAutoriza(value);
  };

  //Se actualiza cuenta de banco
  const updateBnc = async (value) => {
    setIdBnc(value);
    setValorBnc(0);
  };

  const onChangeGto = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setValorGto(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeBnc = (value) => {
    if (Number.parseFloat(value) >= 0 && Number.parseInt(idBnc) > 0) {
      setValorBnc(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  //GESTION DE CARGAR DE ARCHIVOS PDF
  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  // called every time a file's `status` changes
  //const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }

  const handleChangeStatus = ({ meta, remove }, status) => {
    if (status === "headers_received") {
      alert(`${meta.name} uploaded!`);
      remove();
    } else if (status === "aborted") {
      alert(`${meta.name}, upload failed...`);
    }
  };
  //FIN GESTION DE CARGA DE ARCHIVOS

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const registrarGasto = async () => {
    if (
      Number.parseInt(idProveedor) === 0 ||
      Number.parseInt(idCnc) === 0 ||
      detalleCnc.length === 0 ||
      fechaGto.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "REGISTRAR GASTOS",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      if (Number.parseFloat(valorBnc) !== Number.parseFloat(valorGto)) {
        showNotification(
          "topRight",
          2,
          "REGISTRAR GASTOS",
          "EL VALOR DEL DETALLE DIFIERE DEL VALOR TOTAL"
        );
      } else {
        let record = {
          tipoMov: 1,
          idMov: 0,
          numero_anm: 0,
          usr_id: currentUser.id,
          usr_apr: idAutoriza,
          trc_id: idProveedor,
          cnc_id: idCnc,
          efe_id: 0,
          bnc_id: idBnc,
          meg_detalle: detalleCnc,
          meg_importe: Number.parseFloat(valorGto),
          meg_efectivo: 0,
          meg_transfer: Number.parseFloat(valorBnc),
          meg_nota: nota,
          meg_fecha: fechaGto,
        };

        var val_gasto = await FRM.sendGasto(currentUser, record);
        if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
          updateEnviroment(val_gasto.datos.rol_entorno);
          showNotification(
            "topRight",
            4,
            "CARGAR GASTOS",
            "REGISTRO DE GASTO EXITOSO"
          );
        } else {
          showNotification("topRight", 2, "CARGAR GASTOS", val_gasto.message);
        }
      }
    }
  };

  return (
    <div className="upload-panel">
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 20 }}>
        {"DATOS GENERALES DEL GASTO"}
      </Divider>
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Fecha de Emision">
              <DatePicker onChange={onChangeFecha} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label="Proveedor Beneficiario">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={
                  idProveedor > 0 ? idProveedor : "SELECCIONE BENEFICIARIO"
                }
                placeholder="PROVEEDOR"
                onChange={updateProveedor}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {suppliersList.map((proveedor) => (
                  <Option key={proveedor.trc_id} value={proveedor.trc_id}>
                    {proveedor.trc_nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label="Usuario que Autoriza">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={
                  idAutoriza === 0
                    ? "SELECCIONE USUARIO QUE AUTORIZA"
                    : idAutoriza
                }
                placeholder="USUARIO AUTORIZA"
                onChange={updateAutoriza}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {autoriza.map((autor) => (
                  <Option key={autor.id} value={autor.id}>
                    {autor.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 10 }}>
          <Col span={10}>
            <Form.Item label="Concepto a Imputar">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={idCnc > 0 ? idCnc : "ELIJA CONCEPTO"}
                placeholder="CONCEPTO"
                onChange={updateCnc}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {listaCnc.map((conceptos) => (
                  <Option key={conceptos.cnc_id} value={conceptos.cnc_id}>
                    {conceptos.cnc_nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Detalle del Concepto">
              <Input
                placeholder="Detalle Concepto"
                defaultValue={""}
                style={{ width: "100%" }}
                value={detalleCnc}
                onChange={onChangeDeta}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Monto a Imputar">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={valorGto}
                value={valorGto}
                onChange={onChangeGto}
                placeholder="Valor"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <TextArea
              rows={2}
              value={nota}
              placeholder={"Observaciones"}
              onChange={onChangeNota}
              allowClear
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
          {" "}
          Detalle del Desembolso{" "}
        </Divider>
        <Row gutter={16} style={{ marginTop: 10 }}>
          <Col span={7} style={{ textAlign: "left" }}>
            <Title
              level={4}
              style={{
                padding: 2,
                margin: 2,
                fontWeight: 600,
                color: colorGrn,
                fontSize: 16,
              }}
            >
              CUENTA BANCARIA:
            </Title>
          </Col>
          <Col span={13}>
            <Select
              showSearch
              showArrow
              style={{ width: "100%" }}
              defaultValue={idBnc > 0 ? idBnc : "ELIJA CUENTA EN DOLARES"}
              placeholder="CUENTAS BANCARIAS"
              onChange={updateBnc}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {cuentasLcl.map((bancos) => (
                <Option key={bancos.cue_id} value={bancos.cue_id}>
                  {bancos.cue_nombre}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <InputNumber
              style={{ width: "100%", textAlign: "right" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              min={0}
              defaultValue={valorBnc}
              value={valorBnc}
              onChange={onChangeBnc}
              placeholder="Deposito"
            />
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
        {" "}
        Adjuntar comprobante de respaldo (FORMATO PDF){" "}
      </Divider>
      <div
        style={{
          textAlign: "center",
          marginTop: 20,
          border: 0,
        }}
      >
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          /*onSubmit={handleSubmit}*/
          autoUpload={false}
          maxFiles={1}
          multiple={false}
          canCancel={false}
          accept=".pdf"
          inputContent={"Arrastre o seleccione el PDF a adjuntar"}
          inputWithFilesContent={"Agregar Archivos"}
          submitButtonContent={"Subir Archivos"}
          className="upload_ppal"
        />
      </div>

      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => registrarGasto()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            block
            style={{
              display: Number.parseFloat(valorGto) > 0 ? "block" : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> REGISTRAR EL GASTO
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}

//https://pastebin.com/yKpF4A2h

function info(text) {
  Modal.info({
    title: "OBSERVACIONES",
    content: (
      <div>
        <p>{text}</p>
      </div>
    ),
    onOk() {},
  });
}
