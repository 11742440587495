import React, { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  Divider,
  Statistic,
  InputNumber,
  message,
} from "antd";
import { UploadOutlined, CalendarOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../../actions/rolEnviromentActions";
//Styles
import "./CjbGastosFrm.scss";

// componentes
import FRM from "../../../../utils/FrmService";
import { reloadData } from "../../../../utils/ReloadEnv";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { URL_FILES } from "../../../../constants";
import CST from "../../../../utils/CustomSettings";

export default function DetalleOrden(props) {
  const {
    currentUser,
    closeModal,
    setStateInf,
    currentSetup,
    myNumber,
    detalleModal,
    currentMoneda,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [idCnc, setIdCnc] = useState(0);
  const [idCncOld, setIdCncOld] = useState(0);
  const [nota, setNota] = useState("");
  const [valorGto, setValorGto] = useState(detalleModal.ord_importe);

  const { Option } = Select;
  const [form] = Form.useForm();

  const estado =
    detalleModal.ord_estado === 0
      ? " (SIN IMPORTE)"
      : detalleModal.ord_estado === 1
      ? " (POR APROBAR)"
      : detalleModal.ord_estado === 2
      ? " (APROBADA)"
      : " (PAGADA)";

  //Listado de conceptos
  const listaCnc = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.conceptos_gto
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Se actualiza el concepto
  const updateCnc = async (value) => {
    setIdCnc(value);
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  const onChangeGto = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setValorGto(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const registrarGasto = async () => {
    if (
      detalleModal.ord_estado === 0 &&
      Number.parseFloat(valorGto) === 0 &&
      Number.parseInt(currentUser.tipo) === 4
    ) {
      showNotification(
        "topRight",
        2,
        "ACTUALIZAR ORDEN DE COMPRA",
        "EL VALOR DE LA ORDEN NO PUEDE SER CERO"
      );
    } else {
      setLoadingButton(true);
      let record = {
        tipoMov: currentUser.tipo === 4 ? 12 : 13,
        ord_id: detalleModal.ord_id,
        ord_importe: valorGto,
        ord_nota_aprueba: nota,
      };

      var val_gasto = await FRM.sendGasto(currentUser, record);
      if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
        updateEnviroment(val_gasto.datos.rol_entorno);
        setStateInf(Math.random());
        showNotification(
          "topRight",
          4,
          "ORDEN DE COMPRA",
          "REGISTRO DE ORDEN EXITOSO"
        );
      } else {
        showNotification("topRight", 2, "ORDEN DE COMPRA", val_gasto.message);
      }
      setLoadingButton(false);
      closeModal();
    }
  };

  return (
    <div className="upload-panel">
      <Divider
        style={{
          marginTop: 15,
          paddingTop: 10,
          marginBottom: 20,
          color: detalleModal.ord_moneda === 2 ? CST.orangeBdy : CST.greenBdy,
          fontSize: 20,
        }}
      >
        {detalleModal.ord_moneda === 1
          ? "DATOS GENERALES ORDEN EN DOLARES"
          : "DATOS GENERALES ORDEN EN PESOS UYU"}
      </Divider>
      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col span={6}>
            <Statistic
              title="Fecha Emision"
              value={detalleModal.ord_fecha}
              prefix={<CalendarOutlined />}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="F. de Autorizacion"
              value={
                detalleModal.ord_faprueba
                  ? detalleModal.ord_faprueba.toString().substr(0, 10)
                  : "..."
              }
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Proveedor del Producto o Servicio"
              value={detalleModal.trc_nombre}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={12}>
            <Statistic
              title="Usuario que Solicita"
              value={detalleModal.registro}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Usuario que Autoriza"
              value={detalleModal.autoriza}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={18}>
            <Statistic
              title="Concepto de la Orden:"
              value={detalleModal.ord_detalle}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Valor Orden"
              value={
                detalleModal.ord_moneda === 1
                  ? currentMoneda.simbolo +
                    myNumber(1, detalleModal.ord_importe)
                  : currentMoneda.simbolo_frn +
                    myNumber(1, detalleModal.ord_importe)
              }
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Row
          gutter={16}
          style={{
            display:
              Number.parseInt(currentUser.tipo) === 4 &&
              detalleModal.ord_estado === 0
                ? "block"
                : "none",
          }}
        >
          <Col span={20}>
            <Statistic
              title="Concepto de la Orden:"
              value={detalleModal.ord_detalle}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={4}>
            <Form.Item label="Monto">
              <InputNumber
                style={{
                  width: "100%",
                  textAlign: "right",
                  color:
                    detalleModal.ord_moneda === 1
                      ? CST.orangeBdy
                      : CST.greenBdy,
                  fontSize: 16,
                }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={valorGto}
                value={valorGto}
                onChange={onChangeGto}
                placeholder="Valor"
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={24}>
            <Statistic
              title="Observaciones al Registrar:"
              value={detalleModal.ord_nota ? detalleModal.ord_nota : "..."}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Statistic
              title="Observaciones al Aprobar:"
              value={
                detalleModal.ord_nota_aprueba
                  ? detalleModal.ord_nota_aprueba
                  : "..."
              }
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider
          style={{
            marginTop: 5,
            paddingTop: 5,
            marginBottom: 5,
            display:
              detalleModal.usr_apr === currentUser.id &&
              detalleModal.ord_estado === 0
                ? "block"
                : "none",
          }}
        />
        <Row
          style={{
            display:
              detalleModal.usr_apr === currentUser.id &&
              detalleModal.ord_estado <= 1
                ? "block"
                : "none",
          }}
        >
          <Col span={24}>
            <TextArea
              rows={2}
              value={nota}
              placeholder={"Observaciones al Aprobar"}
              onChange={onChangeNota}
              allowClear
            />
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => registrarGasto()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={
              detalleModal.ord_moneda === 2
                ? curStyle.btnOrange
                : curStyle.btnSuccess
            }
            shape="round"
            block
            style={{
              display:
                (detalleModal.usr_apr === currentUser.id &&
                  detalleModal.ord_estado <= 1) ||
                (Number.parseInt(currentUser.tipo) === 4 &&
                  detalleModal.ord_estado === 0)
                  ? "block"
                  : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined />{" "}
            {detalleModal.usr_apr === currentUser.id
              ? "AUTORIZAR LA ORDEN DE COMPRA"
              : "ACTUALIZAR LA ORDEN DE COMPRA"}
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
