import React from "react";
import { Typography, Row, Col, Divider, Statistic, Image, Button } from "antd";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { URL_FILES } from "../../../constants";

export default function DetalleActivity(props) {
  const { detalleModal, currentUser } = props;

  const { Title } = Typography;

  const estado =
    detalleModal.inf_estado === 0 ? " (POR APROBAR)" : " (APROBADO)";

  const openAdjunto = (pdf_file) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp = URL_FILES + currentUser.dominio + "/informes/" + pdf_file;
    window.open(`${url_temp}`);
  };

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"INFORME DIARIO DE ACTIVIDAD" + estado}
      </Title>
      <Row gutter={8}>
        <Col span={8}>
          <Statistic
            title="Fecha Inicio"
            value={
              detalleModal.inf_dia_ini +
              "-" +
              detalleModal.inf_mes_ini +
              "-" +
              detalleModal.inf_ano_ini
            }
            prefix={<CalendarOutlined />}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="Hora Inicio"
            value={detalleModal.inf_hora_ini + ":" + detalleModal.inf_min_ini}
            prefix={<ClockCircleOutlined />}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Fecha Final"
            value={
              detalleModal.inf_dia_fin +
              "-" +
              detalleModal.inf_mes_fin +
              "-" +
              detalleModal.inf_ano_fin
            }
            prefix={<CalendarOutlined />}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="Hora Final"
            value={detalleModal.inf_hora_fin + ":" + detalleModal.inf_min_fin}
            prefix={<ClockCircleOutlined />}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Row gutter={8}>
        <Col span={24}>
          <Statistic
            title="Asunto de la actividad reportada"
            value={detalleModal.inf_asunto}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Row gutter={8}>
        <Col span={12}>
          <Statistic
            title="Registrado por"
            value={detalleModal.registro}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Dirigido a"
            value={detalleModal.aprueba}
            groupSeparator=""
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Row gutter={8}>
        <Col span={24}>
          <Statistic
            title="Observaciones al Registrar:"
            value={detalleModal.inf_nota}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Row gutter={8}>
        <Col span={24}>
          <Statistic
            title={
              detalleModal.inf_estado === 1
                ? "EL " +
                detalleModal.inf_faprueba.toString().substr(0, 10) +
                " A LAS " +
                detalleModal.inf_faprueba.toString().substr(11, 5) +
                " se aprobó y anotó"
                : "POR APROBAR"
            }
            value={
              detalleModal.inf_estado === 1
                ? detalleModal.inf_nota_aprueba
                : "..."
            }
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }}> PDFs adjuntos </Divider>
      <Row style={{ marginTop: 10 }} gutter={8}>
        <Col span={8}>
          {detalleModal.inf_adjunto1 ? (
            <Button
              type="link"
              size="large"
              onClick={() => openAdjunto(detalleModal.inf_adjunto1)}
            >
              {detalleModal.inf_adjunto1.substring(0, 10) + '...pdf'}
            </Button>
          ) : null}
        </Col>
        <Col span={8}>
          {detalleModal.inf_adjunto2 ? (
            <Button
              type="link"
              size="large"
              onClick={() => openAdjunto(detalleModal.inf_adjunto2)}
            >
              {detalleModal.inf_adjunto2.substring(0, 10) + '...pdf'}
            </Button>
          ) : null}
        </Col>
        <Col span={8}>
          {detalleModal.inf_adjunto3 ? (
            <Button
              type="link"
              size="large"
              onClick={() => openAdjunto(detalleModal.inf_adjunto3)}
            >
              {detalleModal.inf_adjunto3.substring(0, 10) + '...pdf'}
            </Button>
          ) : null}
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 20 }} />
      <Row gutter={8}>
        <Col span={6}>
          <Image
            width={100}
            src={
              detalleModal.inf_imagen1
                ? URL_FILES +
                currentUser.dominio +
                "/informes/" +
                detalleModal.inf_imagen1
                : URL_FILES + currentUser.dominio + "/fotos/default.png"
            }
          />
        </Col>
        <Col span={6}>
          <Image
            width={100}
            src={
              detalleModal.inf_imagen2
                ? URL_FILES +
                currentUser.dominio +
                "/informes/" +
                detalleModal.inf_imagen2
                : URL_FILES + currentUser.dominio + "/fotos/default.png"
            }
          />
        </Col>
        <Col span={6}>
          <Image
            width={100}
            src={
              detalleModal.inf_imagen3
                ? URL_FILES +
                currentUser.dominio +
                "/informes/" +
                detalleModal.inf_imagen3
                : URL_FILES + currentUser.dominio + "/fotos/default.png"
            }
          />
        </Col>
        <Col span={6}>
          <Image
            width={100}
            src={
              detalleModal.inf_imagen4
                ? URL_FILES +
                currentUser.dominio +
                "/informes/" +
                detalleModal.inf_imagen4
                : URL_FILES + currentUser.dominio + "/fotos/default.png"
            }
          />
        </Col>
      </Row>
    </div>
  );
}
