import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { Table, Input, Button, Space, message } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import { TitlePage } from "../../TitlePage";

//Floating Buttons
import FloatingButtons from "react-floating-buttons";
import newRec from "../../../assets/icons/file-edit-outline.svg";
import backHome from "../../../assets/icons/home-outline.svg";

//Importacion del form
import CjbConceptosForm from "./CjbConceptosForm";

//Importaciones propias
import ModalApp from "../../Modal";
import { colorPrm } from "../../../constants";

/**
 * Conceptos de Ingreso y Gasto la Lista que está seteada en el State
 */
export default function CjbConceptos() {
  const conceptos_ig = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.conceptos_ig
  );
  const clases_cnc = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.clases_cnc
  );
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [record, setRecord] = useState(null);
  const history = useHistory();

  //Definicion de los floating
  const buttonsList = [
    {
      onClick: () => history.push(`/${currentUser.pagina}`),
      src: backHome,
      style: { color: "red" },
    },
    {
      onClick: () => openModal(null),
      src: newRec,
    },
  ];
  //Fin floating

  //Nuevas funciones de busqueda de columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //Fin de nuevas funciones de busqueda

  const columns = [
    {
      title: "ID",
      dataIndex: "cnc_id",
      key: "cnc_id",
      width: "5%",
      ...getColumnSearchProps("cnc_id"),
    },
    {
      title: "Nombre",
      dataIndex: "cnc_nombre",
      key: "cnc_nombre",
      width: "15%",
      ...getColumnSearchProps("cnc_nombre"),
    },
    {
      title: "Tipo",
      dataIndex: "cnc_tipo",
      key: "cnc_tipo",
      width: "10%",
      ...getColumnSearchProps("cnc_tipo"),
      render: (text, record) => (record.cnc_tipo === 1 ? "Ingresos" : "Gastos"),
    },
    {
      title: "Clase",
      dataIndex: "cls_nombre",
      key: "cls_nombre",
      width: "10%",
      ...getColumnSearchProps("cls_nombre"),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          size="large"
          shape="circle"
          onClick={() => openModal(record)}
        >
          <EditOutlined />
        </Button>
      ),
    },
  ];

  // MODALES/////////////////////
  const openModal = (item) => {
    if (item) {
      setRecord(item);
    } else {
      let newRecord = {
        cnc_id: 0,
        cnc_divide: 0,
      };
      setRecord(newRecord);
    }

    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };
  //FIN MODLAES////////////////

  const probar = () => {
    message.success("controlado", 1);
  };

  return (
    <div style={{ margin: 15 }}>
      <TitlePage title="ABM Conceptos de Ingreso y Gasto" />
      <Table
        columns={columns}
        dataSource={conceptos_ig}
        pagination={{ pageSize: 45 }}
        scroll={{ y: window.innerHeight - 230 }}
      />
      <FloatingButtons
        buttonType="plus"
        buttonColor={"#ffffff"}
        backgroundColor={colorPrm}
        dimension={60}
        buttonsList={buttonsList}
        top={"calc(85% - 25px)"}
        left={"calc(90% - 25px)"}
        direction="circular"
        degree={-180}
        distance={100}
      />
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={600}
      >
        <CjbConceptosForm
          record={record}
          clases_cnc={clases_cnc}
          closeModal={closeModal}
          currentUser={currentUser}
          varEffec={Math.random()}
        />
      </ModalApp>
    </div>
  );
}
