import React, { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  Divider,
  Statistic,
  InputNumber,
  message,
  DatePicker,
} from "antd";
import { UploadOutlined, CalendarOutlined } from "@ant-design/icons";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../../actions/rolEnviromentActions";
//Styles
import "./CjbGastosFrm.scss";

// componentes
import FRM from "../../../../utils/FrmService";
import { reloadData } from "../../../../utils/ReloadEnv";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { URL_FILES } from "../../../../constants";
import CST from "../../../../utils/CustomSettings";

export default function CjbPagosLcl(props) {
  const {
    currentUser,
    closeModal,
    setStateInf,
    currentSetup,
    myNumber,
    detalleModal,
    currentMoneda,
    tipoMoneda,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [valorBnc, setValorBnc] = useState(0);
  const [idProveedor, setIdProveedor] = useState(detalleModal.trc_id);
  const [nota, setNota] = useState("");
  const [nameAdjunto, setNameAdjunto] = useState("");
  const [idAutoriza, setIdAutoriza] = useState(0);
  const [fechaPgo, setFechaPgo] = useState(null);

  const { Option } = Select;
  const [form] = Form.useForm();

  //Listado de conceptos
  const listaCnc = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.conceptos_gto
  );

  const cuentasLcl = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.cuentas_lcl
  );

  //Listado de proveedores
  const suppliersList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.servicios
  );

  const autoriza = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.gst_users_aut
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  const updateAutoriza = async (value) => {
    setIdAutoriza(value);
  };

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaPgo(dateString);
  }

  //Se actualiza el nombre
  const updateProveedor = async (value) => {
    setIdProveedor(value);
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  //GESTION DE CARGAR DE ARCHIVOS PDF
  // specify upload params and url for your files
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    body.append("file", file);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return { url: "https://api.aal-erp.com/frm/api/712", body, headers };
  };

  // called every time a file's `status` changes
  //const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }
  const handleChangeStatus = ({ meta, remove }, status) => {
    console.log(meta);
    if (status === "headers_received") {
      setNameAdjunto(meta.name);
      message.success("CARGA DE ADJUNTO EXITOSA", 1);
      //alert(`${meta.name} uploaded!`);
      remove();
    } else if (status === "aborted") {
      message.error("CARGA DE ADJUNTO FALLIDA", 2);
    }
  };

  const openAdjunto = (pdf_file) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp = URL_FILES + currentUser.dominio + "/temporal/" + pdf_file;
    window.open(`${url_temp}`);
  };
  //FIN GESTION DE CARGA DE ARCHIVOS

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const registrarPago = async () => {
    if (
      (fechaPgo.length === 0) === 0 ||
      Number.parseInt(idProveedor) === 0 ||
      Number.parseInt(idAutoriza) === 0
    ) {
      showNotification(
        "topRight",
        2,
        "REGISTRAR PAGO DE GASTO",
        "DATOS INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      setLoadingButton(true);
      let record = {
        tipoMov: 19,
        usr_id: currentUser.id,
        trc_id: idProveedor,
        usr_apr: idAutoriza,
        pgo_nota: nota,
        pgo_fecha: fechaPgo,
        pgo_moneda: tipoMoneda,
        pgo_cambio: currentSetup.sup_cambio,
        pgo_importe:
          tipoMoneda === 1
            ? Number.parseFloat(detalleModal.saldo_lcl) -
              Number.parseFloat(detalleModal.pendiente_lcl)
            : Number.parseFloat(detalleModal.saldo_frn) -
              Number.parseFloat(detalleModal.pendiente_frn),
      };

      var val_gasto = await FRM.sendGasto(currentUser, record);
      if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
        updateEnviroment(val_gasto.datos.rol_entorno);
        setStateInf(Math.random());
        showNotification(
          "topRight",
          4,
          "CARGAR PAGOS",
          "REGISTRO DE PAGO EXITOSO"
        );
      } else {
        showNotification("topRight", 2, "CARGAR PAGOS", val_gasto.message);
      }
      setLoadingButton(false);
      closeModal();
    }
  };

  return (
    <div className="upload-panel">
      <Divider
        style={{
          marginTop: 15,
          paddingTop: 10,
          marginBottom: 20,
          color: tipoMoneda === 2 ? CST.orangeBdy : CST.greenBdy,
          fontSize: 20,
        }}
      >
        {tipoMoneda === 1 ? "PAGO EN DOLARES" : "PAGO EN PESOS UYU"}
      </Divider>
      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col span={18}>
            <Statistic
              title="Proveedor del Servio o Producto"
              value={detalleModal.trc_nombre}
              valueStyle={{
                fontSize: 18,
                color: tipoMoneda === 2 ? "orangered" : "green",
              }}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Importe a Pagar"
              value={
                tipoMoneda === 1
                  ? currentMoneda.simbolo +
                    myNumber(
                      1,
                      Number.parseFloat(detalleModal.saldo_lcl) -
                        Number.parseFloat(detalleModal.pendiente_lcl)
                    )
                  : currentMoneda.simbolo_frn +
                    myNumber(
                      1,
                      Number.parseFloat(detalleModal.saldo_frn) -
                        Number.parseFloat(detalleModal.pendiente_frn)
                    )
              }
              valueStyle={{
                fontSize: 18,
                color: tipoMoneda === 2 ? "orangered" : "green",
              }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Fecha de Pago">
              <DatePicker onChange={onChangeFecha} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label="Usuario que Autoriza">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={
                  idAutoriza === 0
                    ? "SELECCIONE USUARIO QUE AUTORIZA"
                    : idAutoriza
                }
                placeholder="USUARIO AUTORIZA"
                onChange={updateAutoriza}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {autoriza.map((autor) => (
                  <Option key={autor.id} value={autor.id}>
                    {autor.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <TextArea
              rows={2}
              value={nota}
              placeholder={"Observaciones"}
              onChange={onChangeNota}
              allowClear
            />
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => registrarPago()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={
              tipoMoneda === 2 ? curStyle.btnOrange : curStyle.btnSuccess
            }
            shape="round"
            block
            loading={loadingButton}
          >
            <UploadOutlined /> REGISTRAR EL PAGO AL PROVEEDOR
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
