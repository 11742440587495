import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Table,
  Upload,
  Typography,
  Select,
  Input,
  Row,
  Col,
  InputNumber,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  Space,
  Tabs,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./Inventario.scss";

// Excel Render
import { ExcelRenderer } from "react-excel-renderer";

// componentes
import FRM from "../../../utils/FrmService";
import { reloadData } from "../../../utils/ReloadEnv";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";

export default function UpdateCaravana(props) {
  const { currentUser, closeModal, setIsLoading, myNumber } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [usrId, setUsrId] = useState(currentUser.id);
  const [caravanaOld, setCaravanaOld] = useState("");
  const [caravanaNew, setCaravanaNew] = useState("");

  const [form] = Form.useForm();

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;

  const onChangeCaravana = (e) => {
    let dato = e.target.value;
    if (dato.length > 0) {
      setCaravanaOld(dato.toUpperCase());
    } else {
      setCaravanaOld("");
    }
  };

  const onChangeCaravanaNew = (e) => {
    let dato = e.target.value;
    if (dato.length > 0) {
      setCaravanaNew(dato.toUpperCase());
    } else {
      setCaravanaNew("");
    }
  };

  // ENVIO A WEBSERVICES
  const uploadLotWS = async () => {
    setLoadingButton(true);
    if (caravanaOld === caravanaNew) {
      showNotification(
        "topRight",
        2,
        "CAMBIO DE CARAVANA",
        "LAS CARAVANAS DEBEN SER DIFERENTES"
      );
    } else {
      let record = {
        usr_id: currentUser.id,
        caravanaOld: caravanaOld,
        caravanaNew: caravanaNew,
        cmb_cambio: 3,
        record_def: [],
      };
      var val_inventory = await FRM.sendCambioCat(currentUser, record);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "CAMBIAR CARAVANA",
          "REGISTRO DE CARAVANA EXITOSO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CAMBIAR CARAVANA",
          val_inventory.message
        );
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"ACTUALIZAR CARAVANA"}
      </Title>
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Caravana Actual">
              <Input
                placeholder="Caravana hasta 9 caracteres"
                defaultValue={caravanaOld}
                style={{ width: "100%" }}
                value={caravanaOld}
                onChange={onChangeCaravana}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Nueva Caravana">
              <Input
                placeholder="Caravana 8 caracteres"
                defaultValue={caravanaNew}
                style={{ width: "100%" }}
                value={caravanaNew}
                onChange={onChangeCaravanaNew}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => uploadLotWS()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            block
            style={{
              display:
                caravanaNew.length === 8 && (caravanaOld.length >= 7 && caravanaOld.length <= 9)
                  ? "block"
                  : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> PROCESAR EL CAMBIO DE CARAVANA
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
