import React, { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  Divider,
  Statistic,
  InputNumber,
  message,
  DatePicker,
} from "antd";
import { UploadOutlined, CalendarOutlined } from "@ant-design/icons";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../../actions/rolEnviromentActions";
//Styles
import "./CjbGastosFrm.scss";

// componentes
import FRM from "../../../../utils/FrmService";
import { reloadData } from "../../../../utils/ReloadEnv";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { URL_FILES } from "../../../../constants";
import CST from "../../../../utils/CustomSettings";

export default function CjbPagosLcl(props) {
  const {
    currentUser,
    closeModal,
    setStateInf,
    currentSetup,
    myNumber,
    detalleModal,
    currentMoneda,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [idCnc, setIdCnc] = useState(detalleModal.cnc_id);
  const [idCncOld, setIdCncOld] = useState(detalleModal.cnc_id);
  const [valorBnc, setValorBnc] = useState(0);
  const [valorLcl, setValorLcl] = useState(0);
  const [cambioLcl, setCambioLcl] = useState(0);
  const [saldoFrn, setSaldoFrn] = useState(0);
  const [idBnc, setIdBnc] = useState(0);
  const [idLcl, setIdLcl] = useState(0);
  const [idProveedor, setIdProveedor] = useState(0);
  const [nota, setNota] = useState("");
  const [nameAdjunto, setNameAdjunto] = useState("");
  const [fechaPgo, setFechaPgo] = useState(null);
  const [tasaCmb, setTasaCmb] = useState(currentSetup.sup_cambio);

  const { Option } = Select;
  const [form] = Form.useForm();

  const estado =
    detalleModal.pgo_estado === 0
      ? " (POR APROBAR)"
      : detalleModal.pgo_estado === 1
      ? " (APROBADA)"
      : " (FINALIZADA)";

  //Listado de conceptos
  const listaCnc = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.conceptos_gto
  );

  const cuentasFrn = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.cuentas_frn
  );

  const cuentasLcl = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.cuentas_lcl
  );

  //Listado de proveedores
  const suppliersList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.servicios
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaPgo(dateString);
  }

  const onChangeTc = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setTasaCmb(value);
      setCambioLcl(0);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  //Se actualiza el nombre
  const updateProveedor = async (value) => {
    setIdProveedor(value);
  };

  //Se actualiza cuenta de banco
  const updateBnc = async (value) => {
    setIdBnc(value);
    //setValorBnc(0);
  };

  //Se actualiza cuenta de banco
  const updateLcl = async (value) => {
    setIdLcl(value);
    //setValorBnc(0);
  };

  const onChangeBnc = (value) => {
    if (Number.parseFloat(value) >= 0 && Number.parseInt(idBnc) > 0) {
      setValorBnc(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeLcl = (value) => {
    if (Number.parseFloat(value) >= 0 && Number.parseInt(idLcl) > 0) {
      setValorLcl(value);
      let valCmb = Number.parseFloat(value) * Number.parseFloat(tasaCmb);
      setCambioLcl(Number.parseFloat(valCmb).toFixed(0));
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  //GESTION DE CARGAR DE ARCHIVOS PDF
  // specify upload params and url for your files
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    body.append("file", file);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return { url: "https://api.aal-erp.com/frm/api/712", body, headers };
  };

  // called every time a file's `status` changes
  //const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }
  const handleChangeStatus = ({ meta, remove }, status) => {
    console.log(meta);
    if (status === "headers_received") {
      setNameAdjunto(meta.name);
      message.success("CARGA DE ADJUNTO EXITOSA", 1);
      //alert(`${meta.name} uploaded!`);
      remove();
    } else if (status === "aborted") {
      message.error("CARGA DE ADJUNTO FALLIDA", 2);
    }
  };

  const openAdjunto = (pdf_file) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp = URL_FILES + currentUser.dominio + "/temporal/" + pdf_file;
    window.open(`${url_temp}`);
  };
  //FIN GESTION DE CARGA DE ARCHIVOS

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const registrarGasto = async () => {
    if (nameAdjunto.length === 0) {
      showNotification(
        "topRight",
        2,
        "ADJUNTAR PDF AL PAGO ",
        "DEBE SELECCIONAR EL COMPROBANTE EN PDF"
      );
    } else {
      setLoadingButton(true);
      let record = {
        tipoMov: 4,
        pgo_id: detalleModal.pgo_numero,
        usr_id: 0,
        pgo_adjunto: nameAdjunto.length === 0 ? "NA" : nameAdjunto,
      };

      var val_gasto = await FRM.sendGasto(currentUser, record);
      if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
        updateEnviroment(val_gasto.datos.rol_entorno);
        setStateInf(Math.random());
        showNotification("topRight", 4, "ADJUNTAR PDF", "PROCESO EXITOSO");
      } else {
        showNotification("topRight", 2, "ADJUNTAR PDF", val_gasto.message);
      }
      setLoadingButton(false);
      closeModal();
    }
  };

  return (
    <div className="upload-panel">
      <Divider
        style={{
          marginTop: 15,
          paddingTop: 10,
          marginBottom: 20,
          color: CST.greenBdy,
          fontSize: 20,
        }}
      >
        {detalleModal.pgo_moneda === 1
          ? "DATOS GENERALES DEL PAGO EN DOLARES (" +
            currentMoneda.simbolo +
            myNumber(1, detalleModal.pgo_importe) +
            ")"
          : "DATOS GENERALES DEL PAGO EN PESOS UYU (" +
            currentMoneda.simbolo +
            myNumber(1, detalleModal.pgo_importe) +
            ")"}
      </Divider>
      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col span={8}>
            <Statistic
              title="Fecha Emision"
              value={detalleModal.pgo_fecha}
              prefix={<CalendarOutlined />}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="F. de Autorizacion"
              value={
                detalleModal.pgo_estado >= 1
                  ? detalleModal.pgo_faprueba.toString().substr(0, 10)
                  : "..."
              }
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Fecha de Pago"
              value={
                detalleModal.pgo_estado === 2 ? detalleModal.pgo_fecha : "..."
              }
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={12}>
            <Statistic
              title="Beneficiario al Solicitar"
              value={detalleModal.trc_nombre}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Beneficiario al Desembolsar"
              value={
                detalleModal.pgo_estado === 2 ? detalleModal.trc_recibe : "..."
              }
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={8}>
            <Statistic
              title="Usuario que Solicita"
              value={detalleModal.registro}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Usuario que Autoriza"
              value={detalleModal.autoriza}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Usuario que Paga"
              value={detalleModal.pgo_estado === 2 ? detalleModal.paga : "..."}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={24}>
            <Statistic
              title="Observaciones al Registrar:"
              value={detalleModal.pgo_nota ? detalleModal.pgo_nota : "..."}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      </Form>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
        {"Comprobante de respaldo"}
      </Divider>
      {nameAdjunto.length > 0 ? (
        <Button
          type="link"
          size="large"
          onClick={() => openAdjunto(nameAdjunto)}
        >
          {nameAdjunto}
        </Button>
      ) : null}
      <div
        style={{
          textAlign: "center",
          marginTop: 20,
          border: 0,
        }}
      >
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          autoUpload={true}
          maxFiles={1}
          multiple={false}
          canCancel={false}
          accept=".pdf"
          inputContent={"Arrastre o seleccione el PDF a adjuntar"}
          inputWithFilesContent={"Agregar Archivos"}
          submitButtonContent={"Subir Archivos"}
        />
      </div>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => registrarGasto()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={
              detalleModal.pgo_moneda === 1
                ? curStyle.btnOrange
                : curStyle.btnSuccess
            }
            shape="round"
            block
            style={{
              display: detalleModal.pgo_estado <= 2 ? "block" : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> ADJUNTAR PDF AL PAGO
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
