import React, { useState, useEffect } from "react";
import { Table, Tag, Button, Typography, Row, Col, Statistic, Divider } from "antd";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";

//Styles
import "./SalidasAnm.scss";

export default function DetalleSalida(props) {
    const { myNumber, detalleModal, detalleList, detalleAvg } = props;
    const [dataDef, setDataDef] = useState([]);

    useEffect(() => {
        setDataDef(detalleList);
    }, [detalleModal]);

    const { Title } = Typography;

    //Columnas de la tabla
    const header = [
        {
            title: "CARAVANA", //10%
            dataIndex: "anm_codigo",
            key: "anm_codigo",
        },
        {
            title: "SEXO",
            dataIndex: "anm_sexo",
            key: "anm_sexo",
            render: (text, row, index) => {
                if (row.anm_sexo === 1) {
                    return "MACHO";
                } else {
                    return "HEMBRA";
                }
            },
        },
        {
            title: "CATEGORIA",
            dataIndex: "ctg_nombre",
            key: "ctg_nombre",
        },
        {
            title: "A FEEDLOT",
            dataIndex: "anm_alimento_fecha",
            key: "anm_alimento_fecha",
            render: (text, row, index) => {
                return (
                    <Tag color="geekblue">{row.anm_alimento_fecha}</Tag>
                );
            },
        },
        {
            title: "Kg IN",
            dataIndex: "anm_peso_alimento",
            key: "anm_peso_alimento",
            align: "right",
        },
        {
            title: "F. PESAJE",
            dataIndex: "anm_last_peso",
            key: "anm_last_peso",
            render: (text, row, index) => {
                return (
                    <Tag color="gold">{row.anm_last_peso}</Tag>
                );
            },
        },
        {
            title: "Kg OUT",
            dataIndex: "anm_peso_neto",
            key: "anm_peso_neto",
            align: "right",
        },
        {
            title: "KG GEN",
            dataIndex: "anm_kilos_gen",
            key: "anm_kilos_gen",
            align: "right",
            sorter: (a, b) => a.anm_kilos_gen - b.anm_kilos_gen,
            render: (text, row, index) => {
                return (
                    row.anm_peso_alimento > 0 ? (
                        <Tag color="blue">{myNumber(
                            1,
                            Number.parseFloat(row.anm_kilos_gen))}
                        </Tag>
                    ) : (
                        <Tag color="gold">{myNumber(
                            1,
                            Number.parseFloat(0))}
                        </Tag>
                    )
                );
            },
        },
        {
            title: "DIAS",
            dataIndex: "anm_dias_peso",
            key: "anm_dias_peso",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="geekblue">{myNumber(
                        1,
                        Number.parseInt(row.anm_dias_peso))}
                    </Tag>);
            },
        },
        {
            title: "Kg/Dia",
            dataIndex: "anm_kilos_dia",
            key: "anm_kilos_dia",
            align: "right",
            sorter: (a, b) => a.anm_kilos_dia - b.anm_kilos_dia,
            render: (text, row, index) => {
                return (
                    row.anm_peso_alimento > 0 ? (
                        <Tag color="volcano">{myNumber(
                            1,
                            Number.parseFloat(row.anm_kilos_dia), 2)}
                        </Tag>
                    ) : (
                        <Tag color="volcano">{myNumber(
                            1,
                            Number.parseFloat(0))}
                        </Tag>
                    )
                );
            },
        },
        {
            title: "KG-SEC",
            dataIndex: "anm_kilos_peso",
            key: "anm_kilos_peso",
            align: "right",
            sorter: (a, b) => a.anm_kilos_peso - b.anm_kilos_peso,
            render: (text, row, index) => {
                return (
                    <Tag color="magenta">{myNumber(
                        1,
                        Number.parseFloat(row.anm_kilos_peso))}
                    </Tag>);
            },
        },
        {
            title: "CONV.",
            dataIndex: "anm_conversion",
            key: "anm_conversion",
            align: "right",
            sorter: (a, b) => a.anm_conversion - b.anm_conversion,
            render: (text, row, index) => {
                return (row.anm_dias_peso > 0 ? (
                    <Tag color="purple">{myNumber(
                        1,
                        Number.parseFloat(row.anm_conversion))}
                    </Tag>
                ) : (
                    <Tag color="#f50">{0}
                    </Tag>
                )
                );
            },
        },
        {
            title: "$ ALM",
            dataIndex: "anm_alimento",
            key: "anm_alimento",
            align: "right",
            sorter: (a, b) => a.anm_alimento - b.anm_alimento,
            render: (text, row, index) => {
                return (
                    <Tag color="cyan">{myNumber(
                        1,
                        Number.parseFloat(row.anm_alimento))}
                    </Tag>);
            },
        }
    ];

    return (
        <div className="upload-panel">
            <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
                {"DETALLE FACTURA Nº - " + detalleModal.fac_numero}
            </Title>
            <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
            <Table
                columns={header}
                dataSource={dataDef}
                bordered
                size="middle"
                scroll={{ y: 640 }}
                summary={(pageData) => {
                    let totalGto = 0;

                    pageData.forEach(({ meg_importe }) => {
                        totalGto += Number.parseFloat(meg_importe);
                    });

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={6}>
                                    <Title level={4}>{"PROMEDIOS RESULTANTES"}</Title>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Title level={4} style={{ textAlign: "right" }}>
                                        {myNumber(1, detalleAvg.peso_neto)}
                                    </Title>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Title level={4} style={{ textAlign: "right" }}>
                                        {myNumber(1, detalleAvg.kilos_gen)}
                                    </Title>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Title level={4} style={{ textAlign: "right" }}>
                                        {'...'}
                                    </Title>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Title level={4} style={{ textAlign: "right" }}>
                                        {myNumber(1, detalleAvg.kilos_dia)}
                                    </Title>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Title level={4} style={{ textAlign: "right" }}>
                                        {myNumber(1, detalleAvg.kg_secos)}
                                    </Title>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Title level={4} style={{ textAlign: "right" }}>
                                        {myNumber(1, detalleAvg.conversion)}
                                    </Title>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <Title level={4} style={{ textAlign: "right" }}>
                                        {myNumber(1, detalleAvg.alimento)}
                                    </Title>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}







            />
        </div>
    );
}
