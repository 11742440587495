import React, { useState, useEffect } from "react";
import {
    Modal,
    Button,
    Table,
    Upload,
    Typography,
    Select,
    Input,
    Row,
    Col,
    InputNumber,
    Popconfirm,
    message,
    DatePicker,
    Divider,
    Tag,
    Tabs,
} from "antd";
import {
    UploadOutlined,
    SearchOutlined,
    ReloadOutlined,
} from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./UploadAlimento.scss";

// Excel Render
import { ExcelRenderer } from "react-excel-renderer";
import * as XLSX from "xlsx";
import ReactExport from "react-export-excel";

// componentes
import FRM from "../../../utils/FrmService";
import { reloadData } from "../../../utils/ReloadEnv";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";

export default function UploadAlimentoXml(props) {
    const {
        currentUser,
        closeModal,
        setIsLoading,
        myNumber,
        detalleCarga,
        detalleList,
        detalleFood,
        foodResume,
        foodDetalle,
    } = props;
    const [loadingButton, setLoadingButton] = useState(false);
    const [usrId, setUsrId] = useState(currentUser.id);
    const [idOrigen, setIdOrigen] = useState("SELECCIONE ORIGEN");
    const [data, setData] = useState([]);
    const [dataDef, setDataDef] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [alimentosHst, setAlimentosHst] = useState([]);
    const [stateInf, setStateInf] = useState(Math.random());
    const [verTabla, setVerTabla] = useState(1);
    const [nameAlm, setNameAlm] = useState("");
    const [detaAlm, setDetaAlm] = useState([]);
    const [detaSec, setDetaSec] = useState(0);
    const [detaHmd, setDetaHmd] = useState(0);
    const [detaImp, setDetaImp] = useState(0);
    const [recetas, setRecetas] = useState([]);
    const [cargas, setCargas] = useState([]);
    const [resumen, setResumen] = useState([]);
    const [resumenTpo, setResumenTpo] = useState([]);
    const [resumenLte, setResumenLte] = useState([]);
    const [inventAlm, setInventAlm] = useState([]);
    const [descargas, setDescargas] = useState([]);
    const [descargasDet, setDescargasDet] = useState([]);
    const [downExcel, setDownExcel] = useState(false);

    //Para uso de excel
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


    const { TabPane } = Tabs;
    const { Text } = Typography;

    const alimentoList = useSelector(
        (state) => state.rolEnviroment.rolEnviroment.tipoalimento
    );

    const inventarioAlm = useSelector(
        (state) => state.rolEnviroment.rolEnviroment.inventario_alm
    );

    useEffect(() => {
        (async () => {
            setIsLoading(false);
            let datos = await FRM.getAgenda(currentUser, 4, detalleCarga.id);
            if (datos.type && datos.type > 0) {
                setAlimentosHst(datos.listado);
            } else {
                showNotification(
                    "topRight",
                    1,
                    "HISTRIAL DE ALIMENTOS",
                    "No hay ALIMENTOS REGISTRADOS"
                );
            }
            setIsLoading(false);
        })();
    }, [stateInf]);

    //Ejecutar dispatch tras el logueo
    const dispatch = useDispatch();
    const updateEnviroment = (state) =>
        dispatch(updateRolEnviromentAction(state));

    const { Title } = Typography;

    //Se actualiza el nombre
    const updateOrigen = async (value) => {
        setIdOrigen(value);
    };

    const getDetalle = async (record, detalle) => {
        if (verTabla === 1) {
            const almFilter = detalle.filter((tipo) => {
                return Number.parseInt(tipo.alm_id) === Number.parseInt(record.alm_id);
            });
            setDetaAlm(almFilter);
            setNameAlm(record.alm_nombre);
            setDetaHmd(record.humedos);
            setDetaSec(record.secos);
            setDetaImp(record.importe);
            setVerTabla(2);
        } else {
            setVerTabla(1);
        }
    };

    const getNameAlm = (almId) => {
        let nAlm = '';
        alimentoList.forEach((alms) => {
            if (Number.parseInt(alms.id) === Number.parseInt(almId)) {
                nAlm = alms.alm_nombre
            }
        });
        return nAlm;
    };

    const propis = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            if (fileList.length > 0) {
                setFileList([file]);
            } else {
                setFileList([...fileList, file]);
            }
            console.log(file)
            UploadExcel(file);
            return false;
        },
        fileList,
    };

    const UploadExcel = async (file) => {
        //Obtengo la informacion del Input File
        let fileObj = file;

        //Render del Excel
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                console.log(err);
            } else {
                // Elimino el encabezado del archivo
                var excel = resp.rows.filter(function (item, index, arr) {
                    return item[0] !== "DESCARGA";
                });
                const bh_excel2 = [];
                excel.forEach(function (item, index, arr) {
                    let key = index;
                    let cod = item[0].toString();
                    let kilos_alm = item[11];
                    let namAlm = getNameAlm(item[8]);

                    let exceldata = {
                        key: key,
                        dsc_id: item[0],
                        cmb_numero: item[1],
                        dsc_lote: item[2],
                        cmb_campo: item[3],
                        cmb_animales: item[4],
                        dsc_fecha: item[5].toString(),
                        dsc_cantidad: item[6],
                        rct_id: item[7],
                        alm_id: item[8],
                        alm_nombre: namAlm,
                        dsc_ingrediente: item[9],
                        dsc_porcentaje: item[10],
                        dsc_consumo: Number.parseFloat(kilos_alm).toFixed(2)
                    };
                    bh_excel2.push(exceldata);
                });
                setData(bh_excel2);
                setDataDef(bh_excel2);
            }
        });
    };


    // ENVIO A WEBSERVICES
    const uploadLotWS = async () => {
        setLoadingButton(true);
        if (Number.parseInt(recetas.length) === 0 || Number.parseInt(cargas.length) === 0 || Number.parseInt(descargas.length) === 0) {
            showNotification(
                "topRight",
                2,
                "CARGA DE ALIMENTOS",
                "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
            );
        } else {
            let record = {
                tipoMov: 100,
                usr_id: currentUser.id,
                recetas: recetas,
                cargas: cargas,
                descargas: descargas
            };
            var val_inventory = await FRM.sendAlimento(currentUser, record);
            if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
                setDescargasDet(val_inventory.datos);
                setResumen(val_inventory.resumen);
                setResumenTpo(val_inventory.resumen_tpo);
                setInventAlm(val_inventory.invent_alm);
                setResumenLte(val_inventory.resumen_lte);
                showNotification(
                    "topRight",
                    4,
                    "PROCESO EXITOSO",
                    val_inventory.message
                );
            } else {
                showNotification(
                    "topRight",
                    2,
                    "CARGA DE ALIMENTOS",
                    val_inventory.message
                );
            }
        }
        //setData([]);
        //setFileList([]);
        setLoadingButton(false);
        //closeModal();
    };

    // ENVIO A WEBSERVICES
    const uploadAlmWS = async () => {
        setLoadingButton(true);
        if (Number.parseInt(dataDef.length) === 0) {
            showNotification(
                "topRight",
                2,
                "CARGA DE ALIMENTOS",
                "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
            );
        } else {
            let record = {
                tipoMov: 101,
                usr_id: currentUser.id,
                record_def: dataDef,
            };
            var val_inventory = await FRM.sendAlimento(currentUser, record);
            console.log(val_inventory);
            if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
                updateEnviroment(val_inventory.datos.rol_entorno);
                showNotification(
                    "topRight",
                    4,
                    "PESOS ACTUALIZADOS",
                    val_inventory.message
                );
            } else {
                showNotification(
                    "topRight",
                    2,
                    "ACTUALIZAR PESOS",
                    val_inventory.message
                );
            }
        }
        setData([]);
        setFileList([]);
        setLoadingButton(false);
        closeModal();
    };

    const onChange = (e) => {
        const [file] = e.target.files;
        const reader = new FileReader();
        const a_receta = [];
        const a_carga = [];
        const a_descarga = [];
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsnameRecetas = wb.SheetNames[0];
            const wsnameGuia = wb.SheetNames[1];
            const wsnameAutonomo = wb.SheetNames[2];
            const wsnameIngrediente = wb.SheetNames[3];
            const wsnameCargas = wb.SheetNames[4];
            const wsnameDescargas = wb.SheetNames[5];
            const wsr = wb.Sheets[wsnameRecetas];
            const wsi = wb.Sheets[wsnameIngrediente];
            const wsg = wb.Sheets[wsnameGuia];
            const wsd = wb.Sheets[wsnameDescargas];
            const wsc = wb.Sheets[wsnameCargas];
            const wsa = wb.Sheets[wsnameAutonomo];
            const data_recetas = XLSX.utils.sheet_to_csv(wsr, { header: 1 });
            const data_ingredientes = XLSX.utils.sheet_to_csv(wsi, { header: 1 });
            const data_guia = XLSX.utils.sheet_to_csv(wsg, { header: 1 });
            const data_descargas = XLSX.utils.sheet_to_csv(wsd, { header: 1 });
            const data_carga = XLSX.utils.sheet_to_csv(wsc, { header: 1 });
            const data_autonomo = XLSX.utils.sheet_to_csv(wsa, { header: 1 });

            const csvIngredientes = data_ingredientes.slice(data_ingredientes.indexOf("\n") + 1).split("\n");
            console.log(data_ingredientes);
            const csvCarga = data_carga.slice(data_carga.indexOf("\n") + 1).split("\n");
            console.log(data_carga);
            const csvGuia = data_guia.slice(data_guia.indexOf("\n") + 1).split("\n");
            console.log(data_autonomo);
            console.log(data_guia);
            const csvDescargas = data_descargas.slice(data_descargas.indexOf("\n") + 1).split("\n");
            console.log(data_descargas);
            const csvReceta = data_recetas.slice(data_recetas.indexOf("\n") + 1).split("\n");
            csvReceta.forEach((row) => {
                const d = row.split(",");
                let item = {
                    receta_id: d[0],
                    receta_nombre: d[1],
                    ing_1: d[4],
                    ctd_1: d[5],
                    ing_2: d[6],
                    ctd_2: d[7],
                    ing_3: d[8],
                    ctd_3: d[9],
                    ing_4: d[10],
                    ctd_4: d[11],
                    ing_5: d[12],
                    ctd_5: d[13],
                    ing_6: d[14],
                    ctd_6: d[15],
                    ing_7: d[16],
                    ctd_7: d[17],
                    ing_8: d[18],
                    ctd_8: d[19],
                    ing_9: d[20],
                    ctd_9: d[21],
                    ing_10: d[22],
                    ctd_10: d[23],
                };
                a_receta.push(item);
            });
            setRecetas(a_receta)
            csvCarga.forEach((row) => {

                const d = row.split(",");
                let w = d[1].split('/');
                if (d[0].length > 0) {
                    let item = {
                        carga_id: d[0],
                        carga_fecha: w[0] + '-' + w[1] + '-' + w[2],
                        carga_hora: d[2],
                        receta_id: d[3],
                        carga_ing: d[5],
                        carga_ctd: d[6],
                        carga_ideal: d[7]
                    };
                    a_carga.push(item);
                }
            });
            setCargas(a_carga)
            csvDescargas.forEach((row) => {
                const d = row.split(",");
                let w = d[1].split('/');
                if (d[0].length > 0) {
                    let item = {
                        descarga_id: d[0],
                        descarga_fecha: w[0] + '-' + w[1] + '-' + w[2],
                        descarga_hora: d[2],
                        carga_id: d[4],
                        lote_id: d[5],
                        descarga_ctd: d[6],
                        descarga_ideal: d[7]
                    };
                    a_descarga.push(item);
                }
            });
            setDescargas(a_descarga)
        };
        reader.readAsBinaryString(file);
    };

    const downComsumo = async () => {
        if (descargasDet.length > 0) {
            setDownExcel(true);
            setTimeout(function () {
                setDownExcel(false);
            }, 1000);
        } else {
            message.error("NO HAY DATOS PARA EXPORTAR", 1);
        }
    };

    const recetas_enc = [
        {
            title: "ID", //10%
            dataIndex: "receta_id",
            key: "receta_id",
        },
        {
            title: "NOMBRE RECETA",
            dataIndex: "receta_nombre",
            key: "receta_nombre",
            align: "left",
        },
        {
            title: "ING 1",
            dataIndex: "ing_1",
            key: "ing_1",
            align: "left",
        },
        {
            title: "CTD 1",
            dataIndex: "ctd_1",
            key: "ctd_1",
            align: "right",
        },
        {
            title: "ING 2",
            dataIndex: "ing_2",
            key: "ing_2",
            align: "left",
        },
        {
            title: "CTD 2",
            dataIndex: "ctd_2",
            key: "ctd_2",
            align: "right",
        },
        {
            title: "ING 3",
            dataIndex: "ing_3",
            key: "ing_3",
            align: "left",
        },
        {
            title: "CTD 3",
            dataIndex: "ctd_3",
            key: "ctd_3",
            align: "right",
        },
        {
            title: "ING 4",
            dataIndex: "ing_4",
            key: "ing_4",
            align: "left",
        },
        {
            title: "CTD 4",
            dataIndex: "ctd_4",
            key: "ctd_4",
            align: "right",
        },
        {
            title: "ING 5",
            dataIndex: "ing_5",
            key: "ing_5",
            align: "left",
        },
        {
            title: "CTD 5",
            dataIndex: "ctd_5",
            key: "ctd_5",
            align: "right",
        },
        {
            title: "ING 6",
            dataIndex: "ing_6",
            key: "ing_6",
            align: "left",
        },
        {
            title: "CTD 6",
            dataIndex: "ctd_6",
            key: "ctd_6",
            align: "right",
        },
        {
            title: "ING 7",
            dataIndex: "ing_7",
            key: "ing_7",
            align: "left",
        },
        {
            title: "CTD 7",
            dataIndex: "ctd_7",
            key: "ctd_7",
            align: "right",
        },
        {
            title: "ING 8",
            dataIndex: "ing_8",
            key: "ing_8",
            align: "left",
        },
        {
            title: "CTD 8",
            dataIndex: "ctd_8",
            key: "ctd_8",
            align: "right",
        },
        {
            title: "ING 9",
            dataIndex: "ing_9",
            key: "ing_9",
            align: "left",
        },
        {
            title: "CTD 9",
            dataIndex: "ctd_9",
            key: "ctd_9",
            align: "right",
        },
        {
            title: "ING 10",
            dataIndex: "ing_10",
            key: "ing_10",
            align: "left",
        },
        {
            title: "CTD 10",
            dataIndex: "ctd_10",
            key: "ctd_10",
            align: "right",
        },
    ];

    const cargas_enc = [
        {
            title: "ID", //10%
            dataIndex: "carga_id",
            key: "carga_id",
        },
        {
            title: "FECHA",
            dataIndex: "carga_fecha",
            key: "carga_fecha",
            align: "left",
        },
        {
            title: "HORA",
            dataIndex: "carga_hora",
            key: "carga_hora",
            align: "left",
        },
        {
            title: "RECETA",
            dataIndex: "receta_id",
            key: "receta_id",
            align: "right",
        },
        {
            title: "INGRD",
            dataIndex: "carga_ing",
            key: "carga_ing",
            align: "left",
        },
        {
            title: "CANTD",
            dataIndex: "carga_ctd",
            key: "carga_ctd",
            align: "right",
        },
        {
            title: "IDEAL",
            dataIndex: "carga_ideal",
            key: "carga_ideal",
            align: "right",
        }
    ];

    const descargas_enc = [
        {
            title: "ID", //10%
            dataIndex: "descarga_id",
            key: "descarga_id",
        },
        {
            title: "FECHA",
            dataIndex: "descarga_fecha",
            key: "descarga_fecha",
            align: "left",
        },
        {
            title: "HORA",
            dataIndex: "descarga_hora",
            key: "descarga_hora",
            align: "left",
        },
        {
            title: "CARGA ID",
            dataIndex: "carga_id",
            key: "carga_id",
            align: "right",
        },
        {
            title: "LOTES",
            dataIndex: "lote_id",
            key: "lote_id",
            align: "left",
        },
        {
            title: "CANTD",
            dataIndex: "descarga_ctd",
            key: "descarga_ctd",
            align: "right",
        },
        {
            title: "IDEAL",
            dataIndex: "descarga_ideal",
            key: "descarga_ideal",
            align: "right",
        }
    ];

    const descargas_det = [
        {
            title: "DSC ID", //10%
            dataIndex: "dsc_id",
            key: "dsc_id",
            render: (text, row, index) => {
                return (
                    <Tag color="orange" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {row.dsc_id}
                    </Tag>
                );
            },
        },
        {
            title: "Nº",
            dataIndex: "cmb_numero",
            key: "cmb_numero",
            align: "left",
            render: (text, row, index) => {
                return (
                    <Tag color="red" style={{ fontSize: 16, fontWeight: 'bold' }}>
                        {row.cmb_numero}
                    </Tag>
                );
            },
        },
        {
            title: "LOTE",
            dataIndex: "dsc_lote",
            key: "dsc_lote",
            align: "left",
            render: (text, row, index) => {
                return (
                    <Tag color="geekblue" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {row.dsc_lote}
                    </Tag>
                );
            },
        },
        {
            title: "NOMBRE",
            dataIndex: "cmb_campo",
            key: "cmb_campo",
            align: "left",
            render: (text, row, index) => {
                return (
                    <Title level={4} style={{ color: "#6A3E98", fontSize: 15, fontWeight: 'bold' }}>
                        {row.cmb_campo}
                    </Title>
                );
            },
        },
        {
            title: "ANIMALES",
            dataIndex: "cmb_animales",
            key: "cmb_animales",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="orange" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {myNumber(1, row.cmb_animales, 0)}
                    </Tag>
                );
            },
        },
        {
            title: "FECHA",
            dataIndex: "dsc_fecha",
            key: "dsc_fecha",
            align: "left",
        },
        {
            title: "Kg LOTE",
            dataIndex: "dsc_cantidad",
            key: "dsc_cantidad",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="purple" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {myNumber(1, row.dsc_cantidad, 2)}
                    </Tag>
                );
            },
        },
        {
            title: "RECETA",
            dataIndex: "rct_id",
            key: "rct_id",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="cyan" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {myNumber(1, row.rct_id, 2)}
                    </Tag>
                );
            },
        },
        {
            title: "INGREDIENTE",
            dataIndex: "dsc_ingrediente",
            key: "dsc_ingrediente",
            align: "left",
        },
        {
            title: "%",
            dataIndex: "dsc_porcentaje",
            key: "dsc_porcentaje",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="magenta" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {myNumber(1, (row.dsc_porcentaje * 100), 3)}
                    </Tag>
                );
            },
        },
        {
            title: "KILOS",
            dataIndex: "dsc_consumo",
            key: "dsc_consumo",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="green" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {myNumber(1, row.dsc_consumo, 2)}
                    </Tag>
                );
            },
        }
    ];

    const header = [
        {
            title: "DSC ID", //10%
            dataIndex: "dsc_id",
            key: "dsc_id",
            render: (text, row, index) => {
                return (
                    <Tag color="orange" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {row.dsc_id}
                    </Tag>
                );
            },
        },
        {
            title: "Nº",
            dataIndex: "cmb_numero",
            key: "cmb_numero",
            align: "left",
            render: (text, row, index) => {
                return (
                    <Tag color="red" style={{ fontSize: 16, fontWeight: 'bold' }}>
                        {row.cmb_numero}
                    </Tag>
                );
            },
        },
        {
            title: "LOTE",
            dataIndex: "dsc_lote",
            key: "dsc_lote",
            align: "left",
            render: (text, row, index) => {
                return (
                    <Tag color="geekblue" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {row.dsc_lote}
                    </Tag>
                );
            },
        },
        {
            title: "NOMBRE",
            dataIndex: "cmb_campo",
            key: "cmb_campo",
            align: "left",
            render: (text, row, index) => {
                return (
                    <Title level={4} style={{ color: "#6A3E98", fontSize: 15, fontWeight: 'bold' }}>
                        {row.cmb_campo}
                    </Title>
                );
            },
        },
        {
            title: "ANIMALES",
            dataIndex: "cmb_animales",
            key: "cmb_animales",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="orange" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {myNumber(1, row.cmb_animales, 0)}
                    </Tag>
                );
            },
        },
        {
            title: "FECHA",
            dataIndex: "dsc_fecha",
            key: "dsc_fecha",
            align: "left",
        },
        {
            title: "Kg LOTE",
            dataIndex: "dsc_cantidad",
            key: "dsc_cantidad",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="purple" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {myNumber(1, row.dsc_cantidad, 2)}
                    </Tag>
                );
            },
        },
        {
            title: "RECETA",
            dataIndex: "rct_id",
            key: "rct_id",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="cyan" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {myNumber(1, row.rct_id, 2)}
                    </Tag>
                );
            },
        },
        {
            title: "INGREDIENTE",
            dataIndex: "dsc_ingrediente",
            key: "dsc_ingrediente",
            align: "left",
        },
        {
            title: "ID ALM",
            dataIndex: "alm_id",
            key: "alm_id",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="green" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {myNumber(1, row.alm_id, 0)}
                    </Tag>
                );
            },
        },
        {
            title: "ALIMENTO",
            dataIndex: "alm_nombre",
            key: "alm_nombre",
            align: "left",
            render: (text, row, index) => {
                return (
                    <Title level={4} style={{ color: "#6A3E98", fontSize: 15, fontWeight: 'bold' }}>
                        {row.alm_nombre}
                    </Title>
                );
            },
        },
        {
            title: "%",
            dataIndex: "dsc_porcentaje",
            key: "dsc_porcentaje",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="magenta" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {myNumber(1, (row.dsc_porcentaje * 100), 3)}
                    </Tag>
                );
            },
        },
        {
            title: "KILOS",
            dataIndex: "dsc_consumo",
            key: "dsc_consumo",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="green" style={{ fontSize: 15, fontWeight: 'bold' }}>
                        {myNumber(1, row.dsc_consumo, 2)}
                    </Tag>
                );
            },
        }
    ];

    // Encabezados de la tabla de Excel Upload
    const header_stock = [
        {
            title: "TIPO DE ALIMENTO", //10%
            dataIndex: "alm_nombre",
            key: "alm_nombre",
        },
        {
            title: "KG DISP", //10%
            dataIndex: "entra",
            key: "alm_entra",
            align: "right",
            render: (text, row, index) => {
                return myNumber(1, Number.parseInt(row.entra - row.sale));
            },
        },
    ];

    const header_rsm = [
        {
            title: "TIPO DE ALIMENTO", //10%
            dataIndex: "alm_nombre",
            key: "alm_nombre",
        },
        {
            title: "KG DISP", //10%
            dataIndex: "consumo",
            key: "consumo",
            align: "right",
            render: (text, row, index) => {
                return myNumber(1, Number.parseInt(row.consumo));
            },
        },
    ];

    const header_lte = [
        {
            title: "LOTE", //10%
            dataIndex: "cmp_nombre",
            key: "cmp_nombre",
        },
        {
            title: "ALIMENTO", //10%
            dataIndex: "alm_nombre",
            key: "alm_nombre",
        },
        {
            title: "KILOS", //10%
            dataIndex: "consumo",
            key: "consumo",
            align: "right",
            render: (text, row, index) => {
                return myNumber(1, Number.parseInt(row.consumo));
            },
        },
    ];

    // Encabezados de la tabla de Excel Upload

    return (
        <div className="upload-panel">
            <Title level={4} style={{ color: "#6A3E98", textAlign: "center" }}>
                {"CARGA DE ALIMENTOS DESDE XML"}
            </Title>
            <Tabs defaultActiveKey="1">
                <TabPane tab="DETALLES BALANZA" key="1">
                    <Row gutter={16} style={{ marginTop: 15, paddingTop: 10 }}>
                        <Col>
                            <input type="file" onChange={onChange} />
                        </Col>
                    </Row>
                    <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
                    <Table columns={recetas_enc} dataSource={recetas} bordered size="middle" />
                    <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
                    <Row gutter={16} style={{ marginTop: 15, paddingTop: 10 }}>
                        <Col span="12">
                            <Title level={4} style={{ color: "#6A3E98", textAlign: "center" }}>
                                {"CARGAS"}
                            </Title>
                            <Table columns={cargas_enc} dataSource={cargas} bordered size="middle" />
                        </Col>
                        <Col span="12">
                            <Title level={4} style={{ color: "#6A3E98", textAlign: "center" }}>
                                {"DESCARGAS"}
                            </Title>
                            <Table columns={descargas_enc} dataSource={descargas} bordered size="middle" />
                        </Col>
                    </Row>
                    <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
                        <Popconfirm
                            placement="top"
                            title="CONFIRMAR EL MOVIMIENTO?"
                            onConfirm={() => uploadLotWS()}
                            okText="Si"
                            cancelText="No"
                        >
                            <Button
                                type="primary"
                                className={curStyle.btnSuccess}
                                shape="round"
                                block
                                style={{
                                    display: descargas.length > 0 ? "block" : "none",
                                }}
                                loading={loadingButton}
                            >
                                <UploadOutlined /> PROCESAR LA CARGA Y GENERAR CONSUMOS POR LOTE
                            </Button>
                        </Popconfirm>
                    </div>
                </TabPane>
                <TabPane tab="DETALLES CONSUMO" key="2">
                    <Table columns={descargas_det} dataSource={descargasDet} bordered size="middle" />
                    <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
                    <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
                        <Popconfirm
                            placement="top"
                            title="Dercargar el Consumo?"
                            onConfirm={() => downComsumo()}
                            okText="Si"
                            cancelText="No"
                        >
                            <Button
                                type="primary"
                                className={curStyle.btnLila}
                                shape="round"
                                block
                                style={{
                                    display: descargasDet.length > 0 ? "block" : "none",
                                }}
                                loading={loadingButton}
                            >
                                <UploadOutlined /> DESCARGAR EXCEL PARA PROCESAR
                            </Button>
                        </Popconfirm>
                    </div>
                </TabPane>
                <TabPane tab="CARGA DE ALIMENTOS" key="3">
                    <Row gutter={16} style={{ marginTop: 15, paddingTop: 10 }}>
                        <Col span={12}>
                            <Upload {...propis}>
                                <Button
                                    type="primary"
                                    shape="round"
                                    className={curStyle.btnInfo}
                                    style={{
                                        marginLeft: 20,
                                    }}
                                >
                                    <UploadOutlined /> SELECCIONE ARCHIVO DE CONSUMO
                                </Button>
                            </Upload>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                    <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
                    <Table columns={header} dataSource={dataDef} bordered size="middle" />
                    <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
                        <Popconfirm
                            placement="top"
                            title="CONFIRMAR LA CARGA DEL CONSUMO?"
                            onConfirm={() => uploadAlmWS()}
                            okText="Si"
                            cancelText="No"
                        >
                            <Button
                                type="primary"
                                className={curStyle.btnDanger}
                                shape="round"
                                block
                                style={{
                                    display: dataDef.length > 0 ? "block" : "none",
                                }}
                                loading={loadingButton}
                            >
                                <UploadOutlined /> PROCESAR LA CARGA DE ALIMENTOS
                            </Button>
                        </Popconfirm>
                    </div>
                </TabPane>
                <TabPane tab="RESUMENES" key="4">
                    <Row gutter={16} style={{ marginTop: 5, paddingTop: 5 }}>
                        <Col span={6}>
                            <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
                                Inventario de Alimentos Disponible
                            </Divider>
                            <Table
                                columns={header_stock}
                                dataSource={inventAlm}
                                bordered
                                size="middle"
                                scroll={{ y: 640 }}
                            />
                        </Col>
                        <Col span={6}>
                            <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
                                Inventario de Tipo de Alimentos
                            </Divider>
                            <Table
                                columns={header_rsm}
                                dataSource={resumenTpo}
                                bordered
                                size="middle"
                                scroll={{ y: 640 }}
                            />
                        </Col>
                        <Col span={6}>
                            <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
                                Consumo Lote Alimento
                            </Divider>
                            <Table
                                columns={header_lte}
                                dataSource={resumenLte}
                                bordered
                                size="middle"
                                scroll={{ y: 640 }}
                            />
                        </Col>
                        <Col span={6}>
                            <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
                                Resumen Descarga por Tipo
                            </Divider>
                            <Table
                                columns={header_rsm}
                                dataSource={resumen}
                                bordered
                                size="middle"
                                scroll={{ y: 640 }}
                            />
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
            {downExcel ? (
                <ExcelFile
                    filename={
                        "CONSUMO ALIMENTOS"
                    }
                    hideElement={true}
                >
                    <ExcelSheet
                        data={descargasDet}
                        name={"CONSUMO DE ALIMENTOS"}
                    >
                        <ExcelColumn label="DESCARGA" value="dsc_id" />
                        <ExcelColumn label="NUMERO" value="cmb_numero" />
                        <ExcelColumn label="LOTE" value="dsc_lote" />
                        <ExcelColumn label="NOMBRE" value="cmb_campo" />
                        <ExcelColumn label="ANIMALES" value="cmb_animales" />
                        <ExcelColumn label="FECHA" value="dsc_fecha" />
                        <ExcelColumn label="CANTIDAD" value="dsc_cantidad" />
                        <ExcelColumn label="RECETA" value="rct_id" />
                        <ExcelColumn label="ID ALM" value="alm_id" />
                        <ExcelColumn label="INGREDIENTE" value="dsc_ingrediente" />
                        <ExcelColumn label="% PARTC" value="dsc_porcentaje" />
                        <ExcelColumn label="Kg DESCARGADOS" value="dsc_consumo" />
                    </ExcelSheet>
                </ExcelFile>
            ) : null}
        </div>
    );
}
