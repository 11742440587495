import React, { useState } from "react";
import {
  Form,
  Modal,
  Button,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  InputNumber,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../../actions/rolEnviromentActions";
//Styles
import "./CjbGastosFrm.scss";

// componentes
import FRM from "../../../../utils/FrmService";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { URL_FILES } from "../../../../constants";
import CST from "../../../../utils/CustomSettings";

export default function CjbCreateIng(props) {
  const {
    currentUser,
    closeModal,
    setStateInf,
    currentSetup,
    myNumber,
    monedaIng,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [idProveedor, setIdProveedor] = useState(0);
  const [idCnc, setIdCnc] = useState(0);
  const [detalleCnc, setDetalleCnc] = useState("");
  const [idBnc, setIdBnc] = useState(0);
  const [valorBnc, setValorBnc] = useState(0);
  const [nota, setNota] = useState("");
  const [fechaGto, setFechaGto] = useState(null);
  const [nameAdjunto, setNameAdjunto] = useState("");

  const { Option } = Select;
  const [form] = Form.useForm();

  //Listado de proveedores
  const suppliersList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.servicios
  );

  //Listado de conceptos
  const listaCnc = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.conceptos_ing
  );

  //Listado de cuentas
  const cuentasLcl = useSelector((state) =>
    monedaIng === 1
      ? state.rolEnviroment.rolEnviroment.cuentas_lcl
      : state.rolEnviroment.rolEnviroment.cuentas_frn
  );

  const openAdjunto = (pdf_file) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp = URL_FILES + currentUser.dominio + "/temporal/" + pdf_file;
    window.open(`${url_temp}`);
  };

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaGto(dateString);
  }
  //Se actualiza el nombre
  const updateProveedor = async (value) => {
    setIdProveedor(value);
  };

  //Se actualiza el concepto
  const updateCnc = async (value) => {
    setIdCnc(value);
  };

  const onChangeDeta = (e) => {
    const detaCnc = e.target.value;
    setDetalleCnc(detaCnc.toUpperCase());
  };

  //Se actualiza cuenta de banco
  const updateBnc = async (value) => {
    setIdBnc(value);
    setValorBnc(0);
  };

  const onChangeBnc = (value) => {
    if (Number.parseFloat(value) >= 0 && Number.parseInt(idBnc) > 0) {
      setValorBnc(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  //GESTION DE CARGAR DE ARCHIVOS PDF
  // specify upload params and url for your files
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    body.append("file", file);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return { url: "https://api.aal-erp.com/frm/api/712", body, headers };
  };

  // called every time a file's `status` changes
  //const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }
  const handleChangeStatus = ({ meta, remove }, status) => {
    console.log(meta);
    if (status === "headers_received") {
      setNameAdjunto(meta.name);
      message.success("CARGA DE ADJUNTO EXITOSA", 1);
      //alert(`${meta.name} uploaded!`);
      remove();
    } else if (status === "aborted") {
      message.error("CARGA DE ADJUNTO FALLIDA", 2);
    }
  };
  //FIN GESTION DE CARGA DE ARCHIVOS

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const registrarIngreso = async () => {
    if (
      Number.parseInt(idProveedor) === 0 ||
      Number.parseInt(idCnc) === 0 ||
      detalleCnc.length === 0 ||
      fechaGto.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "REGISTRAR INGRESOS",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      if (Number.parseFloat(valorBnc) <= 0) {
        showNotification(
          "topRight",
          2,
          "REGISTRAR INGRESOS",
          "EL VALOR DEL INGRESO NO PUEDE SER CERO"
        );
      } else {
        setLoadingButton(true);
        let record = {
          tipoMov: 15,
          usr_id: currentUser.id,
          trc_id: idProveedor,
          cnc_id: idCnc,
          cue_id: idBnc,
          min_detalle: detalleCnc,
          min_importe: Number.parseFloat(valorBnc),
          min_transfer: Number.parseFloat(valorBnc),
          min_nota: nota,
          min_fecha: fechaGto,
          min_adjunto: nameAdjunto.length === 0 ? "NA" : nameAdjunto,
          min_moneda: monedaIng,
        };

        var val_gasto = await FRM.sendGasto(currentUser, record);
        if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
          updateEnviroment(val_gasto.datos.rol_entorno);
          setStateInf(Math.random());
          showNotification(
            "topRight",
            4,
            "CARGAR GASTOS",
            "REGISTRO DE GASTO EXITOSO"
          );
        } else {
          showNotification("topRight", 2, "CARGAR GASTOS", val_gasto.message);
        }
        setLoadingButton(false);
        closeModal();
      }
    }
  };

  return (
    <div className="upload-panel">
      <Divider
        style={{
          marginTop: 15,
          paddingTop: 10,
          marginBottom: 20,
          color: monedaIng === 1 ? CST.orangeBdy : CST.greenBdy,
          fontSize: 20,
        }}
      >
        {monedaIng === 1
          ? "DATOS GENERALES DEL INGRESO EN DOLARES"
          : "DATOS GENERALES DEL INGRESO EN PESOS"}
      </Divider>
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Fecha de Emision">
              <DatePicker onChange={onChangeFecha} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="Persona que entrega los fondos">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={
                  idProveedor > 0 ? idProveedor : "SELECCIONE EMISOR DE FONDOS"
                }
                placeholder="PROVEEDOR"
                onChange={updateProveedor}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {suppliersList.map((proveedor) => (
                  <Option key={proveedor.trc_id} value={proveedor.trc_id}>
                    {proveedor.trc_nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Concepto a Imputar">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={idCnc > 0 ? idCnc : "ELIJA CONCEPTO"}
                placeholder="CONCEPTO"
                onChange={updateCnc}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {listaCnc.map((conceptos) => (
                  <Option key={conceptos.cnc_id} value={conceptos.cnc_id}>
                    {conceptos.cnc_describe}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Detalle del Concepto">
              <Input
                placeholder="Detalle Concepto"
                defaultValue={""}
                style={{ width: "100%" }}
                value={detalleCnc}
                onChange={onChangeDeta}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={18}>
            <Form.Item label="Cuenta EReceptora de los Fondos">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={idBnc > 0 ? idBnc : "ELIJA CUENTA"}
                placeholder="CONCEPTO"
                onChange={updateBnc}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {cuentasLcl.map((bancos) => (
                  <Option key={bancos.cue_id} value={bancos.cue_id}>
                    {bancos.cue_nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Monto Recibido">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={valorBnc}
                value={valorBnc}
                onChange={onChangeBnc}
                placeholder="Deposito"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <TextArea
              rows={2}
              value={nota}
              placeholder={"Observaciones"}
              onChange={onChangeNota}
              allowClear
            />
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
        {"Comprobante de respaldo"}
      </Divider>
      {nameAdjunto.length > 0 ? (
        <Button
          type="link"
          size="large"
          onClick={() => openAdjunto(nameAdjunto)}
        >
          {nameAdjunto}
        </Button>
      ) : null}
      <div
        style={{
          textAlign: "center",
          marginTop: 20,
          border: 0,
        }}
      >
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          autoUpload={true}
          maxFiles={1}
          multiple={false}
          canCancel={false}
          accept=".pdf"
          inputContent={"Arrastre o seleccione el PDF a adjuntar"}
          inputWithFilesContent={"Agregar Archivos"}
          submitButtonContent={"Subir Archivos"}
        />
      </div>

      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => registrarIngreso()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={
              monedaIng === 1 ? curStyle.btnOrange : curStyle.btnSuccess
            }
            shape="round"
            block
            style={{
              display: Number.parseFloat(valorBnc) > 0 ? "block" : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> REGISTRAR EL INGRESO
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}

//https://pastebin.com/yKpF4A2h

function info(text) {
  Modal.info({
    title: "OBSERVACIONES",
    content: (
      <div>
        <p>{text}</p>
      </div>
    ),
    onOk() {},
  });
}
