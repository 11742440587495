import React, { useState, useEffect } from "react";
import { Table, Input, Button, Space, message, Tag, Typography } from "antd";
import Highlighter from "react-highlight-words";

import Fab from "@material-ui/core/Fab";
import { Add } from "@material-ui/icons";

//Iconos y constantes
import { SearchOutlined, FilePdfOutlined } from "@ant-design/icons";
import Icon from "@mdi/react";
import {
  mdiCalendarTextOutline,
  mdiCalendarClock,
  mdiCalendarCheck,
  mdiAccountCashOutline,
} from "@mdi/js";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../../Modal";
import CST from "../../../../utils/CustomSettings";
import DetallePagos from "./DetallePagos";
import CjbAprobarPago from "./CjbAprobarPago";
import CjbPagosLcl from "./CjbPagosLcl";
import CjbPagosFrn from "./CjbPagosFrn";
import CjbPagosLclGrp from "./CjbPagosLclGrp";
import CjbPagosFrnGrp from "./CjbPagosFrnGrp";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../../utils/ShowNotification";
import FRM from "../../../../utils/FrmService";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { myNumber } from "../../../../utils/ArrayFunctions";
import { URL_FILES } from "../../../../constants";

//Estilos
import "./CjbGastosFrm.scss";

export default function CjbPagosPen(props) {
  const { setIsLoading, tipoGastos } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState(0);
  const [listGastos, setListGastos] = useState([]);
  const [totalGastos, setTotalGastos] = useState(0);
  const [stateInf, setStateInf] = useState(Math.random());
  const [detalleDbt, setDetalleDbt] = useState([]);
  const [detalleCrd, setDetalleCrd] = useState([]);
  const [numGastos, setNumGastos] = useState(0);

  const { Title, Text } = Typography;

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const currentSetup = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.utlsetup
  );

  const currentMoneda = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      let datos = await FRM.getPagos(currentUser, tipoGastos);
      if (datos.type && datos.type > 0) {
        setListGastos(datos.listado);
        setTotalGastos(datos.total_rsm);
        setNumGastos(datos.num_rsm);
      } else {
        showNotification(
          "topRight",
          1,
          "ORDENES DE PAGO",
          "No hay PAGOS REGISTRADOS"
        );
      }
      setIsLoading(false);
    })();
  }, [stateInf]);

  const getDetalle = async (record, opcion) => {
    let datos = await FRM.getPagos(currentUser, 1, record.pgo_numero, opcion);
    if (datos.type && datos.type > 0) {
      setDetalleDbt(datos.lstdbt);
      setDetalleCrd(datos.lstcrd);
      openModal(record, opcion, "1000px");
    } else {
      showNotification(
        "topRight",
        1,
        "DETALLE CUENTA X PAGAR ",
        "No hay SE ENCONTRARON DATOS"
      );
    }
  };

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  //Funciones para busqueda en las columnas
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.focus());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  //Fin funciones para busqueda en las columnas

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={
            record.pgo_moneda === 2 ? curStyle.btnOrange : curStyle.btnSuccess
          }
          size="large"
          shape="circle"
        >
          {record.pgo_numero}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "pgo_fecha",
      key: "pgo_fecha",
      ...getColumnSearchProps("pgo_fecha"),
    },
    {
      title: "BENEFICIARIO", //10%
      dataIndex: "trc_nombre",
      key: "trc_nombre",
      ...getColumnSearchProps("trc_nombre"),
    },
    {
      title: "REGISTRO",
      dataIndex: "registro",
      key: "registro",
    },
    {
      title: "AUTORIZA",
      dataIndex: "autoriza",
      key: "autoriza",
    },
    {
      title: "ESTADO",
      dataIndex: "pgo_estado",
      key: "pgo_estado",
      align: "right",
      render: (text, row, index) => {
        return row.pgo_estado === 0 ? (
          <Tag color="cyan" style={{ fontSize: 15 }}>
            {"POR APROBAR"}
          </Tag>
        ) : (
          <Tag color="blue" style={{ fontSize: 15 }}>
            {"POR DESEMBOLSO"}
          </Tag>
        );
      },
    },
    {
      title: "FACTURAS",
      dataIndex: "debitos",
      key: "debitos",
      align: "right",
      render: (text, row, index) => {
        return row.pgo_moneda === 1 ? (
          <Tag color="geekblue" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo + myNumber(1, row.debitos)}
          </Tag>
        ) : (
          <Tag color="purple" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo_frn + myNumber(1, row.debitos)}
          </Tag>
        );
      },
    },
    {
      title: "N. CREDITO",
      dataIndex: "creditos",
      key: "creditos",
      align: "right",
      render: (text, row, index) => {
        return row.pgo_moneda === 1 ? (
          <Tag color="cyan" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo + myNumber(1, row.creditos)}
          </Tag>
        ) : (
          <Tag color="blue" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo_frn + myNumber(1, row.creditos)}
          </Tag>
        );
      },
    },
    {
      title: "A PAGAR",
      dataIndex: "pgo_importe",
      key: "pgo_importe",
      align: "right",
      render: (text, row, index) => {
        return row.pgo_moneda === 1 ? (
          <Tag color="green" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo + myNumber(1, row.pgo_importe)}
          </Tag>
        ) : (
          <Tag color="orange" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo_frn + myNumber(1, row.pgo_importe)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => getDetalle(record, 3, "900px")}
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={
            record.pgo_moneda === 2 ? curStyle.btnOrange : curStyle.btnSuccess
          }
          size="large"
          shape="circle"
          onClick={() =>
            record.pgo_moneda === 1 && record.pgo_estado === 1
              ? openModal(record, 1, "1000px")
              : record.pgo_moneda === 2 && record.pgo_estado === 1
              ? openModal(record, 2, "1000px")
              : message.info("EL GASTO ESTA APLAZADO... VERIFIQUE")
          }
        >
          <Icon path={mdiAccountCashOutline} size={"23"} />
        </Button>
      ),
    },
  ];

  //Renderizacion del componente Number.parseInt(tipoGastos) < 4
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listGastos}
        style={{ padding: 3, marginTop: 5 }}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={8}>
                  <Title level={4}>{"TOTAL POR DESEMBOLSAR"}</Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Title level={4} style={{ textAlign: "right" }}>
                    {tipoGastos === 2
                      ? "$" + myNumber(1, totalGastos, 2)
                      : "UYU$" + myNumber(1, totalGastos, 2)}
                  </Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={2}>
                  <Title level={4}>...</Title>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      {Number.parseInt(tipoGastos) === 1000 ? (
        <Fab
          color="primary"
          aria-label="add"
          style={{
            background: tipoGastos === 2 ? CST.orangeBdy : CST.greenBdy,
            position: "absolute",
            left: 50,
            bottom: 20,
          }}
          onClick={() =>
            tipoGastos === 2
              ? openModal(null, 4, "900px")
              : openModal(null, 5, "900px")
          }
        >
          <Add />
        </Fab>
      ) : null}
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 1 ? (
          <CjbPagosLcl
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            currentMoneda={currentMoneda}
          />
        ) : tipoModal === 2 ? (
          <CjbPagosFrn
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            currentMoneda={currentMoneda}
          />
        ) : tipoModal === 3 ? (
          <CjbAprobarPago
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            currentMoneda={currentMoneda}
            detalleDbt={detalleDbt}
            detalleCrd={detalleCrd}
          />
        ) : tipoModal === 4 ? (
          <CjbPagosLclGrp
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            totalGastos={totalGastos}
            numGastos={numGastos}
            currentMoneda={currentMoneda}
          />
        ) : (
          <CjbPagosFrnGrp
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            totalGastos={totalGastos}
            numGastos={numGastos}
            currentMoneda={currentMoneda}
          />
        )}
      </ModalApp>
    </div>
  );
}
