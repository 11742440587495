import React, { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  Divider,
  Statistic,
} from "antd";
import { UploadOutlined, CalendarOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../../actions/rolEnviromentActions";
//Styles
import "./CjbGastosFrm.scss";

// componentes
import FRM from "../../../../utils/FrmService";
import { reloadData } from "../../../../utils/ReloadEnv";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { URL_FILES } from "../../../../constants";
import CST from "../../../../utils/CustomSettings";

export default function DetalleGasto(props) {
  const {
    currentUser,
    closeModal,
    setStateInf,
    currentSetup,
    myNumber,
    detalleModal,
    currentMoneda,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [idCnc, setIdCnc] = useState(detalleModal.cnc_id);
  const [idCncOld, setIdCncOld] = useState(detalleModal.cnc_id);
  const [nota, setNota] = useState("");

  const { Option } = Select;
  const [form] = Form.useForm();

  const estado =
    detalleModal.meg_estado === 0
      ? " (POR APROBAR)"
      : detalleModal.meg_estado === 1
        ? " (APROBADA)"
        : " (FINALIZADA)";

  //Listado de conceptos
  const listaCnc = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.conceptos_gto
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Se actualiza el concepto
  const updateCnc = async (value) => {
    setIdCnc(value);
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const registrarGasto = async () => {
    if (Number.parseInt(idCnc) === 0) {
      showNotification(
        "topRight",
        2,
        "REGISTRAR GASTOS",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      if (Number.parseFloat(detalleModal.meg_importe) === 0) {
        showNotification(
          "topRight",
          2,
          "REGISTRAR GASTOS",
          "EL VALOR DEL GASTO NO PUEDE SER CERO"
        );
      } else {
        setLoadingButton(true);
        let record = {
          tipoMov: 3,
          meg_id: detalleModal.meg_id,
          meg_estado: detalleModal.meg_estado,
          cnc_id: idCnc,
          cnc_old: idCncOld,
          meg_nota_aprueba: nota,
        };

        var val_gasto = await FRM.sendGasto(currentUser, record);
        if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
          updateEnviroment(val_gasto.datos.rol_entorno);
          //setStateInf(idCncOld);
          showNotification(
            "topRight",
            4,
            "CARGAR GASTOS",
            "REGISTRO DE GASTO EXITOSO... POR FAVOR DESCARGUE DATOS PARA ACTUALIZAR LOS VALORES LOCALES"
          );
        } else {
          showNotification("topRight", 2, "CARGAR GASTOS", val_gasto.message);
        }
        setLoadingButton(false);
        closeModal();
      }
    }
  };

  return (
    <div className="upload-panel">
      <Divider
        style={{
          marginTop: 15,
          paddingTop: 10,
          marginBottom: 20,
          color: detalleModal.meg_moneda === 2 ? CST.orangeBdy : CST.greenBdy,
          fontSize: 20,
        }}
      >
        {detalleModal.meg_moneda === 1
          ? "DATOS GENERALES DEL GASTO EN DOLARES"
          : "DATOS GENERALES DEL GASTO EN PESOS UYU"}
      </Divider>
      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col span={5}>
            <Statistic
              title="Fecha Emision"
              value={detalleModal.meg_fecha}
              prefix={<CalendarOutlined />}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={5}>
            <Statistic
              title="F. de Autorizacion"
              value={
                detalleModal.meg_faprueba
                  ? detalleModal.meg_faprueba.toString().substr(0, 10)
                  : "..."
              }
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={5}>
            <Statistic
              title="Fecha de Pago"
              value={
                detalleModal.meg_fdesembolso
                  ? detalleModal.meg_fdesembolso.toString().substr(0, 10)
                  : "..."
              }
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={9}>
            <Statistic
              title="Beneficiario"
              value={detalleModal.trc_nombre}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={8}>
            <Statistic
              title="Usuario que Solicita"
              value={detalleModal.registro}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Usuario que Autoriza"
              value={detalleModal.autoriza}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Usuario que Paga"
              value={detalleModal.usrpaga ? detalleModal.usrpaga : "..."}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={24}>
            <Statistic
              title="Concepto Imputado"
              value={detalleModal.cnc_nombre + " - " + detalleModal.cls_nombre}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={18}>
            <Statistic
              title="Detalle del Concepto:"
              value={detalleModal.meg_detalle}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Monto a Pagar"
              value={
                detalleModal.meg_moneda === 1
                  ? currentMoneda.simbolo +
                  myNumber(1, detalleModal.meg_importe)
                  : currentMoneda.simbolo_frn +
                  myNumber(1, detalleModal.meg_importe)
              }
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={24}>
            <Statistic
              title="Observaciones al Registrar:"
              value={detalleModal.meg_nota ? detalleModal.meg_nota : "..."}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Statistic
              title="Observaciones al Aprobar:"
              value={
                detalleModal.meg_nota_aprueba
                  ? detalleModal.meg_nota_aprueba
                  : "..."
              }
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row
          gutter={8}
          style={{
            display:
              (currentUser.id === 7 && detalleModal.meg_estado <= 2) ||
                (currentUser.id === 2 && detalleModal.meg_estado === 4)
                ? "block"
                : "none",
          }}
        >
          <Col span={24}>
            <Form.Item label="ACTUALIZAR CONCEPTO IMPUTADO">
              <Select
                showSearch
                showArrow
                style={{
                  width: "100%",
                }}
                defaultValue={idCnc > 0 ? idCnc : "ELIJA CONCEPTO"}
                placeholder="CONCEPTO"
                onChange={updateCnc}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {listaCnc.map((conceptos) => (
                  <Option key={conceptos.cnc_id} value={conceptos.cnc_id}>
                    {conceptos.cnc_describe}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            display:
              detalleModal.usr_apr === currentUser.id &&
                detalleModal.meg_estado === 0
                ? "block"
                : "none",
          }}
        >
          <Col span={24}>
            <TextArea
              rows={2}
              value={nota}
              placeholder={"Observaciones al Aprobar"}
              onChange={onChangeNota}
              allowClear
            />
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => registrarGasto()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={
              detalleModal.meg_moneda === 2
                ? curStyle.btnOrange
                : curStyle.btnSuccess
            }
            shape="round"
            block
            style={{
              display:
                (currentUser.id === 7 && detalleModal.meg_estado <= 2) ||
                  (currentUser.id === 2 && detalleModal.meg_estado === 4)
                  ? "block"
                  : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> ACTUALIZAR EL GASTO
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
