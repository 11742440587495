import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  message,
  Tag,
  Modal as ModalB,
} from "antd";
import Highlighter from "react-highlight-words";

//Floating Buttons
import Fab from "@material-ui/core/Fab";
import { Add } from "@material-ui/icons";

//Iconos y constantes
import { SearchOutlined } from "@ant-design/icons";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../../Modal";
import CST from "../../../../utils/CustomSettings";
import CjbCreateTransfer from "./CjbCreateTransfer";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../../utils/ShowNotification";
import FRM from "../../../../utils/FrmService";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { myNumber } from "../../../../utils/ArrayFunctions";
import { URL_FILES } from "../../../../constants";

//Estilos
import "./CjbGastosFrm.scss";

export default function CjbTransfer(props) {
  const { setIsLoading, tipoGastos } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState(0);
  const [listGastos, setListGastos] = useState([]);
  const [totalGastos, setTotalGastos] = useState([]);
  const [numGastos, setNumGastos] = useState([]);
  const [stateInf, setStateInf] = useState(Math.random());

  const { confirm } = ModalB;

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const currentSetup = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.utlsetup
  );

  const currentMoneda = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      let datos = await FRM.getGastos(currentUser, tipoGastos);
      if (datos.type && datos.type > 0) {
        setListGastos(datos.listado);
        setTotalGastos(datos.total_rsm);
        setNumGastos(datos.num_rsm);
      } else {
        showNotification(
          "topRight",
          1,
          "LISTADO DE TRANSFERENCIAS",
          "No hay TRANSFER REGISTRADAS"
        );
      }
      setIsLoading(false);
    })();
  }, [stateInf]);

  //Aplazar gastos
  const aplGastos = async (gasto) => {
    setIsLoading(true);
    let record = {
      tipoMov: 6,
      meg_estado: tipoGastos < 4 ? 3 : 1,
      meg_id: gasto.meg_id,
    };
    var val_gasto = await FRM.sendGasto(currentUser, record);
    if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
      setStateInf(Math.random());
      showNotification("topRight", 4, "APLAZAR GASTOS", "MOVIMIENTO EXITOSO");
    } else {
      showNotification("topRight", 2, "APLAZAR GASTOS", val_gasto.message);
    }
    setIsLoading(false);
  };

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const openAdjunto = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/gastos/" + record.meg_adjunto;
    window.open(`${url_temp}`);
  };

  //Funciones para busqueda en las columnas
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.focus());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  //Fin funciones para busqueda en las columnas

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnBlueLight}
          size="large"
          shape="circle"
        >
          {record.trn_id}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "trn_fecha",
      key: "trn_fecha",
      ...getColumnSearchProps("trn_fecha"),
    },
    {
      title: "TIPO",
      dataIndex: "trn_tipo",
      key: "trn_tipo",
      render: (text, row, index) => {
        return row.trn_tipo === 1 ? (
          <Tag color="magenta">DOLAR A DOLAR</Tag>
        ) : row.trn_tipo === 2 ? (
          <Tag color="orange">DOLAR A PESOS</Tag>
        ) : row.trn_tipo === 3 ? (
          <Tag color="cyan">PESOS A PESOS</Tag>
        ) : (
          <Tag color="geekblue">PESOS A DOLAR</Tag>
        );
      },
    },
    {
      title: "CTA ORIGEN", //10%
      dataIndex: "origen",
      key: "origen",
      ...getColumnSearchProps("origen"),
    },
    {
      title: "CTA DESTINO",
      dataIndex: "destino",
      key: "destino",
    },
    {
      title: "REGISTRO",
      dataIndex: "registro",
      key: "registro",
    },
    {
      title: "VALOR SALIDA",
      dataIndex: "trn_importe",
      key: "trn_importe",
      align: "right",
      render: (text, record, index) => {
        return record.trn_tipo === 1 || record.trn_tipo === 2
          ? currentMoneda.simbolo + myNumber(1, record.trn_importe)
          : currentMoneda.simbolo_frn + myNumber(1, record.trn_importe);
      },
    },
    {
      title: "T. CAMBIO",
      dataIndex: "trn_cambio",
      key: "trn_cambio",
      align: "right",
      render: (text, record, index) => {
        return myNumber(1, record.trn_cambio, 3);
      },
    },
    {
      title: "VALOR ENTRADA",
      dataIndex: "trn_importe",
      key: "trn_importe",
      align: "right",
      render: (text, record, index) => {
        return record.trn_tipo === 1 || record.trn_tipo === 4
          ? currentMoneda.simbolo + myNumber(1, record.trn_importe_dst)
          : currentMoneda.simbolo_frn + myNumber(1, record.trn_importe_dst);
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() =>
            showNotification(
              "topRight",
              1,
              "TRANSFERENCIAS",
              "Nota: " + record.trn_nota
            )
          }
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listGastos}
        style={{ padding: 3, marginTop: 5 }}
      />

      <Fab
        color="primary"
        aria-label="add"
        style={{
          background: CST.blueBdy,
          position: "absolute",
          left: 50,
          bottom: 20,
        }}
        onClick={() => openModal(null, 4, "1000px")}
      >
        <Add />
      </Fab>
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        <CjbCreateTransfer
          currentUser={currentUser}
          closeModal={closeModal}
          setStateInf={setStateInf}
          currentSetup={currentSetup}
          myNumber={myNumber}
          currentMoneda={currentMoneda}
        />
      </ModalApp>
    </div>
  );
}
