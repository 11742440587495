import React from "react";
import { Typography, Row, Col, Divider, Statistic, Image } from "antd";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { URL_FILES } from "../../../constants";

export default function DetalleActivity(props) {
  const { detalleModal, currentUser } = props;

  const { Title } = Typography;

  const estado =
    detalleModal.atv_estado === 0
      ? " (POR APROBAR)"
      : detalleModal.atv_estado === 1
      ? " (APROBADA)"
      : " (FINALIZADA)";

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {detalleModal.tac_id === 1
          ? "GESTION DE LLEGADA DE GANADO" + estado
          : detalleModal.tac_id === 2
          ? "GESTION DE ALIMENTACION" + estado
          : detalleModal.tac_id === 3
          ? "GESTION DE DESPARASITACION" + estado
          : detalleModal.tac_id === 4
          ? "GESTION DE VACUNACION" + estado
          : detalleModal.tac_id === 5
          ? "GESTION SALIDA DE GANADO" + estado
          : "GESTION TAREAS DE CAMPO" + estado}
      </Title>
      <Row gutter={8}>
        <Col span={8}>
          <Statistic
            title="Fecha Inicio"
            value={
              detalleModal.dia_ini +
              "-" +
              detalleModal.mes_ini +
              "-" +
              detalleModal.ano_ini
            }
            prefix={<CalendarOutlined />}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="Hora Inicio"
            value={detalleModal.hora_ini + ":" + detalleModal.min_ini}
            prefix={<ClockCircleOutlined />}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Fecha Final"
            value={
              detalleModal.dia_fin +
              "-" +
              detalleModal.mes_fin +
              "-" +
              detalleModal.ano_fin
            }
            prefix={<CalendarOutlined />}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="Hora Final"
            value={detalleModal.hora_fin + ":" + detalleModal.min_fin}
            prefix={<ClockCircleOutlined />}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Row gutter={8}>
        <Col span={8}>
          <Statistic
            title="Registrado por"
            value={detalleModal.registro}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Autorizado por"
            value={detalleModal.autoriza}
            groupSeparator=""
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Asignado a"
            value={detalleModal.asigna}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Row gutter={8}>
        <Col span={24}>
          <Statistic
            title="Observaciones al Registrar:"
            value={detalleModal.desc}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Row gutter={8}>
        <Col span={24}>
          <Statistic
            title={detalleModal.faprueba + detalleModal.haprueba}
            value={detalleModal.nota_aprueba}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
      </Row>
      {detalleModal.fcierre ? (
        <>
          <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
          <Row gutter={8}>
            <Col span={24}>
              <Statistic
                title={detalleModal.fcierre + detalleModal.hcierre}
                value={detalleModal.nota_cierre}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
          </Row>
        </>
      ) : null}
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 20 }} />
      <Row gutter={8}>
        <Col span={6}>
          <Image
            width={100}
            src={
              URL_FILES +
              currentUser.dominio +
              "/fotos/" +
              detalleModal.atv_imagen1
            }
          />
        </Col>
        <Col span={6}>
          <Image
            width={100}
            src={
              URL_FILES +
              currentUser.dominio +
              "/fotos/" +
              detalleModal.atv_imagen2
            }
          />
        </Col>
        <Col span={6}>
          <Image
            width={100}
            src={
              URL_FILES +
              currentUser.dominio +
              "/fotos/" +
              detalleModal.atv_imagen3
            }
          />
        </Col>
        <Col span={6}>
          <Image
            width={100}
            src={
              URL_FILES +
              currentUser.dominio +
              "/fotos/" +
              detalleModal.atv_imagen4
            }
          />
        </Col>
      </Row>
    </div>
  );
}
