import React, { useState } from "react";
import { Layout } from "antd";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Importo Store y Provider desde Redux
import store from "./store";
import { Provider } from "react-redux";

import "./App.css";

//Paginas
import Loading from "./components/Loading";
import Login from "./pages/login";
import Maestros from "./pages/Maestros/index";
import MaestrosFrm from "./pages/MaestrosFrm/index";
import MaestrosCrm from "./pages/MaestrosCrm/index";
import MaestrosErp from "./pages/MaestrosErp/index";
import ProcesosFrm from "./pages/ProcesosFrm/index";
import InformesFrm from "./pages/InformesFrm/index";
import Invoice from "./pages/Pos/invoice";
import Farmboard from "./pages/Farm/frmboard";
import FarmAuxiliar from "./pages/Farm/frmauxiliar";
import FarmCompras from "./pages/Farm/frmcompras";
import FarmAsistente from "./pages/Farm/frmasistente";
import FarmFinanza from "./pages/Farm/frmfinanza";
import FarmManager from "./pages/Farm/frmmanager";
import Obdgmboard from "./pages/Crm/obdgmboard";
import ErpSuperAdm from "./pages/Erp/superadmin";
import ErpGerencialEsp from "./pages/Erp/gerencialesp";
import Error404 from "./pages/error404";

export default function App() {
  const { Content } = Layout;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Provider store={store}>
      <Layout>
        <Router>
          <Content>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Switch>
                  <Route path="/" exact={true}>
                    <Login setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/invoice" exact={true}>
                    <Invoice setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/frmboard" exact={true}>
                    <Farmboard setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/frmauxiliar" exact={true}>
                    <FarmAuxiliar setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/frmcompras" exact={true}>
                    <FarmCompras setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/frmfinanza" exact={true}>
                    <FarmFinanza setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/frmasistente" exact={true}>
                    <FarmAsistente setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/frmmanager" exact={true}>
                    <FarmManager setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/obdgmboard" exact={true}>
                    <Obdgmboard setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/erpsuperadm" exact={true}>
                    <ErpSuperAdm setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/erpgerencialesp" exact={true}>
                    <ErpGerencialEsp setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/maestros" exact={true}>
                    <Maestros setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/maestrosfrm" exact={true}>
                    <MaestrosFrm setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/maestroscrm" exact={true}>
                    <MaestrosCrm setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/maestroserp" exact={true}>
                    <MaestrosErp setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/procesosfrm" exact={true}>
                    <ProcesosFrm setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="/informesfrm" exact={true}>
                    <InformesFrm setIsLoading={setIsLoading} />
                  </Route>
                  <Route path="*">
                    <Error404 />
                  </Route>
                </Switch>
              </>
            )}
          </Content>
        </Router>
      </Layout>
    </Provider>
  );
}
