import React from "react";
import { useSelector } from "react-redux";

//Componentes adicionales
import { AALLayout } from "../../components/AALLayout";
import { TitlePage } from "../../components/TitlePage";
//Funciones para administrar el carrito
import { myNumber, nombreMes } from "../../utils/ArrayFunctions";

//Farm
import CjbGastosLcl from "../../components/Farm/Procesos/CjbGastosLcl";
import CjbGastosFrn from "../../components/Farm/Procesos/CjbGastosFrn";

/**
 * ProcesosPage para cargar los componentes validando el currentComponent del State
 */
export default function Procesos() {
  const currentComponent = useSelector(
    (state) => state.currentComponent.currentComponent
  );
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );
  const currentSetup = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.utlsetup
  );

  function loadComponent(currentComponent) {
    switch (currentComponent.opcion) {
      case "CjbGastosLcl":
        return (
          <CjbGastosLcl
            myNumber={myNumber}
            currentUser={currentUser}
            currentSetup={currentSetup}
          />
        );
      case "CjbGastosFrn":
        return (
          <CjbGastosFrn
            myNumber={myNumber}
            currentUser={currentUser}
            currentSetup={currentSetup}
          />
        );
      default:
        return <h2>NO HAY PROCESO DEFINIDO</h2>;
    }
  }

  return (
    <AALLayout>
      <TitlePage title="Procesos" />
      {loadComponent(currentComponent)}
    </AALLayout>
  );
}
