import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  message,
  Tag,
  Modal as ModalB,
} from "antd";
import Highlighter from "react-highlight-words";

//Floating Buttons
import Fab from "@material-ui/core/Fab";
import { Add } from "@material-ui/icons";

//Iconos y constantes
import { SearchOutlined } from "@ant-design/icons";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../../Modal";
import CST from "../../../../utils/CustomSettings";
import CjbCreateIni from "./CjbCreateIni";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../../utils/ShowNotification";
import FRM from "../../../../utils/FrmService";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { myNumber } from "../../../../utils/ArrayFunctions";
import { URL_FILES } from "../../../../constants";

export default function CjbSaldosIni(props) {
  const { setIsLoading, tipoGastos } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [listGastos, setListGastos] = useState([]);
  const [totalGastos, setTotalGastos] = useState([]);
  const [numGastos, setNumGastos] = useState([]);
  const [stateInf, setStateInf] = useState(Math.random());

  const { confirm } = ModalB;

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const currentSetup = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.utlsetup
  );

  const currentMoneda = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      let datos = await FRM.getGastos(currentUser, tipoGastos);
      if (datos.type && datos.type > 0) {
        setListGastos(datos.listado);
        setTotalGastos(datos.total_rsm);
        setNumGastos(datos.num_rsm);
      } else {
        showNotification(
          "topRight",
          1,
          "LISTADO DE AJUSTES",
          "No hay AJUSTES REGISTRADAS"
        );
      }
      setIsLoading(false);
    })();
  }, [stateInf]);

  //Aplazar gastos
  const aplGastos = async (gasto) => {
    setIsLoading(true);
    let record = {
      tipoMov: 6,
      meg_estado: tipoGastos < 4 ? 3 : 1,
      meg_id: gasto.meg_id,
    };
    var val_gasto = await FRM.sendGasto(currentUser, record);
    if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
      setStateInf(Math.random());
      showNotification("topRight", 4, "APLAZAR GASTOS", "MOVIMIENTO EXITOSO");
    } else {
      showNotification("topRight", 2, "APLAZAR GASTOS", val_gasto.message);
    }
    setIsLoading(false);
  };

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const openAdjunto = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/gastos/" + record.meg_adjunto;
    window.open(`${url_temp}`);
  };

  //Nuevas funciones de busqueda de columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //Fin de nuevas funciones de busqueda

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSuccess}
          size="large"
          shape="circle"
        >
          {record.mov_numero}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "mov_fecha",
      key: "mov_fecha",
      ...getColumnSearchProps("mov_fecha"),
    },
    {
      title: "MONEDA",
      dataIndex: "cue_moneda",
      key: "cue_moneda",
      render: (text, row, index) => {
        return row.cue_moneda === 1 ? (
          <Tag color="green">DOLARES</Tag>
        ) : (
          <Tag color="orange">PESOS</Tag>
        );
      },
    },
    {
      title: "CUENTA AFECTADA", //10%
      dataIndex: "cue_nombre",
      key: "cue_nombre",
      ...getColumnSearchProps("cue_nombre"),
    },
    {
      title: "USUARIO",
      dataIndex: "registro",
      key: "registro",
    },
    {
      title: "USD$ +",
      dataIndex: "mov_debito",
      key: "mov_debito",
      align: "right",
      render: (text, record, index) => {
        return currentMoneda.simbolo + myNumber(1, record.mov_debito);
      },
    },
    {
      title: "USD$ -",
      dataIndex: "mov_credito",
      key: "mov_credito",
      align: "right",
      render: (text, record, index) => {
        return currentMoneda.simbolo + myNumber(1, record.mov_credito);
      },
    },
    {
      title: "UYU$ +",
      dataIndex: "mov_debito_fr",
      key: "mov_debito_fr",
      align: "right",
      render: (text, record, index) => {
        return currentMoneda.simbolo + myNumber(1, record.mov_debito_fr);
      },
    },
    {
      title: "UYU$ -",
      dataIndex: "mov_credito_fr",
      key: "mov_credito_fr",
      align: "right",
      render: (text, record, index) => {
        return currentMoneda.simbolo + myNumber(1, record.mov_credito_fr);
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() =>
            showNotification(
              "topRight",
              1,
              "TRANSFERENCIAS",
              "Nota: " + record.mov_detalle
            )
          }
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listGastos}
        style={{ padding: 3, marginTop: 5 }}
      />

      <Fab
        color="primary"
        aria-label="add"
        style={{
          background: CST.greenBdy,
          position: "absolute",
          left: 50,
          bottom: 20,
        }}
        onClick={() => openModal(null, 4, "700px")}
      >
        <Add />
      </Fab>
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        <CjbCreateIni
          currentUser={currentUser}
          closeModal={closeModal}
          setStateInf={setStateInf}
          currentSetup={currentSetup}
          myNumber={myNumber}
          currentMoneda={currentMoneda}
        />
      </ModalApp>
    </div>
  );
}
