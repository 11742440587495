import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Typography,
  Row,
  Col,
  Popconfirm,
  Statistic,
  Divider,
  Tabs,
  Tag,
} from "antd";
import {
  CalendarOutlined,
  DeleteOutlined,
  SearchOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./DetalleLote.scss";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";

export default function DetalleLote(props) {
  const {
    currentUser,
    closeModal,
    setIsLoading,
    myNumber,
    detalleCarga,
    opcionCarga,
    detalleList,
    detalleFood,
    foodResume,
    foodDetalle,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataDef, setDataDef] = useState([]);
  const [verTabla, setVerTabla] = useState(1);
  const [nameAlm, setNameAlm] = useState("");
  const [detaAlm, setDetaAlm] = useState([]);
  const [detaSec, setDetaSec] = useState(0);
  const [detaHmd, setDetaHmd] = useState(0);
  const [detaImp, setDetaImp] = useState(0);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  useEffect(() => {
    setDataDef(detalleList);
  }, [detalleCarga]);

  const { Title, Text } = Typography;
  const { TabPane } = Tabs;

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const deleteCambio = async () => {
    setLoadingButton(true);
    if (Number.parseInt(detalleCarga.cmb_estado) > 0) {
      showNotification(
        "topRight",
        2,
        "ELIMINAR CAMBIO DE LOTE",
        "EL CAMBIO DE LOTE YA FUE CONFIRMADO"
      );
    } else {
      let record = {
        ent_id: detalleCarga.ent_numero,
        ent_option: opcionCarga,
      };
      var val_inventory = await FRM.updateCambio(currentUser, record);
      console.log(val_inventory);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.ganaderia);
        showNotification(
          "topRight",
          4,
          "ELIMINAR CAMBIO",
          "MOVIMIENTO ELIMINADO CON EXITO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "CARGAR CARAVANA",
          val_inventory.message
        );
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  const getDetalle = async (record, detalle) => {
    if (verTabla === 1) {
      const almFilter = detalle.filter((tipo) => {
        return Number.parseInt(tipo.alm_id) === Number.parseInt(record.alm_id);
      });
      setDetaAlm(almFilter);
      setNameAlm(record.alm_nombre);
      setDetaHmd(record.humedos);
      setDetaSec(record.secos);
      setDetaImp(record.importe);
      setVerTabla(2);
    } else {
      setVerTabla(1);
    }
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "DISPOSITIVO", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
    },
    {
      title: "PESO",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.anm_peso_neto);
      },
    },
  ];

  // Encabezados de la tabla de Historico de Alimentos
  const historico = [
    {
      title: "FECHA", //10%
      dataIndex: "alm_fecha",
      key: "alm_fecha",
    },
    {
      title: "CEBADA H",
      dataIndex: "cebada_h",
      key: "cebada_h",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.cebada_h, 2)}
          </Tag>
        );
      },
    },
    {
      title: "CEBADA S",
      dataIndex: "cebada_s",
      key: "cebada_s",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.cebada_s, 2)}
          </Tag>
        );
      },
    },
    {
      title: "MAIZ H",
      dataIndex: "maiz_h",
      key: "maiz_h",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.maiz_h, 2)}
          </Tag>
        );
      },
    },
    {
      title: "MAIZ S",
      dataIndex: "maiz_s",
      key: "maiz_s",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.maiz_s, 2)}
          </Tag>
        );
      },
    },
    {
      title: "FARDO H",
      dataIndex: "fardo_h",
      key: "fardo_h",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.fardo_h, 2)}
          </Tag>
        );
      },
    },
    {
      title: "FARDO S",
      dataIndex: "fardo_s",
      key: "fardo_s",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.fardo_s, 2)}
          </Tag>
        );
      },
    },
    {
      title: "NUCLEO H",
      dataIndex: "nucleo_h",
      key: "nucleo_h",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.nucleo_h, 2)}
          </Tag>
        );
      },
    },
    {
      title: "NUCLEO S",
      dataIndex: "nucleo_s",
      key: "nucleo_s",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.nucleo_s, 2)}
          </Tag>
        );
      },
    },
    {
      title: "TOTAL Kg H",
      dataIndex: "totaleh",
      key: "totaleh",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="volcano" style={{ fontSize: 14 }}>
            {myNumber(
              1,
              Number.parseFloat(row.nucleo_h) +
              Number.parseFloat(row.cebada_h) +
              Number.parseFloat(row.maiz_h) +
              Number.parseFloat(row.fardo_h),
              2
            )}
          </Tag>
        );
      },
    },
    {
      title: "TOTAL Kg S",
      dataIndex: "totales",
      key: "totales",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="purple" style={{ fontSize: 14 }}>
            {myNumber(
              1,
              Number.parseFloat(row.nucleo_s) +
              Number.parseFloat(row.cebada_s) +
              Number.parseFloat(row.maiz_s) +
              Number.parseFloat(row.fardo_s),
              2
            )}
          </Tag>
        );
      },
    },
  ];

  const listaAlm = [
    {
      title: "ID", //10%
      dataIndex: "alm_id",
      key: "alm_id",
    },
    {
      title: "ALIMENTO",
      dataIndex: "alm_nombre",
      key: "alm_nombre",
      align: "left",
    },
    {
      title: "ANIMALES",
      dataIndex: "animales",
      key: "animales",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="orange" style={{ fontSize: 14 }}>
            {myNumber(1, row.animales, 2)}
          </Tag>
        );
      },
    },
    {
      title: "Kg HUMEDOS",
      dataIndex: "humedos",
      key: "humedos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.humedos, 2)}
          </Tag>
        );
      },
    },
    {
      title: "Kg SECOS",
      dataIndex: "secos",
      key: "secos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.secos, 2)}
          </Tag>
        );
      },
    },
    {
      title: "IMPORTE",
      dataIndex: "secos",
      key: "secos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="purple" style={{ fontSize: 14 }}>
            {myNumber(1, row.importe, 2)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="medium"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => getDetalle(record, foodDetalle)}
        />
      ),
    },
  ];

  const detalleAlm = [
    {
      title: "FECHA",
      dataIndex: "alm_fecha",
      key: "alm_fecha",
      align: "left",
    },
    {
      title: "ANIMALES",
      dataIndex: "animales",
      key: "animales",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="orange" style={{ fontSize: 14 }}>
            {myNumber(1, row.animales, 2)}
          </Tag>
        );
      },
    },
    {
      title: "Kg HUMEDOS",
      dataIndex: "humedos",
      key: "humedos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.humedos, 2)}
          </Tag>
        );
      },
    },
    {
      title: "Kg SECOS",
      dataIndex: "secos",
      key: "secos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.secos, 2)}
          </Tag>
        );
      },
    },
    {
      title: "IMPORTE",
      dataIndex: "secos",
      key: "secos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="purple" style={{ fontSize: 14 }}>
            {myNumber(1, row.importe, 2)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="medium"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={() => getDetalle(record)}
        />
      ),
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"DETALLES DEL CAMPO " + detalleCarga.cmp_nombre}
      </Title>
      <Tabs defaultActiveKey="1">
        <TabPane tab="ANIMALES EN CAMPO" key="1">
          <Row gutter={8}>
            <Col span={6}>
              <Statistic
                title="Fecha"
                value={detalleCarga.cmb_fecha}
                prefix={<CalendarOutlined />}
              />
            </Col>
            <Col span={9}>
              <Statistic
                title="Campo de Origen"
                value={
                  detalleCarga.org_id === 0
                    ? "ENTRADA NUEVA"
                    : detalleCarga.cmp_origen
                }
              />
            </Col>
            <Col span={9}>
              <Statistic
                title="Campo de Destino"
                value={detalleCarga.cmp_nombre}
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <Row gutter={8}>
            <Col span={4}>
              <Statistic
                title="Existente"
                value={myNumber(1, detalleCarga.cmb_cantidad)}
                valueStyle={{ color: colorSec }}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Conteo"
                value={myNumber(
                  1,
                  detalleCarga.cmb_animales +
                  detalleCarga.cmb_move_anm +
                  detalleCarga.cmb_sale_anm +
                  detalleCarga.cmb_muere_anm
                )}
                valueStyle={{ color: colorPrm }}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Diferencia"
                value={myNumber(
                  1,
                  detalleCarga.cmb_cantidad -
                  (detalleCarga.cmb_animales +
                    detalleCarga.cmb_move_anm +
                    detalleCarga.cmb_sale_anm +
                    detalleCarga.cmb_muere_anm)
                )}
                valueStyle={{ color: colorPrm }}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Movidos a Lote"
                value={myNumber(1, detalleCarga.cmb_move_anm)}
                valueStyle={{ color: colorPrm }}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Vendidos"
                value={myNumber(1, detalleCarga.cmb_sale_anm)}
                valueStyle={{ color: colorPrm }}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Muertes"
                value={myNumber(1, detalleCarga.cmb_muere_anm)}
                valueStyle={{ color: colorPrm }}
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <Table
            columns={header}
            dataSource={dataDef}
            bordered
            size="middle"
            scroll={{ y: 640 }}
          />
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <Row style={{ marginTop: 10 }}>
            <Col span={24}>
              <Statistic value={detalleCarga.cmb_nota} />
            </Col>
          </Row>
          <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
            <Popconfirm
              placement="top"
              title="CONFIRMAR EL MOVIMIENTO?"
              onConfirm={() => deleteCambio()}
              okText="Si"
              cancelText="No"
            >
              <Button
                type="primary"
                shape="round"
                block
                style={{
                  display:
                    dataDef.length > 0 &&
                      detalleCarga.cmb_estado === 0 &&
                      opcionCarga === 3
                      ? "block"
                      : "none",
                }}
                loading={loadingButton}
              >
                <DeleteOutlined /> DESCARTAR EL CAMBIO
              </Button>
            </Popconfirm>
          </div>
        </TabPane>
        <TabPane tab="HISTRICO DE ALIMENTOS (Kg)" key="2">
          {verTabla === 1 ? (
            <>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                RELACION DE ALIMENTOS SUMINISTRADOS AL LOTE
              </Divider>
              <Table
                columns={listaAlm}
                dataSource={detalleFood}
                bordered
                size="middle"
                summary={(pageData) => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={3}>
                          TOTALES
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "rebeccapurple",
                            }}
                          >
                            {myNumber(
                              1,
                              Number.parseFloat(foodResume.humedos) > 0
                                ? Number.parseFloat(foodResume.humedos)
                                : 0,
                              2
                            )}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "rebeccapurple",
                            }}
                          >
                            {myNumber(
                              1,
                              foodResume.secos
                                ? Number.parseFloat(foodResume.secos)
                                : 0,
                              2
                            )}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "rebeccapurple",
                            }}
                          >
                            {myNumber(
                              1,
                              foodResume.importe
                                ? Number.parseFloat(foodResume.importe)
                                : 0,
                              2
                            )}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </>
          ) : (
            <>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                {"DETALE DE " + nameAlm + " SUMINISTRADO AL LOTE"}
              </Divider>
              <Table
                columns={detalleAlm}
                dataSource={detaAlm}
                bordered
                size="middle"
                summary={(pageData) => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={2}>TOTALES</Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "rebeccapurple",
                            }}
                          >
                            {myNumber(
                              1,
                              Number.parseFloat(detaHmd) > 0
                                ? Number.parseFloat(detaHmd)
                                : 0,
                              2
                            )}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "rebeccapurple",
                            }}
                          >
                            {myNumber(
                              1,
                              detaSec ? Number.parseFloat(detaSec) : 0,
                              2
                            )}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "rebeccapurple",
                            }}
                          >
                            {myNumber(
                              1,
                              detaImp ? Number.parseFloat(detaImp) : 0,
                              2
                            )}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
}
