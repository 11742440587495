import React, { useState } from "react";
import {
  Button,
  Table,
  Upload,
  Typography,
  Row,
  Col,
  message,
  Divider,
  Space,
  Popconfirm,
  DatePicker,
  Form
} from "antd";
import { UploadOutlined, FileSyncOutlined } from "@ant-design/icons";

//Styles
import "./SyncSnig.scss";

// Excel Render Y Exportar Excel
import { ExcelRenderer } from "react-excel-renderer";
import ReactExport from "react-export-excel";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorWar, colorGrn } from "../../../constants";

export default function UpdatePeso(props) {
  const {
    currentUser,
    closeModal,
    myNumber,
    listActivos,
    setListAnimales,
    setListCampos,
    filtrarActivos,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData] = useState([]);
  const [dataDef, setDataDef] = useState([]);
  const [anmDias, setAnmDias] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fechaChg, setFechaChg] = useState(null)
  //const [listAnimales, setListAnimales] = useState([]);
  const [downSnig, setDownSnig] = useState(false);

  const { Title } = Typography;

  //Para uso de excel
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaChg(dateString);
  }

  //Ejecutar dispatch tras el proceso
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const propis = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (fileList.length > 0) {
        setFileList([file]);
      } else {
        setFileList([...fileList, file]);
      }
      UploadExcel(file);
      return false;
    },
    fileList,
  };

  const UploadExcel = async (file) => {
    //Obtengo la informacion del Input File
    let fileObj = file;

    //Render del Excel
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        // Elimino el encabezado del archivo
        var excel = resp.rows.filter(function (item, index, arr) {
          let titColumn = item[0].toString();
          //.replace(/^\s+|\s+$/gm, "");
          return titColumn.trim() !== "Dispositivo";
        });
        const bh_excel2 = [];
        excel.forEach(function (item, index, arr) {
          let valColumn = item[0].toString();
          let key = index;
          let anm_codigo = valColumn.trim();
          let rza_nombre = "";
          let anm_sexo = "";
          let anm_dias = item[5];

          let exceldata = {
            key: key,
            anm_codigo: anm_codigo,
            rza_nombre: rza_nombre,
            anm_sexo: anm_sexo,
            anm_dias: anm_dias,
          };

          bh_excel2.push(exceldata);
        });
        setData(bh_excel2);
        //getMatchCampo();
      }
    });
  };

  //Funcion para completar los datos de la caravana
  const completaDatos = async () => {
    setLoadingButton(true);
    const new_snig = [];
    const val_snig = [];
    listActivos.forEach((row) => {
      let val_exist = estaEnArray(row.anm_codigo.toString().trim(), data);
      if (Number.parseInt(val_exist) === 0) {
        if (Number.parseInt(row.anm_estado) === 0) {
          let newsnig = {
            anm_id: row.id,
            anm_codigo: row.anm_codigo,
            anm_dias: row.anm_dias,
            anm_ubica: row.anm_ubica,
            ctg_nombre: row.ctg_nombre,
          };
          new_snig.push(newsnig);
        }
      } else {
        let cursnig = {
          anm_id: row.id,
          anm_codigo: row.anm_codigo,
          anm_dias: val_exist,
          anm_ubica: row.anm_ubica,
          ctg_nombre: row.ctg_nombre,
        };
        val_snig.push(cursnig);
      }
    });
    setDataDef(new_snig);
    setAnmDias(val_snig);
    setLoadingButton(false);
  };

  //Validar la existencia de un animal
  const estaEnArray = (value, array) => {
    let count = 0;
    array.forEach((arrayValue) => {
      if (arrayValue.anm_codigo.toString().trim() === value) {
        count = arrayValue.anm_dias;
      }
    });
    return count > 0 ? count : 0;
  };

  //Descargar diferencias
  const downDifs = (tipo, arrDif) => {
    if (arrDif.length > 0) {
      setDownSnig(true);
      setTimeout(function () {
        setDownSnig(false);
      }, 1000);
    } else {
      message.error("NO HAY DATOS PARA EXPORTAR", 1);
    }
  };

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const uploadInvetoryWS = async () => {
    setLoadingButton(true);

    let record = {
      tipoMov: 3,
      sng_fecha: fechaChg,
      record_def: dataDef,
      record_dia: anmDias,
    };
    var val_inventory = await FRM.sendEntrada(currentUser, record);
    if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
      setListAnimales(val_inventory.listado);
      setListCampos(val_inventory.lstcampos);
      filtrarActivos(val_inventory.listado);
      updateEnviroment(val_inventory.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "VALIDACION DE SNIG",
        "PROCESO DE VALIDACION EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "VALIDACION DE SNIG",
        val_inventory.message
      );
    }
    setData([]);
    setFileList([]);
    setLoadingButton(false);
    closeModal();
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "CARAVANA", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
    },
    {
      title: "DIAS", //10%
      dataIndex: "anm_dias",
      key: "anm_dias",
    },
    {
      title: "CTAEGORIA", //10%
      dataIndex: "ctg_nombre",
      key: "ctg_nombre",
    },
    {
      title: "UBICACION", //10%
      dataIndex: "anm_ubica",
      key: "anm_ubica",
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"INVENTARIO CONTRA SNIG - CATEGORIAS"}
      </Title>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item>
            <DatePicker onChange={onChangeFecha} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item>
            <Upload {...propis}>
              <Button
                type="primary"
                className={curStyle.btnLila}
                shape="round"
                block
                loading={loadingButton}
                style={{
                  display: "block",
                }}
              >
                <UploadOutlined /> SELECCIONE EL ARCHIVO DEL SNIG
              </Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item>
            <Button
              type="primary"
              shape="round"
              className={curStyle.btnInfo}
              onClick={() => completaDatos()}
              loading={loadingButton}
              style={{
                display: fileList.length > 0 ? "block" : "none",
              }}
            >
              <FileSyncOutlined /> VERIFICAR SNIG
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Table columns={header} dataSource={dataDef} bordered size="middle" />
      <Space>
        <Title
          level={4}
          style={{
            color: colorSec,
            textAlign: "left",
            marginLeft: 30,
            marginTop: 15,
          }}
        >
          {"EN SNIG: " + myNumber(1, data.length)}
        </Title>
        <Title
          level={4}
          style={{
            color: colorGrn,
            textAlign: "left",
            marginLeft: 30,
            marginTop: 15,
          }}
        >
          {"EN INVENTARIO: " + myNumber(1, listActivos.length)}
        </Title>
        <Title
          level={4}
          style={{
            color: colorWar,
            textAlign: "left",
            marginLeft: 30,
            marginTop: 15,
          }}
          onClick={() => downDifs(2, dataDef)}
        >
          {"DIFERENCIA: " + myNumber(1, dataDef.length)}
        </Title>
      </Space>
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => uploadInvetoryWS()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="round"
            block
            style={{
              display: (dataDef.length > 0 && fechaChg.length > 0) ? "block" : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> PROCESAR LA ACTUALIZACION
          </Button>
        </Popconfirm>
      </div>
      {downSnig ? (
        <ExcelFile filename={"Inventario_no_SNIG"} hideElement={true}>
          <ExcelSheet data={dataDef} name={"SNIG - DIF"}>
            <ExcelColumn label="DISPOSITIVO" value="anm_codigo" />
            <ExcelColumn label="CATEGORIA" value="ctg_nombre" />
            <ExcelColumn label="UBICACION" value="anm_ubica" />
          </ExcelSheet>
        </ExcelFile>
      ) : null}
    </div>
  );
}
