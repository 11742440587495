import React, { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Select,
  Input,
  Row,
  Col,
  InputNumber,
  Popconfirm,
  message,
  DatePicker,
  Divider,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

//Api para mapas
/*AIzaSyCadN8K3_yCdcm9vjrngpEBHMvdydLPt0E*/

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./CambiosLote.scss";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";
import CST from "../../../utils/CustomSettings";

export default function AddAnimals(props) {
  const { currentUser, closeModal, myNumber, detalleCarga } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [idCategoria, setIdCategoria] = useState(0);
  const [cantidad, setCantidad] = useState(0);
  const [kilos, setKilos] = useState(0);
  const [nota, setNota] = useState("");
  const [fechaEntr, setFechaEntr] = useState(null);
  const [caravana, setCaravana] = useState("");

  const { Option } = Select;
  const [form] = Form.useForm();

  const categoriasList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.categorias
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaEntr(dateString);
  }

  //Se actualiza el nombre
  const updateCategoria = async (value) => {
    setIdCategoria(value);
  };

  const onChangeCaravana = (e) => {
    let dato = e.target.value;
    if (Number.parseInt(cantidad) > 1) {
      setCaravana("");
    } else {
      setCaravana(dato.toUpperCase());
    }
  };

  const onChangeUnd = (value) => {
    if (Number.parseInt(idCategoria) > 0) {
      if (Number.parseInt(value) >= 0) {
        setCantidad(value);
        if (Number.parseInt(value) > 1) {
          setCaravana("");
        }
      } else {
        setCaravana("");
        message.error("VALOR INCORRECTO!", 1);
        return false;
      }
    } else {
      message.error("SELECCIONE LA CATEGORIA!", 1);
      return false;
    }
  };

  const onChangeKgm = (value) => {
    if (Number.parseInt(idCategoria) > 0) {
      if (Number.parseFloat(value) >= 0) {
        setKilos(value);
      } else {
        message.error("VALOR INCORRECTO!", 1);
        return false;
      }
    } else {
      message.error("SELECCIONE LA CATEGORIA!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  // ENVIO A WEBSERVICES
  const uploadDspWS = async () => {
    setLoadingButton(true);
    if (
      Number.parseInt(idCategoria) === 0 ||
      Number.parseFloat(kilos) === 0 ||
      fechaEntr.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "AGREGAR ANIMALES",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      let record = {
        tipoMov: 1,
        usr_id: currentUser.id,
        cmb_id: detalleCarga.cmb_numero,
        cmp_id: detalleCarga.cmp_id,
        cmb_area: detalleCarga.cmb_area,
        ctg_id: idCategoria,
        ent_cantidad: cantidad,
        ent_kilos: kilos,
        anm_caravana: caravana,
        ent_nota: nota,
        ent_fecha: fechaEntr,
      };
      var val_inventory = await FRM.sendAdicion(currentUser, record);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "ADICIONAR ANIMALES",
          "PROCESO DE REGISTRO EXITOSO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "ADICIONAR ANIMALES",
          val_inventory.message
        );
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"AGREGAR ANIMALES SIN CARAVANA EN " + detalleCarga.cmp_nombre}
      </Title>
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item label="Fecha">
              <DatePicker onChange={onChangeFecha} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Categoria de los Animales">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={
                  idCategoria === 0 ? "SELECCIONE CATEGORIA" : idCategoria
                }
                placeholder="CATEGORIA"
                onChange={updateCategoria}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {categoriasList.map((categoria) => (
                  <Option key={categoria.id} value={categoria.id}>
                    {categoria.ctg_nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="No. de Animales">
              <InputNumber
                style={{
                  width: "100%",
                  textAlign: "right",
                  color: "blue",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={cantidad}
                value={cantidad}
                onChange={onChangeUnd}
                placeholder="Animales"
              />{" "}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Caravana">
              <Input
                placeholder="Caravana"
                defaultValue={caravana}
                style={{ width: "100%" }}
                value={caravana}
                onChange={onChangeCaravana}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Kg x Animal">
              <InputNumber
                style={{
                  width: "100%",
                  textAlign: "right",
                  color: "orangered",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={kilos}
                value={kilos}
                onChange={onChangeKgm}
                placeholder="Kilos"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <TextArea
            rows={2}
            value={nota}
            placeholder={"Observaciones"}
            onChange={onChangeNota}
            allowClear
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => uploadDspWS()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={curStyle.btnSecundary}
            shape="round"
            block
            loading={loadingButton}
          >
            <UploadOutlined /> PROCESAR LA ADICION DE ANIMALES
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
