import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  message,
  Typography,
  Tag,
  Modal as ModalB,
} from "antd";
import Highlighter from "react-highlight-words";

//Iconos y constantes
import {
  SearchOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../Modal";
import CST from "../../../utils/CustomSettings";
import DetalleFactura from "./DetalleFactura";
import DetalleSalida from "./DetalleSalida";
import CreateFactura from "./CreateFactura";
import FacturaDetalle from "./FacturaDetalle";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";
import curStyle from "../../../css/GlobalCmp.module.css";
import { myNumber } from "../../../utils/ArrayFunctions";
import { URL_FILES } from "../../../constants";

//Estilos
import "./SalidasAnm.scss";

export default function FacturasSal(props) {
  const { setIsLoading, myNumber, tipoSal } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [detalleList, setDetalleList] = useState([]);
  const [detalleAvg, setDetalleAvg] = useState({});
  const [totalKg, setTotalKg] = useState(0);
  const [totalFac, setTotalFac] = useState(0);
  const [totalPie, setTotalPie] = useState(0);
  const [totalAnm, setTotalAnm] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [listFacturas, setListFacturas] = useState([]);
  const [stateFac, setStateFac] = useState(Math.random());

  const { confirm } = ModalB;
  const { Title } = Typography;

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const currentSetup = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.utlsetup
  );

  const currentMoneda = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );

  useEffect(() => {
    (async () => {
      let datos = await FRM.getGastos(currentUser, tipoSal);
      if (datos.type && datos.type > 0) {
        setListFacturas(datos.listado);
        setTotalKg(datos.num_rsm);
        setTotalFac(datos.total_rsm);
        setTotalPie(datos.kgenpie);
        setTotalAnm(datos.animales);
      } else {
        showNotification(
          "topRight",
          1,
          "LISTADO DE FACTURAS",
          "No hay FACTURAS REGISTRADAS"
        );
      }
    })();
  }, [stateFac]);

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const getDetalle = async (record, opcion) => {
    let datos = await FRM.getGastos(currentUser, 24, record.fac_numero);
    if (datos.type && datos.type > 0) {
      setDetalleList(datos.listado);
      openModal(record, opcion, "1100px");
    } else {
      showNotification(
        "topRight",
        2,
        "DETALLE SALIDA",
        "No hay Datos registrados"
      );
    }
  };

  const getDetalleSal = async (record, opcion) => {
    let datos = await FRM.getLoteDet(currentUser, record.fac_numero, 3);
    if (datos.type && datos.type > 0) {
      setDetalleList(datos.listado);
      setDetalleAvg(datos.alimento_rsm);
      openModal(record, opcion, "90%");
    } else {
      showNotification(
        "topRight",
        2,
        "DETALLE SALIDA",
        "No hay Datos registrados"
      );
    }
  };

  const openAdjunto = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/gastos/" + record.fac_adjunto;
    window.open(`${url_temp}`);
  };

  const deleRecord = async (record) => {
    if (record.sal_estado !== 0) {
      showNotification(
        "topRight",
        4,
        "ELIMINAR VENTA",
        "LA VENTA YA FUE FACTURADA"
      );
      return false;
    }

    if (record.sal_tipo !== 1) {
      showNotification(
        "topRight",
        4,
        "ELIMINAR SALIDA",
        "SOLO SE PUEDEN ELIMINAR SALIDAS POR VENTA"
      );
      return false;
    }
    confirm({
      title: "EILIMINAR LA VENTA",
      icon: <ExclamationCircleOutlined />,
      okText: "ELIMINAR",
      cancelText: "Cancelar",
      content: "DESEA ELIMINAR LA VENTA?",
      onOk() {
        deleVenta(record);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //Eliminar Ventas
  const deleVenta = async (salida) => {
    setIsLoading(true);
    let record = {
      sal_id: salida.sal_numero,
      usr_id: currentUser.id,
      cmb_id: salida.cmb_org,
      cmp_id: salida.cmp_id,
      sal_cantidad: salida.sal_cantidad,
    };
    var val_gasto = await FRM.sendSalida(currentUser, record, 32);
    if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
      setStateFac(Math.random());
      showNotification("topRight", 4, "ELIMINAR VENTAS", "MOVIMIENTO EXITOSO");
    } else {
      showNotification("topRight", 2, "ELIMINAR VENTAS", val_gasto.message);
    }
    setIsLoading(false);
  };

  //Nuevas funciones de busqueda de columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //Fin de nuevas funciones de busqueda

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          onClick={() => getDetalleSal(record, 5)}
        >
          {record.fac_numero}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "fac_fecha",
      key: "fac_fecha",
    },
    {
      title: "CLIENTE", //10%
      dataIndex: "trc_nombre",
      key: "trc_nombre",
      ...getColumnSearchProps("trc_nombre"),
    },
    {
      title: "REGISTRO",
      dataIndex: "registro",
      key: "registro",
    },
    {
      title: "ANIMALES",
      dataIndex: "fac_animales",
      key: "fac_animales",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="blue" style={{ fontSize: 15 }}>
            {myNumber(1, row.fac_animales)}
          </Tag>
        );
      },
    },
    {
      title: "KG EN PIE",
      dataIndex: "fac_enpie",
      key: "fac_enpie",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="lime" style={{ fontSize: 15 }}>
            {myNumber(1, row.fac_enpie)}
          </Tag>
        );
      },
    },
    {
      title: "KG NETOS",
      dataIndex: "fac_kg_neto",
      key: "fac_kg_neto",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="purple" style={{ fontSize: 15 }}>
            {myNumber(1, row.fac_kg_neto, 2)}
          </Tag>
        );
      },
    },
    {
      title: "$US TOTAL",
      dataIndex: "fac_total",
      key: "fac_total",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 15 }}>
            {myNumber(1, row.fac_total, 2)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => getDetalle(record, 3)}
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="large"
          shape="circle"
          icon={<FilePdfOutlined />}
          onClick={() =>
            record.fac_adjunto
              ? openAdjunto(record)
              : message.error("NO HAY ARCHIVO ADJUNTO", 1)
          }
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnLila}
          size="large"
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() => deleRecord(record)}
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listFacturas}
        style={{ padding: 3, marginTop: 5 }}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={4}>
                  <Title level={4}>{"TOTALES"}</Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Title level={4} style={{ textAlign: "right" }}>
                    {myNumber(1, totalAnm, 2)}
                  </Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Title level={4} style={{ textAlign: "right" }}>
                    {myNumber(1, totalPie, 2)}
                  </Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Title level={4} style={{ textAlign: "right" }}>
                    {myNumber(1, totalKg, 2)}
                  </Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Title level={4} style={{ textAlign: "right" }}>
                    {"$" + myNumber(1, totalFac, 2)}
                  </Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={2}></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={tipoModal === 4 ? 1307 : widthModal}
      >
        {tipoModal === 1 ? (
          <DetalleSalida
            myNumber={myNumber}
            detalleModal={detalleModal}
            detalleList={detalleList}
            totalKg={totalKg}
          />
        ) : tipoModal === 2 ? (
          <CreateFactura
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateFac}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            detalleList={detalleList}
            totalKg={totalKg}
          />
        ) : tipoModal === 3 ? (
          <DetalleFactura
            myNumber={myNumber}
            detalleModal={detalleModal}
            detalleList={detalleList}
          />
        ) : (
          <FacturaDetalle
            myNumber={myNumber}
            detalleModal={detalleModal}
            detalleList={detalleList}
            detalleAvg={detalleAvg}
          />
        )}
      </ModalApp>
    </div>
  );
}
