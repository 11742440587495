import React, { useState, useEffect } from "react";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  DirectionsRenderer,
} from "google-maps-react";

const MapContainer = (props) => {
  const [directions, setDirections] = useState("");

  useEffect(() => {
    (async () => {
      const DirectionsService = new props.google.maps.DirectionsService();
      DirectionsService.route(
        {
          /*
            origin: new props.google.maps.LatLng(41.8507300, -87.6512600),
            destination: new props.google.maps.LatLng(41.8525800, -87.6514100),*/
          origin: "New York, NY",
          destination: "Miami, Fl",
          waypoints: [
            {
              location: "Washington, DC",
              stopover: false,
            },
            {
              location: "Orlando, Fl",
              stopover: true,
            },
          ],
          travelMode: props.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === props.google.maps.DirectionsStatus.OK) {
            setDirections(result);
            console.log(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    })();
  }, []);

  const mapStyles = {
    width: "700px",
    height: "700px",
  };

  const coords = { lat: 40.75615, lng: -73.98505 };

  return (
    <Map
      google={props.google}
      initialCenter={coords}
      className="map"
      style={mapStyles}
      zoom={14}
    >
      <Marker
        name="SOMA"
        draggable={true}
        animation={props.google.maps.Animation.DROP}
        icon={{
          path: props.google.maps.SymbolPath.CIRCLE,
          scale: 10,
        }}
        position={{ lat: 37.778519, lng: -122.40564 }}
        title="The marker`s title will appear as a tooltip."
      />

      <Marker
        name="Dolores park"
        animation={props.google.maps.Animation.DROP}
        position={{ lat: 37.759703, lng: -122.428093 }}
      />

      <Marker animation={props.google.maps.Animation.DROP} />

      {/*directions && <DirectionsRenderer directions={directions} />*/}
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCadN8K3_yCdcm9vjrngpEBHMvdydLPt0E",
})(MapContainer);
