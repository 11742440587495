import React from "react";
import { Form, Row, Col, Divider, Statistic } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

//Styles
import "./CjbGastosFrm.scss";

// componentes
import CST from "../../../../utils/CustomSettings";

export default function DetalleIngreso(props) {
  const {
    currentUser,
    closeModal,
    setStateInf,
    currentSetup,
    myNumber,
    detalleModal,
    currentMoneda,
  } = props;

  const [form] = Form.useForm();

  return (
    <div className="upload-panel">
      <Divider
        style={{
          marginTop: 15,
          paddingTop: 10,
          marginBottom: 20,
          color: CST.orangeBdy,
          fontSize: 20,
        }}
      >
        {detalleModal.min_moneda === 1
          ? "DATOS GENERALES DEL INGRESO EN DOLARES"
          : "DATOS GENERALES DEL INGRESO EN PESOS UYU"}
      </Divider>
      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col span={6}>
            <Statistic
              title="Fecha Emision"
              value={detalleModal.min_fecha}
              prefix={<CalendarOutlined />}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={12}>
            <Statistic
              title="Persona de quien provienen los recursos"
              value={detalleModal.trc_nombre}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Usuario que Registró"
              value={detalleModal.registro}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={24}>
            <Statistic
              title="Concepto Imputado"
              value={detalleModal.cnc_nombre + " - " + detalleModal.cls_nombre}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={24}>
            <Statistic
              title="Detalle del Concepto:"
              value={detalleModal.min_detalle}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={18}>
            <Statistic
              title="Cuenta receptora de los fondos:"
              value={detalleModal.cue_nombre}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Monto Recibido"
              value={
                detalleModal.min_moneda === 1
                  ? currentMoneda.simbolo +
                    myNumber(1, detalleModal.min_importe)
                  : currentMoneda.simbolo_frn +
                    myNumber(1, detalleModal.min_importe)
              }
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={24}>
            <Statistic
              title="Observaciones al Registrar:"
              value={detalleModal.meg_nota ? detalleModal.meg_nota : "..."}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}
