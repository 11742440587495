import React, { useState } from "react";
import {
  Form,
  Button,
  Table,
  Upload,
  Typography,
  Select,
  Input,
  Row,
  Col,
  InputNumber,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  Space,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

//Api para mapas
/*AIzaSyCadN8K3_yCdcm9vjrngpEBHMvdydLPt0E*/

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./CreateAlimento.scss";

// Excel Render
import { ExcelRenderer } from "react-excel-renderer";

// componentes
import FRM from "../../../utils/FrmService";
import { reloadData } from "../../../utils/ReloadEnv";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";
import CST from "../../../utils/CustomSettings";

export default function CreateAlimento(props) {
  const {
    currentUser,
    closeModal,
    setIsLoading,
    myNumber,
    detalleCarga,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [idOrigen, setIdOrigen] = useState(0);
  const [idAlimento, setIdAlimento] = useState(0);
  const [importe, setImporte] = useState("");
  const [cantidad, setCantidad] = useState(0);
  const [kilosHumedos, setKilosHumedos] = useState(0);
  const [kilosSecos, setKilosSecos] = useState(0);
  const [prcConver, setPrcConver] = useState(0);
  const [almKilos, setAlmKilos] = useState(0);
  const [precioHumedo, setPrecioHumedo] = useState(0);
  const [precioSeco, setPrecioSeco] = useState(0);
  const [precioUnd, setPrecioUnd] = useState(0);
  const [nota, setNota] = useState("");
  const [fechaEntr, setFechaEntr] = useState(null);
  const [fileList, setFileList] = useState([]);

  const { Option } = Select;
  const [form] = Form.useForm();

  const alimentoList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.tipoalimento
  );
  const fieldsList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.origenes
  );
  const inventarioAlm = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.inventario_alm
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaEntr(dateString);
  }

  //Se actualiza el nombre
  const updateAlimento = async (value) => {
    setIdAlimento(value);
    getPrcConver(alimentoList, value);
    setKilosHumedos(0);
    setKilosSecos(0);
    setPrecioSeco(0);
    setPrecioHumedo(0);
    setCantidad(0);
    setPrecioUnd(0);
  };

  const onChangeImporte = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setImporte(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeUnd = (value) => {
    if (Number.parseInt(idAlimento) > 0) {
      if (Number.parseFloat(value) >= 0) {
        setCantidad(value);
        setKilosHumedos(value * almKilos);
        setKilosSecos(value * almKilos * prcConver);
        setPrecioSeco(0);
        setPrecioHumedo(0);
        setPrecioUnd(0);
      } else {
        message.error("VALOR INCORRECTO!", 1);
        return false;
      }
    } else {
      message.error("SELECCIONE EL TIPO DE ALIMENTO!", 1);
      return false;
    }
  };

  const onChangeKH = (value) => {
    if (Number.parseInt(idAlimento) > 0) {
      if (Number.parseFloat(value) >= 0) {
        setKilosHumedos(value);
        setCantidad(value / almKilos);
        setKilosSecos(value * prcConver);
        setPrecioSeco(0);
        setPrecioHumedo(0);
      } else {
        message.error("VALOR INCORRECTO!", 1);
        return false;
      }
    } else {
      message.error("SELECCIONE EL TIPO DE ALIMENTO!", 1);
      return false;
    }
  };

  const onChangePH = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setPrecioHumedo(value / almKilos);
      setPrecioSeco(0);
      setImporte((value / almKilos) * kilosHumedos);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangePS = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setPrecioHumedo(0);
      setPrecioSeco(value / almKilos);
      setImporte((value / almKilos) * kilosSecos);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  //Funcion para traer el % de conversion del alimento
  const getPrcConver = async (listaAlm, idFld) => {
    message.info("ACTUALIZANDO FACTORES", 1);
    listaAlm.forEach((arrayValue) => {
      if (Number.parseInt(arrayValue.id) === Number.parseInt(idFld)) {
        setPrcConver(Number.parseFloat(arrayValue.alm_factor));
        setAlmKilos(Number.parseFloat(arrayValue.alm_kilos));
        setKilosHumedos(0);
        setKilosSecos(0);
        setPrecioHumedo(0);
        setPrecioSeco(0);
        setImporte(0);
      }
    });
  };

  // ENVIO A WEBSERVICES
  const uploadDspWS = async () => {
    setLoadingButton(true);
    if (Number.parseInt(idAlimento) === 0 || fechaEntr.length === 0) {
      showNotification(
        "topRight",
        2,
        "SUMINISTRO DE ALIMENTOS",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      let record = {
        tipoMov: 2,
        usr_id: currentUser.id,
        id_cmb: detalleCarga.id,
        cmb_id: detalleCarga.cmb_numero,
        cmp_id: detalleCarga.cmp_id,
        cmb_numero: Number.parseInt(detalleCarga.cmb_animales),
        alm_id: idAlimento,
        alm_importe: importe,
        alm_cantidad: cantidad,
        alm_kilos_h: kilosHumedos,
        alm_factor: prcConver,
        alm_kilos: almKilos,
        alm_kilos_s: kilosSecos,
        alm_precio: precioUnd,
        alm_precio_h: precioHumedo,
        alm_precio_s: precioSeco,
        alm_nota: nota,
        alm_fecha: fechaEntr,
      };
      var val_inventory = await FRM.sendAlimento(currentUser, record);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "SUMINISTRO DE ALIMENTOS",
          "PROCESO DE REGISTRO EXITOSO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "SUMINISTRO DE ALIMENTOS",
          val_inventory.message
        );
      }
    }
    setFileList([]);
    setLoadingButton(false);
    closeModal();
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "TIPO DE ALIMENTO", //10%
      dataIndex: "alm_nombre",
      key: "alm_nombre",
    },
    {
      title: "KG DISPONIBLES", //10%
      dataIndex: "entra",
      key: "alm_entra",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.entra - row.sale);
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"SUMINISTRO DE ALIMENTO EN " + detalleCarga.cmp_nombre}
      </Title>
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Fecha">
              <DatePicker onChange={onChangeFecha} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="Alimento suministrado">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={
                  idAlimento === 0 ? "SELECCIONE TIPO DE ALIMENTO" : idAlimento
                }
                placeholder="ALIMENTO SUMINISTRADO"
                onChange={updateAlimento}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {alimentoList.map((alimento) => (
                  <Option key={alimento.id} value={alimento.id}>
                    {alimento.alm_nombre +
                      " (" +
                      Number.parseFloat(alimento.alm_kilos).toFixed(1) +
                      "Kg x Und)"}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Fardos o UND">
              <InputNumber
                style={{
                  width: "100%",
                  textAlign: "right",
                  color: "orangered",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={cantidad}
                value={cantidad}
                onChange={onChangeUnd}
                placeholder="Fardos"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Kg Humedos">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={kilosHumedos}
                value={kilosHumedos}
                onChange={onChangeKH}
                placeholder="Kilos H"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="% Conversion">
              <Title
                level={4}
                style={{
                  padding: 2,
                  margin: 2,
                  fontWeight: 600,
                  fontSize: 16,
                  textAlign: "right",
                  color: CST.greenBdy,
                }}
              >
                {myNumber(1, prcConver * 100) + "%"}
              </Title>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Kilos Secos">
              <Title
                level={4}
                style={{
                  padding: 2,
                  margin: 2,
                  fontWeight: 600,
                  fontSize: 16,
                  textAlign: "right",
                  color: CST.blueBdy,
                }}
              >
                {myNumber(1, kilosSecos) + "Kg"}
              </Title>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <TextArea
            rows={2}
            value={nota}
            placeholder={"Observaciones"}
            onChange={onChangeNota}
            allowClear
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}>
        Inventario de Alimentos Disponible
      </Divider>
      <Table
        columns={header}
        dataSource={inventarioAlm}
        bordered
        size="middle"
        scroll={{ y: 640 }}
      />
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => uploadDspWS()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={curStyle.btnLila}
            shape="round"
            block
            loading={loadingButton}
          >
            <UploadOutlined /> PROCESAR SUMINSTRO DE ALIMENTO
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
