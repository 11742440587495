import React, { useState, useRef } from "react";
import { Table, Input, Button, Space, message, Tag } from "antd";
import Highlighter from "react-highlight-words";

import Fab from "@material-ui/core/Fab";
import { Add } from "@material-ui/icons";

//Iconos y constantes
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import Icon from "@mdi/react";
import { mdiGrass } from "@mdi/js";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../Modal";
import UploadGanado from "../UploadGanado";
import DetalleEntrada from "../DetalleEntrada";
import EntradaFaltante from "../DetalleEntrada/EntradaFaltante";
import EditEntrada from "../EditEntrada";
import ConfirmEntrada from "../ConfirmEntrada";
import curStyle from "../../../css/GlobalCmp.module.css";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";
import CST from "../../../utils/CustomSettings";

//Estilos
import "./Entradas.scss";

export default function Entradas(props) {
  const { setIsLoading, myNumber } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleCarga, setDetalleCarga] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [detalleList, setDetalleList] = useState([]);
  const [detalleRepeat, setDetalleRepeat] = useState([]);

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );
  const listEntradas = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.entradas
  );

  const entradasDet = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.entradas_det
  );

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleCarga(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const getDetalle = async (record, opcion) => {
    setIsLoading(false);
    let datos = await FRM.getEntradaDet(
      currentUser,
      record.ent_numero,
      opcion === 6 ? 6 : 1
    );
    if (datos.type && datos.type > 0) {
      setDetalleList(datos.listado);
      setDetalleRepeat(datos.repiten);
      openModal(record, opcion, "90%");
    } else {
      showNotification(
        "topRight",
        2,
        "DETALLE DE ENTRADA",
        "No hay detalle disponible"
      );
    }
    setIsLoading(false);
  };

  const getDiferencia = async (record, opcion) => {
    setIsLoading(false);
    let datos = await FRM.getEntradaDet(currentUser, record.ent_numero, 2);
    if (datos.type && datos.type > 0) {
      setDetalleList(datos.listado);
      openModal(record, opcion, "25%");
    } else {
      showNotification(
        "topRight",
        2,
        "CARAVANAS FALTANTES",
        "NO HAY REGISTROS FALTANTES"
      );
    }
    setIsLoading(false);
  };

  //Nuevas funciones de busqueda de columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //Fin de nuevas funciones de busqueda

  //Columnas de la tabla
  const columns = [
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          size="large"
          shape="circle"
          onClick={() => getDetalle(record, 2)}
        >
          {record.ent_numero}
        </Button>
      ),
    },
    {
      title: "LOTE",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSuccess}
          size="large"
          shape="circle"
        >
          {record.cmb_id}
        </Button>
      ),
    },
    {
      title: "FECHA",
      dataIndex: "ent_fecha",
      key: "ent_fecha",
      ...getColumnSearchProps("ent_fecha"),
      width: 150,
    },
    {
      title: "PROVEEDOR",
      dataIndex: "trc_nombre",
      key: "trc_nombre",
      ...getColumnSearchProps("trc_nombre"),
      width: 250,
    },
    {
      title: "ORIGEN",
      dataIndex: "ent_origen",
      key: "ent_origen",
      ...getColumnSearchProps("ent_origen"),
      width: 250,
    },
    {
      title: "DESTINO",
      dataIndex: "cmp_nombre",
      key: "cmp_nombre",
      ...getColumnSearchProps("cmp_nombre"),
      width: 250,
    },
    {
      title: "KILOS",
      dataIndex: "ent_kilos",
      key: "ent_kilos",
      width: 80,
      align: "right",
      render: (text, record) => {
        return myNumber(1, record.ent_kilos);
      },
    },
    {
      title: "DESTARE",
      dataIndex: "ent_destare",
      key: "ent_destare",
      width: 80,
      align: "right",
      ...getColumnSearchProps("ent_destare"),
      render: (text, row, index) => {
        return myNumber(1, row.ent_destare) + "%";
      },
    },
    {
      title: "$ KILO",
      dataIndex: "ent_costo_kg",
      key: "ent_costo_kg",
      width: 80,
      align: "right",
      ...getColumnSearchProps("ent_costo_kg"),
    },
    {
      title: "COMISION",
      dataIndex: "ent_prc_comision",
      key: "ent_prc_comision",
      width: 80,
      align: "right",
      ...getColumnSearchProps("ent_prc_comision"),
      render: (text, row, index) => {
        return (
          myNumber(1, row.trc_comision > 0 ? row.ent_prc_comision : 0) + "%"
        );
      },
    },
    {
      title: "$ TRANSP",
      dataIndex: "ent_transporte",
      key: "ent_transporte",
      width: 80,
      align: "right",
      ...getColumnSearchProps("ent_transporte"),
      render: (text, row, index) => {
        return myNumber(1, row.trc_transporte > 0 ? row.ent_transporte : 0.0);
      },
    },
    {
      title: "ESTADO",
      dataIndex: "ent_estado",
      key: "ent_estado",
      width: 100,
      align: "left",
      render: (text, row, index) => {
        return row.ent_estado === 0 ? (
          <Tag color="green">PENDIENTE</Tag>
        ) : row.ent_estado === 1 ? (
          <Tag color="geekblue">EN LOTE</Tag>
        ) : (
          <Tag color="volcano">CERRADA</Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnWarning}
          size="large"
          shape="circle"
          onClick={() => getDiferencia(record, 7)}
        >
          {record.ent_faltante}
        </Button>
      ),
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDark}
          size="large"
          shape="circle"
          onClick={() =>
            record.ent_estado === 0
              ? getDetalle(record, 6)
              : message.error("EL LOTE YA ESTA CONFIRMADO", 1)
          }
        >
          <Icon path={mdiGrass} size={"20"} />
        </Button>
      ),
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSuccess}
          size="large"
          shape="circle"
          icon={<EditOutlined />}
          onClick={() =>
            record.ent_estado === 1
              ? getDetalle(record, 4)
              : message.error("PENDIENTE CONFIRMAR LOTE", 1)
          }
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listEntradas}
        style={{ padding: 3, marginTop: 5 }}
        scroll={{ x: 1500 }}
      />
      <Fab
        color="primary"
        aria-label="add"
        style={{
          background: CST.blueBdy,
          position: "absolute",
          left: 50,
          bottom: 20,
        }}
        onClick={() => openModal(null, 1, "90%")}
      >
        <Add />
      </Fab>
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 1 ? (
          <UploadGanado
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
          />
        ) : tipoModal === 2 ? (
          <DetalleEntrada
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            opcionCarga={2}
            detalleList={detalleList}
          />
        ) : tipoModal === 3 ? (
          <DetalleEntrada
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            opcionCarga={3}
            detalleList={detalleList}
          />
        ) : tipoModal === 4 ? (
          <EditEntrada
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            opcionCarga={3}
            detalleList={detalleList}
          />
        ) : tipoModal === 6 ? (
          <ConfirmEntrada
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            opcionCarga={4}
            detalleList={detalleList}
            detalleRepeat={detalleRepeat}
          />
        ) : (
          <EntradaFaltante
            currentUser={currentUser}
            closeModal={closeModal}
            detalleCarga={detalleCarga}
            opcionCarga={3}
            detalleList={detalleList}
          />
        )}
      </ModalApp>
    </div>
  );
}
