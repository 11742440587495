import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  Typography,
  Tag,
  message,
  Spin,
  Row,
  Col,
} from "antd";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router-dom";

//currentComponent Action
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentComponentAction } from "../../../actions/currentComponentActions";

//Floating Buttons
import FloatingButtons from "react-floating-buttons";
import weight from "../../../assets/icons/weight-kilogram.svg";
import download from "../../../assets/icons/cloud-sync-outline.svg";
import syncr from "../../../assets/icons/archive-sync-outline.svg";
import refresh from "../../../assets/icons/refresh.svg";
import mapMark from "../../../assets/icons/map-marker-plus-outline.svg";
import report from "../../../assets/icons/chart-areaspline.svg";
import swapHrzl from "../../../assets/icons/swap-horizontal.svg";
import cowSvg from "../../../assets/icons/cow.svg";

//Iconos y constantes
import { SearchOutlined } from "@ant-design/icons";

//Bacground y Logo
import bg3 from "../../../assets/img/bg-3.jpg";
import logo from "../../../assets/img/logo-3.png";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../Modal";
import CST from "../../../utils/CustomSettings";
import CreateCatChg from "../CreateCatChg";
import CreateAlimento from "../CreateAlimento";
import UpdatePeso from "../UpdatePeso";
import SyncSnig from "../SyncSnig";
import PointsMap from "../../ShowMaps/PointsMap";
import DetalleAnimal from "./DetalleAnimal";
import UpdateCaravana from "./UpdateCaravana";

//Importaciones y componentes propios
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";

//Estilos
import "./Inventario.scss";
import { myNumber } from "../../../utils/ArrayFunctions";

export default function Inventario(props) {
  const { setIsLoading, nombreMes, gstResumen, downData } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [puntos, setPuntos] = useState([]);
  const [latCen, setLatCen] = useState("");
  const [lngCen, setLngCen] = useState("");
  const [stateId, setStateId] = useState(0);
  const [detalleAnimal, setDetalleAnimal] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [listAnimales, setListAnimales] = useState([]);
  const [listActivos, setListActivos] = useState([]);
  const [listCampos, setListCampos] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [detalleField, setDetalleField] = useState([]);
  const [detalleSale, setDetalleSale] = useState([]);
  const [detalleCome, setDetalleCome] = useState([]);
  const [detallePeso, setDetallePeso] = useState([]);

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const updateCurrentComponent = (state) =>
    dispatch(updateCurrentComponentAction(state));


  useEffect(() => {
    (async () => {
      setIsLoad(true);
      let datos = await FRM.getInventario(currentUser, 0, 0, null);
      if (datos.type && datos.type > 0) {
        setListAnimales(datos.listado);
        setListCampos(datos.lstcampos);
        filtrarActivos(datos.listado);
      } else {
        showNotification(
          "topRight",
          2,
          "INVENTARIO",
          "No se encontraron datos"
        );
      }
      setIsLoad(false);
    })();
  }, [gstResumen]);

  const getDetalle = async (record, opcion) => {
    let datos = await FRM.getInventario(currentUser, record.id, 0, null);
    if (datos.type && datos.type > 0) {
      setDetalleField(datos.listado);
      setDetalleSale(datos.sale);
      setDetalleCome(datos.alimento);
      setDetallePeso(datos.pesos);
      openModal(record, opcion, "1000px");
    } else {
      showNotification(
        "topRight",
        2,
        "VER FICHA ANIMAL",
        "No se encontraron datos"
      );
    }
  };

  //Funcion para filtrar animales activos
  const filtrarActivos = (array) => {
    const newActivos = array.filter((animales) => {
      return Number.parseInt(animales.anm_estado) === 0;
    });
    setListActivos(newActivos);
  };

  //const { confirm } = Modal;
  const { Title } = Typography;

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleAnimal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  //Funcion para abrir la ruta de reportes
  const openReports = async (cmpName, cmpTitle) => {
    let curComp = {
      opcion: cmpName,
      titulo: cmpTitle,
    };
    updateCurrentComponent(curComp);
    history.push("/informesfrm");
  }
  //Fin apertura ruta de reportes

  //Nuevas funciones de busqueda de columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //Fin de nuevas funciones de busqueda

  //Definicion de los floating
  const buttonsList = [
    {
      onClick: () => openReports('FrmStockGerencial', 'INFORMES DE STOCK GERENCIAL'),
      src: cowSvg,
    },
    {
      onClick: () => openReports('FrmGestionGral', 'INFORMES DE GESTION GENERAL'),
      src: report,
    },
    {
      onClick: () => openModal(null, 6, "900px"),
      src: swapHrzl,
    },
    {
      onClick: () => createPuntos(),
      src: mapMark,
    },
    {
      onClick: () => openModal(null, 3, "900px"),
      src: weight,
    },
    {
      onClick: () => openModal(null, 5, "900px"),
      src: syncr,
    },
    {
      onClick: () => openModal(null, 1, "900px"),
      src: refresh,
    },
  ];
  //Fin floating

  //Columnas de la tabla
  const columns = [
    {
      title: "CARAVANA", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
      ...getColumnSearchProps("anm_codigo"),
    },
    {
      title: "SNIG",
      dataIndex: "val_snig",
      key: "val_snig",
      align: "left",
      ...getColumnSearchProps("val_snig"),
      render: (text, row, index) => {
        return row.anm_snig === 0 ? (
          <Tag color="cyan">{row.val_snig}</Tag>
        ) : (
          <Tag color="gold">{row.val_snig}</Tag>
        );
      },
    },
    {
      title: "ESTADO",
      dataIndex: "anm_estado_nm",
      key: "anm_estado_nm",
      align: "left",
      ...getColumnSearchProps("anm_estado_nm"),
      render: (text, row, index) => {
        return row.anm_estado === 0 ? (
          <Tag color="blue">{row.anm_estado_nm}</Tag>
        ) : row.anm_estado === 1 ? (
          <Tag color="green">{row.anm_estado_nm}</Tag>
        ) : (
          <Tag color="red">{row.anm_estado_nm}</Tag>
        );
      },
    },
    {
      title: "UBICACION",
      dataIndex: "anm_ubica",
      key: "anm_ubica",
      ...getColumnSearchProps("anm_ubica"),
    },
    {
      title: "RAZA",
      dataIndex: "rza_nombre",
      key: "rza_nombre",
      ...getColumnSearchProps("rza_nombre"),
    },
    {
      title: "SEXO",
      dataIndex: "anm_sexo",
      key: "anm_sexo",
      render: (text, row, index) => {
        if (row.anm_sexo === 1) {
          return "MACHO";
        } else {
          return "HEMBRA";
        }
      },
    },
    {
      title: "CATEGORIA",
      dataIndex: "ctg_nombre",
      key: "ctg_nombre",
      ...getColumnSearchProps("ctg_nombre"),
    },
    {
      title: "A FEEDLOT",
      dataIndex: "anm_fecha_alimento",
      key: "anm_fecha_alimento",
      ...getColumnSearchProps("anm_fecha_alimento"),
      render: (text, row, index) => {
        return (
          <Tag color="geekblue">{row.anm_fecha_alimento}</Tag>
        );
      },
    },
    {
      title: "PESO ENTRA",
      dataIndex: "anm_peso_alimento",
      key: "anm_peso_alimento",
      align: "right",
    },
    {
      title: "F. PESAJE",
      dataIndex: "anm_fecha_peso",
      key: "anm_fecha_peso",
      ...getColumnSearchProps("anm_fecha_peso"),
      render: (text, row, index) => {
        return (
          <Tag color="gold">{row.anm_fecha_peso}</Tag>
        );
      },
    },
    {
      title: "PESO ACT",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      align: "right",
      sorter: (a, b) => a.anm_peso_neto - b.anm_peso_neto,
    },
    {
      title: "KG GEN",
      dataIndex: "anm_kilos_gen",
      key: "anm_kilos_gen",
      align: "right",
      sorter: (a, b) => a.anm_kilos_gen - b.anm_kilos_gen,
      render: (text, row, index) => {
        return (
          row.anm_peso_alimento > 0 ? (
            <Tag color="blue">{myNumber(
              1,
              Number.parseFloat(row.anm_kilos_gen))}
            </Tag>
          ) : (
            <Tag color="gold">{myNumber(
              1,
              Number.parseFloat(0))}
            </Tag>
          )
        );
      },
    },
    {
      title: "DIAS",
      dataIndex: "anm_dias_peso",
      key: "anm_dias_peso",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue">{myNumber(
            1,
            Number.parseInt(row.anm_dias_peso))}
          </Tag>);
      },
    },
    {
      title: "Kg/Dia",
      dataIndex: "anm_kilos_dia",
      key: "anm_kilos_dia",
      align: "right",
      sorter: (a, b) => a.anm_kilos_dia - b.anm_kilos_dia,
      render: (text, row, index) => {
        return (
          row.anm_peso_alimento > 0 ? (
            <Tag color="volcano">{myNumber(
              1,
              Number.parseFloat(row.anm_kilos_dia), 2)}
            </Tag>
          ) : (
            <Tag color="volcano">{myNumber(
              1,
              Number.parseFloat(0))}
            </Tag>
          )
        );
      },
    },
    {
      title: "KG-SEC",
      dataIndex: "anm_kilos_peso",
      key: "anm_kilos_peso",
      align: "right",
      sorter: (a, b) => a.anm_kilos_peso - b.anm_kilos_peso,
      render: (text, row, index) => {
        return (
          <Tag color="magenta">{myNumber(
            1,
            Number.parseFloat(row.anm_kilos_peso))}
          </Tag>);
      },
    },
    {
      title: "CONV.",
      dataIndex: "anm_conversion",
      key: "anm_conversion",
      align: "right",
      sorter: (a, b) => a.anm_conversion - b.anm_conversion,
      render: (text, row, index) => {
        return (row.anm_dias_peso > 0 ? (
          <Tag color="purple">{myNumber(
            1,
            Number.parseFloat(row.anm_conversion))}
          </Tag>
        ) : (
          <Tag color="#f50">{0}
          </Tag>
        )
        );
      },
    },
    {
      title: "$ ALM",
      dataIndex: "anm_alimento",
      key: "anm_alimento",
      align: "right",
      sorter: (a, b) => a.anm_alimento - b.anm_alimento,
      render: (text, row, index) => {
        return (
          <Tag color="cyan">{myNumber(
            1,
            Number.parseFloat(row.anm_alimento))}
          </Tag>);
      },
    },
    {
      title: "+",
      key: "action",
      render: (text, record) => (
        <Button
          type="primary"
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => getDetalle(record, 2)}
        />
      ),
    },
  ];

  //Funcion para crear los puntos del Mapa con los campos
  const createPuntos = async () => {
    const new_array = [];
    listCampos.forEach((def) => {
      if (
        Number.parseFloat(def.cmp_latitud) < 0 &&
        Number.parseFloat(def.cmp_longitud) < 0
      ) {
        let val_contenido =
          def.cmp_nombre +
          " - " +
          def.cmp_ubicacion +
          " (" +
          def.cmb_animales +
          " Animales)<br/>Alimento: " +
          myNumber(1, Number.parseFloat(def.cmb_alimento)) +
          " - Otros Gastos: " +
          myNumber(1, Number.parseFloat(def.cmb_otros));
        let newdata = {
          title: def.cmb_numero,
          desc: "",
          url: "https://aal-team.com",
          coordinates: {
            lat: def.cmp_latitud,
            lng: def.cmp_longitud,
          },
          id: def.id,
          content: "<div>" + val_contenido + "</div>",
        };
        new_array.push(newdata);
        setLatCen(def.cmp_latitud);
        setLngCen(def.cmp_longitud);
      }
    });
    setPuntos(new_array);
    openModal(null, 4);
  };

  //Renderizacion del componente
  return (
    <div className="main-content">
      {isLoad ? (
        <div
          className="loading"
          style={{
            backgroundImage: `url('${bg3}')`,
            height: "100%",
            backgroundSize: "cover",
            width: "100vw",
          }}
        >
          <Row>
            <Col span={24} style={{ alignItems: "center" }}>
              <img
                src={logo}
                alt="imagen"
                style={{
                  marginBottom: "20px",
                  marginTop: window.innerHeight * 0.2,
                  width: window.innerWidth * 0.2,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ alignItems: "center" }}>
              <Spin size="large" />
            </Col>
          </Row>
        </div>
      ) : (
        <div id="main" style={{ width: '100vw', overflow: 'scroll', borderWidth: 2, borderColor: 'red' }}>
          <Table
            columns={columns}
            dataSource={listAnimales}
            style={{ padding: 3, marginTop: 5 }}
          />

          <ModalApp
            isVisibleModal={isVisibleModal}
            closeModal={closeModal}
            widthModal={tipoModal === 4 ? 1307 : widthModal}
          >
            {tipoModal === 1 ? (
              <CreateCatChg
                currentUser={currentUser}
                closeModal={closeModal}
                setIsLoading={setIsLoading}
                myNumber={myNumber}
              />
            ) : tipoModal === 2 ? (
              <DetalleAnimal
                currentUser={currentUser}
                closeModal={closeModal}
                detalleAnimal={detalleAnimal}
                detalleField={detalleField}
                detalleSale={detalleSale}
                detalleVacuna={[]}
                detalleDespa={[]}
                detalleCome={detalleCome}
                detallePeso={detallePeso}
                myNumber={myNumber}
              />
            ) : tipoModal === 3 ? (
              <UpdatePeso
                currentUser={currentUser}
                closeModal={closeModal}
                setIsLoading={setIsLoading}
                myNumber={myNumber}
              />
            ) : tipoModal === 4 ? (
              <PointsMap puntos={puntos} latCen={latCen} lngCen={lngCen} />
            ) : tipoModal === 5 ? (
              <SyncSnig
                currentUser={currentUser}
                closeModal={closeModal}
                myNumber={myNumber}
                listActivos={listActivos}
                setListAnimales={setListAnimales}
                setListCampos={setListCampos}
                filtrarActivos={filtrarActivos}
              />
            ) : (
              <UpdateCaravana
                currentUser={currentUser}
                closeModal={closeModal}
                setIsLoading={setIsLoading}
                myNumber={myNumber}
              />
            )}
          </ModalApp>
          <FloatingButtons
            buttonType="plus"
            buttonColor={"#ffffff"}
            backgroundColor={CST.lilaBdy}
            dimension={50}
            buttonsList={buttonsList}
            top={"calc(90% - 45px)"}
            left={"120px"}
            direction="circular"
            degree={-270}
            distance={70}
          />
        </div>
      )}
    </div>
  );
}
