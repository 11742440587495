import { showNotification } from "./ShowNotification";
const URL_USER = "https://api.aal-erp.com/frm/api";

class FRM {
  //Datos de entorno
  getEnviroment(user) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/1`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Detalle entrada
  getEntradaDet(user, ent_id, tipo = 1) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    body.append("ent_id", ent_id);
    body.append("tipoMov", tipo);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/7`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Detalle Cambio de Lote
  getLoteDet(user, cmb_id, tipo) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    body.append("cmb_id", cmb_id);
    body.append("tipoMov", tipo);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/11`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Detalle Desparasitado
  getDesparaDet(user, dsp_id) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    body.append("dsp_id", dsp_id);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/12`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Detalle Desparasitado
  getVacunaDet(user, vcn_id) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    body.append("vcn_id", vcn_id);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/13`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Datos de entorno
  getInventario(user, idAnm = 0, idOpt, record) {
    let newRcrd = record ? JSON.stringify(record) : null;
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    body.append("anm_id", idAnm);
    body.append("tipoInfo", idOpt);
    body.append("record", newRcrd);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/8`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Datos de entorno
  getGestionGrc(user, record) {
    let newRcrd = record ? JSON.stringify(record) : null;
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    body.append("record", newRcrd);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/35`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Maestros
  sendMaestro(user, id, maestro, record) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("id", id);
    uploadData.append("maestro", maestro);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/2`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Maestros
  sendTipoAct(user, id, fuente, icono, maestro, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("id", id);
    uploadData.append("fuente", fuente);
    uploadData.append("icono", icono);
    uploadData.append("maestro", maestro);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/2`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Maestros Caja y Cuentas
  sendCjbCuentas(user, id, moneda, agrupa, maestro, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("id", id);
    uploadData.append("moneda", moneda);
    uploadData.append("agrupa", agrupa);
    uploadData.append("maestro", maestro);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/2`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Maestros Caja y Cuentas
  sendCjbConceptos(
    user,
    id,
    cncClase,
    afectautilidad,
    maestro,
    record,
    cncDiv
  ) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("id", id);
    uploadData.append("cncclase", cncClase);
    uploadData.append("afectautilidad", afectautilidad);
    uploadData.append("cncDiv", cncDiv);
    uploadData.append("maestro", maestro);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/2`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  sendGstConceptos(user, id, activo, maestro, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("id", id);
    uploadData.append("activo", activo);
    uploadData.append("maestro", maestro);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/2`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Caravana
  sendEntrada(user, record) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/3`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Caravana
  sendAdicion(user, record) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/30`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Descargar Diferencias
  descargarDif(user, record) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/4`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Descartar la Entrada
  updateCarga(user, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/5`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Confirmar la Entrada al Lote
  confirmEntra(user, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/21`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Confirmar la Entrada al Lote
  depurarEntra(user, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/29`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Cerrar la Entrada
  confirmCarga(user, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/6`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Detalle de animales en campo
  getCampoDet(user, cmp_id, cmb_id = 0) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    body.append("cmp_id", cmp_id);
    body.append("cmb_id", cmb_id);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/9`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Cambio de Lote
  sendLote(user, record) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/10`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Salida de Animales
  sendSalida(user, record, opcion) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/${opcion}`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Confirmar Cambio de Lote
  confirmLote(user, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/14`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Obtener los gastos de un Movimiento
  getGastosMov(user, objeto) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(objeto));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/15`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Gastos
  sendGasto(user, record) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/16`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de DESPARASITADO
  sendDespara(user, record) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/17`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de DESPARASITADO
  sendVacuna(user, record) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/18`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  // Envio de Cambio de Categoria
  sendCambioCat(user, record) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/19`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  sendAlimento(user, record) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/20`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  sendActividad(user, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/22`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Obtener agenda
  getAgenda(user, tipo, cmbId = 0) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    body.append("usr_id", user.id);
    body.append("usr_tipo", user.tipo);
    body.append("tipoAgenda", tipo);
    body.append("cmb_id", cmbId);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/23`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  sendInforme(user, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/24`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Obtener gastos
  getGastos(user, tipo, trcId = 0, gstMn = 1) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    body.append("usr_id", user.id);
    body.append("usr_tipo", user.tipo);
    body.append("tipoGastos", tipo);
    body.append("trc_id", trcId);
    body.append("tipoMoneda", gstMn);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/25`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Obtener gastos
  getPagos(user, tipo, pgoId = 0, gstMn = 1) {
    let body = new FormData();
    body.append("dbName", user.dbname);
    body.append("id_mobile", user.id_mobile);
    body.append("wservice", user.wservice);
    body.append("usr_id", user.id);
    body.append("usr_tipo", user.tipo);
    body.append("tipoGastos", tipo);
    body.append("pgo_id", pgoId);
    body.append("tipoMoneda", gstMn);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/31`, {
      method: "POST",
      body,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Obtener Movimientos de Caja
  getMovCaja(user, record) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/26`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  sendFactura(user, record) {
    console.log(record);
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("record", JSON.stringify(record));
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/33`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }

  //Obtener Detalles Tiles
  getTilesDet(user, opcion) {
    let uploadData = new FormData();
    uploadData.append("dbName", user.dbname);
    uploadData.append("id_mobile", user.id_mobile);
    uploadData.append("wservice", user.wservice);
    uploadData.append("tipoInf", opcion);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return fetch(`${URL_USER}/34`, {
      method: "POST",
      body: uploadData,
      headers,
    })
      .then((res) => res.json())
      .catch((error) =>
        showNotification("topRight", 2, "Error Inesperado", error)
      )
      .then((response) => response);
  }
}

export default new FRM();
