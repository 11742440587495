import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  message,
  Typography,
  Tag,
  Modal as ModalB,
} from "antd";
import Highlighter from "react-highlight-words";

//Floating Buttons
import Fab from "@material-ui/core/Fab";
import { Add } from "@material-ui/icons";

//Iconos y constantes
import {
  SearchOutlined,
  DollarOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../Modal";
import CST from "../../../utils/CustomSettings";
import DetalleSalida from "./DetalleSalida";
import CreateFactura from "./CreateFactura";
import CreateFacturas from "./CreateFacturas";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";
import curStyle from "../../../css/GlobalCmp.module.css";
import { myNumber } from "../../../utils/ArrayFunctions";
import { URL_FILES } from "../../../constants";

//Estilos
import "./SalidasAnm.scss";

export default function SalidasAnm(props) {
  const { setIsLoading, myNumber, tipoSal } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [detalleList, setDetalleList] = useState([]);
  const [totalKg, setTotalKg] = useState(0);
  const [totalAnm, setTotalAnm] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [listSalidas, setListSalidas] = useState([]);
  const [selectSalidas, setSelectSalidas] = useState([]);
  const [stateInf, setStateInf] = useState(Math.random());
  const [trcId, setTrcId] = useState(0);
  const [trcName, setTrcName] = useState("");
  const [numTrc, setNumTrc] = useState(0);
  const [kilosVnd, setKilosVnd] = useState(0);

  const { confirm } = ModalB;
  const { Title } = Typography;

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const currentSetup = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.utlsetup
  );

  const currentMoneda = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );

  useEffect(() => {
    (async () => {
      let datos = await FRM.getGastos(currentUser, tipoSal);
      if (datos.type && datos.type > 0) {
        setListSalidas(datos.listado);
        setKilosVnd(datos.total_rsm);
      } else {
        showNotification(
          "topRight",
          1,
          "LISTADO DE SALIDAS",
          "No hay SALIDAS REGISTRADAS"
        );
      }
    })();
  }, [stateInf]);

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const getDetalle = async (record, opcion) => {
    let datos = await FRM.getLoteDet(currentUser, record.sal_numero, 2);
    if (datos.type && datos.type > 0) {
      setDetalleList(datos.listado);
      setTotalKg(datos.alimento_rsm);
      openModal(record, opcion, opcion === 2 ? "1100px" : "1000px");
    } else {
      showNotification(
        "topRight",
        2,
        "DETALLE SALIDA",
        "No hay Datos registrados"
      );
    }
  };

  const deleRecord = async (record) => {
    if (record.sal_estado !== 0) {
      showNotification(
        "topRight",
        4,
        "ELIMINAR VENTA",
        "LA VENTA YA FUE FACTURADA"
      );
      return false;
    }

    if (record.sal_tipo !== 1) {
      showNotification(
        "topRight",
        4,
        "ELIMINAR SALIDA",
        "SOLO SE PUEDEN ELIMINAR SALIDAS POR VENTA"
      );
      return false;
    }
    confirm({
      title: "EILIMINAR LA VENTA",
      icon: <ExclamationCircleOutlined />,
      okText: "ELIMINAR",
      cancelText: "Cancelar",
      content: "DESEA ELIMINAR LA VENTA?",
      onOk() {
        deleVenta(record);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //Eliminar Ventas
  const deleVenta = async (salida) => {
    setIsLoading(true);
    let record = {
      sal_id: salida.sal_numero,
      usr_id: currentUser.id,
      cmb_id: salida.cmb_org,
      cmp_id: salida.cmp_id,
      sal_cantidad: salida.sal_cantidad,
    };
    var val_gasto = await FRM.sendSalida(currentUser, record, 32);
    if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
      setStateInf(Math.random());
      showNotification("topRight", 4, "ELIMINAR VENTAS", "MOVIMIENTO EXITOSO");
    } else {
      showNotification("topRight", 2, "ELIMINAR VENTAS", val_gasto.message);
    }
    setIsLoading(false);
  };

  //Funcion para seleccionar las salidas a facturar
  const selectSalida = (record) => {
    const newDet = [];
    listSalidas.forEach((row) => {
      if (
        Number.parseInt(row.sal_numero) === Number.parseInt(record.sal_numero)
      ) {
        let item = {
          id: row.id,
          sal_numero: row.sal_numero,
          sal_fecha: row.sal_fecha,
          sal_nota: row.sal_nota,
          cmb_org: row.cmb_org,
          cmp_id: row.cmp_id,
          sal_cantidad: row.sal_cantidad,
          mtm_id: row.mtm_id,
          trc_id: row.trc_id,
          usr_id: row.usr_id,
          sal_tipo: row.sal_tipo,
          sal_estado: row.sal_estado,
          fac_id: row.fac_id,
          sal_ffactura: row.sal_ffactura,
          usr_fac: row.usr_fac,
          sal_nota_anula: row.sal_nota_anula,
          usr_anula: row.usr_anula,
          sal_fanula: row.sal_fanula,
          trc_nombre: row.trc_nombre,
          mtm_nombre: row.mtm_nombre,
          registro: row.registro,
          cmp_nombre: row.cmp_nombre,
          prefac: Number.parseInt(row.prefac) === 0 ? 1 : 0,
          sal_kilos: row.sal_kilos,
        };
        newDet.push(item);
      } else {
        newDet.push(row);
      }
    });
    setListSalidas(newDet);
    message.success("SALIDA PROCESADA", 1);
  };

  //Funcion para seleccionar las salidas a facturar
  const crearFactura = async () => {
    const newDet = [];
    var totKg = 0;
    var totAnm = 0;
    var numTer = 0;
    var trcAnt = 0;
    listSalidas.forEach((row) => {
      if (Number.parseInt(row.prefac) === 1) {
        newDet.push(row);
        totKg += Number.parseFloat(row.sal_kilos);
        totAnm += Number.parseInt(row.sal_cantidad);
        if (numTer === 0) {
          numTer = 1;
          trcAnt = row.trc_id;
          setTrcId(row.trc_id);
          setTrcName(row.trc_nombre);
        } else {
          numTer +=
            Number.parseInt(trcAnt) === Number.parseInt(row.trc_id) ? 0 : 1;
        }
      }
    });

    if (numTer > 1) {
      showNotification(
        "topRight",
        2,
        "CREAR FACTURA",
        "LAS SALIDAS DEBEN SER DEL MISMO CLIENTE... VERIFIQUE"
      );
      return false;
    } else {
      if (totKg === 0) {
        showNotification(
          "topRight",
          2,
          "CREAR FACTURA",
          "NO HAY DATOS PARA FACTURAR... VERIFIQUE"
        );
        return false;
      } else {
        setSelectSalidas(newDet);
        setTotalKg(totKg);
        setTotalAnm(totAnm);
        setNumTrc(numTer);
        openModal(newDet, 1, "1100px");
      }
    }
  };

  //Nuevas funciones de busqueda de columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //Fin de nuevas funciones de busqueda

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={record.prefac === 0 ? curStyle.btnDanger : curStyle.btnSky}
          size="large"
          shape="circle"
          onClick={() => selectSalida(record)}
        >
          {record.sal_numero}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "sal_fecha",
      key: "sal_fecha",
    },
    {
      title: "COMPRADOR", //10%
      dataIndex: "trc_nombre",
      key: "trc_nombre",
      ...getColumnSearchProps("trc_nombre"),
    },
    {
      title: "SALIDA DESDE",
      dataIndex: "cmp_nombre",
      key: "cmp_nombre",
      ...getColumnSearchProps("mtm_nombre"),
    },
    {
      title: "REGISTRO",
      dataIndex: "registro",
      key: "registro",
    },
    {
      title: "ANIMALES",
      dataIndex: "sal_cantidad",
      key: "sal_cantidad",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="green" style={{ fontSize: 15 }}>
            {myNumber(1, row.sal_cantidad)}
          </Tag>
        );
      },
    },
    {
      title: "TOTAL KG",
      dataIndex: "sal_kilos",
      key: "sal_kilos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 15 }}>
            {myNumber(1, row.sal_kilos)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => getDetalle(record, 3)}
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSuccess}
          size="large"
          shape="circle"
          icon={<DollarOutlined />}
          onClick={() => openModal(record, 2, "1100px")}
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnLila}
          size="large"
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() => deleRecord(record)}
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listSalidas}
        style={{ padding: 3, marginTop: 5 }}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={6}>
                  <Title level={4}>{"TOTAL KILOS VENDIDOS"}</Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Title level={4} style={{ textAlign: "right" }}>
                    {myNumber(1, kilosVnd, 2)}
                  </Title>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <Fab
        color="primary"
        aria-label="add"
        style={{
          background: CST.blueBdy,
          position: "absolute",
          left: 50,
          bottom: 20,
        }}
        onClick={() => crearFactura()}
      >
        <Add />
      </Fab>
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={tipoModal === 4 ? 1307 : widthModal}
      >
        {tipoModal === 1 ? (
          <CreateFacturas
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            totalKg={totalKg}
            totalAnm={totalAnm}
            trcId={trcId}
            trcName={trcName}
          />
        ) : tipoModal === 2 ? (
          <CreateFactura
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            totalKg={detalleModal.sal_kilos}
          />
        ) : (
          <DetalleSalida
            myNumber={myNumber}
            detalleModal={detalleModal}
            detalleList={detalleList}
            totalKg={totalKg}
          />
        )}
      </ModalApp>
    </div>
  );
}
