import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  message,
  Typography,
  Tag,
  Modal as ModalB,
} from "antd";
import Highlighter from "react-highlight-words";

//Floating Buttons
import FloatingButtons from "react-floating-buttons";
import moneyl from "../../../../assets/icons/clipboard-list.svg";
import moneyf from "../../../../assets/icons/clipboard-list-outline.svg";

//Iconos y constantes
import {
  SearchOutlined,
  FilePdfOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  DollarOutlined,
} from "@ant-design/icons";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../../Modal";
import CST from "../../../../utils/CustomSettings";
import CjbGastosOrd from "./CjbGastosOrd";
import DetalleNcr from "./DetalleNcr";
import CjbCreatePago from "./CjbCreatePago";
import DetalleCxP from "./DetalleCxP";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../../utils/ShowNotification";
import FRM from "../../../../utils/FrmService";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { myNumber } from "../../../../utils/ArrayFunctions";
import { URL_FILES } from "../../../../constants";

//Estilos
import "./CjbGastosFrm.scss";

export default function CjbCuentasxP(props) {
  const { setIsLoading, tipoGastos } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [listClientes, setListClientes] = useState([]);
  const [stateInf, setStateInf] = useState(Math.random());
  const [detalleList, setDetalleList] = useState([]);
  const [totalLcl, setTotalLcl] = useState(0);
  const [totalFrn, setTotalFrn] = useState(0);

  const { confirm } = ModalB;
  const { Title, Text } = Typography;

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const currentSetup = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.utlsetup
  );

  const currentMoneda = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      let datos = await FRM.getGastos(currentUser, tipoGastos, 0, 0);
      if (datos.type && datos.type > 0) {
        setListClientes(datos.listado);
        setTotalLcl(datos.total_rsm);
        setTotalFrn(datos.num_rsm);
      } else {
        showNotification(
          "topRight",
          1,
          "CUENTAS POR PAGAR",
          "No hay NOTAS REGISTRADAS"
        );
      }
      setIsLoading(false);
    })();
  }, [stateInf]);

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const getDetalle = async (record, opcion) => {
    let datos = await FRM.getGastos(
      currentUser,
      tipoGastos,
      record.trc_id,
      opcion
    );
    if (datos.type && datos.type > 0) {
      setDetalleList(datos.listado);
      openModal(record, opcion, "900px");
    } else {
      showNotification(
        "topRight",
        1,
        "DETALLE CUENTA X PAGAR ",
        "No hay SE ENCONTRARON DATOS"
      );
    }
  };

  //Nuevas funciones de busqueda de columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //Fin de nuevas funciones de busqueda

  //Definicion de los floating
  const buttonsList = [
    {
      onClick: () => openModal(null, 1, "900px"),
      src: moneyl,
    },
    {
      onClick: () => openModal(null, 2, "900px"),
      src: moneyf,
    },
  ];
  //Fin floating

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "50",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnLila}
          size="large"
          shape="circle"
        >
          {record.trc_id}
        </Button>
      ),
    },
    {
      title: "NOMBRE", //10%
      dataIndex: "trc_nombre",
      key: "trc_nombre",
      width: 250,
      ...getColumnSearchProps("trc_nombre"),
    },
    {
      title: "CONTACTO", //10%
      dataIndex: "trc_contacto",
      key: "trc_contacto",
      width: 250,
      ...getColumnSearchProps("trc_contacto"),
    },
    {
      title: "EMAIL",
      dataIndex: "trc_email",
      key: "trc_email",
      width: 250,
      ...getColumnSearchProps("trc_email"),
    },
    {
      title: "DB $",
      dataIndex: "saldo_lcl",
      key: "saldo_lcl",
      align: "right",
      width: 120,
      render: (text, row, index) => {
        return (
          <Tag color="green" style={{ fontSize: 15 }}>
            {myNumber(1, row.saldo_lcl)}
          </Tag>
        );
      },
    },
    {
      title: "CRD $",
      dataIndex: "pendiente_lcl",
      key: "pendiente_lcl",
      align: "right",
      width: 120,
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 15 }}>
            {myNumber(1, row.pendiente_lcl)}
          </Tag>
        );
      },
    },
    {
      title: "SALDO $",
      dataIndex: "pendiente_lcl",
      key: "pendiente_lcl",
      align: "right",
      width: 120,
      render: (text, row, index) => {
        return (
          <Tag color="purple" style={{ fontSize: 15 }}>
            {myNumber(1, row.saldo_lcl - row.pendiente_lcl)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: 40,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => getDetalle(record, 1, "900px")}
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: 40,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSuccess}
          size="large"
          shape="circle"
          icon={<DollarOutlined />}
          onClick={() =>
            Number.parseFloat(record.saldo_lcl) -
              Number.parseFloat(record.pendiente_lcl) >
              0
              ? openModal(record, 3, "900px")
              : message.error("NO HAY SALDO POR PAGAR", 1)
          }
        />
      ),
    },
    {
      title: "DB UYU$",
      dataIndex: "saldo_frn",
      key: "saldo_frn",
      align: "right",
      width: 120,
      render: (text, row, index) => {
        return (
          <Tag color="orange" style={{ fontSize: 15 }}>
            {myNumber(1, row.saldo_frn)}
          </Tag>
        );
      },
    },
    {
      title: "CRD UYU$",
      dataIndex: "pendiente_frn",
      key: "pendiente_frn",
      align: "right",
      width: 120,
      render: (text, row, index) => {
        return (
          <Tag color="blue" style={{ fontSize: 15 }}>
            {myNumber(1, row.pendiente_frn)}
          </Tag>
        );
      },
    },
    {
      title: "SALDO UYU$",
      dataIndex: "pendiente_frn",
      key: "pendiente_frn",
      align: "right",
      width: 120,
      render: (text, row, index) => {
        return (
          <Tag color="purple" style={{ fontSize: 15 }}>
            {myNumber(1, row.saldo_frn - row.pendiente_frn)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "40",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => getDetalle(record, 2, "900px")}
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "40",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnOrange}
          size="large"
          shape="circle"
          icon={<DollarOutlined />}
          onClick={() =>
            Number.parseFloat(record.saldo_frn) -
              Number.parseFloat(record.pendiente_frn) >
              0
              ? openModal(record, 4, "900px")
              : message.error("NO HAY SALDO POR PAGAR", 1)
          }
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listClientes}
        style={{ padding: 3, marginTop: 5 }}
        scroll={{ x: 1500 }}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={6}>
                  <Title level={4}>{"TOTALES DOLARES Y PESOS UYU"}</Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Title level={4} style={{ textAlign: "right" }}>
                    {"$" + myNumber(1, totalLcl, 2)}
                  </Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={4}>
                  <Title level={4}>...</Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Title level={4} style={{ textAlign: "right" }}>
                    {"$" + myNumber(1, totalFrn, 2)}
                  </Title>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 1 ? (
          <DetalleCxP
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            cxp_moneda={"USD$"}
            detalleModal={detalleModal}
            detalleList={detalleList}
          />
        ) : tipoModal === 2 ? (
          <DetalleCxP
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            cxp_moneda={"UYU$"}
            detalleModal={detalleModal}
            detalleList={detalleList}
          />
        ) : tipoModal === 4 ? (
          <CjbCreatePago
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            currentMoneda={currentMoneda}
            tipoMoneda={2}
          />
        ) : (
          <CjbCreatePago
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            currentMoneda={currentMoneda}
            tipoMoneda={1}
          />
        )}
      </ModalApp>
      {Number.parseInt(currentUser.tipo) === 25 ? (
        <FloatingButtons
          buttonType="plus"
          buttonColor={"#ffffff"}
          backgroundColor={CST.lilaBdy}
          dimension={60}
          buttonsList={buttonsList}
          top={"calc(90% - 25px)"}
          left={"120px"}
          direction="circular"
          degree={-180}
          distance={90}
        />
      ) : null}
    </div>
  );
}
