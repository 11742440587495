import React from "react";
import { useSelector } from "react-redux";

//Componentes adicionales
import { AALLayout } from "../../components/AALLayout";
import { TitlePage } from "../../components/TitlePage";

//Farm
import Razas from "../../components/Farm/Razas";
import Categorias from "../../components/Farm/Categorias";
import Campos from "../../components/Farm/Campos";
import TiposVacuna from "../../components/Farm/TiposVacuna";
import TiposAntipara from "../../components/Farm/TiposAntiPara";
import CausasMuerte from "../../components/Farm/CausasMuerte";
import MaestroProveedores from "../../components/Farm/MaestroProveedores";
import MaestroClientes from "../../components/Farm/MaestroClientes";
import TiposActividad from "../../components/Farm/TiposActividad";
import TiposAlimento from "../../components/Farm/TiposAlimento";

// Cajas y Bancos
import CjbCuentas from "../../components/Farm/CjbCuentas";
import CjbConceptos from "../../components/Farm/CjbConceptos";
import CjbCncDbyCr from "../../components/Farm/CjbCncDbyCr";

//Configuracion
//import { Opciones } from "../../components/Maestros/Configuracion/Opciones";
//import { TipoUsuarios } from "../../components/Maestros/Configuracion/TipoUsuarios";

/**
 * MaestrosPage para cargar los componentes validando el currentComponent del State
 */
export default function MaestrosFrm() {
  const currentComponent = useSelector(
    (state) => state.currentComponent.currentComponent
  );

  function loadComponent(currentComponent) {
    switch (currentComponent.opcion) {
      case "FrmRazas":
        return <Razas />;
      case "FrmCategorias":
        return <Categorias />;
      case "FrmCampos":
        return <Campos />;
      case "FrmVacunas":
        return <TiposVacuna />;
      case "FrmAntipara":
        return <TiposAntipara />;
      case "FrmCausas":
        return <CausasMuerte />;
      case "FrmProvee":
        return <MaestroProveedores />;
      case "FrmClientes":
        return <MaestroClientes />;
      case "FrmActividad":
        return <TiposActividad />;
      case "FrmAlimento":
        return <TiposAlimento />;
      case "CjbCuentas":
        return <CjbCuentas />;
      case "CjbConceptos":
        return <CjbConceptos />;
      case "CjbCncDbyCr":
        return <CjbCncDbyCr />;
      default:
        return <h2>No hay maestro definido</h2>;
    }
  }

  return (
    <AALLayout>
      <TitlePage title="ABM Maestros" />
      {loadComponent(currentComponent)}
    </AALLayout>
  );
}
