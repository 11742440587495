import React from "react";
import { useSelector } from "react-redux";

//Componentes adicionales
import { AALLayout } from "../../components/AALLayout";
import { TitlePage } from "../../components/TitlePage";
//Funciones para administrar el carrito
import { myNumber, nombreMes } from "../../utils/ArrayFunctions";

//Farm
import CjbGastosLcl from "../../components/Farm/Procesos/CjbGastosLcl";
import CjbGastosFrn from "../../components/Farm/Procesos/CjbGastosFrn";
import CjbMovCaja from "../../components/Farm/Informes/CajayBancos/CjbMovCaja";
import CjbMovBanco from "../../components/Farm/Informes/CajayBancos/CjbMovBanco";
import FrmCuentasxP from "../../components/Farm/Informes/Gerencial/FrmCuentasxP";
import FrmCuentasxC from "../../components/Farm/Informes/Gerencial/FrmCuentasxC";
import FrmGestionGral from '../../components/Farm/Informes/Gerencial/FrmGestionGral';
import CjbLstGastos from "../../components/Farm/Informes/CajayBancos/CjbLstGastos";
import FrmStockGerencial from '../../components/Farm/Informes/Gerencial/FrmStockGerencial';

/**
 * ProcesosPage para cargar los componentes validando el currentComponent del State
 */
export default function InformesFarm(props) {
  const { setIsLoading } = props;
  const currentComponent = useSelector(
    (state) => state.currentComponent.currentComponent
  );
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );
  const currentSetup = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.utlsetup
  );
  const currentMoneda = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );
  const listBancos = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.bancos
  );
  const suppliersList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.servicios
  );
  //Listado de proveedores
  const clientsList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.clientes
  );
  //Listado de conceptos
  const listaCnc = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.conceptos_gto
  );

  function loadComponent(currentComponent) {
    switch (currentComponent.opcion) {
      case "CjbMovCaja":
        return (
          <CjbMovCaja
            myNumber={myNumber}
            currentUser={currentUser}
            currentSetup={currentSetup}
            setIsLoading={setIsLoading}
            currentMoneda={currentMoneda}
          />
        );
      case "CjbMovBanco":
        return (
          <CjbMovBanco
            myNumber={myNumber}
            currentUser={currentUser}
            currentSetup={currentSetup}
            setIsLoading={setIsLoading}
            currentMoneda={currentMoneda}
            listBancos={listBancos}
          />
        );
      case "CjbLstGastos":
        return (
          <CjbLstGastos
            myNumber={myNumber}
            currentUser={currentUser}
            currentSetup={currentSetup}
            setIsLoading={setIsLoading}
            currentMoneda={currentMoneda}
            listaCnc={listaCnc}
            suppliersList={suppliersList}
          />
        );
      case "CjbGastosFrn":
        return (
          <CjbGastosFrn
            myNumber={myNumber}
            currentUser={currentUser}
            currentSetup={currentSetup}
          />
        );
      case "FrmCuentasxP":
        return (
          <FrmCuentasxP
            myNumber={myNumber}
            currentUser={currentUser}
            currentSetup={currentSetup}
            setIsLoading={setIsLoading}
            currentMoneda={currentMoneda}
            suppliersList={suppliersList}
          />
        );
      case "FrmCuentasxC":
        return (
          <FrmCuentasxC
            myNumber={myNumber}
            currentUser={currentUser}
            currentSetup={currentSetup}
            setIsLoading={setIsLoading}
            currentMoneda={currentMoneda}
            clientsList={clientsList}
          />
        );
      case "FrmGestionGral":
        return (
          <FrmGestionGral
            myNumber={myNumber}
            currentUser={currentUser}
            currentSetup={currentSetup}
            setIsLoading={setIsLoading}
            currentMoneda={currentMoneda}
            listBancos={listBancos}
          />
        );
      case "FrmStockGerencial":
        return (
          <FrmStockGerencial
            myNumber={myNumber}
            currentUser={currentUser}
            currentSetup={currentSetup}
            setIsLoading={setIsLoading}
            currentMoneda={currentMoneda}
            listBancos={listBancos}
          />
        );
      default:
        return <h2>NO HAY PROCESO DEFINIDO</h2>;
    }
  }

  return (
    <AALLayout>
      <TitlePage title="Procesos" />
      {loadComponent(currentComponent)}
    </AALLayout>
  );
}
