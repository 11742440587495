import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Table,
  Upload,
  Typography,
  Select,
  Input,
  Row,
  Col,
  InputNumber,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  Tag,
  Tabs,
} from "antd";
import {
  UploadOutlined,
  SearchOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./UploadAlimento.scss";

// Excel Render
import { ExcelRenderer } from "react-excel-renderer";
import * as XLSX from "xlsx";

// componentes
import FRM from "../../../utils/FrmService";
import { reloadData } from "../../../utils/ReloadEnv";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";

export default function UploadAlimento(props) {
  const {
    currentUser,
    closeModal,
    setIsLoading,
    myNumber,
    detalleCarga,
    detalleList,
    detalleFood,
    foodResume,
    foodDetalle,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [usrId, setUsrId] = useState(currentUser.id);
  const [idOrigen, setIdOrigen] = useState("SELECCIONE ORIGEN");
  const [data, setData] = useState([]);
  const [dataDef, setDataDef] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [alimentosHst, setAlimentosHst] = useState([]);
  const [stateInf, setStateInf] = useState(Math.random());
  const [verTabla, setVerTabla] = useState(1);
  const [nameAlm, setNameAlm] = useState("");
  const [detaAlm, setDetaAlm] = useState([]);
  const [detaSec, setDetaSec] = useState(0);
  const [detaHmd, setDetaHmd] = useState(0);
  const [detaImp, setDetaImp] = useState(0);

  const { TabPane } = Tabs;
  const { Text } = Typography;

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      let datos = await FRM.getAgenda(currentUser, 4, detalleCarga.id);
      if (datos.type && datos.type > 0) {
        setAlimentosHst(datos.listado);
      } else {
        showNotification(
          "topRight",
          1,
          "HISTRIAL DE ALIMENTOS",
          "No hay ALIMENTOS REGISTRADOS"
        );
      }
      setIsLoading(false);
    })();
  }, [stateInf]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;

  //Se actualiza el nombre
  const updateOrigen = async (value) => {
    setIdOrigen(value);
  };

  const getDetalle = async (record, detalle) => {
    if (verTabla === 1) {
      const almFilter = detalle.filter((tipo) => {
        return Number.parseInt(tipo.alm_id) === Number.parseInt(record.alm_id);
      });
      setDetaAlm(almFilter);
      setNameAlm(record.alm_nombre);
      setDetaHmd(record.humedos);
      setDetaSec(record.secos);
      setDetaImp(record.importe);
      setVerTabla(2);
    } else {
      setVerTabla(1);
    }
  };

  const propis = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (fileList.length > 0) {
        setFileList([file]);
      } else {
        setFileList([...fileList, file]);
      }
      console.log(file)
      UploadExcel(file);
      return false;
    },
    fileList,
  };

  const UploadExcel = async (file) => {
    //Obtengo la informacion del Input File
    let fileObj = file;

    //Render del Excel
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        // Elimino el encabezado del archivo
        var excel = resp.rows.filter(function (item, index, arr) {
          return item[0] !== "Fecha";
        });
        const bh_excel2 = [];
        excel.forEach(function (item, index, arr) {
          let key = index;
          let cod = item[0].toString();
          let fecha = cod;
          let id_alm = item[1];
          let nombre_alm = item[2];
          let kilos_alm = item[3];

          let exceldata = {
            key: key,
            alm_fecha: fecha,
            alm_id: Number.parseInt(id_alm),
            alm_nombre: nombre_alm,
            alm_kilos_h: Number.parseFloat(kilos_alm).toFixed(2),
          };

          bh_excel2.push(exceldata);
        });
        setData(bh_excel2);
        setDataDef(bh_excel2);
      }
    });
  };

  // ENVIO A WEBSERVICES
  const uploadLotWS = async () => {
    setLoadingButton(true);
    if (Number.parseInt(dataDef.length) === 0) {
      showNotification(
        "topRight",
        2,
        "CARGA DE ALIMENTOS",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      let record = {
        tipoMov: 3,
        usr_id: currentUser.id,
        id_cmb: detalleCarga.id,
        cmb_id: detalleCarga.cmb_numero,
        cmp_id: detalleCarga.cmp_id,
        cmb_numero: Number.parseInt(detalleCarga.cmb_animales),
        record_def: dataDef,
      };
      var val_inventory = await FRM.sendAlimento(currentUser, record);
      console.log(val_inventory);
      if (val_inventory.type && Number.parseInt(val_inventory.type) > 0) {
        updateEnviroment(val_inventory.datos.rol_entorno);
        showNotification(
          "topRight",
          4,
          "PESOS ACTUALIZADOS",
          val_inventory.message
        );
      } else {
        showNotification(
          "topRight",
          2,
          "ACTUALIZAR PESOS",
          val_inventory.message
        );
      }
    }
    setData([]);
    setFileList([]);
    setLoadingButton(false);
    closeModal();
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "FECHA", //10%
      dataIndex: "alm_fecha",
      key: "alm_fecha",
    },
    {
      title: "ID",
      dataIndex: "alm_id",
      key: "alm_id",
      align: "right",
    },
    {
      title: "NOMBRE",
      dataIndex: "alm_nombre",
      key: "alm_nombre",
      align: "left",
    },
    {
      title: "KILOS",
      dataIndex: "alm_kilos_h",
      key: "alm_kilos_h",
      align: "right",
    },
  ];

  // Encabezados de la tabla de Historico de Alimentos
  const historico = [
    {
      title: "FECHA", //10%
      dataIndex: "alm_fecha",
      key: "alm_fecha",
    },
    {
      title: "CEBADA H",
      dataIndex: "cebada_h",
      key: "cebada_h",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.cebada_h, 2)}
          </Tag>
        );
      },
    },
    {
      title: "CEBADA S",
      dataIndex: "cebada_s",
      key: "cebada_s",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.cebada_s, 2)}
          </Tag>
        );
      },
    },
    {
      title: "MAIZ H",
      dataIndex: "maiz_h",
      key: "maiz_h",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.maiz_h, 2)}
          </Tag>
        );
      },
    },
    {
      title: "MAIZ S",
      dataIndex: "maiz_s",
      key: "maiz_s",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.maiz_s, 2)}
          </Tag>
        );
      },
    },
    {
      title: "FARDO H",
      dataIndex: "fardo_h",
      key: "fardo_h",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.fardo_h, 2)}
          </Tag>
        );
      },
    },
    {
      title: "FARDO S",
      dataIndex: "fardo_s",
      key: "fardo_s",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.fardo_s, 2)}
          </Tag>
        );
      },
    },
    {
      title: "NUCLEO H",
      dataIndex: "nucleo_h",
      key: "nucleo_h",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.nucleo_h, 2)}
          </Tag>
        );
      },
    },
    {
      title: "NUCLEO S",
      dataIndex: "nucleo_s",
      key: "nucleo_s",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.nucleo_s, 2)}
          </Tag>
        );
      },
    },
    {
      title: "TOTAL Kg H",
      dataIndex: "totales",
      key: "totales",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="volcano" style={{ fontSize: 14 }}>
            {myNumber(
              1,
              Number.parseFloat(row.nucleo_h) +
              Number.parseFloat(row.cebada_h) +
              Number.parseFloat(row.maiz_h) +
              Number.parseFloat(row.fardo_h),
              2
            )}
          </Tag>
        );
      },
    },
  ];

  const listaAlm = [
    {
      title: "ID", //10%
      dataIndex: "alm_id",
      key: "alm_id",
    },
    {
      title: "ALIMENTO",
      dataIndex: "alm_nombre",
      key: "alm_nombre",
      align: "left",
    },
    {
      title: "Kg HUMEDOS",
      dataIndex: "humedos",
      key: "humedos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.humedos, 2)}
          </Tag>
        );
      },
    },
    {
      title: "Kg SECOS",
      dataIndex: "secos",
      key: "secos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.secos, 2)}
          </Tag>
        );
      },
    },
    {
      title: "IMPORTE",
      dataIndex: "secos",
      key: "secos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.importe, 2)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="medium"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => getDetalle(record, foodDetalle)}
        />
      ),
    },
  ];

  const detalleAlm = [
    {
      title: "FECHA",
      dataIndex: "alm_fecha",
      key: "alm_fecha",
      align: "left",
    },
    {
      title: "Kg HUMEDOS",
      dataIndex: "humedos",
      key: "humedos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 14 }}>
            {myNumber(1, row.humedos, 2)}
          </Tag>
        );
      },
    },
    {
      title: "Kg SECOS",
      dataIndex: "secos",
      key: "secos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.secos, 2)}
          </Tag>
        );
      },
    },
    {
      title: "IMPORTE",
      dataIndex: "secos",
      key: "secos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 14 }}>
            {myNumber(1, row.importe, 2)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="medium"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={() => getDetalle(record)}
        />
      ),
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={4} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"CARGA MASIVA DE ALIMENTOS"}
      </Title>
      <Tabs defaultActiveKey="1">
        <TabPane tab="CARGA DE ALIMENTOS" key="1">
          <Row gutter={16} style={{ marginTop: 15, paddingTop: 10 }}>
            <Col span={12}>
              <Upload {...propis}>
                <Button
                  type="primary"
                  shape="round"
                  className={curStyle.btnInfo}
                  style={{
                    marginLeft: 20,
                  }}
                >
                  <UploadOutlined /> SELECCIONE ARCHIVO DE ALIMENTOS
                </Button>
              </Upload>
            </Col>
            <Col>

            </Col>
          </Row>
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <Table columns={header} dataSource={dataDef} bordered size="middle" />
          <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
            <Popconfirm
              placement="top"
              title="CONFIRMAR EL MOVIMIENTO?"
              onConfirm={() => uploadLotWS()}
              okText="Si"
              cancelText="No"
            >
              <Button
                type="primary"
                className={curStyle.btnSuccess}
                shape="round"
                block
                style={{
                  display: dataDef.length > 0 ? "block" : "none",
                }}
                loading={loadingButton}
              >
                <UploadOutlined /> PROCESAR LA CARGA DE ALIMENTOS
              </Button>
            </Popconfirm>
          </div>
        </TabPane>
        <TabPane tab="HISTRICO DE ALIMENTOS (Kg)" key="2">
          {verTabla === 1 ? (
            <>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                RELACION DE ALIMENTOS SUMINISTRADOS AL LOTE
              </Divider>
              <Table
                columns={listaAlm}
                dataSource={detalleFood}
                bordered
                size="middle"
                summary={(pageData) => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={2}>
                          TOTALES
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "rebeccapurple",
                            }}
                          >
                            {myNumber(
                              1,
                              Number.parseFloat(foodResume.humedos) > 0
                                ? Number.parseFloat(foodResume.humedos)
                                : 0,
                              2
                            )}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "rebeccapurple",
                            }}
                          >
                            {myNumber(
                              1,
                              foodResume.secos
                                ? Number.parseFloat(foodResume.secos)
                                : 0,
                              2
                            )}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "rebeccapurple",
                            }}
                          >
                            {myNumber(
                              1,
                              foodResume.importe
                                ? Number.parseFloat(foodResume.importe)
                                : 0,
                              2
                            )}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </>
          ) : (
            <>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                {"DETALE DE " + nameAlm + " SUMINISTRADO AL LOTE"}
              </Divider>
              <Table
                columns={detalleAlm}
                dataSource={detaAlm}
                bordered
                size="middle"
                summary={(pageData) => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell>TOTALES</Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "rebeccapurple",
                            }}
                          >
                            {myNumber(
                              1,
                              Number.parseFloat(detaHmd) > 0
                                ? Number.parseFloat(detaHmd)
                                : 0,
                              2
                            )}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "rebeccapurple",
                            }}
                          >
                            {myNumber(
                              1,
                              detaSec ? Number.parseFloat(detaSec) : 0,
                              2
                            )}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell align="right">
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "rebeccapurple",
                            }}
                          >
                            {myNumber(
                              1,
                              detaImp ? Number.parseFloat(detaImp) : 0,
                              2
                            )}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
}
