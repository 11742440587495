import React from "react";
import { Table, Typography, Divider } from "antd";

//Styles
import "./Informes.scss";

export default function DetalleAlimentos(props) {
  const { currentUser, closeModal, detalleModal, myNumber } = props;

  const { Title } = Typography;

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "TIPO ALIMENTO",
      dataIndex: "alm_nombre",
      key: "alm_nombre",
    },
    {
      title: "Kg ENTRAN",
      dataIndex: "entra",
      key: "entra",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.entra));
      },
    },
    {
      title: "Kg SALEN",
      dataIndex: "sale",
      key: "sale",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.sale));
      },
    },
    {
      title: "Kg STOCK",
      dataIndex: "entra",
      key: "entra",
      align: "right",
      render: (text, row, index) => {
        return myNumber(
          1,
          Number.parseFloat(row.entra) - Number.parseFloat(row.sale)
        );
      },
    },
    {
      title: "$ AVG",
      dataIndex: "alm_precio",
      key: "alm_precio",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.alm_precio), 3);
      },
    },
    {
      title: "$ STOCK",
      dataIndex: "alm_precio",
      key: "alm_precio",
      align: "right",
      render: (text, row, index) => {
        return myNumber(
          1,
          (Number.parseFloat(row.entra) - Number.parseFloat(row.sale)) *
            row.alm_precio
        );
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        INVENTARIO DE ALIMENTOS
      </Title>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Table
        columns={header}
        dataSource={detalleModal}
        bordered
        //scroll={{ x: "calc(1000px + 50%)", y: 640 }}
      />
    </div>
  );
}
