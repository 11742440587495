import React, { useState, useEffect, useRef } from "react";
import { Table, Input, Button, Space, message, Tag } from "antd";
import Highlighter from "react-highlight-words";

//Floating Buttons
import Fab from "@material-ui/core/Fab";
import { Add } from "@material-ui/icons";

//Iconos y constantes
import { SearchOutlined, FilePdfOutlined } from "@ant-design/icons";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../../Modal";
import CST from "../../../../utils/CustomSettings";
import CreateAlimentoInv from "./CreateAlimentoInv";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../../utils/ShowNotification";
import FRM from "../../../../utils/FrmService";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { myNumber } from "../../../../utils/ArrayFunctions";
import { URL_FILES } from "../../../../constants";

//Estilos
import "./FrmInventario.scss";

export default function FrmAlimentos(props) {
  const { setIsLoading } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [listGastos, setListGastos] = useState([]);
  const [stateInf, setStateInf] = useState(Math.random());

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const currentSetup = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.utlsetup
  );

  const currentMoneda = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      let datos = await FRM.getGastos(currentUser, 7);
      if (datos.type && datos.type > 0) {
        setListGastos(datos.listado);
      } else {
        showNotification(
          "topRight",
          1,
          "LISTADO DE ALIMENTOS",
          "No hay ALIMENTOS REGISTRADOS"
        );
      }
      setIsLoading(false);
    })();
  }, [stateInf]);

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const openAdjunto = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/alimentos/" + record.alm_adjunto;
    window.open(`${url_temp}`);
  };

  const showNota = (record) => {
    showNotification("topRight", 1, "CARGA DE ALIMENTOS", record.alm_nota);
  };

  //Nuevas funciones de busqueda de columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //Fin de nuevas funciones de busqueda

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSuccess}
          size="large"
          shape="circle"
        >
          {record.alm_numero}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "alm_fecha",
      key: "alm_fecha",
      ...getColumnSearchProps("alm_fecha"),
    },
    {
      title: "PROVEEDOR", //10%
      dataIndex: "trc_nombre",
      key: "trc_nombre",
      ...getColumnSearchProps("trc_nombre"),
    },
    {
      title: "FACTURA",
      dataIndex: "alm_factura",
      key: "alm_factura",
      ...getColumnSearchProps("alm_factura"),
    },
    {
      title: "TIPO DE ALIMENTO",
      dataIndex: "alm_nombre",
      key: "alm_nombre",
      ...getColumnSearchProps("alm_nombre"),
    },
    {
      title: "Kg Humedos",
      dataIndex: "alm_kilos_h",
      key: "alm_kilos_h",
      align: "right",
      render: (text, row, index) => {
        return <Tag color="green">{myNumber(1, row.alm_kilos_h)}</Tag>;
      },
    },
    {
      title: "Kg Secos",
      dataIndex: "alm_kilos_s",
      key: "alm_kilos_s",
      align: "right",
      render: (text, row, index) => {
        return <Tag color="volcano">{myNumber(1, row.alm_kilos_s)}</Tag>;
      },
    },
    {
      title: "Prc Humedos",
      dataIndex: "alm_kilos_h",
      key: "alm_kilos_h",
      align: "right",
      render: (text, row, index) => {
        return <Tag color="green">{myNumber(1, row.alm_precio_h, 3)}</Tag>;
      },
    },
    {
      title: "Prc Secos",
      dataIndex: "alm_precio_s",
      key: "alm_precio_s",
      align: "right",
      render: (text, row, index) => {
        return <Tag color="volcano">{myNumber(1, row.alm_precio_s, 3)}</Tag>;
      },
    },
    {
      title: "MONTO",
      dataIndex: "alm_importe",
      key: "alm_importe",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.alm_importe, 3);
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="large"
          shape="circle"
          icon={<FilePdfOutlined />}
          onClick={() =>
            record.alm_adjunto
              ? openAdjunto(record)
              : message.error("NO HAY ARCHIVO ADJUNTO", 1)
          }
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => showNota(record, 3, "900px")}
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listGastos}
        style={{ padding: 3, marginTop: 5 }}
      />
      <Fab
        color="primary"
        aria-label="add"
        style={{
          background: CST.greenBdy,
          position: "absolute",
          left: 50,
          bottom: 20,
          display:
            Number.parseInt(currentUser.tipo) === 4 || currentUser.id === 12
              ? "block"
              : "none",
        }}
        onClick={() => openModal(null, 1, "900px")}
      >
        <Add />
      </Fab>
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 1 ? (
          <CreateAlimentoInv
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
          />
        ) : tipoModal === 2 ? (
          <CreateAlimentoInv
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
          />
        ) : (
          <CreateAlimentoInv
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            currentMoneda={currentMoneda}
          />
        )}
      </ModalApp>
    </div>
  );
}
