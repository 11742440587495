import React, { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  Divider,
  Statistic,
  Tag,
  message,
  Table,
  Tabs,
} from "antd";
import {
  UploadOutlined,
  CalendarOutlined,
  FilePdfOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../../actions/rolEnviromentActions";
//Styles
import "./CjbGastosFrm.scss";

// componentes
import FRM from "../../../../utils/FrmService";
import { reloadData } from "../../../../utils/ReloadEnv";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { URL_FILES } from "../../../../constants";
import CST from "../../../../utils/CustomSettings";

export default function CjbPagosLcl(props) {
  const {
    currentUser,
    closeModal,
    setStateInf,
    currentSetup,
    myNumber,
    detalleModal,
    currentMoneda,
    detalleDbt,
    detalleCrd,
    updGasto,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [idCnc, setIdCnc] = useState(detalleModal.cnc_id);
  const [idCncOld, setIdCncOld] = useState(detalleModal.cnc_id);
  const [valorBnc, setValorBnc] = useState(detalleModal.meg_importe);
  const [idBnc, setIdBnc] = useState(0);
  const [idProveedor, setIdProveedor] = useState(0);
  const [nota, setNota] = useState("");
  const [nameAdjunto, setNameAdjunto] = useState("");

  const { Option } = Select;
  const [form] = Form.useForm();
  const { TabPane } = Tabs;

  const openAdjunto = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/gastos/" + record.meg_adjunto;
    window.open(`${url_temp}`);
  };

  const openAttach = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/notascrd/" + record.ntc_adjunto;
    window.open(`${url_temp}`);
  };

  const estado =
    detalleModal.pgo_estado === 0
      ? " (POR APROBAR)"
      : detalleModal.pgo_estado === 1
      ? " (APROBADA)"
      : " (FINALIZADA)";

  //Listado de conceptos
  const listaCnc = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.conceptos_gto
  );

  const cuentasLcl = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.cuentas_lcl
  );

  //Listado de proveedores
  const suppliersList = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.servicios
  );

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;
  const { TextArea } = Input;

  //Se actualiza el nombre
  const updateProveedor = async (value) => {
    setIdProveedor(value);
  };

  //Se actualiza cuenta de banco
  const updateBnc = async (value) => {
    setIdBnc(value);
    setValorBnc(0);
  };

  const onChangeBnc = (value) => {
    if (Number.parseFloat(value) >= 0 && Number.parseInt(idBnc) > 0) {
      setValorBnc(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  //GESTION DE CARGAR DE ARCHIVOS PDF
  // specify upload params and url for your files
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    body.append("file", file);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return { url: "https://api.aal-erp.com/frm/api/712", body, headers };
  };

  // called every time a file's `status` changes
  //const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }
  const handleChangeStatus = ({ meta, remove }, status) => {
    console.log(meta);
    if (status === "headers_received") {
      setNameAdjunto(meta.name);
      message.success("CARGA DE ADJUNTO EXITOSA", 1);
      //alert(`${meta.name} uploaded!`);
      remove();
    } else if (status === "aborted") {
      message.error("CARGA DE ADJUNTO FALLIDA", 2);
    }
  };
  //FIN GESTION DE CARGA DE ARCHIVOS

  /////////////////////////////////
  // ENVIO A WEBSERVICES
  const registrarGasto = async () => {
    setLoadingButton(true);
    let record = {
      tipoMov: 20,
      pgo_numero: detalleModal.pgo_numero,
      pgo_nota_aprueba: nota,
    };

    var val_gasto = await FRM.sendGasto(currentUser, record);
    if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
      updateEnviroment(val_gasto.datos.rol_entorno);
      setStateInf(Math.random());
      showNotification(
        "topRight",
        4,
        "APROBACION DE PAGO",
        "PROCESO REALIZADO DE FORMA EXITOSA"
      );
    } else {
      showNotification("topRight", 2, "APROBAR PAGOS", val_gasto.message);
    }
    setLoadingButton(false);
    closeModal();
  };

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, row, index) => {
        return row.meg_moneda === 1 ? (
          <Tag color="green" style={{ fontSize: 15 }}>
            {row.meg_numero}
          </Tag>
        ) : (
          <Tag color="orange" style={{ fontSize: 15 }}>
            {row.meg_numero}
          </Tag>
        );
      },
    },
    {
      title: "FECHA", //10%
      dataIndex: "meg_fecha",
      key: "meg_fecha",
    },
    {
      title: "CONCEPTO",
      dataIndex: "cnc_nombre",
      key: "cnc_nombre",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 15 }}>
            {row.cnc_nombre + " - " + row.cls_nombre}
          </Tag>
        );
      },
    },
    {
      title: "MONTO",
      dataIndex: "meg_importe",
      key: "meg_importe",
      align: "right",
      render: (text, row, index) => {
        return row.meg_moneda === 1 ? (
          <Tag color="green" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo + myNumber(1, row.meg_importe)}
          </Tag>
        ) : (
          <Tag color="orange" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo_frn + myNumber(1, row.meg_importe)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="medium"
          shape="circle"
          icon={<FilePdfOutlined />}
          onClick={() =>
            record.meg_adjunto
              ? openAdjunto(record)
              : message.error("NO HAY ARCHIVO ADJUNTO", 1)
          }
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="medium"
          shape="circle"
          icon={<ReloadOutlined />}
          onClick={() => updGasto(record)}
        />
      ),
    },
  ];

  const columnsNc = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, row, index) => {
        return row.ntc_moneda === 1 ? (
          <Tag color="green" style={{ fontSize: 15 }}>
            {row.ntc_numero}
          </Tag>
        ) : (
          <Tag color="orange" style={{ fontSize: 15 }}>
            {row.ntc_numero}
          </Tag>
        );
      },
    },
    {
      title: "FECHA", //10%
      dataIndex: "ntc_fecha",
      key: "ntc_fecha",
    },
    {
      title: "CONCEPTO",
      dataIndex: "cnc_nombre",
      key: "cnc_nombre",
    },
    {
      title: "REGISTRO",
      dataIndex: "registro",
      key: "registro",
    },
    {
      title: "MONTO",
      dataIndex: "ntc_importe",
      key: "ntc_importe",
      align: "right",
      render: (text, row, index) => {
        return row.ntc_moneda === 1 ? (
          <Tag color="green" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo + myNumber(1, row.ntc_importe)}
          </Tag>
        ) : (
          <Tag color="orange" style={{ fontSize: 15 }}>
            {currentMoneda.simbolo_frn + myNumber(1, row.ntc_importe)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSuccess}
          size="medium"
          shape="circle"
          icon={<FilePdfOutlined />}
          onClick={() =>
            record.ntc_adjunto
              ? openAttach(record)
              : message.error("NO HAY ARCHIVO ADJUNTO", 1)
          }
        />
      ),
    },
  ];

  return (
    <div className="upload-panel">
      <Divider
        style={{
          marginTop: 15,
          paddingTop: 10,
          marginBottom: 20,
          color: detalleModal.pgo_moneda === 2 ? CST.orangeBdy : CST.greenBdy,
          fontSize: 20,
        }}
      >
        {detalleModal.pgo_moneda === 1
          ? "DATOS GENERALES DEL PAGO EN DOLARES"
          : "DATOS GENERALES DEL PAGO EN PESOS UYU"}
      </Divider>
      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col span={8}>
            <Statistic
              title="Fecha Generacion"
              value={detalleModal.pgo_fecha}
              prefix={<CalendarOutlined />}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Usuario que Solicita"
              value={detalleModal.registro}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="Usuario que Autoriza"
              value={detalleModal.autoriza}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row gutter={8}>
          <Col span={18}>
            <Statistic
              title="Beneficiario al Solicitar"
              value={detalleModal.trc_nombre}
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Valor a Pagar"
              value={
                detalleModal.pgo_moneda === 1
                  ? currentMoneda.simbolo +
                    myNumber(1, detalleModal.pgo_importe)
                  : currentMoneda.simbolo +
                    myNumber(1, detalleModal.pgo_importe)
              }
              valueStyle={{ fontSize: 18 }}
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
        <Row
          style={{
            display:
              detalleModal.usr_apr === currentUser.id &&
              detalleModal.pgo_estado === 0
                ? "block"
                : "none",
          }}
        >
          <Col span={24}>
            <TextArea
              rows={2}
              value={nota}
              placeholder={"Observaciones al Aprobar"}
              onChange={onChangeNota}
              allowClear
            />
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      </Form>
      <Tabs defaultActiveKey="1">
        <TabPane tab="FACTURAS" key="1">
          <Table
            columns={columns}
            dataSource={detalleDbt}
            style={{ padding: 3, marginTop: 5 }}
          />
        </TabPane>
        <TabPane tab="N. CREDITO" key="2">
          <Table
            columns={columnsNc}
            dataSource={detalleCrd}
            style={{ padding: 3, marginTop: 5 }}
          />
        </TabPane>
      </Tabs>
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => registrarGasto()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={
              detalleModal.pgo_moneda === 2
                ? curStyle.btnOrange
                : curStyle.btnSuccess
            }
            shape="round"
            block
            style={{
              display:
                currentUser.id === detalleModal.usr_apr &&
                detalleModal.pgo_estado === 0
                  ? "block"
                  : "none",
            }}
            loading={loadingButton}
          >
            <UploadOutlined /> APROBAR EL PAGO
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
