import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  message,
  Typography,
  Tag,
  Modal as ModalB,
} from "antd";
import Highlighter from "react-highlight-words";

//Floating Buttons
import FloatingButtons from "react-floating-buttons";
import moneyl from "../../../assets/icons/cash.svg";
import moneyf from "../../../assets/icons/cash-multiple.svg";

//Iconos y constantes
import {
  SearchOutlined,
  FilePdfOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../Modal";
import CST from "../../../utils/CustomSettings";
import DetalleSalida from "./DetalleSalida";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";
import curStyle from "../../../css/GlobalCmp.module.css";
import { myNumber } from "../../../utils/ArrayFunctions";
import { URL_FILES } from "../../../constants";

//Estilos
import "./SalidasAnm.scss";

export default function MuertesAnm(props) {
  const { setIsLoading, myNumber, tipoSal } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [detalleList, setDetalleList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [listSalidas, setListSalidas] = useState([]);
  const [stateInf, setStateInf] = useState(Math.random());
  const [kilosVnd, setKilosVnd] = useState(0);
  const [animalesVnd, setAnimalesVnd] = useState(0);

  const { confirm } = ModalB;
  const { Title } = Typography;

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const currentSetup = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.utlsetup
  );

  const currentMoneda = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );

  useEffect(() => {
    (async () => {
      let datos = await FRM.getGastos(currentUser, tipoSal);
      if (datos.type && datos.type > 0) {
        setListSalidas(datos.listado);
        setKilosVnd(datos.total_rsm);
        setAnimalesVnd(datos.num_rsm);
      } else {
        showNotification(
          "topRight",
          1,
          "LISTADO DE SALIDAS",
          "No hay SALIDAS REGISTRADAS"
        );
      }
    })();
  }, [stateInf]);

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const getDetalle = async (record, opcion) => {
    let datos = await FRM.getLoteDet(currentUser, record.sal_numero, 2);
    if (datos.type && datos.type > 0) {
      setDetalleList(datos.listado);
      openModal(record, opcion, "1000px");
    } else {
      showNotification(
        "topRight",
        2,
        "DETALLE SALIDA",
        "No hay Datos registrados"
      );
    }
  };

  const openAdjunto = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/gastos/" + record.meg_adjunto;
    window.open(`${url_temp}`);
  };

  const deleRecord = async (record) => {
    if (record.sal_estado !== 0) {
      showNotification(
        "topRight",
        4,
        "ELIMINAR VENTA",
        "LA VENTA YA FUE FACTURADA"
      );
      return false;
    }

    if (record.sal_tipo !== 1) {
      showNotification(
        "topRight",
        4,
        "ELIMINAR SALIDA",
        "SOLO SE PUEDEN ELIMINAR SALIDAS POR VENTA"
      );
      return false;
    }
    confirm({
      title: "EILIMINAR LA VENTA",
      icon: <ExclamationCircleOutlined />,
      okText: "ELIMINAR",
      cancelText: "Cancelar",
      content: "DESEA ELIMINAR LA VENTA?",
      onOk() {
        deleVenta(record);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //Eliminar Ventas
  const deleVenta = async (salida) => {
    setIsLoading(true);
    let record = {
      sal_id: salida.sal_numero,
      usr_id: currentUser.id,
      cmb_id: salida.cmb_org,
      cmp_id: salida.cmp_id,
      sal_cantidad: salida.sal_cantidad,
    };
    var val_gasto = await FRM.sendSalida(currentUser, record, 32);
    if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
      setStateInf(Math.random());
      showNotification("topRight", 4, "ELIMINAR VENTAS", "MOVIMIENTO EXITOSO");
    } else {
      showNotification("topRight", 2, "ELIMINAR VENTAS", val_gasto.message);
    }
    setIsLoading(false);
  };

  //Nuevas funciones de busqueda de columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //Fin de nuevas funciones de busqueda

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="large"
          shape="circle"
        >
          {record.sal_numero}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "sal_fecha",
      key: "sal_fecha",
    },
    {
      title: "CAMPO", //10%
      dataIndex: "cmp_nombre",
      key: "cmp_nombre",
      ...getColumnSearchProps("cmp_nombre"),
    },
    {
      title: "CAUSA O MOTIVO",
      dataIndex: "mtm_nombre",
      key: "mtm_nombre",
      ...getColumnSearchProps("mtm_nombre"),
    },
    {
      title: "DETALLE",
      dataIndex: "sal_nota",
      key: "sal_nota",
    },
    {
      title: "CANTD",
      dataIndex: "sal_cantidad",
      key: "sal_cantidad",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.sal_cantidad);
      },
    },
    {
      title: "KILOS",
      dataIndex: "sal_kilos",
      key: "sal_kilos",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.sal_kilos, 2);
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => getDetalle(record, 3)}
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnLila}
          size="large"
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() => deleRecord(record)}
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listSalidas}
        style={{ padding: 3, marginTop: 5 }}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={5}>
                  <Title level={4}>{"TOTALES ANIMALES Y KILOS PERDIDOS"}</Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Title level={4} style={{ textAlign: "right" }}>
                    {myNumber(1, animalesVnd, 2)}
                  </Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Title level={4} style={{ textAlign: "right" }}>
                    {myNumber(1, kilosVnd, 2)}
                  </Title>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={tipoModal === 4 ? 1307 : widthModal}
      >
        {tipoModal === 1 ? (
          <DetalleSalida
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
          />
        ) : tipoModal === 2 ? (
          <DetalleSalida
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
          />
        ) : (
          <DetalleSalida
            myNumber={myNumber}
            detalleModal={detalleModal}
            detalleList={detalleList}
            totalKg={detalleList[0].anm_peso_neto}
          />
        )}
      </ModalApp>
    </div>
  );
}
