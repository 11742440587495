import React, { useState, useEffect } from "react";
import { Form, Typography, Input, Space, Button, Radio, Switch } from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";

//Styles
import curStyle from "../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../utils/CustomSettings";
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";

export default function CjbCuentasForm(props) {
  const { record, closeModal, currentUser, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.cue_id);
  const [loadingButton, setLoadingButton] = useState(false);
  const [cueTipo, setCueTipo] = useState(record.cue_tipo);
  const [cueMoneda, setCueMoneda] = useState(
    record.cue_id > 0 ? record.cue_moneda : 1
  );
  const [cueAgrupa, setCueAgrupa] = useState(
    record.cue_id > 0 ? record.cue_agrupa : 0
  );

  useEffect(() => {
    setCurId(record.cue_id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
  };

  const { Title } = Typography;

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await FRM.sendCjbCuentas(
      currentUser,
      curId,
      cueMoneda,
      cueAgrupa,
      "CjbCuentas",
      values
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "GESTION DE CAJAS Y CUENTAS",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE CAJAS Y CUENTAS",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="cjbCuentas"
      onFinish={guardarCambios}
      initialValues={{
        cue_nombre: record.cue_id > 0 ? record.cue_nombre : "",
        cue_tipo: record.cue_id > 0 ? record.cue_tipo : "",
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.cue_id > 0
            ? "ACTUALIZAR CAJA Y BANCOS"
            : "AGREGAR CAJA Y BANCO"}
        </Title>
      </Form.Item>
      <Form.Item
        name="cue_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.cue_id > 0 ? record.cue_nombre : ""}
        />
      </Form.Item>
      <Form.Item
        name="cue_tipo"
        label="Tipo"
        rules={[
          {
            required: true,
            message: "El tipo es obligatorio",
          },
        ]}
      >
        <Radio.Group
          onChange={(option) => setCueTipo(option.target.value)}
          value={cueTipo}
        >
          <Radio value={1}>Efectivo</Radio>
          <Radio value={2}>Bancaria</Radio>
          <Radio value={3}>Cheques</Radio>
          <Radio value={4}>Retencion</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="cue_moneda"
        label="Moneda Local"
        wrapperCol={{ span: 30 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Space direction="horizontal">
          <Switch
            checked={cueMoneda}
            onChange={(val) => setCueMoneda(val ? 1 : 0)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />
          Agrupa Movimientos:
          <Switch
            checked={cueAgrupa}
            onChange={(val) => setCueAgrupa(val ? 1 : 0)}
            checkedChildren="SI"
            unCheckedChildren="NO"
            defaultChecked
          />
        </Space>
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
