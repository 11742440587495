import React, { useState, useEffect } from "react";

import post from "./PostMap";

let i = 0;

function generateIframeName() {
  return `react-post-iframe-${i++}`;
}

export default function VerMapas(props) {
  const { puntos, latCen, lngCen } = props;
  const iframeName = generateIframeName();

  useEffect(() => {
    (async () => {
      //let points = JSON.stringify(puntos);
      //Tipo Mapa => marker - directions
      const params = {
        tipo: "marker",
        points: JSON.stringify(puntos),
        latCenter: latCen,
        lngCenter: lngCen,
      };
      post({
        action: "https://api.aal-erp.com/map-google",
        target: iframeName,
        params,
      });
    })();
  }, []);

  return (
    <iframe
      name={iframeName}
      style={{ width: "100%", minHeight: window.innerHeight - 200 }}
      allowfullscreen=""
    ></iframe>
  );
}
