import React, { useState, useRef } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  message,
  Tag,
  Tabs,
  Typography,
} from "antd";
import Highlighter from "react-highlight-words";

import Fab from "@material-ui/core/Fab";
import { Add } from "@material-ui/icons";

//Iconos y constantes
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";

import Icon from "@mdi/react";
import { mdiCow, mdiBarn, mdiGrass, mdiPill, mdiNeedle } from "@mdi/js";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../Modal";
import AddAnimals from "./AddAnimals";
import CountAnimals from "./CountAnimals";
import CreateLot from "../CreateLot";
import CreateSalida from "../CreateSalida";
import CreateBaja from "../CreateBaja";
import DetalleLote from "../DetalleLote";
import EditLote from "../EditLote";
import CrearGasto from "../CrearGasto";
import CreateAlimento from "../CreateAlimento";
import UploadAlimento from "../CreateAlimento/UploadAlimento";
import UploadAlimentoXml from "../CreateAlimento/UploadAlimentoXml";
import CreateDesparasita from "../CreateDesparasita";
import CreateVacuna from "../CreateVacuna";
import OpcionesLote from "./OpcionesLote";
import { colorPrm } from "../../../constants";
import curStyle from "../../../css/GlobalCmp.module.css";

// Excel Render Y Exportar Excel
import { ExcelRenderer } from "react-excel-renderer";
import ReactExport from "react-export-excel";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";
import CST from "../../../utils/CustomSettings";

//Estilos
import "./CambiosLote.scss";

export default function CambiosLote(props) {
  const { setIsLoading, myNumber } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleCarga, setDetalleCarga] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [detalleList, setDetalleList] = useState([]);
  const [foodList, setFoodList] = useState([]);
  const [foodResume, setFoodResume] = useState({});
  const [foodDetalle, setFoodDetalle] = useState([]);
  const [cuentasEfe, setCuentasEfe] = useState([]);
  const [cuentasBnc, setCuentasBnc] = useState([]);
  const [cuentasChk, setCuentasChk] = useState([]);
  const [listaCnc, setListaCnc] = useState([]);
  const [curRecord, setCurRecord] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [downDif, setDownDif] = useState(false);

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );
  const listEntradas = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.cambios_lote
  );
  const lotesActivos = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.lotes_activos
  );

  const entradasDet = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.entradas_det
  );

  //Resumen de Cabezas y Kilos
  const gstTiles = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.resumen_gen
  );

  //Para uso de excel
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const { TabPane } = Tabs;
  const { Title, Text } = Typography;

  //Se controlan los cambios de Tab
  const changeTab = (activeKey) => {
    setActiveKey(activeKey);
  };

  const onClickMenu = (key) => {
    setIsVisibleModal(false);
    if (key === "1") {
      openModal(curRecord, 1, "1000px");
    } else if (key === "2") {
      openModal(curRecord, 6, "900px");
    } else if (key === "3") {
      openModal(curRecord, 7, "900px");
    } else if (key === "4") {
      openModal(curRecord, 8, "1000px");
    } else if (key === "5") {
      openModal(curRecord, 9, "1000px");
    } else if (key === "7") {
      openModal(curRecord, 10, "1000px");
    } else if (key === "8") {
      openModal(curRecord, 11, "1000px");
    } else if (key === "9") {
      getDetalle(curRecord, 13);
    } else if (key === "10") {
      openModal(curRecord, 15, "90%");
    } else {
      getDetalle(curRecord, 4);
    }
  };

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleCarga(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const opcionMenu = async (record) => {
    setCurRecord(record);
    openModal(record, 12);
  };

  const getDetalle = async (record, opcion) => {
    if (opcion === 4) {
      openModal(record, opcion, "900px");
    } else {
      let datos = await FRM.getLoteDet(currentUser, record.cmb_numero, 1);
      if (datos.type && datos.type > 0) {
        setDetalleList(datos.listado);
        setFoodList(datos.alimento);
        setFoodResume(datos.alimento_rsm);
        setFoodDetalle(datos.alimento_det);
        openModal(record, opcion, "1100px");
      } else {
        showNotification(
          "topRight",
          1,
          "DETALLE DEL CAMPO",
          "No hay informacion RELACIONADA AL CAMPO... Verifique!"
        );
      }
    }
  };

  const addGasto = async (record, opcion) => {
    let objeto = {
      idMov: record.cmb_numero,
      tipoMov: 1,
      idOpt: 1,
    };
    let datos = await FRM.getGastosMov(currentUser, objeto);
    console.log(datos);
    if (datos.type && datos.type > 0) {
      setDetalleList(datos.listado);
      setListaCnc(datos.listacnc);
      setCuentasEfe(datos.listaefe);
      setCuentasBnc(datos.listabnc);
      setCuentasChk(datos.listachk);
      openModal(record, opcion, "900px");
    } else {
      showNotification("topRight", 2, "ERROR DE CONEXION", "Intente mas Tarde");
    }
  };

  const addAlimento = async (record, opcion) => {
    openModal(record, opcion, "900px");
  };

  //Nuevas funciones de busqueda de columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //Fin de nuevas funciones de busqueda

  //Descargar diferencias
  const downDifs = (tipo, arrDif) => {
    if (arrDif.length > 0) {
      setDownDif(true);
      setTimeout(function () {
        setDownDif(false);
      }, 1000);
    } else {
      message.error("NO HAY DATOS PARA EXPORTAR", 1);
    }
  };

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={activeKey === "1" ? curStyle.btnLila : curStyle.btnSuccess}
          size="large"
          shape="circle"
          onClick={() => getDetalle(record, 2)}
        >
          {record.cmb_numero}
        </Button>
      ),
    },
    {
      title: "FECHA",
      dataIndex: "cmb_fecha",
      width: 150,
      key: "cmb_fecha",
      ...getColumnSearchProps("cmb_fecha"),
    },
    {
      title: "DIAS",
      dataIndex: "cmb_dias",
      width: 50,
      key: "cmb_dias",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_estado > 0 ? row.cmb_dias : row.dias);
      },
    },
    {
      title: "CAMPO DE ORIGEN",
      dataIndex: "cmp_origen",
      width: 250,
      key: "cmp_origen",
      ...getColumnSearchProps("cmp_nombre"),
      render: (text, row, index) => {
        return row.cmp_origen ? row.cmp_origen : "ENTRADA NUEVA";
      },
    },
    {
      title: "CAMPO OCUPADO",
      dataIndex: "cmp_nombre",
      width: 250,
      key: "cmp_nombre",
      ...getColumnSearchProps("cmp_nombre"),
    },
    {
      title: "CANTD",
      dataIndex: "cmb_cantidad",
      key: "cmb_cantidad",
      width: 80,
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_cantidad);
      },
    },
    {
      title: "VALIDADO",
      dataIndex: "cmb_animales",
      key: "cmb_animales",
      width: 80,
      align: "right",
      render: (text, row, index) => {
        return myNumber(
          1,
          row.cmb_animales +
          row.cmb_move_anm +
          row.cmb_sale_anm +
          row.cmb_muere_anm
        );
      },
    },
    {
      title: "DIF.",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={
            record.cmb_cantidad ===
              record.cmb_animales +
              record.cmb_move_anm +
              record.cmb_sale_anm +
              record.cmb_muere_anm
              ? curStyle.btnInfo
              : curStyle.btnDanger
          }
          size="large"
          shape="circle"
          onClick={() => message.info("PROCESO EN DESARROLLO")}
        >
          {record.cmb_cantidad -
            (record.cmb_animales +
              record.cmb_move_anm +
              record.cmb_sale_anm +
              record.cmb_muere_anm)}
        </Button>
      ),
    },
    {
      title: "SNIG",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="large"
          shape="circle"
          onClick={() => message.info("PROCESO EN DESARROLLO")}
        >
          {record.cmb_snig}
        </Button>
      ),
    },
    {
      title: "CAMBIO",
      dataIndex: "cmb_move_anm",
      key: "cmb_move_anm",
      width: 80,
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_move_anm);
      },
    },
    {
      title: "VENTA",
      dataIndex: "cmb_sale_anm",
      key: "cmb_sale_anm",
      width: 80,
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_sale_anm);
      },
    },
    {
      title: "MUERTE",
      dataIndex: "cmb_muere_anm",
      key: "cmb_muere_anm",
      width: 80,
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_muere_anm);
      },
    },
    {
      title: "ACTIVOS",
      dataIndex: "cmb_estado",
      key: "cmb_estado",
      width: 100,
      align: "left",
      render: (text, record, index) => {
        return <Tag color="cyan">{record.cmb_animales}</Tag>;
      },
    },
    {
      title: "CONTEO",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnWarning}
          size="large"
          shape="circle"
          onClick={() =>
            Number.parseInt(currentUser.id) === 2
              ? openModal(record, 14, "300px")
              : message.error("USUARIO NO AUTORIZADO", 1)
          }
        >
          {record.cmb_conteo}
        </Button>
      ),
    },
    {
      title: "ALIMENTO",
      dataIndex: "alimento_cmb",
      key: "alimento_cmb",
      width: 100,
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.alimento_cmb > 0 ? row.alimento_cmb : 0.0);
      },
    },
    {
      title: "ESTADO",
      dataIndex: "cmb_estado",
      key: "cmb_estado",
      width: 100,
      align: "left",
      render: (text, row, index) => {
        return row.cmb_estado === 0 ? (
          <Tag color="volcano">VIGENTE</Tag>
        ) : (
          <Tag color="geekblue">CERRADO</Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDark}
          size="large"
          shape="circle"
          onClick={() =>
            record.cmb_estado === 0
              ? opcionMenu(record)
              : message.error("EL CAMBIO YA ESTA CERRADO", 1)
          }
        >
          <Icon path={mdiGrass} size={"23"} />
        </Button>
      ),
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnLila}
          size="large"
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() =>
            record.cmb_estado === 0
              ? getDetalle(record, 3)
              : message.error(
                "EL CAMBIO YA ESTA CONFIRMADO... NO SE PUEDE ELIMINAR",
                1
              )
          }
        />
      ),
    },
  ];

  const nostock = [
    {
      title: "No",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={activeKey === "1" ? curStyle.btnLila : curStyle.btnSuccess}
          size="large"
          shape="circle"
          onClick={() => getDetalle(record, 2)}
        >
          {record.cmb_numero}
        </Button>
      ),
    },
    {
      title: "FECHA",
      dataIndex: "cmb_fecha",
      width: 150,
      key: "cmb_fecha",
      ...getColumnSearchProps("cmb_fecha"),
    },
    {
      title: "DIAS",
      dataIndex: "cmb_dias",
      width: 50,
      key: "cmb_dias",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_estado > 0 ? row.cmb_dias : row.dias);
      },
    },
    {
      title: "CAMPO DE ORIGEN",
      dataIndex: "cmp_origen",
      width: 250,
      key: "cmp_origen",
      ...getColumnSearchProps("cmp_nombre"),
      render: (text, row, index) => {
        return row.cmp_origen ? row.cmp_origen : "ENTRADA NUEVA";
      },
    },
    {
      title: "CAMPO OCUPADO",
      dataIndex: "cmp_nombre",
      width: 250,
      key: "cmp_nombre",
      ...getColumnSearchProps("cmp_nombre"),
    },
    {
      title: "CANTD",
      dataIndex: "cmb_cantidad",
      key: "cmb_cantidad",
      width: 80,
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_cantidad);
      },
    },
    {
      title: "VALIDADO",
      dataIndex: "cmb_animales",
      key: "cmb_animales",
      width: 80,
      align: "right",
      render: (text, row, index) => {
        return myNumber(
          1,
          row.cmb_animales +
          row.cmb_move_anm +
          row.cmb_sale_anm +
          row.cmb_muere_anm
        );
      },
    },
    {
      title: "DIF.",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={
            record.cmb_cantidad ===
              record.cmb_animales +
              record.cmb_move_anm +
              record.cmb_sale_anm +
              record.cmb_muere_anm
              ? curStyle.btnInfo
              : curStyle.btnDanger
          }
          size="large"
          shape="circle"
          onClick={() => message.info("PROCESO EN DESARROLLO")}
        >
          {record.cmb_cantidad -
            (record.cmb_animales +
              record.cmb_move_anm +
              record.cmb_sale_anm +
              record.cmb_muere_anm)}
        </Button>
      ),
    },
    {
      title: "CAMBIO",
      dataIndex: "cmb_move_anm",
      key: "cmb_move_anm",
      width: 80,
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_move_anm);
      },
    },
    {
      title: "VENTA",
      dataIndex: "cmb_sale_anm",
      key: "cmb_sale_anm",
      width: 80,
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_sale_anm);
      },
    },
    {
      title: "MUERTE",
      dataIndex: "cmb_muere_anm",
      key: "cmb_muere_anm",
      width: 80,
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.cmb_muere_anm);
      },
    },
    {
      title: "ACTIVOS",
      dataIndex: "cmb_estado",
      key: "cmb_estado",
      width: 100,
      align: "left",
      render: (text, record, index) => {
        return <Tag color="cyan">{record.cmb_animales}</Tag>;
      },
    },
    {
      title: "ALIMENTO",
      dataIndex: "alimento_cmb",
      key: "alimento_cmb",
      width: 100,
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.alimento_cmb > 0 ? row.alimento_cmb : 0.0);
      },
    },
    {
      title: "ESTADO",
      dataIndex: "cmb_estado",
      key: "cmb_estado",
      width: 100,
      align: "left",
      render: (text, row, index) => {
        return row.cmb_estado === 0 ? (
          <Tag color="volcano">VIGENTE</Tag>
        ) : (
          <Tag color="geekblue">CERRADO</Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDark}
          size="large"
          shape="circle"
          onClick={() =>
            record.cmb_estado === 0
              ? opcionMenu(record)
              : message.error("EL CAMBIO YA ESTA CERRADO", 1)
          }
        >
          <Icon path={mdiGrass} size={"23"} />
        </Button>
      ),
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnLila}
          size="large"
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={() =>
            record.cmb_estado === 0
              ? getDetalle(record, 3)
              : message.error(
                "EL CAMBIO YA ESTA CONFIRMADO... NO SE PUEDE ELIMINAR",
                1
              )
          }
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <>
      <Tabs defaultActiveKey={activeKey} onChange={changeTab} centered>
        <TabPane
          tab={
            <span style={{ display: "flex", flexDirection: "row" }}>
              <Icon
                path={mdiCow}
                size={"20"}
                color={activeKey === "1" ? CST.lilaBdy : "#c2c2c2"}
              />
              <Title
                className="h5-tile"
                level={4}
                style={{
                  fontSize: 16,
                  marginLeft: 10,
                  marginBottom: 0,
                  color: activeKey === "1" ? CST.lilaBdy : "#c2c2c2",
                }}
              >
                LOSTES CON STOCK
              </Title>
            </span>
          }
          key="1"
        >
          <Table
            columns={columns}
            dataSource={lotesActivos}
            style={{ padding: 3, marginTop: 5 }}
            scroll={{ x: 1500 }}
            summary={(pageData) => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={12}>
                      <Title level={4}>
                        {"TOTALES ANIMALES ACTIVOS Y CONTADOS"}
                      </Title>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Title level={4} style={{ textAlign: "center" }}>
                        {myNumber(1, gstTiles.activos, 0)}
                      </Title>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Title
                        level={4}
                        style={{ textAlign: "cneter", color: "red" }}
                        onClick={() => downDifs(2, lotesActivos)}
                      >
                        {myNumber(1, gstTiles.contados, 0)}
                      </Title>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell colSpan={3}>
                      <Title level={4}>...</Title>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </TabPane>
        <TabPane
          tab={
            <span style={{ display: "flex", flexDirection: "row" }}>
              <Icon
                path={mdiBarn}
                size={"20"}
                color={activeKey === "2" ? CST.greenBdy : "#c2c2c2"}
              />
              <Title
                className="h5-tile"
                level={4}
                style={{
                  fontSize: 16,
                  marginLeft: 10,
                  marginBottom: 0,
                  color: activeKey === "2" ? CST.greenBdy : "#c2c2c2",
                }}
              >
                LOSTES SIN STOCK
              </Title>
            </span>
          }
          key="2"
        >
          <Table
            columns={nostock}
            dataSource={listEntradas}
            style={{ padding: 3, marginTop: 5 }}
            scroll={{ x: 1500 }}
          />
        </TabPane>
      </Tabs>
      <Fab
        color="primary"
        aria-label="add"
        style={{
          background: CST.darkBdy,
          position: "absolute",
          left: 50,
          bottom: 20,
          display: "none",
        }}
        onClick={() => openModal(null, 1, "900px")}
      >
        <Add />
      </Fab>
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 1 ? (
          <CreateLot
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
          />
        ) : tipoModal === 2 ? (
          <DetalleLote
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            opcionCarga={2}
            detalleList={detalleList}
            detalleFood={foodList}
            foodResume={foodResume}
            foodDetalle={foodDetalle}
          />
        ) : tipoModal === 3 ? (
          <DetalleLote
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            opcionCarga={3}
            detalleList={detalleList}
            detalleFood={foodList}
            foodResume={foodResume}
            foodDetalle={foodDetalle}
          />
        ) : tipoModal === 4 ? (
          <EditLote
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            opcionCarga={3}
          />
        ) : tipoModal === 5 ? (
          <CrearGasto
            currentUser={currentUser}
            closeModal={closeModal}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            detalleList={detalleList}
            listaCnc={listaCnc}
            cuentasEfe={cuentasEfe}
            cuentasBnc={cuentasBnc}
            cuentasChk={cuentasChk}
            opcionCarga={1}
            tituloModal={
              "AGREGAR GASTO A LOTE ACTIVO " + detalleCarga.cmp_nombre
            }
            id_mov={detalleCarga.cmb_numero}
            numero_anm={detalleCarga.cmb_cantidad}
          />
        ) : tipoModal === 6 ? (
          <CreateAlimento
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
          />
        ) : tipoModal === 7 ? (
          <CreateDesparasita
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
          />
        ) : tipoModal === 8 ? (
          <CreateVacuna
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
          />
        ) : tipoModal === 9 ? (
          <CreateSalida
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
          />
        ) : tipoModal === 10 ? (
          <CreateBaja
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
          />
        ) : tipoModal === 11 ? (
          <AddAnimals
            currentUser={currentUser}
            closeModal={closeModal}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
          />
        ) : tipoModal === 13 ? (
          <UploadAlimento
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            detalleList={detalleList}
            detalleFood={foodList}
            foodResume={foodResume}
            foodDetalle={foodDetalle}
          />
        ) : tipoModal === 14 ? (
          <CountAnimals
            currentUser={currentUser}
            closeModal={closeModal}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
          />
        ) : tipoModal === 15 ? (
          <UploadAlimentoXml
            currentUser={currentUser}
            closeModal={closeModal}
            setIsLoading={setIsLoading}
            myNumber={myNumber}
            detalleCarga={detalleCarga}
            detalleList={detalleList}
            detalleFood={foodList}
            foodResume={foodResume}
            foodDetalle={foodDetalle}
          />
        ) : (
          <OpcionesLote detalleCarga={detalleCarga} onClickMenu={onClickMenu} />
        )}
      </ModalApp>
      {downDif ? (
        <ExcelFile filename={"Lotes_conteo"} hideElement={true}>
          <ExcelSheet data={lotesActivos} name={"CONTEO - LOTES"}>
            <ExcelColumn label="LOTE" value="cmb_numero" />
            <ExcelColumn label="NOMBRE LOTE" value="cmp_nombre" />
            <ExcelColumn label="ACTIVOS" value="cmb_animales" />
            <ExcelColumn label="CONTEO" value="cmb_conteo" />
          </ExcelSheet>
        </ExcelFile>
      ) : null}
    </>
  );
}
