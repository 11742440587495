import React, { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Upload,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  InputNumber,
} from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";

import moment from "moment";

//Api para mapas
/*AIzaSyCadN8K3_yCdcm9vjrngpEBHMvdydLPt0E*/

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";

export default function CreateActivity(props) {
  const {
    currentUser,
    closeModal,
    tipoModal,
    setStateAgn,
    setShowBoard,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [idAsigna, setIdAsigna] = useState(0);
  const [idAutoriza, setIdAutoriza] = useState(0);
  const [atvRepite, setAtvRepite] = useState(0);
  const [nota, setNota] = useState("");
  const [fechaEntr, setFechaEntr] = useState("");

  //Estados para subida de foto
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileListB64, setFileListB64] = useState([]);

  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();

  const asignados = useSelector(
    (state) => state.enviroment.enviroment.gst_users
  );
  const autoriza = useSelector(
    (state) => state.enviroment.enviroment.gst_users
  );

  //Funciones para gestion de calendarios
  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().subtract(1, "day").endOf("day");
  }
  // Fin calendarios

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(value, dateString) {
    setFechaEntr(dateString.toString());
    //message.success(dateString.toString());
    //message.success(dateString.toString().substr(0, 16));
  }

  //Se actualiza el nombre
  const updateAsigna = async (value) => {
    setIdAsigna(value);
  };

  const updateAutoriza = async (value) => {
    setIdAutoriza(value);
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  const onChangeRepite = (value) => {
    if (Number.parseInt(value) >= 0) {
      setAtvRepite(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  //Estilo para la cuadricula de imagen y funciones de foto
  const gridStyle = {
    width: "20%",
    textAlign: "center",
  };

  //Convierte la imagen en base 64
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Accion para Obtener las nuevas imagenes
  const handleBefore = async (file) => {
    ///////////////////////////////////////////
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Solo se permiten imagenes JPG/PNG!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error("Solo esta permitido archivos de Menos 3MB!");
      return false;
    }
    ///////////////////////////////////////////

    let imgBase64 = await getBase64(file); // Obtengo la conversion de la Imagen a Base 64
    console.log(imgBase64.substr(0, 50));

    // Convierto la imagen en Base64 para el envio a Webservices
    //let imgb64end = imgBase64.split(";base64,"); // Para solo tomar el objeto [1] para la imagen base64 limpia

    // Seteo de Valores para el Modal
    setPreviewTitle(file.name);
    setPreviewImage(imgBase64);

    // le Creo un uid al Objeto de la Nueva Imagen
    let uid = ((Math.random() * 16) | 0).toString(16);

    // Creo nuevo Objeto con la Imagen Nueva
    let newImg = { uid: uid, name: file.name, status: "done", url: imgBase64 };
    setFileListB64([...fileListB64, { base64: imgBase64 }]); // Guarda la Imagen para base64 Final
    setFileList([...fileList, newImg]); // Guarda la nueva imagen
  };

  // Cambio de Imagen
  const handleChange = (file) => {
    //console.log(fileList);
    //console.log(fileListB64);
  };

  // Imagen Preview
  const handlePreview = async (file) => {
    setPreviewImage(file.url);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  };

  const handleCancel = () => setPreviewVisible(false);

  // Elimino Imagen
  const onRemove = (file) => {
    // Borrar el Objeto Principal
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    var removed = newFileList.splice(index, 1);
    setFileList(newFileList);
    console.log(index);

    // Borrar el Objeto Base64
    const newFileListB64 = fileListB64.slice();
    var removedB64 = newFileListB64.splice(index, 1);
    setFileListB64(newFileListB64);
  };

  // Mostar Boton Upload
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Subir</div>
    </div>
  );

  //Fin funciones de foto

  // ENVIO A WEBSERVICES
  const uploadTarea = async () => {
    setLoadingButton(true);
    if (
      Number.parseInt(idAsigna) === 0 ||
      fechaEntr.length === 0 ||
      nota.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "AGENDAR ACTIVIDAD",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      if (Number.parseInt(idAsigna) === Number.parseInt(idAutoriza)) {
        showNotification(
          "topRight",
          2,
          "AGENDAR ACTIVIDAD",
          "EL USUARIO ASIGNADO Y EL QUE AUTORIZA DEBEN SER DIFERENTES"
        );
      } else {
        if (Number.parseInt(currentUser.id) === Number.parseInt(idAutoriza)) {
          showNotification(
            "topRight",
            2,
            "AGENDAR ACTIVIDAD",
            "EL USUARIO QUE REGISTRA Y EL QUE AUTORIZA DEBEN SER DIFERENTES"
          );
        } else {
          let record = {
            usr_rgs: currentUser.id,
            tac_id: tipoModal,
            usr_asg: idAsigna,
            usr_apr: idAutoriza,
            atv_nota: nota,
            atv_fecha: fechaEntr,
            atv_repetir: atvRepite,
            atv_tipo: 1,
            atv_imagen1: fileList.length > 0 ? fileList[0].url : "NA",
            atv_imagen2: fileList.length > 1 ? fileList[1].url : "NA",
            atv_imagen3: fileList.length > 2 ? fileList[2].url : "NA",
            atv_imagen4: fileList.length > 3 ? fileList[3].url : "NA",
          };
          var val_agenda = await FRM.sendActividad(currentUser, record);
          if (val_agenda.type && Number.parseInt(val_agenda.type) > 0) {
            setStateAgn(val_agenda.datos);
            setShowBoard(Math.random());
            showNotification(
              "topRight",
              4,
              "AGENDAR ACTIVIDAD",
              "PROCESO DE REGISTRO EXITOSO"
            );
          } else {
            showNotification(
              "topRight",
              2,
              "AGENDAR ACTIVIDAD",
              val_agenda.message
            );
          }
        }
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {tipoModal === 1
          ? "AGENDAR LLEGADA DE GANADO"
          : tipoModal === 2
          ? "PROGRAMAR ALIMENTACION"
          : tipoModal === 3
          ? "PROGRAMAR DESPARASITACION"
          : tipoModal === 4
          ? "PROGRAMAR VACUNACION"
          : tipoModal === 5
          ? "AGENDAR SALIDA DE GANADO"
          : "PROGRAMAR TAREA DE CAMPO"}
      </Title>
      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col span={20}>
            <Form.Item label="Fecha y Hora de Ejecucion">
              <RangePicker
                disabledDate={disabledDate}
                style={{ width: "100%" }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm"),
                    moment("11:59:59", "HH:mm"),
                  ],
                }}
                format="YYYY-MM-DD HH:mm"
                onChange={onChangeFecha}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Repetir">
              <InputNumber
                style={{ width: "100%", textAlign: "right" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                min={0}
                defaultValue={atvRepite}
                value={atvRepite}
                onChange={onChangeRepite}
                placeholder="Repetir"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Tarea o Actividad Asignada a?">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={
                  idAsigna === 0 ? "SELECCIONE USUARIO A ASIGNAR" : idAsigna
                }
                placeholder="USUARIO ASIGNADO"
                onChange={updateAsigna}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {asignados.map((asigna) => (
                  <Option key={asigna.id} value={asigna.id}>
                    {asigna.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Tarea o Actividad Autorizada por?">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={
                  idAutoriza === 0
                    ? "SELECCIONE USUARIO QUE AUTORIZA"
                    : idAutoriza
                }
                placeholder="USUARIO ASIGNADO"
                onChange={updateAutoriza}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {autoriza.map((autor) => (
                  <Option key={autor.id} value={autor.id}>
                    {autor.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <TextArea
            rows={6}
            value={nota}
            placeholder={"Observaciones"}
            onChange={onChangeNota}
            allowClear
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Upload
        listType="picture-card"
        fileList={fileList}
        beforeUpload={handleBefore}
        onChange={handleChange}
        onPreview={handlePreview}
        onRemove={onRemove}
      >
        {fileList.length >= 4 ? null : uploadButton}
      </Upload>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => uploadTarea()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={curStyle.btnLila}
            shape="round"
            block
            loading={loadingButton}
          >
            <UploadOutlined /> REGISTRAR LA PROGRAMACION
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
