import React, { useState, useEffect } from "react";
import { Table, Typography, Row, Col, Statistic, Divider } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

//Styles
import "./SalidasAnm.scss";

export default function DetalleSalida(props) {
  const { myNumber, detalleModal, detalleList, totalKg } = props;
  const [dataDef, setDataDef] = useState([]);

  useEffect(() => {
    setDataDef(detalleList);
  }, [detalleModal]);

  const { Title } = Typography;

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "ID", //10%
      dataIndex: "anm_id",
      key: "anm_id",
    },
    {
      title: "DISPOSITIVO", //10%
      dataIndex: "anm_codigo",
      key: "anm_codigo",
    },
    {
      title: "CATEGORIA", //10%
      dataIndex: "ctg_nombre",
      key: "ctg_nombre",
    },
    {
      title: "PESO",
      dataIndex: "anm_peso_neto",
      key: "anm_peso_neto",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.anm_peso_neto);
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"DETALLES SALIDA DE " + detalleModal.cmp_nombre}
      </Title>
      <Row gutter={8}>
        <Col span={6}>
          <Statistic
            title="Fecha"
            value={detalleModal.sal_fecha}
            prefix={<CalendarOutlined />}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Comprador o Destinatario"
            value={detalleModal.trc_nombre}
          />
        </Col>
        <Col span={8}>
          <Statistic title="Motivo de Salida" value={detalleModal.mtm_nombre} />
        </Col>
        <Col span={2}>
          <Statistic title="Animales" value={detalleModal.sal_cantidad} />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Table
        columns={header}
        dataSource={dataDef}
        bordered
        size="middle"
        scroll={{ y: 640 }}
      />
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <Statistic
            value={
              detalleModal.sal_nota + " (" + myNumber(1, totalKg, 2) + " Kg)"
            }
          />
        </Col>
      </Row>
    </div>
  );
}
