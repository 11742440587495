import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Typography,
  Row,
  Col,
  Tag,
  Statistic,
  Divider,
  Space,
  Descriptions,
} from "antd";
import { CalendarOutlined, DeleteOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import "./SalidasAnm.scss";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { colorSec, colorPrm, colorGrn } from "../../../constants";
import CST from "../../../utils/CustomSettings";

export default function DetalleFactura(props) {
  const { myNumber, detalleModal, detalleList } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataDef, setDataDef] = useState([]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const { Title } = Typography;

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="medium"
          shape="circle"
        >
          {record.sal_numero}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "sal_fecha",
      key: "sal_fecha",
    },
    {
      title: "SALIDA DESDE",
      dataIndex: "cmp_nombre",
      key: "cmp_nombre",
    },
    {
      title: "ANIMALES",
      dataIndex: "sal_cantidad",
      key: "sal_cantidad",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="green" style={{ fontSize: 15 }}>
            {myNumber(1, row.sal_cantidad)}
          </Tag>
        );
      },
    },
    {
      title: "TOTAL KG",
      dataIndex: "sal_kilos",
      key: "sal_kilos",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="geekblue" style={{ fontSize: 15 }}>
            {myNumber(1, row.sal_kilos)}
          </Tag>
        );
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"DETALLE DE LA FACTURA Nº - " + detalleModal.fac_numero}
      </Title>
      <Row gutter={8}>
        <Col span={4}>
          <Statistic
            title="Fecha"
            value={detalleModal.fac_fecha}
            prefix={<CalendarOutlined />}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Cliente"
            value={detalleModal.trc_nombre}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="Guia"
            value={detalleModal.fac_guia}
            groupSeparator=""
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Destino"
            value={detalleModal.fac_destino}
            valueStyle={{ fontSize: 18 }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Row gutter={8}>
        <Col span={4}>
          <Statistic
            title="Animales"
            value={myNumber(1, detalleModal.fac_animales)}
            valueStyle={{
              fontSize: 20,
              fontWeight: "bold",
              color: CST.greenBdy,
            }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="Kg Balanza"
            value={myNumber(1, detalleModal.fac_balanza)}
            valueStyle={{ fontSize: 20, color: CST.redBdy, fontWeight: "bold" }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="Kg En Pie"
            value={myNumber(1, detalleModal.fac_enpie)}
            valueStyle={{
              color: CST.blueBdy,
              fontSize: 20,
              fontWeight: "bold",
            }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="Kg Promedio"
            value={myNumber(
              1,
              detalleModal.fac_enpie / detalleModal.fac_animales
            )}
            valueStyle={{
              fontSize: 20,
              color: CST.greenBdy,
              fontWeight: "bold",
            }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="% Rendimiento"
            value={myNumber(1, detalleModal.fac_rinde) + "%"}
            valueStyle={{
              fontSize: 20,
              color: CST.blueFoo,
              fontWeight: "bold",
            }}
          />
        </Col>
        <Col span={4}>
          <Statistic
            title="Kg Netos"
            value={myNumber(1, detalleModal.fac_kg_neto)}
            valueStyle={{
              fontSize: 20,
              color: CST.redBdy,
              fontWeight: "bold",
            }}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }}>
        SALIDAS FACTURADAS
      </Divider>
      <Table
        columns={columns}
        dataSource={detalleList}
        style={{ padding: 3, marginTop: 5 }}
      />
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Descriptions bordered size="small">
        <Descriptions.Item
          label="US$ Precio Kg"
          span={3}
          labelStyle={{
            textAlign: "right",
            fontSize: 16,
            fontWeight: "bolder",
          }}
          contentStyle={{
            textAlign: "right",
            fontSize: 16,
            fontWeight: "bolder",
            color: CST.greenBdy,
          }}
        >
          {myNumber(1, detalleModal.fac_precio_kg)}
        </Descriptions.Item>
        <Descriptions.Item
          label="US$ Subtotal"
          span={3}
          labelStyle={{
            textAlign: "right",
            fontSize: 16,
            fontWeight: "bolder",
          }}
          contentStyle={{
            textAlign: "right",
            fontSize: 16,
            fontWeight: "bolder",
            color: CST.greenBdy,
          }}
        >
          {myNumber(1, detalleModal.fac_precio_kg * detalleModal.fac_kg_neto)}
        </Descriptions.Item>
        <Descriptions.Item
          label="IMEBA, IVA, MEVIR, INIA"
          span={3}
          labelStyle={{
            textAlign: "right",
            fontSize: 16,
            fontWeight: "bolder",
          }}
          contentStyle={{
            textAlign: "right",
            fontSize: 16,
            fontWeight: "bolder",
            color: CST.redBdy,
          }}
        >
          {myNumber(
            1,
            Number.parseFloat(detalleModal.fac_imeba) +
              Number.parseFloat(detalleModal.fac_iva) +
              Number.parseFloat(detalleModal.fac_mevir) +
              Number.parseFloat(detalleModal.fac_inia)
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label="Comision"
          span={3}
          labelStyle={{
            textAlign: "right",
            fontSize: 16,
            fontWeight: "bolder",
          }}
          contentStyle={{
            textAlign: "right",
            fontSize: 16,
            fontWeight: "bolder",
            color: CST.redBdy,
          }}
        >
          {myNumber(1, detalleModal.fac_comision)}
        </Descriptions.Item>
        <Descriptions.Item
          label="US$ Total a Pagar"
          span={3}
          labelStyle={{
            textAlign: "right",
            fontSize: 16,
            fontWeight: "bolder",
          }}
          contentStyle={{
            textAlign: "right",
            fontSize: 16,
            fontWeight: "bolder",
            color: CST.greenBdy,
          }}
        >
          {myNumber(1, detalleModal.fac_total)}
        </Descriptions.Item>
      </Descriptions>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <Statistic
            valueStyle={{
              fontSize: 16,
            }}
            value={detalleModal.fac_nota}
          />
        </Col>
      </Row>
    </div>
  );
}
