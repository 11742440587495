import React, { useState } from "react";
import {
  Button,
  Typography,
  Input,
  Row,
  Col,
  Popconfirm,
  Divider,
  Statistic,
  Image,
  Tabs,
  message,
  Upload,
} from "antd";
import {
  CalendarOutlined,
  LikeOutlined,
  ClockCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

// componentes
import FRM from "../../../utils/FrmService";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";
import { URL_FILES } from "../../../constants";

export default function ApproveInform(props) {
  const { detalleModal, currentUser, closeModal, setStateInf } = props;
  const [nota, setNota] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);

  //Estados para subida de foto
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileListB64, setFileListB64] = useState([]);

  const { Title } = Typography;
  const { TextArea } = Input;
  const { TabPane } = Tabs;

  const estado =
    detalleModal.inf_estado === 0 ? " (POR APROBAR)" : " (APROBADA)";

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  const openAdjunto = (pdf_file) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp = URL_FILES + currentUser.dominio + "/informes/" + pdf_file;
    window.open(`${url_temp}`);
  };

  //Funciones de Fotos
  //Estilo para la cuadricula de imagen y funciones de foto
  const gridStyle = {
    width: "20%",
    textAlign: "center",
  };

  //Convierte la imagen en base 64
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Accion para Obtener las nuevas imagenes
  const handleBefore = async (file) => {
    ///////////////////////////////////////////
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Solo se permiten imagenes JPG/PNG!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error("Solo esta permitido archivos de Menos 3MB!");
      return false;
    }
    ///////////////////////////////////////////

    let imgBase64 = await getBase64(file); // Obtengo la conversion de la Imagen a Base 64
    console.log(imgBase64.substr(0, 50));

    // Convierto la imagen en Base64 para el envio a Webservices
    //let imgb64end = imgBase64.split(";base64,"); // Para solo tomar el objeto [1] para la imagen base64 limpia

    // Seteo de Valores para el Modal
    setPreviewTitle(file.name);
    setPreviewImage(imgBase64);

    // le Creo un uid al Objeto de la Nueva Imagen
    let uid = ((Math.random() * 16) | 0).toString(16);

    // Creo nuevo Objeto con la Imagen Nueva
    let newImg = { uid: uid, name: file.name, status: "done", url: imgBase64 };
    setFileListB64([...fileListB64, { base64: imgBase64 }]); // Guarda la Imagen para base64 Final
    setFileList([...fileList, newImg]); // Guarda la nueva imagen
  };

  // Cambio de Imagen
  const handleChange = (file) => {
    //console.log(fileList);
    //console.log(fileListB64);
  };

  // Imagen Preview
  const handlePreview = async (file) => {
    setPreviewImage(file.url);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  };

  const handleCancel = () => setPreviewVisible(false);

  // Elimino Imagen
  const onRemove = (file) => {
    // Borrar el Objeto Principal
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    var removed = newFileList.splice(index, 1);
    setFileList(newFileList);
    console.log(index);

    // Borrar el Objeto Base64
    const newFileListB64 = fileListB64.slice();
    var removedB64 = newFileListB64.splice(index, 1);
    setFileListB64(newFileListB64);
  };

  // Mostar Boton Upload
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Subir</div>
    </div>
  );

  //Fin funciones de foto

  // ENVIO A WEBSERVICES
  const uploadTarea = async () => {
    setLoadingButton(true);
    if (nota.length === 0) {
      showNotification(
        "topRight",
        2,
        "APROBAR ACTIVIDAD",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      let record = {
        usr_id: currentUser.id,
        inf_id: detalleModal.inf_id,
        usr_asg: detalleModal.usr_asg,
        inf_nota_aprueba: nota,
        inf_tipo: 2,
        inf_fotos: detalleModal.inf_fotos,
        inf_imagen1: fileList.length > 0 ? fileList[0].url : "NA",
        inf_imagen2: fileList.length > 1 ? fileList[1].url : "NA",
        inf_imagen3: fileList.length > 2 ? fileList[2].url : "NA",
        inf_imagen4: fileList.length > 3 ? fileList[3].url : "NA",
      };
      var val_informe = await FRM.sendInforme(currentUser, record);
      if (val_informe.type && Number.parseInt(val_informe.type) > 0) {
        setStateInf(Math.random());
        showNotification(
          "topRight",
          4,
          "APROBAR INFORME",
          "PROCESO DE REGISTRO EXITOSO"
        );
      } else {
        showNotification("topRight", 2, "APROBAR INFORME", val_informe.message);
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        {"INFORME DIARIO DE ACTIVIDAD" + estado}
      </Title>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Tabs defaultActiveKey="1">
        <TabPane tab="GENERALES" key="1">
          <Row gutter={8}>
            <Col span={8}>
              <Statistic
                title="Fecha Inicio"
                value={
                  detalleModal.inf_dia_ini +
                  "-" +
                  detalleModal.inf_mes_ini +
                  "-" +
                  detalleModal.inf_ano_ini
                }
                prefix={<CalendarOutlined />}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Hora Inicio"
                value={
                  detalleModal.inf_hora_ini + ":" + detalleModal.inf_min_ini
                }
                prefix={<ClockCircleOutlined />}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Fecha Final"
                value={
                  detalleModal.inf_dia_fin +
                  "-" +
                  detalleModal.inf_mes_fin +
                  "-" +
                  detalleModal.inf_ano_fin
                }
                prefix={<CalendarOutlined />}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Hora Final"
                value={
                  detalleModal.inf_hora_fin + ":" + detalleModal.inf_min_fin
                }
                prefix={<ClockCircleOutlined />}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
          <Row gutter={8}>
            <Col span={24}>
              <Statistic
                title="Asunto de la actividad reportada"
                value={detalleModal.inf_asunto}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
          <Row gutter={8}>
            <Col span={12}>
              <Statistic
                title="Registrado por"
                value={detalleModal.registro}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
            <Col span={12}>
              <Statistic
                title="Dirigido a"
                value={detalleModal.aprueba}
                groupSeparator=""
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
          <Row gutter={8}>
            <Col span={24}>
              <Statistic
                title="Observaciones al Registrar:"
                value={detalleModal.inf_nota}
                valueStyle={{ fontSize: 18 }}
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
          <Row style={{ marginTop: 10 }}>
            <Col span={24}>
              <TextArea
                rows={6}
                value={nota}
                placeholder={"Observaciones al Aprobar"}
                onChange={onChangeNota}
                allowClear
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }}> PDFs adjuntos </Divider>
          <Row style={{ marginTop: 10 }} gutter={8}>
            <Col span={8}>
              {detalleModal.inf_adjunto1 ? (
                <Button
                  type="link"
                  size="large"
                  onClick={() => openAdjunto(detalleModal.inf_adjunto1)}
                >
                  {detalleModal.inf_adjunto1.substring(0, 10) + '...pdf'}
                </Button>
              ) : null}
            </Col>
            <Col span={8}>
              {detalleModal.inf_adjunto2 ? (
                <Button
                  type="link"
                  size="large"
                  onClick={() => openAdjunto(detalleModal.inf_adjunto2)}
                >
                  {detalleModal.inf_adjunto2.substring(0, 10) + '...pdf'}
                </Button>
              ) : null}
            </Col>
            <Col span={8}>
              {detalleModal.inf_adjunto3 ? (
                <Button
                  type="link"
                  size="large"
                  onClick={() => openAdjunto(detalleModal.inf_adjunto3)}
                >
                  {detalleModal.inf_adjunto3.substring(0, 10) + '...pdf'}
                </Button>
              ) : null}
            </Col>
          </Row>
          {detalleModal.inf_fotos < 4 ? (
            <>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              > Adjuntar Imegenes </Divider>
              <Upload
                listType="picture-card"
                fileList={fileList}
                beforeUpload={handleBefore}
                onChange={handleChange}
                onPreview={handlePreview}
                onRemove={onRemove}
              >
                {fileList.length >= 4 - detalleModal.inf_fotos
                  ? null
                  : uploadButton}
              </Upload>
            </>
          ) : null}
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
            <Popconfirm
              placement="top"
              title="APROBAR LA ACTIVIDAD?"
              onConfirm={() => uploadTarea()}
              okText="Si"
              cancelText="No"
            >
              <Button
                type="primary"
                className={curStyle.btnSky}
                shape="round"
                block
                loading={loadingButton}
              >
                <LikeOutlined /> APROBAR EL INFORME
              </Button>
            </Popconfirm>
          </div>
        </TabPane>
        <TabPane tab="ADJUNTOS" key="2">
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <Row gutter={8}>
            <Col span={6}>
              <Image
                width={200}
                src={
                  detalleModal.inf_imagen1
                    ? URL_FILES +
                    currentUser.dominio +
                    "/informes/" +
                    detalleModal.inf_imagen1
                    : URL_FILES + currentUser.dominio + "/fotos/default.png"
                }
              />
            </Col>
            <Col span={6}>
              <Image
                width={200}
                src={
                  detalleModal.inf_imagen2
                    ? URL_FILES +
                    currentUser.dominio +
                    "/informes/" +
                    detalleModal.inf_imagen2
                    : URL_FILES + currentUser.dominio + "/fotos/default.png"
                }
              />
            </Col>
            <Col span={6}>
              <Image
                width={200}
                src={
                  detalleModal.inf_imagen3
                    ? URL_FILES +
                    currentUser.dominio +
                    "/informes/" +
                    detalleModal.inf_imagen3
                    : URL_FILES + currentUser.dominio + "/fotos/default.png"
                }
              />
            </Col>
            <Col span={6}>
              <Image
                width={200}
                src={
                  detalleModal.inf_imagen4
                    ? URL_FILES +
                    currentUser.dominio +
                    "/informes/" +
                    detalleModal.inf_imagen4
                    : URL_FILES + currentUser.dominio + "/fotos/default.png"
                }
              />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
}
