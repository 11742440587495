import React, { useState, useEffect, useRef } from "react";
import { Table, Input, Button, Space, Typography } from "antd";
import Highlighter from "react-highlight-words";

import Fab from "@material-ui/core/Fab";
import { Add } from "@material-ui/icons";

//Iconos y constantes
import { SearchOutlined, LikeOutlined } from "@ant-design/icons";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../Modal";
import CST from "../../../utils/CustomSettings";
import DetalleInform from "./DetalleInform";
import ApproveInform from "./ApproveInform";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";
import curStyle from "../../../css/GlobalCmp.module.css";

//Estilos
import "./InformesDay.scss";

export default function InformesAdm(props) {
  const { setIsLoading } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [listInformes, setListInformes] = useState([]);
  const [stateInf, setStateInf] = useState(Math.random());

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      let datos = await FRM.getAgenda(currentUser, 3);
      if (datos.type && datos.type > 0) {
        setListInformes(datos.listado);
      } else {
        setListInformes([]);
        showNotification(
          "topRight",
          1,
          "INFORMES DIARIOS",
          "No hay informes REGISTRADOS"
        );
      }
      setIsLoading(false);
    })();
  }, [stateInf]);

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  //Nuevas funciones de busqueda de columnas
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  //Fin de nuevas funciones de busqueda

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
        >
          {record.inf_id}
        </Button>
      ),
    },
    {
      title: "INICIA", //10%
      dataIndex: "inf_finicio",
      key: "inf_finicio",
      ...getColumnSearchProps("inf_finicio"),
    },
    {
      title: "TERMINA", //10%
      dataIndex: "inf_ffinal",
      key: "inf_ffinal",
      ...getColumnSearchProps("inf_ffinal"),
    },
    {
      title: "ASUNTO",
      dataIndex: "inf_asunto",
      key: "inf_asunto",
    },
    {
      title: "REGISTRADO POR",
      dataIndex: "registro",
      key: "registro",
    },
    {
      title: "DIRIGIDO A",
      dataIndex: "aprueba",
      key: "aprueba",
    },
    {
      title: "ESTADO",
      dataIndex: "inf_estado",
      key: "inf_estado",
      render: (text, row, index) => {
        if (row.inf_estado === 0) {
          return "PENDIENTE";
        } else {
          return "APROBADO";
        }
      },
    },
    {
      title: "F. APRUEBA",
      dataIndex: "inf_faprueba",
      key: "inf_faprueba",
      render: (text, row, index) => {
        if (row.inf_estado === 1) {
          return row.inf_faprueba;
        } else {
          return "...";
        }
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<LikeOutlined />}
          onClick={() =>
            openModal(record, record.inf_estado === 0 ? 1 : 2, "900px")
          }
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listInformes}
        style={{ padding: 3, marginTop: 5 }}
      />
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 1 ? (
          <ApproveInform
            detalleModal={detalleModal}
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
          />
        ) : (
          <DetalleInform
            detalleModal={detalleModal}
            currentUser={currentUser}
          />
        )}
      </ModalApp>
    </div>
  );
}
