import React, { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Upload,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  InputNumber,
} from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";

import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

import moment from "moment";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

// componentes
import FRM from "../../../utils/FrmService";
import { URL_FILES } from "../../../constants";
import { showNotification } from "../../../utils/ShowNotification";
import curStyle from "../../../css/GlobalCmp.module.css";

export default function CreateInform(props) {
  const { currentUser, closeModal, setStateInf } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [idAutoriza, setIdAutoriza] = useState(0);
  const [nota, setNota] = useState("");
  const [asunto, setAsunto] = useState("");
  const [fechaEntr, setFechaEntr] = useState("");

  //Estados para subida de foto
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileListB64, setFileListB64] = useState([]);
  const [nameAdjunto1, setNameAdjunto1] = useState("");
  const [nameAdjunto2, setNameAdjunto2] = useState("");
  const [nameAdjunto3, setNameAdjunto3] = useState("");

  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();

  const autoriza = useSelector(
    (state) => state.enviroment.enviroment.gst_users_aut
  );

  //Se actualiza el nombre
  const onChangeAsunto = (e) => {
    let infAst = e.target.value;
    setAsunto(infAst.toUpperCase());
  };

  //Funciones para gestion de calendarios
  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().subtract(1, "day").endOf("day");
  }
  // Fin calendarios

  const { Title } = Typography;
  const { TextArea } = Input;

  //Cambio de fecha
  function onChangeFecha(value, dateString) {
    setFechaEntr(dateString.toString());
  }

  const updateAutoriza = async (value) => {
    setIdAutoriza(value);
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  //GESTION DE CARGAR DE ARCHIVOS PDF
  // specify upload params and url for your files
  const getUploadParams = ({ file, meta }) => {
    const body = new FormData();
    body.append("file", file);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return { url: "https://api.aal-erp.com/frm/api/712", body, headers };
  };
  const getUploadParams2 = ({ file, meta }) => {
    const body = new FormData();
    body.append("file", file);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return { url: "https://api.aal-erp.com/frm/api/712", body, headers };
  };
  const getUploadParams3 = ({ file, meta }) => {
    const body = new FormData();
    body.append("file", file);
    const headers = new Headers();
    headers.append("accept", "application/json");
    return { url: "https://api.aal-erp.com/frm/api/712", body, headers };
  };

  // called every time a file's `status` changes
  //const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }
  const handleChangeStatus = ({ meta, remove }, status) => {
    console.log(meta);
    if (status === "headers_received") {
      setNameAdjunto1(meta.name);
      message.success("CARGA DE ADJUNTO EXITOSA", 1);
      //alert(`${meta.name} uploaded!`);
      remove();
    } else if (status === "aborted") {
      message.error("CARGA DE ADJUNTO FALLIDA", 2);
    }
  };
  const handleChangeStatus2 = ({ meta, remove }, status) => {
    console.log(meta);
    if (status === "headers_received") {
      setNameAdjunto2(meta.name);
      message.success("CARGA DE ADJUNTO EXITOSA", 1);
      //alert(`${meta.name} uploaded!`);
      remove();
    } else if (status === "aborted") {
      message.error("CARGA DE ADJUNTO FALLIDA", 2);
    }
  };
  const handleChangeStatus3 = ({ meta, remove }, status) => {
    console.log(meta);
    if (status === "headers_received") {
      setNameAdjunto3(meta.name);
      message.success("CARGA DE ADJUNTO EXITOSA", 1);
      //alert(`${meta.name} uploaded!`);
      remove();
    } else if (status === "aborted") {
      message.error("CARGA DE ADJUNTO FALLIDA", 2);
    }
  };
  //FIN GESTION DE CARGA DE ARCHIVOS

  //Estilo para la cuadricula de imagen y funciones de foto
  const gridStyle = {
    width: "20%",
    textAlign: "center",
  };

  const openAdjunto = (pdf_file) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp = URL_FILES + currentUser.dominio + "/temporal/" + pdf_file;
    window.open(`${url_temp}`);
  };

  //Convierte la imagen en base 64
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Accion para Obtener las nuevas imagenes
  const handleBefore = async (file) => {
    ///////////////////////////////////////////
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Solo se permiten imagenes JPG/PNG!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 / 1024 < 3;
    if (!isLt2M) {
      message.error("Solo esta permitido archivos de Menos 3MB!");
      return false;
    }
    ///////////////////////////////////////////

    let imgBase64 = await getBase64(file); // Obtengo la conversion de la Imagen a Base 64
    console.log(imgBase64.substr(0, 50));

    // Convierto la imagen en Base64 para el envio a Webservices
    //let imgb64end = imgBase64.split(";base64,"); // Para solo tomar el objeto [1] para la imagen base64 limpia

    // Seteo de Valores para el Modal
    setPreviewTitle(file.name);
    setPreviewImage(imgBase64);

    // le Creo un uid al Objeto de la Nueva Imagen
    let uid = ((Math.random() * 16) | 0).toString(16);

    // Creo nuevo Objeto con la Imagen Nueva
    let newImg = { uid: uid, name: file.name, status: "done", url: imgBase64 };
    setFileListB64([...fileListB64, { base64: imgBase64 }]); // Guarda la Imagen para base64 Final
    setFileList([...fileList, newImg]); // Guarda la nueva imagen
  };

  // Cambio de Imagen
  const handleChange = (file) => {
    //console.log(fileList);
    //console.log(fileListB64);
  };

  // Imagen Preview
  const handlePreview = async (file) => {
    setPreviewImage(file.url);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  };

  const handleCancel = () => setPreviewVisible(false);

  // Elimino Imagen
  const onRemove = (file) => {
    // Borrar el Objeto Principal
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    var removed = newFileList.splice(index, 1);
    setFileList(newFileList);
    console.log(index);

    // Borrar el Objeto Base64
    const newFileListB64 = fileListB64.slice();
    var removedB64 = newFileListB64.splice(index, 1);
    setFileListB64(newFileListB64);
  };

  // Mostar Boton Upload
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Subir</div>
    </div>
  );

  //Fin funciones de foto

  // ENVIO A WEBSERVICES
  const uploadTarea = async () => {
    setLoadingButton(true);
    if (
      Number.parseInt(idAutoriza) === 0 ||
      fechaEntr.length === 0 ||
      nota.length === 0
    ) {
      showNotification(
        "topRight",
        2,
        "REGISTRAR INFORME DE ACTIVIDAD",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      if (Number.parseInt(currentUser.id) === Number.parseInt(idAutoriza)) {
        showNotification(
          "topRight",
          2,
          "AGENDAR ACTIVIDAD",
          "EL USUARIO QUE REGISTRA Y EL QUE APRUEBA DEBEN SER DIFERENTES"
        );
      } else {
        let record = {
          usr_rgs: currentUser.id,
          usr_apr: idAutoriza,
          inf_nota: nota,
          inf_asunto: asunto,
          inf_fecha: fechaEntr,
          inf_tipo: 1,
          inf_imagen1: fileList.length > 0 ? fileList[0].url : "NA",
          inf_imagen2: fileList.length > 1 ? fileList[1].url : "NA",
          inf_imagen3: fileList.length > 2 ? fileList[2].url : "NA",
          inf_imagen4: fileList.length > 3 ? fileList[3].url : "NA",
          inf_adjunto1: nameAdjunto1.length === 0 ? "NA" : nameAdjunto1,
          inf_adjunto2: nameAdjunto2.length === 0 ? "NA" : nameAdjunto2,
          inf_adjunto3: nameAdjunto3.length === 0 ? "NA" : nameAdjunto3,
        };
        var val_informe = await FRM.sendInforme(currentUser, record);
        if (val_informe.type && Number.parseInt(val_informe.type) > 0) {
          setStateInf(Math.random());
          showNotification(
            "topRight",
            4,
            "REGISTRAR INFORME",
            "PROCESO DE REGISTRO EXITOSO"
          );
        } else {
          showNotification(
            "topRight",
            2,
            "REGISTRAR INFORME",
            val_informe.message
          );
        }
      }
    }
    setLoadingButton(false);
    closeModal();
  };

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        REGISTRAR INFORME DIARIO
      </Title>
      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item label="Fecha y Hora de Ejecucion">
              <RangePicker
                disabledDate={disabledDate}
                style={{ width: "100%" }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm"),
                    moment("11:59:59", "HH:mm"),
                  ],
                }}
                format="YYYY-MM-DD HH:mm"
                onChange={onChangeFecha}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item label="Asunto del Informe (Max. 100 Caracteres)">
              <Input
                placeholder="Asunto del Informe"
                style={{ width: "100%" }}
                value={asunto}
                onChange={onChangeAsunto}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Informe Dirigido a?">
              <Select
                showSearch
                showArrow
                style={{ width: "100%" }}
                defaultValue={
                  idAutoriza === 0
                    ? "SELECCIONE USUARIO QUE REVISA Y APRUEBA"
                    : idAutoriza
                }
                placeholder="USUARIO DESTINO"
                onChange={updateAutoriza}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {autoriza.map((autor) => (
                  <Option key={autor.id} value={autor.id}>
                    {autor.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row style={{ marginTop: 10 }}>
        <Col span={24}>
          <TextArea
            rows={6}
            value={nota}
            placeholder={"Observaciones"}
            onChange={onChangeNota}
            allowClear
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Row style={{ marginTop: 10 }} gutter={2}>
        <Col span={8}>
          {nameAdjunto1.length > 0 ? (
            <Button
              type="link"
              size="large"
              onClick={() => openAdjunto(nameAdjunto1)}
            >
              {nameAdjunto1.substring(1, 10) + '...pdf'}
            </Button>
          ) : null}
          <div
            style={{
              textAlign: "center",
              marginTop: 20,
              border: 0,
            }}
          >
            <Dropzone
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              autoUpload={true}
              maxFiles={1}
              multiple={false}
              canCancel={false}
              accept=".pdf"
              inputContent={"Seleccione PDF 1"}
              inputWithFilesContent={"Agregar Archivos"}
              submitButtonContent={"Subir Archivos"}
            />
          </div>
        </Col>
        <Col span={8}>
          {nameAdjunto2.length > 0 ? (
            <Button
              type="link"
              size="large"
              onClick={() => openAdjunto(nameAdjunto2)}
            >
              {nameAdjunto2.substring(1, 10) + '...pdf'}
            </Button>
          ) : null}
          <div
            style={{
              textAlign: "center",
              marginTop: 20,
              border: 0,
            }}
          >
            <Dropzone
              getUploadParams={getUploadParams2}
              onChangeStatus={handleChangeStatus2}
              autoUpload={true}
              maxFiles={1}
              multiple={false}
              canCancel={false}
              accept=".pdf"
              inputContent={"Seleccione PDF 2"}
              inputWithFilesContent={"Agregar Archivos"}
              submitButtonContent={"Subir Archivos"}
            />
          </div>
        </Col>
        <Col span={8}>
          {nameAdjunto3.length > 0 ? (
            <Button
              type="link"
              size="large"
              onClick={() => openAdjunto(nameAdjunto3)}
            >
              {nameAdjunto3.substring(1, 10) + '...pdf'}
            </Button>
          ) : null}
          <div
            style={{
              textAlign: "center",
              marginTop: 20,
              border: 0,
            }}
          >
            <Dropzone
              getUploadParams={getUploadParams3}
              onChangeStatus={handleChangeStatus3}
              autoUpload={true}
              maxFiles={1}
              multiple={false}
              canCancel={false}
              accept=".pdf"
              inputContent={"Seleccione PDF 3"}
              inputWithFilesContent={"Agregar Archivos"}
              submitButtonContent={"Subir Archivos"}
            />
          </div>
        </Col>
      </Row>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <Upload
        listType="picture-card"
        fileList={fileList}
        beforeUpload={handleBefore}
        onChange={handleChange}
        onPreview={handlePreview}
        onRemove={onRemove}
      >
        {fileList.length >= 4 ? null : uploadButton}
      </Upload>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
        <Popconfirm
          placement="top"
          title="CONFIRMAR EL MOVIMIENTO?"
          onConfirm={() => uploadTarea()}
          okText="Si"
          cancelText="No"
        >
          <Button
            type="primary"
            className={curStyle.btnSuccess}
            shape="round"
            block
            loading={loadingButton}
          >
            <UploadOutlined /> REGISTRAR EL INFORME DE ACTIVIDAD
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
