import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Button,
  Table,
  Tag,
  Typography,
  Select,
  Input,
  Row,
  Col,
  Popconfirm,
  message,
  DatePicker,
  Divider,
  Space,
  InputNumber,
} from "antd";
import { useHistory } from "react-router-dom";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

import {
  ReloadOutlined,
  HomeOutlined,
  SearchOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

import moment from "moment";

//Componentes Propios
import ModalApp from "../../../Modal";
import DetalleGastos from "../../Procesos/CjbGastosFrm/DetalleGastos";
import DetalleEntrada from "../../DetalleEntrada";
import DetalleFactura from "../../SalidasAnm/DetalleFactura";

// componentes
import FRM from "../../../../utils/FrmService";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import CST from "../../../../utils/CustomSettings";
import { URL_FILES } from "../../../../constants";
import TableGastos from "../../TableGastos";

export default function CjbMovCaja(props) {
  const {
    myNumber,
    currentUser,
    currentSetup,
    setIsLoading,
    currentMoneda,
  } = props;
  const [fechaEntr, setFechaEntr] = useState("");
  const [resumenVtas, setResumenVtas] = useState([]);
  const [resumenCobros, setResumenCobros] = useState([]);
  const [resumenEntra, setResumenEntra] = useState([]);
  const [resumenCtas, setResumenCtas] = useState([]);
  const [totalesCtas, setTotalesCtas] = useState([]);
  const [ajustesBnc, setAjustesBnc] = useState([]);
  const [transferBnc, setTransferBnc] = useState([]);
  const [otrosIngFrn, setOtrosIngFrn] = useState([]);
  const [otrosIngLcl, setOtrosIngLcl] = useState([]);
  const [gastosTp1, setGastosTp1] = useState([]);
  const [gastosTp2, setGastosTp2] = useState([]);
  const [gastosTp3, setGastosTp3] = useState([]);
  const [gastosTp4, setGastosTp4] = useState([]);
  const [gastosTp5, setGastosTp5] = useState([]);
  const [gastosTp6, setGastosTp6] = useState([]);
  const [gastosTp7, setGastosTp7] = useState([]);
  const [gastosTp9, setGastosTp9] = useState([]);
  const [gastosTp11, setGastosTp11] = useState([]);
  const [gastosTp21, setGastosTp21] = useState([]);
  const [gastosTp31, setGastosTp31] = useState([]);
  const [gastosTp41, setGastosTp41] = useState([]);
  const [gastosTp51, setGastosTp51] = useState([]);
  const [gastosTp61, setGastosTp61] = useState([]);
  const [gastosTp71, setGastosTp71] = useState([]);
  const [gastosTp91, setGastosTp91] = useState([]);
  const [listFactura, setListFactura] = useState([]);
  const [listNotaDb, setListNotaDb] = useState([]);
  const [listNotaCr, setListNotaCr] = useState([]);
  const [listCobro, setListCobro] = useState([]);
  const [resumenFac, setResumenFac] = useState(0);
  const [resumenKgm, setResumenKgm] = useState(0);
  const [resumenNdb, setResumenNdb] = useState(0);
  const [resumenNcr, setResumenNcr] = useState(0);
  const [resumenCbr, setResumenCbr] = useState(0);
  const [detalleList, setDetalleList] = useState([]);
  const [isGetting, setIsGetting] = useState(false)
  //Constantes para modales
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});

  const history = useHistory();
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const { Title, Text } = Typography;

  //Cambio de fecha
  function onChangeFecha(value, dateString) {
    setFechaEntr(dateString.toString());
    message.info(dateString.toString(), 3);
  }

  const openAdjunto = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/gastos/" + record.meg_adjunto;
    window.open(`${url_temp}`);
  };

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  // TRAER MOVIMIENTOS
  const downloadCaja = async () => {
    if (fechaEntr.length === 0) {
      showNotification(
        "topRight",
        2,
        "MOVIMIENTOS DE CAJA",
        "LOS DATOS ESTAN INCOMPLETOS PARA EL INFORME"
      );
    } else {
      setIsGetting(true);
      let record = {
        tipoMov: 1,
        usr_rgs: currentUser.id,
        mov_fecha: fechaEntr,
      };
      var val_movcaja = await FRM.getMovCaja(currentUser, record);
      if (val_movcaja.type && Number.parseInt(val_movcaja.type) > 0) {
        setResumenEntra(val_movcaja.resumenEntra);
        setResumenCtas(val_movcaja.resumenCta);
        setTotalesCtas(val_movcaja.totalesCta);
        setAjustesBnc(val_movcaja.ajustesBnc);
        setTransferBnc(val_movcaja.transferBnc);
        setOtrosIngLcl(val_movcaja.otrosIngLcl);
        setOtrosIngFrn(val_movcaja.otrosIngFrn);
        setGastosTp1(val_movcaja.gastosTp1);
        setGastosTp2(val_movcaja.gastosTp2);
        setGastosTp3(val_movcaja.gastosTp3);
        setGastosTp4(val_movcaja.gastosTp4);
        setGastosTp5(val_movcaja.gastosTp5);
        setGastosTp6(val_movcaja.gastosTp6);
        setGastosTp7(val_movcaja.gastosTp7);
        setGastosTp9(val_movcaja.gastosTp9);
        setGastosTp11(val_movcaja.gastosTp11);
        setGastosTp21(val_movcaja.gastosTp21);
        setGastosTp31(val_movcaja.gastosTp31);
        setGastosTp41(val_movcaja.gastosTp41);
        setGastosTp51(val_movcaja.gastosTp51);
        setGastosTp61(val_movcaja.gastosTp61);
        setGastosTp71(val_movcaja.gastosTp71);
        setGastosTp91(val_movcaja.gastosTp91);
        setListFactura(val_movcaja.facturas);
        setListNotaDb(val_movcaja.notasdb);
        setListNotaCr(val_movcaja.notascr);
        setListCobro(val_movcaja.cobros);
        setResumenFac(val_movcaja.total_fac);
        setResumenKgm(val_movcaja.kilos_fac);
        setResumenNdb(val_movcaja.total_ndb);
        setResumenNcr(val_movcaja.total_ncr);
        setResumenCbr(val_movcaja.total_cbr);

        showNotification(
          "topRight",
          4,
          "MOVIMIENTOS DE CAJA",
          "PROCESO DE CONSULTA EXITOSO"
        );
      } else {
        showNotification(
          "topRight",
          2,
          "MOVIMIENTOS DE CAJA",
          val_movcaja.message
        );
      }
    }
    setIsGetting(false);
  };

  const getDetalle = async (record, opcion) => {
    setIsLoading(false);
    let datos = await FRM.getEntradaDet(currentUser, record.ent_numero, 1);
    if (datos.type && datos.type > 0) {
      setDetalleList(datos.listado);
      openModal(record, opcion, "90%");
    } else {
      showNotification(
        "topRight",
        2,
        "DETALLE DE ENTRADA",
        "No hay detalle disponible"
      );
    }
    setIsLoading(false);
  };

  const getDetalleFac = async (record, opcion) => {
    let datos = await FRM.getGastos(currentUser, 24, record.fac_numero);
    if (datos.type && datos.type > 0) {
      setDetalleList(datos.listado);
      openModal(record, opcion, "1100px");
    } else {
      showNotification(
        "topRight",
        2,
        "DETALLE SALIDA",
        "No hay Datos registrados"
      );
    }
  };

  const openAdjuntoFac = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/gastos/" + record.fac_adjunto;
    window.open(`${url_temp}`);
  };

  //Columnas de Entradas
  const entradaHdr = [
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          size="large"
          shape="circle"
          onClick={() => getDetalle(record, 2)}
        >
          {record.ent_numero}
        </Button>
      ),
    },
    {
      title: "FECHA",
      dataIndex: "ent_fecha",
      key: "ent_fecha",
    },
    {
      title: "PROVEEDOR",
      dataIndex: "trc_nombre",
      key: "trc_nombre",
    },
    {
      title: "ORIGEN",
      dataIndex: "ent_origen",
      key: "ent_origen",
    },
    {
      title: "DESTINO",
      dataIndex: "cmp_nombre",
      key: "cmp_nombre",
    },
    {
      title: "KILOS",
      dataIndex: "ent_kilos",
      key: "ent_kilos",
      width: "5%",
      align: "right",
      render: (text, record) => {
        return myNumber(1, record.ent_kilos);
      },
    },
    {
      title: "DESTARE",
      dataIndex: "ent_destare",
      key: "ent_destare",
      width: "5%",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.ent_destare) + "%";
      },
    },
    {
      title: "$ KILO",
      dataIndex: "ent_costo_kg",
      key: "ent_costo_kg",
      width: "5%",
      align: "right",
    },
    {
      title: "COMISION",
      dataIndex: "ent_prc_comision",
      key: "ent_prc_comision",
      width: "5%",
      align: "right",
      render: (text, row, index) => {
        return (
          myNumber(1, row.trc_comision > 0 ? row.ent_prc_comision : 0) + "%"
        );
      },
    },
    {
      title: "TRANSPORTE",
      dataIndex: "ent_transporte",
      key: "ent_transporte",
      width: "5%",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, row.trc_transporte > 0 ? row.ent_transporte : 0.0);
      },
    },
    {
      title: "ESTADO",
      dataIndex: "ent_estado",
      key: "ent_estado",
      width: "5%",
      align: "left",
      render: (text, row, index) => {
        return row.ent_estado === 0 ? (
          <Tag color="green">PENDIENTE</Tag>
        ) : row.ent_estado === 1 ? (
          <Tag color="geekblue">EN LOTE</Tag>
        ) : (
          <Tag color="volcano">CERRADA</Tag>
        );
      },
    },
  ];

  //Columnas resumen caja
  const resCuentas = [
    {
      title: "ID", //10%
      dataIndex: "cue_id",
      key: "cue_id",
      width: "2%",
    },
    {
      title: "NOMBRE",
      dataIndex: "cue_nombre",
      key: "cue_nombre",
    },
    {
      title: "ANTERIOR",
      dataIndex: "anterior",
      key: "anterior",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.anterior));
      },
    },
    {
      title: "ENTRA US$",
      dataIndex: "debitos",
      key: "debitos",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.debitos));
      },
    },
    {
      title: "SALE US$",
      dataIndex: "creditos",
      key: "creditos",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.creditos));
      },
    },
    {
      title: "ENTRA UYU$",
      dataIndex: "debitos_fr",
      key: "debitos_fr",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.debitos_fr));
      },
    },
    {
      title: "SALE UYU$",
      dataIndex: "creditos_fr",
      key: "creditos_fr",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.creditos_fr));
      },
    },
    {
      title: "SALDO",
      dataIndex: "saldo",
      key: "saldo",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.saldo));
      },
    },
  ];

  //Columnas ajustes de cuenta
  const ajustesHdr = [
    {
      title: "ID", //10%
      dataIndex: "mov_id",
      key: "mov_id",
      width: "2%",
    },
    {
      title: "FECHA", //10%
      dataIndex: "mov_fecha",
      key: "mov_fecha",
    },
    {
      title: "NOMBRE",
      dataIndex: "cue_nombre",
      key: "cue_nombre",
    },
    {
      title: "DETALLE",
      dataIndex: "mov_detalle",
      key: "mov_detalle",
    },
    {
      title: "REGISTRO",
      dataIndex: "registro",
      key: "registro",
    },
    {
      title: "ENTRA US$",
      dataIndex: "mov_debito",
      key: "mov_debito",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.mov_debito));
      },
    },
    {
      title: "SALE US$",
      dataIndex: "mov_credito",
      key: "mov_credito",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.mov_credito));
      },
    },
    {
      title: "ENTRA UYU$",
      dataIndex: "mov_debito_fr",
      key: "mov_debito_fr",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.mov_debito_fr));
      },
    },
    {
      title: "SALE UYU$",
      dataIndex: "mov_credito_fr",
      key: "mov_credito_fr",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.mov_credito_fr));
      },
    },
  ];

  //Columnas transferencias
  const transferHdr = [
    {
      title: "ID", //10%
      dataIndex: "trn_id",
      key: "trn_id",
      width: "2%",
    },
    {
      title: "FECHA", //10%
      dataIndex: "trn_fecha",
      key: "trn_fecha",
    },
    {
      title: "ORIGEN",
      dataIndex: "origen",
      key: "origen",
    },
    {
      title: "DESTINO",
      dataIndex: "destino",
      key: "destino",
    },
    {
      title: "REGISTRO",
      dataIndex: "registro",
      key: "registro",
    },
    {
      title: "SALE",
      dataIndex: "trn_importe",
      key: "trn_importe",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.trn_importe));
      },
    },
    {
      title: "T. CAMBIO",
      dataIndex: "trn_cambio",
      key: "trn_cambio",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.trn_cambio));
      },
    },
    {
      title: "INGRESA",
      dataIndex: "trn_importe_dst",
      key: "trn_importe_dst",
      align: "right",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.trn_importe_dst));
      },
    },
  ];

  //Columnas de Gastos
  const gastosHdr = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="large"
          shape="circle"
        >
          {record.meg_id}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "meg_fecha",
      key: "meg_fecha",
    },
    {
      title: "F. APRUEBA",
      dataIndex: "meg_faprueba",
      key: "meg_faprueba",
      render: (text, row, index) => {
        if (row.meg_faprueba) {
          return row.meg_faprueba.toString().substr(0, 10);
        } else {
          return "...";
        }
      },
    },
    {
      title: "F. PAGO",
      dataIndex: "meg_fpago",
      key: "meg_fpago",
      render: (text, row, index) => {
        if (row.meg_fpago) {
          return row.meg_fpago.toString().substr(0, 10);
        } else {
          return "...";
        }
      },
    },
    {
      title: "BENEFICIARIO", //10%
      dataIndex: "trc_nombre",
      key: "trc_nombre",
    },
    {
      title: "CONCEPTO",
      dataIndex: "cnc_nombre",
      key: "cnc_nombre",
    },
    {
      title: "AUTORIZA",
      dataIndex: "autoriza",
      key: "autoriza",
    },
    {
      title: "ESTADO",
      dataIndex: "meg_estado",
      key: "meg_estado",
      render: (text, row, index) => {
        return row.meg_estado === 0 ? (
          <Tag color="green">PENDIENTE</Tag>
        ) : row.meg_estado === 1 ? (
          <Tag color="geekblue">APROBADO</Tag>
        ) : (
          <Tag color="volcano">PAGADO</Tag>
        );
      },
    },
    {
      title: "MONTO",
      dataIndex: "meg_moneda",
      key: "meg_moneda",
      align: "right",
      render: (text, row, index) => {
        return row.meg_moneda === 1 ? (
          <Tag color="green">
            {currentMoneda.simbolo + myNumber(1, row.meg_importe)}
          </Tag>
        ) : (
          <Tag color="volcano">
            {currentMoneda.simbolo_frn + myNumber(1, row.meg_importe)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="large"
          shape="circle"
          icon={<FilePdfOutlined />}
          onClick={() =>
            record.meg_adjunto
              ? openAdjunto(record)
              : message.error("NO HAY ARCHIVO ADJUNTO", 1)
          }
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => openModal(record, 1, "900px")}
        />
      ),
    },
  ];

  //Columnas Facturas
  const facturasSal = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
        >
          {record.fac_numero}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "fac_fecha",
      key: "fac_fecha",
    },
    {
      title: "CLIENTE", //10%
      dataIndex: "trc_nombre",
      key: "trc_nombre",
    },
    {
      title: "REGISTRO",
      dataIndex: "registro",
      key: "registro",
    },
    {
      title: "ANIMALES",
      dataIndex: "fac_animales",
      key: "fac_animales",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="blue" style={{ fontSize: 15 }}>
            {myNumber(1, row.fac_animales)}
          </Tag>
        );
      },
    },
    {
      title: "TOTAL KG",
      dataIndex: "fac_kg_neto",
      key: "fac_kg_neto",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="purple" style={{ fontSize: 15 }}>
            {myNumber(1, row.fac_kg_neto, 2)}
          </Tag>
        );
      },
    },
    {
      title: "$US TOTAL",
      dataIndex: "fac_total",
      key: "fac_total",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 15 }}>
            {myNumber(1, row.fac_total, 2)}
          </Tag>
        );
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => getDetalleFac(record, 3)}
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="large"
          shape="circle"
          icon={<FilePdfOutlined />}
          onClick={() =>
            record.fac_adjunto
              ? openAdjuntoFac(record)
              : message.error("NO HAY ARCHIVO ADJUNTO", 1)
          }
        />
      ),
    },
  ];

  //Columnas Notas DB y CR
  const notasDbCr = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={
            record.ntc_tipo === 1 ? curStyle.btnWarning : curStyle.btnOrange
          }
          size="large"
          shape="circle"
        >
          {record.ntc_numero}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "ntc_fecha",
      key: "ntc_fecha",
    },
    {
      title: "CLIENTE", //10%
      dataIndex: "trc_nombre",
      key: "trc_nombre",
    },
    {
      title: "CONCEPTO", //10%
      dataIndex: "cnc_nombre",
      key: "cnc_nombre",
    },
    {
      title: "REGISTRO",
      dataIndex: "registro",
      key: "registro",
    },
    {
      title: "IMPORTE",
      dataIndex: "ntc_total",
      key: "ntc_total",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 15 }}>
            {myNumber(1, row.ntc_total, 2)}
          </Tag>
        );
      },
    },
  ];

  //Columnas Notas DB y CR
  const cobros = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDark}
          size="large"
          shape="circle"
        >
          {record.rcl_id}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "rcl_fecha",
      key: "rcl_fecha",
    },
    {
      title: "CLIENTE", //10%
      dataIndex: "trc_nombre",
      key: "trc_nombre",
    },
    {
      title: "CUENTA RECEPTORA", //10%
      dataIndex: "cue_nombre",
      key: "cue_nombre",
    },
    {
      title: "REGISTRO",
      dataIndex: "registro",
      key: "registro",
    },
    {
      title: "IMPORTE",
      dataIndex: "rcl_importe",
      key: "rcl_importe",
      align: "right",
      render: (text, row, index) => {
        return (
          <Tag color="cyan" style={{ fontSize: 15 }}>
            {myNumber(1, row.rcl_importe, 2)}
          </Tag>
        );
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Form layout="vertical" form={form}>
        <Row gutter={8}>
          <Col span={6}>
            <Form.Item label="PERIODO A CONSULTAR">
              <RangePicker
                size="large"
                ranges={{
                  Hoy: [moment(), moment()],
                  "Este Mes": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onChange={onChangeFecha}
              />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label=".">
              <Space>
                <Button
                  type="primary"
                  className={curStyle.btnLila}
                  shape="round"
                  icon={<ReloadOutlined />}
                  size="large"
                  onClick={() => downloadCaja()}
                  loading={isGetting}
                >
                  Procesar
                </Button>
                <Button
                  type="primary"
                  className={curStyle.btnSky}
                  shape="round"
                  icon={<HomeOutlined />}
                  size="large"
                  onClick={() => history.push(`/${currentUser.pagina}`)}
                >
                  Volver
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
      <div
        style={{
          height: window.innerHeight,
          overflow: "scroll",
          paddingBottom: 250,
          marginBottom: 250,
        }}
      >
        <div>
          <Row gutter={8}>
            <Col span={24}>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                {
                  <Title
                    level={4}
                    style={{ color: CST.lilaBdy, textAlign: "center" }}
                  >
                    {"ENTRADA DE ANIMALES DEL PERIODO"}
                  </Title>
                }
              </Divider>
              <Table
                columns={entradaHdr}
                dataSource={resumenEntra}
                style={{ padding: 3, marginTop: 5 }}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                {
                  <Title
                    level={4}
                    style={{ color: CST.lilaBdy, textAlign: "center" }}
                  >
                    {"VENTAS REGISTRADAS DEL PERIODO"}
                  </Title>
                }
              </Divider>
              <Table
                columns={facturasSal}
                dataSource={listFactura}
                pagination={false}
                style={{ padding: 3, marginTop: 5 }}
                summary={(pageData) => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={5}>
                          <Title level={4}>{"TOTALES"}</Title>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Title level={4} style={{ textAlign: "right" }}>
                            {"$" + myNumber(1, resumenKgm, 2)}
                          </Title>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Title level={4} style={{ textAlign: "right" }}>
                            {"$" + myNumber(1, resumenFac)}
                          </Title>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell colSpan={2}>
                          <Text>...</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                {
                  <Title
                    level={4}
                    style={{ color: CST.lilaBdy, textAlign: "center" }}
                  >
                    {"NOTAS CREDITO DEL PERIODO"}
                  </Title>
                }
              </Divider>
              <Table
                columns={notasDbCr}
                dataSource={listNotaDb}
                pagination={false}
                style={{ padding: 3, marginTop: 5 }}
                summary={(pageData) => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={5}>
                          <Title level={4}>{"TOTAL"}</Title>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Title level={4} style={{ textAlign: "right" }}>
                            {"$" + myNumber(1, resumenNdb, 2)}
                          </Title>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                {
                  <Title
                    level={4}
                    style={{ color: CST.lilaBdy, textAlign: "center" }}
                  >
                    {"NOTAS CREDITO DEL PERIODO"}
                  </Title>
                }
              </Divider>
              <Table
                columns={notasDbCr}
                dataSource={listNotaCr}
                pagination={false}
                style={{ padding: 3, marginTop: 5 }}
                summary={(pageData) => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={5}>
                          <Title level={4}>{"TOTAL"}</Title>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Title level={4} style={{ textAlign: "right" }}>
                            {"$" + myNumber(1, resumenNcr, 2)}
                          </Title>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                {
                  <Title
                    level={4}
                    style={{ color: CST.lilaBdy, textAlign: "center" }}
                  >
                    {"COBROS REGISTRADOS DEL PERIODO"}
                  </Title>
                }
              </Divider>
              <Table
                columns={cobros}
                dataSource={listCobro}
                pagination={false}
                style={{ padding: 3, marginTop: 5 }}
                summary={(pageData) => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={5}>
                          <Title level={4}>{"TOTAL"}</Title>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Title level={4} style={{ textAlign: "right" }}>
                            {"$" + myNumber(1, resumenCbr, 2)}
                          </Title>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp1}
                tableTitle={
                  "GASTOS OPERATIVOS MANTENIMIENTO ESTANCIA DEL PERIODO EN DOLARES"
                }
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo_frn}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp11}
                tableTitle={
                  "GASTOS OPERATIVOS MANTENIMIENTO ESTANCIA DEL PERIODO EN PESOS"
                }
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp2}
                tableTitle={"GASTOS DE CRIA DEL PERIODO EN DOLARES"}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo_frn}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp21}
                tableTitle={"GASTOS DE CRIA DEL PERIODO EN PESOS"}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp3}
                tableTitle={"GASTOS DE RECRIA DEL PERIODO EN DOLARES"}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo_frn}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp31}
                tableTitle={"GASTOS DE RECRIA DEL PERIODO EN PESOS"}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp4}
                tableTitle={"GASTOS DE FEEDLOT DEL PERIODO EN DOLARES"}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo_frn}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp41}
                tableTitle={"GASTOS DE FEEDLOT DEL PERIODO EN PESOS"}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp5}
                tableTitle={
                  "GASTOS RECUPERABLES INVERSION DEL PERIODO EN DOLARES"
                }
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo_frn}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp51}
                tableTitle={
                  "GASTOS RECUPERABLES INVERSION DEL PERIODO EN PESOS"
                }
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp6}
                tableTitle={
                  "GASTOS RECUPERABLES AGRICULTURA DEL PERIODO EN DOLARES"
                }
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp61}
                tableTitle={
                  "GASTOS RECUPERABLES AGRICULTURA DEL PERIODO EN PESOS"
                }
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp7}
                tableTitle={
                  "CUENTAS POR COBRAR A ACCIONISTAS EN DOLARES"
                }
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp71}
                tableTitle={
                  "CUENTAS POR COBRAR A ACCIONISTAS EN PESOS"
                }
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp9}
                tableTitle={"OTROS GASTOS DEL PERIODO EN DOLARES"}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <TableGastos
                tipoMoneda={currentMoneda.simbolo}
                openModal={openModal}
                openAdjunto={openAdjunto}
                listGastos={gastosTp91}
                tableTitle={"OTROS GASTOS DEL PERIODO EN PESOS"}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                {
                  <Title
                    level={4}
                    style={{ color: CST.lilaBdy, textAlign: "center" }}
                  >
                    {"AJUSTES A CAJAS Y BANCOS  DEL PERIODO"}
                  </Title>
                }
              </Divider>
              <Table
                columns={ajustesHdr}
                dataSource={ajustesBnc}
                style={{ padding: 3, marginTop: 5 }}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                {
                  <Title
                    level={4}
                    style={{ color: CST.lilaBdy, textAlign: "center" }}
                  >
                    {"TRANSFERENCIAS ENTRE CUENTAS DEL PERIODO"}
                  </Title>
                }
              </Divider>
              <Table
                columns={transferHdr}
                dataSource={transferBnc}
                style={{ padding: 3, marginTop: 5 }}
              />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Divider
                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
              >
                {
                  <Title
                    level={4}
                    style={{ color: CST.lilaBdy, textAlign: "center" }}
                  >
                    {"RESUMEN DE EFECTIVO Y CUENTAS BANCARIAS DEL PERIODO"}
                  </Title>
                }
              </Divider>
              <Table
                columns={resCuentas}
                dataSource={resumenCtas}
                style={{ padding: 3, marginTop: 5 }}
              />
            </Col>
          </Row>
        </div>
        <ModalApp
          isVisibleModal={isVisibleModal}
          closeModal={closeModal}
          widthModal={tipoModal === 4 ? 1307 : widthModal}
        >
          {tipoModal === 1 ? (
            <DetalleGastos
              currentUser={currentUser}
              closeModal={closeModal}
              setStateInf={Math.random()}
              currentSetup={currentSetup}
              myNumber={myNumber}
              detalleModal={detalleModal}
              currentMoneda={currentMoneda}
            />
          ) : tipoModal === 2 ? (
            <DetalleEntrada
              currentUser={currentUser}
              closeModal={closeModal}
              setIsLoading={setIsLoading}
              myNumber={myNumber}
              detalleCarga={detalleModal}
              opcionCarga={3}
              detalleList={detalleList}
            />
          ) : (
            <DetalleFactura
              myNumber={myNumber}
              detalleModal={detalleModal}
              detalleList={detalleList}
            />
          )}
        </ModalApp>
      </div>
    </div>
  );
}
