import React, { useState, useEffect } from "react";
import { Table, Input, Button, Space, message, Tag } from "antd";
import Highlighter from "react-highlight-words";

//Floating Buttons
import Fab from "@material-ui/core/Fab";
import { Add } from "@material-ui/icons";

//Iconos y constantes
import { SearchOutlined, FilePdfOutlined } from "@ant-design/icons";

//Componentes propios de detalle y actualizacion
import ModalApp from "../../../Modal";
import CST from "../../../../utils/CustomSettings";
import CreateDesparaInv from "./CreateDesparaInv";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

//Importaciones y componentes propios
import { showNotification } from "../../../../utils/ShowNotification";
import FRM from "../../../../utils/FrmService";
import curStyle from "../../../../css/GlobalCmp.module.css";
import { myNumber } from "../../../../utils/ArrayFunctions";
import { URL_FILES } from "../../../../constants";

//Estilos
import "./FrmInventario.scss";

export default function FrmVacunas(props) {
  const { setIsLoading } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [widthModal, setWidthModal] = useState("70%");
  const [tipoModal, setTipoModal] = useState(1);
  const [detalleModal, setDetalleModal] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState(0);
  const [listGastos, setListGastos] = useState([]);
  const [stateInf, setStateInf] = useState(Math.random());

  //useSelector para acceder al store
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const currentSetup = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.utlsetup
  );

  const currentMoneda = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );

  useEffect(() => {
    (async () => {
      setIsLoading(false);
      let datos = await FRM.getGastos(currentUser, 17);
      if (datos.type && datos.type > 0) {
        setListGastos(datos.listado);
      } else {
        showNotification(
          "topRight",
          1,
          "COMPRAS ANTIPARASITARIO",
          "No hay COMPRAS REGISTRADAS"
        );
      }
      setIsLoading(false);
    })();
  }, [stateInf]);

  const openModal = (record, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setDetalleModal(record);
    setIsVisibleModal(true);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };

  const openAdjunto = (record) => {
    //Url para visaulizacion del PDF Temporal
    const url_temp =
      URL_FILES + currentUser.dominio + "/alimentos/" + record.dsp_adjunto;
    window.open(`${url_temp}`);
  };

  const showNota = (record) => {
    showNotification(
      "topRight",
      1,
      "COMPRA DE ANTIPARASITARIO",
      record.dsp_nota
    );
  };

  //Funciones para busqueda en las columnas
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.focus());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  //Fin funciones para busqueda en las columnas

  //Columnas de la tabla
  const columns = [
    {
      title: "No",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnWarning}
          size="large"
          shape="circle"
        >
          {record.dsp_numero}
        </Button>
      ),
    },
    {
      title: "FECHA", //10%
      dataIndex: "dsp_fecha",
      key: "dsp_fecha",
      ...getColumnSearchProps("dsp_fecha"),
    },
    {
      title: "PROVEEDOR", //10%
      dataIndex: "trc_nombre",
      key: "trc_nombre",
      ...getColumnSearchProps("trc_nombre"),
    },
    {
      title: "FACTURA",
      dataIndex: "dsp_factura",
      key: "dsp_factura",
      ...getColumnSearchProps("dsp_factura"),
    },
    {
      title: "TIPO ANTIPARASITARIO",
      dataIndex: "atp_nombre",
      key: "atp_nombre",
      ...getColumnSearchProps("atp_nombre"),
    },
    {
      title: "CANTIDAD",
      dataIndex: "dsp_cantidad",
      key: "dsp_cantidad",
      align: "right",
      render: (text, row, index) => {
        return <Tag color="blue">{myNumber(1, row.dsp_cantidad, 0)}</Tag>;
      },
    },
    {
      title: "PRECIO UND",
      dataIndex: "dsp_costo_unt",
      key: "dsp_costo_unt",
      align: "right",
      render: (text, row, index) => {
        return <Tag color="volcano">{myNumber(1, row.dsp_costo_unt)}</Tag>;
      },
    },
    {
      title: "CTD. DOSIS",
      dataIndex: "dsp_dosis",
      key: "dsp_dosis",
      align: "right",
      render: (text, row, index) => {
        return <Tag color="green">{myNumber(1, row.dsp_dosis, 0)}</Tag>;
      },
    },
    {
      title: "Nº DE LOTE",
      dataIndex: "dsp_lote",
      key: "dsp_lote",
      render: (text, row, index) => {
        return <Tag color="cyan">{row.dsp_lote}</Tag>;
      },
    },
    {
      title: "VENCIMIENTO",
      dataIndex: "dsp_vence",
      key: "dsp_vence",
      render: (text, row, index) => {
        return <Tag color="magenta">{row.dsp_vence}</Tag>;
      },
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnDanger}
          size="large"
          shape="circle"
          icon={<FilePdfOutlined />}
          onClick={() =>
            record.dsp_adjunto
              ? openAdjunto(record)
              : message.error("NO HAY ARCHIVO ADJUNTO", 1)
          }
        />
      ),
    },
    {
      title: "+",
      key: "action",
      width: "2%",
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="large"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => showNota(record, 3, "900px")}
        />
      ),
    },
  ];

  //Renderizacion del componente
  return (
    <div className="main-content">
      <Table
        columns={columns}
        dataSource={listGastos}
        style={{ padding: 3, marginTop: 5 }}
      />
      <Fab
        color="primary"
        aria-label="add"
        style={{
          background: CST.yellowBdy,
          position: "absolute",
          left: 50,
          bottom: 20,
          display:
            Number.parseInt(currentUser.tipo) === 4 || currentUser.id === 2
              ? "block"
              : "none",
        }}
        onClick={() => openModal(null, 1, "900px")}
      >
        <Add />
      </Fab>
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        {tipoModal === 1 ? (
          <CreateDesparaInv
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
          />
        ) : tipoModal === 2 ? (
          <CreateDesparaInv
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
          />
        ) : (
          <CreateDesparaInv
            currentUser={currentUser}
            closeModal={closeModal}
            setStateInf={setStateInf}
            currentSetup={currentSetup}
            myNumber={myNumber}
            detalleModal={detalleModal}
            currentMoneda={currentMoneda}
          />
        )}
      </ModalApp>
    </div>
  );
}
