import React, { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Input,
  Row,
  Col,
  Popconfirm,
  Divider,
  InputNumber,
  Select,
  Statistic,
  Image,
  Switch,
  Tabs,
  message,
  Upload,
} from "antd";
import {
  CalendarOutlined,
  SaveOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

// componentes
import FRM from "../../../../utils/FrmService";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import CST from "../../../../utils/CustomSettings";

export default function CjbCreateIni(props) {
  const {
    currentUser,
    closeModal,
    setStateInf,
    currentSetup,
    myNumber,
    currentMoneda,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [nota, setNota] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  const [origenBnc, setOrigenBnc] = useState(0);
  const [destinoBnc, setDestinoBnc] = useState(0);
  const [valorTrf, setValorTrf] = useState(0);
  const [tasaCmb, setTasaCmb] = useState(currentSetup.sup_cambio);

  const { Title } = Typography;
  const { TextArea } = Input;
  const { Option } = Select;
  const { TabPane } = Tabs;
  const [form] = Form.useForm();

  const cuentasLcl = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.cuentas_lcl
  );

  const cuentasFrn = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.cuentas_frn
  );

  //Se actualiza cuenta de banco origen
  const updateOrg = async (value) => {
    setOrigenBnc(value);
    setValorTrf(0);
  };

  //Se actualiza cuenta de banco origen
  const updateDst = async (value) => {
    setDestinoBnc(value);
    setValorTrf(0);
  };

  const onChangeValor = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setValorTrf(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeTc = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setTasaCmb(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  //Se controlan los cambios de Tab
  const changeTab = (activeKey) => {
    setActiveKey(activeKey);
  };

  // ENVIO A WEBSERVICES
  const uploadTranfer = async (tipo) => {
    if (Number.parseInt(origenBnc) === 0) {
      showNotification(
        "topRight",
        2,
        "REGISTRAR AJUSTE A CUENTA",
        "DATOS INCOMPLETOS PARA EL REGISTRO"
      );
    } else {
      if (Number.parseFloat(valorTrf) === 0) {
        showNotification(
          "topRight",
          2,
          "REGISTRAR AJUSTE A CUENTA",
          "EL MONTO DEL AJUSTE NO PUEDE SER CERO"
        );
      } else {
        setLoadingButton(true);
        let record = {
          tipoMov: 14,
          ajt_tipo: tipo,
          usr_id: currentUser.id,
          cue_id: origenBnc,
          mov_valor: Number.parseFloat(valorTrf),
          mov_detalle: nota,
        };

        var val_gasto = await FRM.sendGasto(currentUser, record);
        if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
          setStateInf(Math.random());
          showNotification(
            "topRight",
            4,
            "AJUSTE A CUENTA",
            "REGISTRO EXITOSO DEL AJUSTE"
          );
        } else {
          showNotification("topRight", 2, "AJUSTE A CUENTA", val_gasto.message);
        }
        setLoadingButton(false);
        closeModal();
      }
    }
  };

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: CST.greenBdy, textAlign: "center" }}>
        {"AJUSTES A CAJAS Y CUENTAS"}
      </Title>
      <Tabs defaultActiveKey={activeKey} onChange={changeTab}>
        <TabPane
          tab={
            <span style={{ display: "flex", flexDirection: "row" }}>
              <Title
                className="h5-tile"
                level={4}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  marginBottom: 0,
                  color: activeKey === "1" ? CST.blueBdy : "#c2c2c2",
                }}
              >
                + EN DOLARES
              </Title>
            </span>
          }
          key="1"
        >
          <Form layout="vertical" form={form} style={{ marginTop: 10 }}>
            <Row gutter={16}>
              <Col span={18}>
                <Form.Item label="Cuenta Ajustada en el Proceso">
                  <Select
                    showSearch
                    showArrow
                    style={{ width: "100%" }}
                    defaultValue={origenBnc > 0 ? origenBnc : "ELIJA CUENTA"}
                    placeholder="CONCEPTO"
                    onChange={updateOrg}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cuentasLcl.map((origen) => (
                      <Option key={origen.cue_id} value={origen.cue_id}>
                        {origen.cue_nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Valor Ajustado">
                  <InputNumber
                    style={{ width: "100%", textAlign: "right" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    min={0}
                    defaultValue={valorTrf}
                    value={valorTrf}
                    onChange={onChangeValor}
                    placeholder="Deposito"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <TextArea
                  rows={2}
                  value={nota}
                  placeholder={"Observaciones"}
                  onChange={onChangeNota}
                  allowClear
                />
              </Col>
            </Row>
          </Form>
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
            <Popconfirm
              placement="top"
              title="APROBAR EL AJUSTE?"
              onConfirm={() => uploadTranfer(1)}
              okText="Si"
              cancelText="No"
            >
              <Button
                type="primary"
                className={curStyle.btnBlueLight}
                shape="round"
                block
                loading={loadingButton}
              >
                <SaveOutlined /> AJUSTE POSITIVO A CUENTA EN DOLARES
              </Button>
            </Popconfirm>
          </div>
        </TabPane>
        <TabPane
          tab={
            <span style={{ display: "flex", flexDirection: "row" }}>
              <Title
                className="h5-tile"
                level={4}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  marginBottom: 0,
                  color: activeKey === "2" ? CST.greenBdy : "#c2c2c2",
                }}
              >
                - EN DOLARES
              </Title>
            </span>
          }
          key="2"
        >
          <Form layout="vertical" form={form} style={{ marginTop: 10 }}>
            <Row gutter={16}>
              <Col span={16}>
                <Form.Item label="Cuenta Ajustada en el Proceso">
                  <Select
                    showSearch
                    showArrow
                    style={{ width: "100%" }}
                    defaultValue={origenBnc > 0 ? origenBnc : "ELIJA CUENTA"}
                    placeholder="CONCEPTO"
                    onChange={updateOrg}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cuentasLcl.map((origen) => (
                      <Option key={origen.cue_id} value={origen.cue_id}>
                        {origen.cue_nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Valor Ajustado">
                  <InputNumber
                    style={{ width: "100%", textAlign: "right" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    min={0}
                    defaultValue={valorTrf}
                    value={valorTrf}
                    onChange={onChangeValor}
                    placeholder="Deposito"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <TextArea
                  rows={2}
                  value={nota}
                  placeholder={"Observaciones"}
                  onChange={onChangeNota}
                  allowClear
                />
              </Col>
            </Row>
          </Form>
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
            <Popconfirm
              placement="top"
              title="APROBAR EL AJUSTE?"
              onConfirm={() => uploadTranfer(2)}
              okText="Si"
              cancelText="No"
            >
              <Button
                type="primary"
                className={curStyle.btnSuccess}
                shape="round"
                block
                loading={loadingButton}
              >
                <SaveOutlined /> AJUSTE NEGATIVO A CUENTA EN DOLARES
              </Button>
            </Popconfirm>
          </div>
        </TabPane>
        <TabPane
          tab={
            <span style={{ display: "flex", flexDirection: "row" }}>
              <Title
                className="h5-tile"
                level={4}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  marginBottom: 0,
                  color: activeKey === "3" ? CST.orangeBdy : "#c2c2c2",
                }}
              >
                + EN PESOS
              </Title>
            </span>
          }
          key="3"
        >
          <Form layout="vertical" form={form} style={{ marginTop: 10 }}>
            <Row gutter={16}>
              <Col span={16}>
                <Form.Item label="Cuenta Ajustada en el Proceso">
                  <Select
                    showSearch
                    showArrow
                    style={{ width: "100%" }}
                    defaultValue={origenBnc > 0 ? origenBnc : "ELIJA CUENTA"}
                    placeholder="CONCEPTO"
                    onChange={updateOrg}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cuentasFrn.map((origen) => (
                      <Option key={origen.cue_id} value={origen.cue_id}>
                        {origen.cue_nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Valor Ajustado">
                  <InputNumber
                    style={{ width: "100%", textAlign: "right" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    min={0}
                    defaultValue={valorTrf}
                    value={valorTrf}
                    onChange={onChangeValor}
                    placeholder="Deposito"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <TextArea
                  rows={2}
                  value={nota}
                  placeholder={"Observaciones"}
                  onChange={onChangeNota}
                  allowClear
                />
              </Col>
            </Row>
          </Form>
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
            <Popconfirm
              placement="top"
              title="APROBAR EL AJUSTE?"
              onConfirm={() => uploadTranfer(3)}
              okText="Si"
              cancelText="No"
            >
              <Button
                type="primary"
                className={curStyle.btnOrange}
                shape="round"
                block
                loading={loadingButton}
              >
                <SaveOutlined /> AJUSTE POSITIVO A CUENTA EN PESOS
              </Button>
            </Popconfirm>
          </div>
        </TabPane>
        <TabPane
          tab={
            <span style={{ display: "flex", flexDirection: "row" }}>
              <Title
                className="h5-tile"
                level={4}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  marginBottom: 0,
                  color: activeKey === "4" ? CST.darkBdy : "#c2c2c2",
                }}
              >
                - EN PESOS
              </Title>
            </span>
          }
          key="4"
        >
          <Form layout="vertical" form={form} style={{ marginTop: 10 }}>
            <Row gutter={16}>
              <Col span={16}>
                <Form.Item label="Cuenta Ajustada en el Proceso">
                  <Select
                    showSearch
                    showArrow
                    style={{ width: "100%" }}
                    defaultValue={origenBnc > 0 ? origenBnc : "ELIJA CUENTA"}
                    placeholder="CONCEPTO"
                    onChange={updateOrg}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cuentasFrn.map((origen) => (
                      <Option key={origen.cue_id} value={origen.cue_id}>
                        {origen.cue_nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Valor Ajustado">
                  <InputNumber
                    style={{ width: "100%", textAlign: "right" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    min={0}
                    defaultValue={valorTrf}
                    value={valorTrf}
                    onChange={onChangeValor}
                    placeholder="Deposito"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <TextArea
                  rows={2}
                  value={nota}
                  placeholder={"Observaciones"}
                  onChange={onChangeNota}
                  allowClear
                />
              </Col>
            </Row>
          </Form>
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
            <Popconfirm
              placement="top"
              title="APROBAR EL AJUSTE?"
              onConfirm={() => uploadTranfer(4)}
              okText="Si"
              cancelText="No"
            >
              <Button
                type="primary"
                className={curStyle.btnDark}
                shape="round"
                block
                loading={loadingButton}
              >
                <SaveOutlined /> AJUSTE NEGATIVO A CUENTA EN PESOS
              </Button>
            </Popconfirm>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}
