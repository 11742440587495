import React, { useState } from "react";
import {
  Form,
  Button,
  Typography,
  Input,
  Row,
  Col,
  Popconfirm,
  Divider,
  InputNumber,
  Select,
  DatePicker,
  Image,
  Switch,
  Tabs,
  message,
  Upload,
} from "antd";
import {
  CalendarOutlined,
  SaveOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useSelector } from "react-redux";

// componentes
import FRM from "../../../../utils/FrmService";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import CST from "../../../../utils/CustomSettings";

export default function CjbCreateTransfer(props) {
  const {
    currentUser,
    closeModal,
    setStateInf,
    currentSetup,
    myNumber,
    currentMoneda,
  } = props;
  const [loadingButton, setLoadingButton] = useState(false);
  const [nota, setNota] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  const [origenBnc, setOrigenBnc] = useState(0);
  const [destinoBnc, setDestinoBnc] = useState(0);
  const [valorTrf, setValorTrf] = useState(0);
  const [tasaCmb, setTasaCmb] = useState(currentSetup.sup_cambio);
  const [fechaTrn, setFechaTrn] = useState(null);

  const { Title } = Typography;
  const { TextArea } = Input;
  const { Option } = Select;
  const { TabPane } = Tabs;
  const [form] = Form.useForm();

  const cuentasLcl = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.cuentas_lcl
  );

  const cuentasFrn = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.cuentas_frn
  );

  //Cambio de fecha
  function onChangeFecha(date, dateString) {
    setFechaTrn(dateString);
  }

  //Se actualiza cuenta de banco origen
  const updateOrg = async (value) => {
    setOrigenBnc(value);
    setValorTrf(0);
  };

  //Se actualiza cuenta de banco origen
  const updateDst = async (value) => {
    setDestinoBnc(value);
    setValorTrf(0);
  };

  const onChangeValor = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setValorTrf(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeTc = (value) => {
    if (Number.parseFloat(value) >= 0) {
      setTasaCmb(value);
    } else {
      message.error("VALOR INCORRECTO!", 1);
      return false;
    }
  };

  const onChangeNota = (e) => {
    const notaE = e.target.value;
    setNota(notaE.toUpperCase());
  };

  //Se controlan los cambios de Tab
  const changeTab = (activeKey) => {
    setActiveKey(activeKey);
  };

  // ENVIO A WEBSERVICES
  const uploadTranfer = async (tipo) => {
    if (Number.parseInt(origenBnc) === Number.parseInt(destinoBnc)) {
      showNotification(
        "topRight",
        2,
        "REGISTRAR TRANSFERENCIA",
        "LAS CUENTAS ORIGEN Y DESTINO DEBEN SER DIFERENTES"
      );
    } else {
      if (Number.parseFloat(valorTrf) === 0) {
        showNotification(
          "topRight",
          2,
          "REGISTRAR TRANSFERENCIA",
          "EL MONTO DE LA TRANSFER NO PUEDE SER CERO"
        );
      } else {
        if (Number.parseFloat(tasaCmb) === 0) {
          showNotification(
            "topRight",
            2,
            "REGISTRAR TRANSFERENCIA",
            "LA TASA DE CAMBIO NO PUEDE SER CERO"
          );
        } else {
          if (
            fechaTrn.length === 0 ||
            Number.parseInt(origenBnc) === 0 ||
            Number.parseInt(destinoBnc) === 0
          ) {
            showNotification(
              "topRight",
              2,
              "REGISTRAR TRANSFERENCIA",
              "LA FECHA EL ORIGEN O EL DESTINO NO PUEDEN ESTAR VACIOS"
            );
          } else {
            setLoadingButton(true);
            let record = {
              tipoMov: 7,
              trn_tipo: tipo,
              usr_id: currentUser.id,
              trn_origen: origenBnc,
              trn_destino: destinoBnc,
              trn_importe: Number.parseFloat(valorTrf),
              trn_cambio: Number.parseFloat(tasaCmb),
              trn_nota: nota,
              trn_fecha: fechaTrn,
            };

            var val_gasto = await FRM.sendGasto(currentUser, record);
            if (val_gasto.type && Number.parseInt(val_gasto.type) > 0) {
              setStateInf(Math.random());
              showNotification(
                "topRight",
                4,
                "TRANSFERENCIA",
                "REGISTRO DE TRANSFER EXITOSO"
              );
            } else {
              showNotification(
                "topRight",
                2,
                "TRANSFERENCIA",
                val_gasto.message
              );
            }
            setLoadingButton(false);
            closeModal();
          }
        }
      }
    }
  };

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: CST.navyBdy, textAlign: "center" }}>
        {"TRANSFERENCIAS DE FONDOS"}
      </Title>
      <Tabs defaultActiveKey={activeKey} onChange={changeTab}>
        <TabPane
          tab={
            <span style={{ display: "flex", flexDirection: "row" }}>
              <Title
                className="h5-tile"
                level={4}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  marginBottom: 0,
                  color: activeKey === "1" ? CST.blueBdy : "#c2c2c2",
                }}
              >
                DE DOLARES A DOLARES
              </Title>
            </span>
          }
          key="1"
        >
          <Form layout="vertical" form={form} style={{ marginTop: 10 }}>
            <Row gutter={8}>
              <Col span={9}></Col>
              <Col span={6}>
                <Form.Item label="Fecha de Transferencia">
                  <DatePicker
                    onChange={onChangeFecha}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={9}></Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item label="Cuenta Emisora de los Fondos">
                  <Select
                    showSearch
                    showArrow
                    style={{ width: "100%" }}
                    defaultValue={origenBnc > 0 ? origenBnc : "ELIJA CUENTA"}
                    placeholder="CONCEPTO"
                    onChange={updateOrg}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cuentasLcl.map((origen) => (
                      <Option key={origen.cue_id} value={origen.cue_id}>
                        {origen.cue_nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label="Cuenta Receptora de los Fondos">
                  <Select
                    showSearch
                    showArrow
                    style={{ width: "100%" }}
                    defaultValue={destinoBnc > 0 ? destinoBnc : "ELIJA CUENTA"}
                    placeholder="CONCEPTO"
                    onChange={updateDst}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cuentasLcl.map((destino) => (
                      <Option key={destino.cue_id} value={destino.cue_id}>
                        {destino.cue_nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Valor Transfer">
                  <InputNumber
                    style={{ width: "100%", textAlign: "right" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    min={0}
                    defaultValue={valorTrf}
                    value={valorTrf}
                    onChange={onChangeValor}
                    placeholder="Deposito"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <TextArea
                  rows={2}
                  value={nota}
                  placeholder={"Observaciones"}
                  onChange={onChangeNota}
                  allowClear
                />
              </Col>
            </Row>
          </Form>
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
            <Popconfirm
              placement="top"
              title="APROBAR LA TRANSFER?"
              onConfirm={() => uploadTranfer(1)}
              okText="Si"
              cancelText="No"
            >
              <Button
                type="primary"
                className={curStyle.btnBlueLight}
                shape="round"
                block
                loading={loadingButton}
              >
                <SaveOutlined /> REGISTRAR TRANSFER DE DOLARES A DOLARES
              </Button>
            </Popconfirm>
          </div>
        </TabPane>
        <TabPane
          tab={
            <span style={{ display: "flex", flexDirection: "row" }}>
              <Title
                className="h5-tile"
                level={4}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  marginBottom: 0,
                  color: activeKey === "2" ? CST.greenBdy : "#c2c2c2",
                }}
              >
                DE DOLARES A PESOS UYU
              </Title>
            </span>
          }
          key="2"
        >
          <Form layout="vertical" form={form} style={{ marginTop: 10 }}>
            <Row gutter={8}>
              <Col span={9}></Col>
              <Col span={6}>
                <Form.Item label="Fecha de Transferencia">
                  <DatePicker
                    onChange={onChangeFecha}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={9}></Col>
            </Row>
            <Row gutter={16}>
              <Col span={9}>
                <Form.Item label="Cuenta Emisora de los Fondos">
                  <Select
                    showSearch
                    showArrow
                    style={{ width: "100%" }}
                    defaultValue={origenBnc > 0 ? origenBnc : "ELIJA CUENTA"}
                    placeholder="CONCEPTO"
                    onChange={updateOrg}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cuentasLcl.map((origen) => (
                      <Option key={origen.cue_id} value={origen.cue_id}>
                        {origen.cue_nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label="Cuenta Receptora de los Fondos">
                  <Select
                    showSearch
                    showArrow
                    style={{ width: "100%" }}
                    defaultValue={destinoBnc > 0 ? destinoBnc : "ELIJA CUENTA"}
                    placeholder="CONCEPTO"
                    onChange={updateDst}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cuentasFrn.map((destino) => (
                      <Option key={destino.cue_id} value={destino.cue_id}>
                        {destino.cue_nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="T. Cambio">
                  <InputNumber
                    style={{ width: "100%", textAlign: "right" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    min={0}
                    defaultValue={tasaCmb}
                    value={tasaCmb}
                    onChange={onChangeTc}
                    placeholder="Deposito"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Valor Transfer">
                  <InputNumber
                    style={{ width: "100%", textAlign: "right" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    min={0}
                    defaultValue={valorTrf}
                    value={valorTrf}
                    onChange={onChangeValor}
                    placeholder="Deposito"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <TextArea
                  rows={2}
                  value={nota}
                  placeholder={"Observaciones"}
                  onChange={onChangeNota}
                  allowClear
                />
              </Col>
            </Row>
          </Form>
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
            <Popconfirm
              placement="top"
              title="APROBAR LA TRANSFER?"
              onConfirm={() => uploadTranfer(2)}
              okText="Si"
              cancelText="No"
            >
              <Button
                type="primary"
                className={curStyle.btnSuccess}
                shape="round"
                block
                loading={loadingButton}
              >
                <SaveOutlined /> REGISTRAR LA TRANSFER DE DOLARES A PESOS
              </Button>
            </Popconfirm>
          </div>
        </TabPane>
        <TabPane
          tab={
            <span style={{ display: "flex", flexDirection: "row" }}>
              <Title
                className="h5-tile"
                level={4}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  marginBottom: 0,
                  color: activeKey === "3" ? CST.orangeBdy : "#c2c2c2",
                }}
              >
                DE PESOS A PESOS
              </Title>
            </span>
          }
          key="3"
        >
          <Form layout="vertical" form={form} style={{ marginTop: 10 }}>
            <Row gutter={8}>
              <Col span={9}></Col>
              <Col span={6}>
                <Form.Item label="Fecha de Transferencia">
                  <DatePicker
                    onChange={onChangeFecha}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={9}></Col>
            </Row>
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item label="Cuenta Emisora de los Fondos">
                  <Select
                    showSearch
                    showArrow
                    style={{ width: "100%" }}
                    defaultValue={origenBnc > 0 ? origenBnc : "ELIJA CUENTA"}
                    placeholder="CONCEPTO"
                    onChange={updateOrg}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cuentasFrn.map((origen) => (
                      <Option key={origen.cue_id} value={origen.cue_id}>
                        {origen.cue_nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label="Cuenta Receptora de los Fondos">
                  <Select
                    showSearch
                    showArrow
                    style={{ width: "100%" }}
                    defaultValue={destinoBnc > 0 ? destinoBnc : "ELIJA CUENTA"}
                    placeholder="CONCEPTO"
                    onChange={updateDst}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cuentasFrn.map((destino) => (
                      <Option key={destino.cue_id} value={destino.cue_id}>
                        {destino.cue_nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Valor Transfer">
                  <InputNumber
                    style={{ width: "100%", textAlign: "right" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    min={0}
                    defaultValue={valorTrf}
                    value={valorTrf}
                    onChange={onChangeValor}
                    placeholder="Deposito"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <TextArea
                  rows={2}
                  value={nota}
                  placeholder={"Observaciones"}
                  onChange={onChangeNota}
                  allowClear
                />
              </Col>
            </Row>
          </Form>
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
            <Popconfirm
              placement="top"
              title="APROBAR LA TRANSFER?"
              onConfirm={() => uploadTranfer(3)}
              okText="Si"
              cancelText="No"
            >
              <Button
                type="primary"
                className={curStyle.btnOrange}
                shape="round"
                block
                loading={loadingButton}
              >
                <SaveOutlined /> REGISTRAR LA TRANSFER DE PESOS A PESOS
              </Button>
            </Popconfirm>
          </div>
        </TabPane>
        <TabPane
          tab={
            <span style={{ display: "flex", flexDirection: "row" }}>
              <Title
                className="h5-tile"
                level={4}
                style={{
                  fontSize: 14,
                  marginLeft: 10,
                  marginBottom: 0,
                  color: activeKey === "4" ? CST.darkBdy : "#c2c2c2",
                }}
              >
                DE PESOS A DOLARES
              </Title>
            </span>
          }
          key="4"
        >
          <Form layout="vertical" form={form} style={{ marginTop: 10 }}>
            <Row gutter={8}>
              <Col span={9}></Col>
              <Col span={6}>
                <Form.Item label="Fecha de Transferencia">
                  <DatePicker
                    onChange={onChangeFecha}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={9}></Col>
            </Row>
            <Row gutter={16}>
              <Col span={9}>
                <Form.Item label="Cuenta Emisora de los Fondos">
                  <Select
                    showSearch
                    showArrow
                    style={{ width: "100%" }}
                    defaultValue={origenBnc > 0 ? origenBnc : "ELIJA CUENTA"}
                    placeholder="CONCEPTO"
                    onChange={updateOrg}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cuentasFrn.map((origen) => (
                      <Option key={origen.cue_id} value={origen.cue_id}>
                        {origen.cue_nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label="Cuenta Receptora de los Fondos">
                  <Select
                    showSearch
                    showArrow
                    style={{ width: "100%" }}
                    defaultValue={destinoBnc > 0 ? destinoBnc : "ELIJA CUENTA"}
                    placeholder="CONCEPTO"
                    onChange={updateDst}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cuentasLcl.map((destino) => (
                      <Option key={destino.cue_id} value={destino.cue_id}>
                        {destino.cue_nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="T. Cambio">
                  <InputNumber
                    style={{ width: "100%", textAlign: "right" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    min={0}
                    defaultValue={tasaCmb}
                    value={tasaCmb}
                    onChange={onChangeTc}
                    placeholder="Deposito"
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Valor Transfer">
                  <InputNumber
                    style={{ width: "100%", textAlign: "right" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    min={0}
                    defaultValue={valorTrf}
                    value={valorTrf}
                    onChange={onChangeValor}
                    placeholder="Deposito"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <TextArea
                  rows={2}
                  value={nota}
                  placeholder={"Observaciones"}
                  onChange={onChangeNota}
                  allowClear
                />
              </Col>
            </Row>
          </Form>
          <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
          <div style={{ display: "flex", alignContent: "left", marginTop: 5 }}>
            <Popconfirm
              placement="top"
              title="APROBAR LA TRANSFER?"
              onConfirm={() => uploadTranfer(4)}
              okText="Si"
              cancelText="No"
            >
              <Button
                type="primary"
                className={curStyle.btnDark}
                shape="round"
                block
                loading={loadingButton}
              >
                <SaveOutlined /> REGISTRAR LA TRANSFER DE PESOS A DOLARES
              </Button>
            </Popconfirm>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}
