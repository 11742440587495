import React, { useState } from "react";
import {
    Form,
    Button,
    Table,
    Tag,
    Typography,
    Select,
    Row,
    Col,
    message,
    DatePicker,
    Divider,
    Space,
    InputNumber,
} from "antd";
import { useHistory } from "react-router-dom";
import ReactExport from "react-export-excel";

import {
    ReloadOutlined,
    HomeOutlined,
    SearchOutlined,
    DownloadOutlined,
    UndoOutlined
} from "@ant-design/icons";

//Componentes Propios
import ModalApp from "../../../Modal";
import DetalleAnimal from "../../Inventario/DetalleAnimal";

// componentes
import FRM from "../../../../utils/FrmService";
import { showNotification } from "../../../../utils/ShowNotification";
import curStyle from "../../../../css/GlobalCmp.module.css";
import CST from "../../../../utils/CustomSettings";
import { URL_FILES } from "../../../../constants";

export default function CjbMovBanco(props) {
    const {
        myNumber,
        currentUser,
        currentSetup,
        setIsLoading,
        currentMoneda,
        listBancos,
    } = props;
    const [movimientos, setMovimientos] = useState([]);
    const [resumen, setResumen] = useState({
        peso_neto: 0,
        kilos_gen: 0,
        kilos_dia: 0,
        conversion: 0,
        kg_secos: 0,
        alimento: 0
    });
    //Constantes para modales
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [widthModal, setWidthModal] = useState("70%");
    const [tipoModal, setTipoModal] = useState(1);
    const [detalleModal, setDetalleModal] = useState({});
    const [detalleList, setDetalleList] = useState({});
    const [idInfo, setIdInfo] = useState(0);
    const [idEstado, setIdEstado] = useState(3);
    const [valorIni, setValorIni] = useState(0);
    const [valorFin, setValorFin] = useState(0);
    const [detalleField, setDetalleField] = useState([]);
    const [detalleSale, setDetalleSale] = useState([]);
    const [detalleCome, setDetalleCome] = useState([]);
    const [detallePeso, setDetallePeso] = useState([]);
    const [detalleAnimal, setDetalleAnimal] = useState({});
    const [nameList, setNameList] = useState('STOCK GENERAL POR CATEGORIAS');
    const [downExcel, setDownExcel] = useState(false);
    const [isGetting, setIsGetting] = useState(false);
    const [resumenCategoria, setResumenCategoria] = useState([])
    const [resumenCategoriaCampo, setResumenCategoriaCampo] = useState([])
    const [resumenCatCmp, setResumenCatCmp] = useState([])

    const history = useHistory();
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const { Title, Text } = Typography;
    const { Option } = Select;

    //Para uso de excel
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const getDetalle = (record, opcion) => {
        const detalleCat = resumenCategoriaCampo.filter((resumen) => {
            return Number.parseInt(resumen.ctg_id) === Number.parseInt(record.ctg_id);
        });
        setResumenCatCmp(detalleCat);
        setIdInfo(opcion);
        setNameList('STOCK GENERAL ' + record.ctg_nombre + ' POR CAMPOS');
    };

    const backResumen = () => {
        setIdInfo(0);
        setNameList('STOCK GENERAL POR CATEGORIAS');
    };

    const downAnimals = async (curArray) => {
        if (curArray.length > 0) {
            setDownExcel(true);
            setTimeout(function () {
                setDownExcel(false);
            }, 1000);
        } else {
            message.error("NO HAY DATOS PARA EXPORTAR", 1);
        }
    };

    const openModal = (record, tipo, ancho) => {
        setTipoModal(tipo);
        setWidthModal(ancho);
        setDetalleModal(record);
        setDetalleAnimal(record);
        setIsVisibleModal(true);
    };

    const closeModal = () => {
        setIsVisibleModal(false);
    };


    // TRAER MOVIMIENTOS
    const downloadGestion = async () => {
        setIsGetting(true);
        let record = {
            tipoMov: 1
        };
        let val_gestion = await FRM.getGestionGrc(currentUser, record);
        console.log(val_gestion);
        if (val_gestion.type && Number.parseInt(val_gestion.type) > 0) {
            setResumenCategoria(val_gestion.resumenCategoria);
            setResumenCategoriaCampo(val_gestion.resumenCategoriaCampo);
            showNotification(
                "topRight",
                4,
                "INFORMES DE GESTION",
                val_gestion.message
            );
        } else {
            showNotification(
                "topRight",
                2,
                "INOFORMES DE GESTION",
                val_gestion.message
            );
        }
        setIsGetting(false);
    };

    //Columnas de la tabla
    const columns = [
        {
            title: "ID", //10%
            dataIndex: "ctg_id",
            key: "ctg_id",
        },
        {
            title: "CATEGORIA",
            dataIndex: "ctg_nombre",
            key: "ctg_nombre",
        },
        {
            title: "CABEZAS",
            dataIndex: "cabezas",
            key: "cabezas",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="geekblue" style={{ fontSize: 15 }}>{myNumber(
                        1,
                        Number.parseFloat(row.cabezas), 0)}</Tag>
                );
            },
        },
        {
            title: "KG TOTAL",
            dataIndex: "kilos_tot",
            key: "kilos_tot",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="gold" style={{ fontSize: 15 }}>{myNumber(
                        1,
                        Number.parseFloat(row.kilos_tot), 2)}</Tag>
                );
            },
        },
        {
            title: "KG AVG",
            dataIndex: "kilos_avg",
            key: "kilos_avg",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="cyan" style={{ fontSize: 15 }}>{myNumber(
                        1,
                        Number.parseFloat(row.kilos_avg), 2)}</Tag>
                );
            },
        }, {
            title: "+",
            key: "action",
            render: (text, record) => (
                <Button
                    type="primary"
                    size="large"
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={() => getDetalle(record, 1)}
                />
            ),
        },
    ];

    //Columnas de la tabla
    const columnsCat = [
        {
            title: "ID", //10%
            dataIndex: "cmp_id",
            key: "cmp_id",
        },
        {
            title: "CAMPO",
            dataIndex: "cmp_nombre",
            key: "cmp_nombre",
        },
        {
            title: "CABEZAS",
            dataIndex: "cabezas",
            key: "cabezas",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="geekblue" style={{ fontSize: 15 }}>{myNumber(
                        1,
                        Number.parseFloat(row.cabezas), 0)}</Tag>
                );
            },
        },
        {
            title: "KG TOTAL",
            dataIndex: "kilos_tot",
            key: "kilos_tot",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="gold" style={{ fontSize: 15 }}>{myNumber(
                        1,
                        Number.parseFloat(row.kilos_tot), 2)}</Tag>
                );
            },
        },
        {
            title: "KG AVG",
            dataIndex: "kilos_avg",
            key: "kilos_avg",
            align: "right",
            render: (text, row, index) => {
                return (
                    <Tag color="cyan" style={{ fontSize: 15 }}>{myNumber(
                        1,
                        Number.parseFloat(row.kilos_avg), 2)}</Tag>
                );
            },
        }, {
            title: "+",
            key: "action",
            render: (text, record) => (
                <Button
                    type="primary"
                    size="large"
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={() => alert('Por definir')}
                />
            ),
        },
    ];

    return (
        <div className="upload-panel">
            <Form layout="vertical" form={form}>
                <Row gutter={8} style={{ paddingTop: 10, paddingLeft: 15 }}>
                    <Col span={10}>
                        <Form.Item >
                            <Space>
                                <Button
                                    type="primary"
                                    className={curStyle.btnLila}
                                    shape="round"
                                    icon={<ReloadOutlined />}
                                    size="large"
                                    onClick={() => downloadGestion()}
                                    loading={isGetting}
                                >
                                    Generar Informe
                                </Button>
                                <Button
                                    type="primary"
                                    className={curStyle.btnSuccess}
                                    shape="round"
                                    icon={<UndoOutlined />}
                                    size="large"
                                    style={{ display: idInfo === 1 ? 'inline' : 'none' }}
                                    onClick={backResumen}
                                    loading={isGetting}
                                >
                                    Volver Atras
                                </Button>
                                <Button
                                    type="primary"
                                    className={curStyle.btnSky}
                                    shape="round"
                                    icon={<HomeOutlined />}
                                    size="large"
                                    onClick={() => history.push(`/${currentUser.pagina}`)}
                                >
                                    Salir de Informes
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Divider style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }} />
            <div
                style={{
                    height: window.innerHeight,
                    overflow: "scroll",
                    paddingBottom: 250,
                    marginBottom: 250,
                }}
            >
                <div>
                    <Row gutter={8}>
                        <Col span={24}>
                            <Divider
                                style={{ marginTop: 15, paddingTop: 10, marginBottom: 5 }}
                            >
                                {
                                    <Title
                                        level={4}
                                        style={{ color: CST.lilaBdy, textAlign: "center" }}
                                    >
                                        {nameList}
                                    </Title>
                                }
                            </Divider>
                            <Table
                                columns={idInfo === 0 ? columns : columnsCat}
                                dataSource={idInfo === 0 ? resumenCategoria : resumenCatCmp}
                                style={{ padding: 3, marginTop: 5 }}
                                pagination={false}
                                summary={(pageData) => {
                                    let totalCbz = 0;
                                    let totalKgm = 0;

                                    pageData.forEach(({ cabezas, kilos_tot }) => {
                                        totalCbz += Number.parseFloat(cabezas);
                                        totalKgm += Number.parseFloat(kilos_tot);
                                    });

                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell colSpan={2}>
                                                    <Title level={4}>{"TOTALES"}</Title>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <Title level={4} style={{ textAlign: "right" }}>
                                                        {myNumber(1, totalCbz)}
                                                    </Title>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell>
                                                    <Title level={4} style={{ textAlign: "right" }}>
                                                        {myNumber(1, totalKgm)}
                                                    </Title>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell colSpan={1}>
                                                    <Text>...</Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                <ModalApp
                    isVisibleModal={isVisibleModal}
                    closeModal={closeModal}
                    widthModal={tipoModal === 4 ? 1307 : widthModal}
                >
                    {tipoModal === 1 ? (
                        <DetalleAnimal
                            currentUser={currentUser}
                            closeModal={closeModal}
                            detalleAnimal={detalleAnimal}
                            detalleField={detalleField}
                            detalleSale={detalleSale}
                            detalleVacuna={[]}
                            detalleDespa={[]}
                            detalleCome={detalleCome}
                            detallePeso={detallePeso}
                            myNumber={myNumber}
                        />
                    ) : null
                    }
                </ModalApp>
            </div>
            {downExcel ? (
                <ExcelFile
                    filename={
                        "LISTADO DE ANIMALES"
                    }
                    hideElement={true}
                >
                    <ExcelSheet
                        data={movimientos}
                        name={nameList}
                    >
                        <ExcelColumn label="CODIGO" value="anm_codigo" />
                        <ExcelColumn label="ESTADO" value="anm_estado_nm" />
                        <ExcelColumn label="UBICACION" value="anm_ubica" />
                        <ExcelColumn label="RAZA" value="rza_nombre" />
                        <ExcelColumn label="SEXO" value={(col) => col.anm_sexo === 1 ? "MACHO" : "HEMBRA"} />
                        <ExcelColumn label="CATEGORIA" value="ctg_nombre" />
                        <ExcelColumn label="A FEEDLOT" value="anm_fecha_alimento" />
                        <ExcelColumn label="PESO ENTRADA" value="anm_peso_alimento" />
                        <ExcelColumn label="F. PESAJE" value="anm_fecha_peso" />
                        <ExcelColumn label="KG PESAJE" value="anm_peso_neto" />
                        <ExcelColumn label="KG GENERADOS" value="anm_kilos_gen" />
                        <ExcelColumn label="DIAS" value="anm_dias_peso" />
                        <ExcelColumn label="KG DIA" value="anm_kilos_dia" />
                        <ExcelColumn label="KG SECOS" value="anm_kilos_peso" />
                        <ExcelColumn label="CONVERSION" value="anm_conversion" />
                        <ExcelColumn label="ALIMENTO" value="anm_alimento" />
                    </ExcelSheet>
                </ExcelFile>
            ) : null}
        </div>
    );
}
