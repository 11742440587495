import React from "react";
import { Table, Typography, Divider } from "antd";

//Styles
import "./Informes.scss";

export default function DetalleBancos(props) {
  const { currentUser, closeModal, detalleModal, myNumber } = props;

  const { Title } = Typography;

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "Id", //10%
      dataIndex: "cue_id",
      key: "cue_id",
      width: "3%",
    },
    {
      title: "NOMBRE",
      dataIndex: "cue_nombre",
      key: "cue_nombre",
    },
    {
      title: "SALDO",
      dataIndex: "saldo_bnc",
      key: "saldo_bnc",
      align: "right",
      width: "10%",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.saldo_bnc));
      },
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        LISTADO DE CUENTAS Y SALDOS
      </Title>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Table columns={header} dataSource={detalleModal} bordered />
    </div>
  );
}
