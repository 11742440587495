import React, { useState, Suspense, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import {
  Layout,
  Row,
  Col,
  Drawer,
  Popconfirm,
  Select,
  Typography,
  Tabs,
  message,
  Divider,
  Tooltip,
  Button,
  Card,
  Menu,
  Statistic,
} from "antd";

//Creaciones de ID y Fecha
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import Icon from "@mdi/react";
import {
  mdiCalendarText,
  mdiCalendarTextOutline,
  mdiCalendarClock,
  mdiCalendarCheck,
} from "@mdi/js";

//Componentes adicionales
import { TitlePage } from "../../components/TitlePage";
import MainHeader from "../../components/Farm/Header";
import ModalApp from "../../components/Modal";
import MainFooter from "../../components/Footer";
import FrmAgenda from "../../components/Farm/FrmAgenda";
import InformesDay from "../../components/Farm/InformesDay";
import ChangePass from "../../components/ChangePass";
import CjbGastosPen from "../../components/Farm/Procesos/CjbGastosFrm/CjbGastosPen";
import CjbPagosLst from "../../components/Farm/Procesos/CjbGastosFrm/CjbPagosLst";
import CjbTransfer from "../../components/Farm/Procesos/CjbGastosFrm/CjbTransfer";
import CjbPagosxDsmb from "../../components/Farm/Procesos/CjbGastosFrm/CjbPagosxDsmb";

//Funciones y componentes propios
import { showNotification } from "../../utils/ShowNotification";
import TileIcon from "../../components/TileIcon";
import CST from "../../utils/CustomSettings";
import { downloadDataFrm } from "../../utils/ReloadEnv";

//Importaciones para el uso de Redux
import { useDispatch, useSelector } from "react-redux";

//Actualizar entorno
import { updateEnviromentAction } from "../../actions/enviromentActions";
import { updateRolEnviromentAction } from "../../actions/rolEnviromentActions";
import { updateLoguedAction } from "../../actions/isLoguedActions";

//Funciones para administrar el carrito
import { myNumber, nombreMes } from "../../utils/ArrayFunctions";

//LeftMenu UserOptions
import LeftMenu from "../../components/LeftMenu";

//Estilos
import "./frmboard.scss";
import curStyle from "../../css/GlobalCmp.module.css";
import { colorSec, colorPrm } from "../../constants";

const MainCalendar = React.lazy(() => import("../../components/MainCalendar"));
const MainAgenda = React.lazy(() => import("../../components/MainAgenda"));

export default function FarmAuxiliar(props) {
  const { setIsLoading } = props;
  const { Footer } = Layout;
  const [activeKey, setActiveKey] = useState("1");
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [tipoModal, setTipoModal] = useState(1);
  const [widthModal, setWidthModal] = useState("700px");
  const [titleModal, setTitleModal] = useState();
  const [placeDrawer, setPlaceDrawer] = useState(1);
  const [widthDrawer, setWidthDrawer] = useState(700);
  const [isDrawer, setIsDrawer] = useState(false);
  const [drwDetalle, setDrwDetalle] = useState(1);
  const [showBoard, setShowBoard] = useState(Math.random());

  // utilizar use dispatch y te crea una función
  const dispatch = useDispatch();
  //Actuliazar entorno
  const updateEnviroment = (state) => dispatch(updateEnviromentAction(state));
  const updateRolEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));
  const updateLogued = (state) => dispatch(updateLoguedAction(state));

  //useSelector para acceder al store
  //Validacion del logueo de usuario
  const isLogued = useSelector((state) => state.isLogued.isLogued);

  //Datos generales del usuario
  const currentUser = useSelector(
    (state) => state.currentUser.currentUser.user_data
  );

  const userOptions = useSelector(
    (state) => state.currentUser.currentUser.user_options
  );

  const gstOpciones = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.gst_opciones
  );

  const userModules = useSelector(
    (state) => state.currentUser.currentUser.user_modules
  );

  //Monedas
  const curCurrency = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.monedas
  );

  //Resumen de Cabezas y Kilos
  const gstResumen = useSelector(
    (state) => state.rolEnviroment.rolEnviroment.resumen
  );

  //Actualizacion de datos
  const downData = async () => {
    setIsLoading(true);
    let reLoad = await downloadDataFrm(currentUser);
    if (reLoad) {
      updateRolEnviroment(reLoad.rol_entorno);
      updateEnviroment(reLoad.entorno);
      showNotification(
        "topRight",
        4,
        "SINCRONIZAR",
        "DATOS DESCARGADOS CON EXITO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "SINCRONIZAR",
        "ERROR DE CONEXION... INTENTE MAS TARDE"
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      message.success("DATOS ACTUALIZADOS", 1);
    })();
  }, [showBoard]);

  //Defibiciones adicioneles Antd
  const { Option } = Select;
  const { Title } = Typography;
  const { TabPane } = Tabs;

  //Se controlan los cambios de Tab
  const changeTab = (activeKey) => {
    setActiveKey(activeKey);
  };

  // MODALES/////////////////////
  const openModal = (title, tipo, ancho) => {
    setTipoModal(tipo);
    setWidthModal(ancho);
    setIsVisibleModal(true);
    setTitleModal(title);
  };

  const closeModal = () => {
    setIsVisibleModal(false);
  };
  //FIN MODLAES////////////////

  //Abre el Drawer
  const openDrawer = (drwTipo, drwPalce, drwWidth) => {
    setPlaceDrawer(drwPalce);
    setWidthDrawer(drwWidth);
    setDrwDetalle(drwTipo);
    setIsDrawer(!isDrawer);
  };
  //Cierra el Drawer
  const closeDrawer = () => {
    setIsDrawer(false);
  };

  return !isLogued ? (
    <Redirect to="/" />
  ) : (
    <Layout style={{ minHeight: "100vh" }}>
      <TitlePage title="Farm System" />
      <div style={{ height: "50", marginBottom: 10 }}>
        <MainHeader
          setIsLoading={setIsLoading}
          currentUser={currentUser}
          userOptions={userOptions}
          openModal={openModal}
          openDrawer={openDrawer}
          nombreMes={nombreMes}
          updateRolEnviroment={updateRolEnviroment}
          updateEnviroment={updateEnviroment}
        />
      </div>

      <div
        className="site-drawer-render-in-current-wrapper"
        style={{
          maxHeight: window.innerHeight - 55,
          backgroundColor: "transparent",
          marginBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Divider
            style={{
              marginTop: 10,
              marginBottom: 2,
              padding: 0,
            }}
          />
          <Suspense
            fallback={
              <Loader
                type="Watch"
                color={colorPrm}
                height={100}
                width={100}
                timeout={3000} //3 secs
              />
            }
          >
            <div
              className="main-tabs"
              style={{
                height: window.innerHeight - 135,
                width: "100%",
                backgroundColor: "#ffffff",
                marginLeft: 15,
                marginRight: 15,
                overflow: "scroll",
              }}
            >
              <Tabs defaultActiveKey={activeKey} onChange={changeTab} centered>
                <TabPane
                  tab={
                    <span style={{ display: "flex", flexDirection: "row" }}>
                      <Icon
                        path={mdiCalendarText}
                        size={"20"}
                        color={activeKey === "1" ? CST.greenBdy : "#c2c2c2"}
                      />
                      <Title
                        className="h5-tile"
                        level={4}
                        style={{
                          fontSize: 16,
                          marginLeft: 10,
                          marginBottom: 0,
                          color: activeKey === "1" ? CST.greenBdy : "#c2c2c2",
                        }}
                      >
                        {"PAGOS EN " + curCurrency.moneda}
                      </Title>
                    </span>
                  }
                  key="1"
                >
                  <CjbPagosxDsmb setIsLoading={setIsLoading} tipoGastos={2} />
                </TabPane>
                <TabPane
                  tab={
                    <span style={{ display: "flex", flexDirection: "row" }}>
                      <Icon
                        path={mdiCalendarTextOutline}
                        size={"20"}
                        color={activeKey === "2" ? CST.orangeBdy : "#c2c2c2"}
                      />
                      <Title
                        className="h5-tile"
                        level={4}
                        style={{
                          fontSize: 16,
                          marginLeft: 10,
                          marginBottom: 0,
                          color: activeKey === "2" ? CST.orangeBdy : "#c2c2c2",
                        }}
                      >
                        {"PAGOS EN " + curCurrency.moneda_frn}
                      </Title>
                    </span>
                  }
                  key="2"
                >
                  <CjbPagosxDsmb setIsLoading={setIsLoading} tipoGastos={3} />
                </TabPane>
                <TabPane
                  tab={
                    <span style={{ display: "flex", flexDirection: "row" }}>
                      <Icon
                        path={mdiCalendarCheck}
                        size={"20"}
                        color={activeKey === "4" ? CST.lilaBdy : "#c2c2c2"}
                      />
                      <Title
                        className="h5-tile"
                        level={4}
                        style={{
                          fontSize: 16,
                          marginLeft: 10,
                          marginBottom: 0,
                          color: activeKey === "4" ? CST.lilaBdy : "#c2c2c2",
                        }}
                      >
                        PAGOS REALIZADOS
                      </Title>
                    </span>
                  }
                  key="4"
                >
                  <CjbPagosLst setIsLoading={setIsLoading} tipoGastos={5} />
                </TabPane>
                <TabPane
                  tab={
                    <span style={{ display: "flex", flexDirection: "row" }}>
                      <Icon
                        path={mdiCalendarCheck}
                        size={"20"}
                        color={activeKey === "5" ? CST.blueBdy : "#c2c2c2"}
                      />
                      <Title
                        className="h5-tile"
                        level={4}
                        style={{
                          fontSize: 16,
                          marginLeft: 10,
                          marginBottom: 0,
                          color: activeKey === "5" ? CST.blueBdy : "#c2c2c2",
                        }}
                      >
                        TRANSFERENCIAS
                      </Title>
                    </span>
                  }
                  key="5"
                >
                  <CjbTransfer setIsLoading={setIsLoading} tipoGastos={6} />
                </TabPane>
              </Tabs>
            </div>
          </Suspense>
          <Drawer
            width={widthDrawer}
            placement={placeDrawer === 1 ? "left" : "right"}
            closable={false}
            visible={isDrawer}
            onClose={closeDrawer}
            getContainer={false}
            style={{ position: "absolute" }}
          >
            {drwDetalle === 1 ? (
              <LeftMenu
                userModules={userModules}
                userOptions={userOptions}
                gstOpciones={gstOpciones}
                currentUser={currentUser}
                closeDrawer={closeDrawer}
              />
            ) : null}
          </Drawer>
        </Row>
      </div>
      <ModalApp
        isVisibleModal={isVisibleModal}
        closeModal={closeModal}
        widthModal={widthModal}
      >
        <ChangePass
          closeModal={closeModal}
          currentUser={currentUser}
          varEffec={Math.random()}
        />
      </ModalApp>
      <Footer
        style={{
          textAlign: "center",
          bottom: 0,
          maxHeight: 50,
          paddingBottom: 15,
          paddingTop: 15,
          backgroundColor: "#ffffff",
        }}
      >
        <MainFooter setIsLoading={setIsLoading} curFecha={new Date()} />
      </Footer>
    </Layout>
  );
}
