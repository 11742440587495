import React, { useState } from "react";
import { Table, Typography, Tabs, Divider, Button, message } from "antd";

import ReactExport from "react-export-excel";

import { SearchOutlined } from "@ant-design/icons";

//Styles
import "./Informes.scss";
import curStyle from "../../../../css/GlobalCmp.module.css";

export default function DetalleGastos(props) {
  const {
    currentUser,
    closeModal,
    detalleModal,
    detalleFrn,
    myNumber,
    listadoDet,
  } = props;
  const [downDeta, setDownDeta] = useState(false);
  const [listaDet, setListaDet] = useState([]);
  const [nameDet, setNameDet] = useState([]);

  //Para uso de excel
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const { Title } = Typography;
  const { TabPane } = Tabs;

  const downDetalle = (record, arrGasto) => {
    const gastosFlt = arrGasto.filter((gasto) => {
      return (
        Number.parseFloat(gasto.meg_moneda) ===
        Number.parseFloat(record.meg_moneda) &&
        Number.parseFloat(gasto.cnc_clase) ===
        Number.parseFloat(record.cnc_clase)
      );
    });

    if (gastosFlt.length > 0) {
      setNameDet(record.cls_nombre);
      setListaDet(gastosFlt);
      setDownDeta(true);
      setTimeout(function () {
        setDownDeta(false);
      }, 2000);
    } else {
      message.error("NO HAY DATOS PARA EXPORTAR", 1);
    }
  };

  // Encabezados de la tabla de Excel Upload
  const header = [
    {
      title: "Id", //10%
      dataIndex: "cnc_clase",
      key: "cnc_clase",
      width: "3%",
    },
    {
      title: "CLASE",
      dataIndex: "cls_nombre",
      key: "cls_nombre",
    },
    {
      title: "TOTAL",
      dataIndex: "total_gto",
      key: "total_gto",
      align: "right",
      width: "10%",
      render: (text, row, index) => {
        return myNumber(1, Number.parseFloat(row.total_gto));
      },
    },
    {
      title: "+",
      key: "action",
      width: 50,
      render: (text, record) => (
        <Button
          type="primary"
          className={curStyle.btnSky}
          size="medium"
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => downDetalle(record, listadoDet)}
        />
      ),
    },
  ];

  return (
    <div className="upload-panel">
      <Title level={3} style={{ color: "#6A3E98", textAlign: "center" }}>
        GASTOS DEL PERIODO
      </Title>
      <Divider style={{ marginTop: 5, paddingTop: 5, marginBottom: 5 }} />
      <Tabs defaultActiveKey="1">
        <TabPane tab="GASTOS EN DOLARES" key="1">
          <Table
            columns={header}
            dataSource={detalleModal}
            bordered
          //scroll={{ x: "calc(1000px + 50%)", y: 640 }}
          />
        </TabPane>
        <TabPane tab="GASTOS EN PESOS" key="2">
          <Table
            columns={header}
            dataSource={detalleFrn}
            bordered
          //scroll={{ x: "calc(1000px + 50%)", y: 640 }}
          />
        </TabPane>
      </Tabs>
      {downDeta ? (
        <ExcelFile filename={nameDet} hideElement={true}>
          <ExcelSheet data={listaDet} name={"DETALLE GASTOS"}>
            <ExcelColumn label="FECHA" value="meg_fecha" />
            <ExcelColumn label="BENEFICIARIO" value="trc_nombre" />
            <ExcelColumn label="CONCEPTO" value="cnc_nombre" />
            <ExcelColumn label="MONEDA" value="mnd_name" />
            <ExcelColumn label="IMPORTE" value="meg_importe" />
          </ExcelSheet>
        </ExcelFile>
      ) : null}
    </div>
  );
}
