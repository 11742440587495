import React, { useState, useEffect } from "react";
import {
  Form,
  Typography,
  Input,
  Space,
  Button,
  Radio,
  Select,
  Switch,
} from "antd";

import { SaveOutlined, UndoOutlined } from "@ant-design/icons";

//Importaciones para el uso de Redux
import { useDispatch } from "react-redux";
import { updateRolEnviromentAction } from "../../../actions/rolEnviromentActions";
//Styles
import curStyle from "../../../css/GlobalCmp.module.css";

//Importaciones Propias
import CST from "../../../utils/CustomSettings";
import { showNotification } from "../../../utils/ShowNotification";
import FRM from "../../../utils/FrmService";

export default function CjbCuentasForm(props) {
  const { record, clases_cnc, closeModal, currentUser, varEffec } = props;
  const [form] = Form.useForm();
  const [curId, setCurId] = useState(record.cnc_id);
  const [loadingButton, setLoadingButton] = useState(false);
  const [cncTipo, setCncTipo] = useState(record.cnc_tipo);
  const [cncClase, setCncClase] = useState(record.cnc_clase);
  const [afectaUtilidad, setAfectaUtilidad] = useState(false);
  const [cncDiv, setCncDiv] = useState(record.cnc_divide === 1 ? true : false);

  useEffect(() => {
    setCurId(record.cnc_id);
  }, [varEffec]);

  //Ejecutar dispatch tras el logueo
  const dispatch = useDispatch();
  const updateEnviroment = (state) =>
    dispatch(updateRolEnviromentAction(state));

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const { Title } = Typography;
  const { Option } = Select;

  const guardarCambios = async (values) => {
    console.log(values);
    setLoadingButton(true);
    let val_record = await FRM.sendCjbConceptos(
      currentUser,
      curId,
      cncClase,
      afectaUtilidad,
      "CjbConceptos",
      values,
      cncDiv
    );
    console.log(val_record);
    if (Number.parseInt(val_record.type) > 0) {
      updateEnviroment(val_record.datos.rol_entorno);
      showNotification(
        "topRight",
        4,
        "GESTION DE CONCEPTOS DE INGRESO Y GASTO",
        "REGISTRO EXITOSO"
      );
    } else {
      showNotification(
        "topRight",
        2,
        "GESTION DE CONCEPTOS DE INGRESO Y GASTO",
        val_record.message
      );
    }
    setLoadingButton(false);
    closeModal();
  };

  //Renderizacion del componente
  return (
    <Form
      {...layout}
      form={form}
      name="cjbconceptos"
      onFinish={guardarCambios}
      initialValues={{
        cnc_nombre: record.cnc_id > 0 ? record.cnc_nombre : "",
        cnc_tipo: record.cnc_id > 0 ? record.cnc_tipo : "",
        cnc_clase: record.cnc_id > 0 ? record.cnc_clase : "",
      }}
      scrollToFirstError
    >
      <Form.Item>
        <Title
          level={4}
          style={{
            padding: 2,
            margin: 2,
            fontWeight: 600,
            textAlign: "left",
            color: CST.colorSec,
          }}
        >
          {record.cnc_id > 0
            ? "ACTUALIZAR CONCEPTO DE INGRESO Y GASTO"
            : "AGREGAR CONCEPTO DE INGRESO Y GASTO"}
        </Title>
      </Form.Item>
      <Form.Item
        name="cnc_nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "El nombre es obligatorio",
          },
        ]}
      >
        <Input
          style={{ textTransform: "uppercase" }}
          value={record.cnc_id > 0 ? record.cnc_nombre : ""}
        />
      </Form.Item>
      <Form.Item
        name="cnc_tipo"
        label="Tipo"
        rules={[
          {
            required: true,
            message: "El tipo es obligatorio",
          },
        ]}
      >
        <Space direction="horizontal">
          <Radio.Group
            onChange={(option) => setCncTipo(option.target.value)}
            value={cncTipo}
            style={{ marginRight: 70 }}
          >
            <Radio value={1}>Concepto de Ingresos</Radio>
            <Radio value={2}>Concepto de Gastos</Radio>
          </Radio.Group>
        </Space>
      </Form.Item>
      <Form.Item label="Adicional">
        <Space direction="horizontal">
          Afecta Utilidad:
          <Switch
            checked={afectaUtilidad}
            onChange={(val) => setAfectaUtilidad(val)}
            checkedChildren="SI"
            unCheckedChildren="NO"
          />
          El Gasto es Divisible:
          <Switch
            checked={cncDiv}
            onChange={(val) => setCncDiv(val)}
            checkedChildren="SI"
            unCheckedChildren="NO"
          />
        </Space>
      </Form.Item>
      <Form.Item
        name="cnc_clase"
        label="Clase"
        wrapperCol={{ span: 100 }}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Select
          showSearch
          showArrow
          style={{ width: "100%" }}
          defaultValue={cncClase}
          onChange={(val) => setCncClase(val)}
          placeholder="Clases"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {clases_cnc.map((clases) => (
            <Option key={clases.cls_id} value={clases.cls_id}>
              {clases.cls_nombre}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className={curStyle.btnOrange}
            loading={loadingButton}
          >
            <SaveOutlined /> Guardar
          </Button>
          <Button
            type="primary"
            className={curStyle.btnInverse}
            onClick={() => closeModal()}
          >
            <UndoOutlined /> Cancelar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
